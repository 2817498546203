import React, { useCallback } from 'react';
import { Box, Tooltip, Typography } from '@mui/material';
import { CheckboxIconChecked, CheckboxIconDefault } from '@svgAsComponents';

import { ChecklistItemComponentProps } from './interface';
import { colors } from '@theme';
import { useDayJsFormatter } from '@hooks';

function ChecklistItem({
  id,
  label,
  checked,
  checkedBy,
  onClick,
  disabled,
  tooltipText,
  showCheckedBy,
  source,
}: ChecklistItemComponentProps) {
  const { dateFormatter } = useDayJsFormatter();
  const handleClick = useCallback(() => {
    if (disabled) return;
    onClick({ id, label, checked: !checked });
  }, [id, label, checked, disabled]);

  return (
    <Box
      onClick={handleClick}
      sx={{ cursor: 'pointer', width: '100%', '&:not(:last-child)': { mb: '.3rem' } }}
      data-cy={`${source}__checklist__item__checkbox`}
    >
      <Tooltip title={disabled && tooltipText ? tooltipText : ''}>
        <Box
          sx={{
            display: 'flex',
            flexFlow: 'row nowrap',
            alignItems: 'center',
            opacity: disabled ? 0.5 : 1,
          }}
        >
          <Box sx={{ height: 24 }} data-cy={`${source}__checklist__item__checked_${checked}__icon`}>
            {checked ? <CheckboxIconChecked /> : <CheckboxIconDefault />}
          </Box>
          <Typography
            variant="body3"
            sx={{ ml: 0.5 }}
            data-cy={`${source}__checklist__item__label`}
          >
            {label}
          </Typography>
        </Box>
      </Tooltip>
      {showCheckedBy && checked && checkedBy?.name && (
        <Typography
          variant="label"
          sx={{ ml: '28px', color: colors.text.medium, lineHeight: '16px' }}
          data-cy={`${source}__checklist__item__checked_by`}
        >
          {`${checkedBy.name}, ${dateFormatter({ date: checkedBy.updatedAt, withTime: true })}`}
        </Typography>
      )}
    </Box>
  );
}

export default ChecklistItem;
