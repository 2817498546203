import React, { FC } from 'react';
import {
  Checklist,
  LoadingSkeleton,
  ServiceMessage,
  DrawRequestOperationButtons,
} from '@components';
import { useRequestChecklist } from './controller';
import { HookState, IProject } from '@interfaces';
import { Typography, Box, Divider } from '@mui/material';

interface IChecklistRightDrawer {
  projectId: string;
  requestId: string;
  project: Pick<IProject, 'id' | 'is_budget_locked' | 'is_underwriting_enabled'>;
}

const RequestChecklist: FC<IChecklistRightDrawer> = ({ projectId, requestId, project }) => {
  const { items, state, handleItemClick, showActionButtons } = useRequestChecklist(
    projectId,
    requestId,
  );

  switch (state) {
    case HookState.FETCHING: {
      return <LoadingSkeleton type="overviewBlock" />;
    }

    case HookState.SUCCESS: {
      return (
        <>
          <Box sx={{ px: 2 }}>
            {!items.length ? (
              <Typography variant="h4">No policy items.</Typography>
            ) : (
              <Checklist items={items} onItemClick={handleItemClick} isEditable={false} />
            )}
          </Box>

          {showActionButtons && (
            <>
              <Divider sx={{ my: 2 }} />
              <DrawRequestOperationButtons
                checklistItems={items.filter((item) => !item.checked)}
                projectId={projectId}
                drawRequestId={requestId}
                project={project}
              />
            </>
          )}
        </>
      );
    }

    case HookState.ERROR: {
      return <ServiceMessage text="Policies" />;
    }

    default:
      return null;
  }
};

export default RequestChecklist;
