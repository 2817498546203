import { serviceOrdersListQueryFields } from '@constants';

export const serviceAgencies = () => `projects/service_orders/agencies/?limit=100`;

export const projectServiceOrder = ({
  projectId = 'project_pk',
  serviceOrderId = 'service_order_pk',
  restQlquery = '{}',
}) => `projects/${projectId}/service_orders/${serviceOrderId}/?query=${restQlquery}`;

export const projectServiceOrdersList = (
  projectId = 'project_pk',
  restQlquery = `{${serviceOrdersListQueryFields.join(',')}}`,
  stringQueryParams = '',
) => `projects/${projectId}/service_orders/?query=${restQlquery}&${stringQueryParams}`;

export const drawRequestServiceOrdersList = ({
  projectId = 'project_pk',
  drawRequestId = 'draw_request_pk',
  restQlquery = `{${serviceOrdersListQueryFields.join(',')}}`,
  stringQueryParams = '',
}) =>
  `projects/${projectId}/draw_requests/${drawRequestId}/service_orders/?limit=100&query=${restQlquery}&${stringQueryParams}`;

export const scheduleServiceOrder = ({
  projectId = 'project_pk',
  serviceOrderId = 'service_order_pk',
}) => `projects/${projectId}/service_orders/${serviceOrderId}/schedule/`;

export const cancelProjectServiceOrder = ({
  projectId = 'project_pk',
  serviceOrderId = 'service_order_pk',
}) => `projects/${projectId}/service_orders/${serviceOrderId}/cancel/`;

export const serviceOrdersList = ({ stringQueryParams }) =>
  `projects/service_orders/?${stringQueryParams}`;

export const serviceOrderDocumentsTypes = () => `documents/service_order/document_types/`;
