import React, { createContext, useEffect, useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { getUserMe } from '@globalService';
import { userIsAllowedToLogin, getUserForStorage } from '@utils';
import { EngineContext, EngineProvider } from './engine';
import { CustomQueryClient, useGraphQuery } from './dataLayer';
import { PermissionsContext, PermissionsProvider } from './permissions';
import { SettingsContext, SettingsProvider } from './settings';
import { SSOContext, SSOProvider } from './ssoContext';
import { useLaunchDarklyFlags, LaunchdarklyProvider } from './launchDarkly';
import { TransloaditContext, TransloaditProvider } from './transloadit';
import { NotistackProvider } from './notistackProvider';
import { QueryNamesEnums } from '@interfaces';

const initValue = {
  user: null,
  setUser: (_user) => {},
  accessToken: null,
  setAccessToken: (_accessToken) => {},
};

const AuthContext = createContext(initValue);

const AuthProvider = ({ children }) => {
  const localUser = JSON.parse(localStorage.getItem('user@store') || 'null');

  if (localUser) localUser.isAllowedToLogin = userIsAllowedToLogin({ user: localUser });
  const [user, setUser] = useState(localUser);

  const userQuery = useQuery([QueryNamesEnums.GET_USER], getUserMe.bind(this), {
    enabled: Boolean(localUser),
    refetchOnWindowFocus: false,
    staleTime: Infinity,
  });

  // even if we have user in LC we need to refetch it in background mode
  // to update data and S3 link to lender company logo because it has short life term
  useEffect(() => {
    if (localUser && userQuery.data) {
      const userQueryData = getUserForStorage(userQuery.data);
      setUser(userQueryData);
    }
  }, [userQuery]);

  const [accessToken, setAccessToken] = useState(false);

  const authProviderValue = useMemo(
    () => ({ user, setUser, accessToken, setAccessToken }),
    [user, setUser, accessToken, setAccessToken],
  );

  return <AuthContext.Provider value={authProviderValue}>{children}</AuthContext.Provider>;
};

// Okta context

const initOktaData = {
  issuer: '',
  clientId: '',
  redirectUri: '',
  setOktaAuth: (_oktaAuth) => {},
};

const OktaContext = createContext(initOktaData);

const OktaProvider = ({ children }) => {
  const localOktaData = JSON.parse(sessionStorage.getItem('oktaData') || 'null');
  const [oktaData, setOktaData] = useState(localOktaData);
  const clearOktaData = () => setOktaData(null);

  return (
    <OktaContext.Provider value={{ oktaData, setOktaData, clearOktaData }}>
      {children}
    </OktaContext.Provider>
  );
};

// appUpdate context
const initUpdateData = {
  updateAvailable: false,
  setUpdateAvailable: (_value) => {},
};

const AppUpdateContext = createContext(initUpdateData);

const AppUpdateProvider = ({ children }) => {
  const [updateAvailable, setUpdateAvailable] = useState(false);

  return (
    <AppUpdateContext.Provider value={{ updateAvailable, setUpdateAvailable }}>
      {children}
    </AppUpdateContext.Provider>
  );
};

export {
  TransloaditContext,
  TransloaditProvider,
  AuthContext,
  AuthProvider,
  SettingsContext,
  SettingsProvider,
  OktaContext,
  OktaProvider,
  PermissionsProvider,
  PermissionsContext,
  EngineContext,
  EngineProvider,
  useLaunchDarklyFlags,
  LaunchdarklyProvider,
  AppUpdateProvider,
  AppUpdateContext,
  NotistackProvider,
  SSOContext,
  SSOProvider,
  CustomQueryClient,
  useGraphQuery,
};
