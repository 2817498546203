import { useState, useEffect, useMemo, useContext, useCallback } from 'react';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';
import sortBy from 'lodash/sortBy';
import { useSafeSnackbar } from '@hooks';

import {
  HookState,
  QueryNamesEnums,
  ITeam,
  UpdateCompanySettingsPayload,
  ICompanySettings,
  PermissionNamesEnums,
  IRoleNew,
  CrudActionEnum,
} from '@interfaces';
import { FilteredDataByGraph, getHookState, isAllowed, Override } from '@utils';
import {
  getCompanyTeams,
  updateCompanySettings,
  getCompanySettings,
  patchCompanyRole,
  postCompanyRole,
  deleteCompanyRole,
} from '@globalService';
import { PermissionsContext, useGraphQuery } from '@context';

export type ControllerInterface = Override<
  ReturnType<typeof useCompanyWorkflow>,
  {
    state: HookState;
    reviewsQuantity: number;
    fundingApproveRequired: boolean;
    isSettingsMutating: boolean;
    handleQuantityClick: (item: number) => () => void;
    requeredReviewsValuesArray: number[];
    handleFundingReviewClick: (value: boolean) => void;
    hasCompanyTeamsEditPermission: boolean;
    crud: (data: Partial<IRoleNew>, action: CrudActionEnum) => void;
    isUpdating: boolean;
    editAvailable: boolean;
  }
>;

export const useCompanyWorkflow = () => {
  const { companyId } = useParams();
  const { enqueueSnackbar } = useSafeSnackbar();
  const queryClient = useQueryClient();
  const { permissions } = useContext(PermissionsContext);

  const [reviewsQuantity, setReviewsQuantity] = useState<number | null>(null);
  const [fundingApproveRequired, setFundingApproveRequired] = useState<boolean | null>(null);
  const companyTeamsQuery = useQuery<{ results: ITeam[] }, Error>(
    [QueryNamesEnums.GET_COMPANY_TEAMS, { companyId }],
    getCompanyTeams.bind(this, companyId),
  );

  const companyRoles = useGraphQuery({
    type: QueryNamesEnums.GET_COMPANY_ROLES,
    keys: ['company_role', 'id', 'review_level', 'permissions', 'display_name'],
    args: {},
  });

  const roles = useMemo<
    FilteredDataByGraph<
      IRoleNew,
      'permissions' | 'company_role' | 'id' | 'review_level' | 'display_name'
    >[]
  >(() => sortBy(companyRoles?.data?.results || [], 'review_level'), [companyRoles.data]);

  const companySettingsQuery = useQuery<ICompanySettings, Error>(
    [QueryNamesEnums.GET_COMPANY_SETTINGS, { companyId }],
    getCompanySettings.bind(this, companyId),
  );

  const onSuccess = useCallback(() => {
    queryClient.invalidateQueries([QueryNamesEnums.GET_COMPANY_ROLES]);
    queryClient.invalidateQueries([QueryNamesEnums.GET_COMPANY_TEAMS]);
    queryClient.invalidateQueries([QueryNamesEnums.GET_ROLES_WITH_PERMISSIONS]);
  }, [queryClient]);

  useEffect(() => {
    const { data } = companySettingsQuery;
    if (!data) return;
    setReviewsQuantity(data.reviews_required_quantity || null);
    setFundingApproveRequired(data.funding_approve_required || null);
  }, [companySettingsQuery.data]);

  const updateRoleMutation = useMutation<Response, Error, Partial<IRoleNew>>(patchCompanyRole, {
    onSuccess,
    onError: (error) => {
      enqueueSnackbar(error.message, { variant: 'error' });
    },
    onSettled: () => {},
  });

  const addRoleMutation = useMutation<Response, Error, Partial<IRoleNew>>(postCompanyRole, {
    onSuccess,
    onError: (error) => {
      enqueueSnackbar(error.message, { variant: 'error' });
    },
    onSettled: () => {},
  });

  const deleteRoleMutation = useMutation<Response, Error, string>(deleteCompanyRole, {
    onSuccess,
  });

  const crud = useCallback(
    (data: Partial<IRoleNew>, action: CrudActionEnum) => {
      switch (action) {
        case CrudActionEnum.CREATE:
          addRoleMutation.mutateAsync(data);
          break;
        case CrudActionEnum.UPDATE:
          updateRoleMutation.mutateAsync(data);
          break;
        case CrudActionEnum.DELETE:
          deleteRoleMutation.mutateAsync(data.id);
          break;
      }
    },
    [addRoleMutation, updateRoleMutation, deleteRoleMutation],
  );

  const updateCompanySettingsMutation = useMutation<Response, Error, UpdateCompanySettingsPayload>(
    updateCompanySettings,
    {
      onSuccess: () => {
        queryClient.invalidateQueries([QueryNamesEnums.GET_COMPANY_SETTINGS, { companyId }]);
      },
      onError: (error) => {
        enqueueSnackbar(error.message, { variant: 'error' });
      },
    },
  );

  const handleQuantityClick = (item: number) => () => {
    if (!updateCompanySettingsMutation.isLoading) {
      updateCompanySettingsMutation.mutateAsync({
        companyId,
        data: {
          reviews_required_quantity: item,
        },
      });
      setReviewsQuantity(item);
    }
  };

  const handleFundingReviewClick = (value: boolean) => {
    if (!updateCompanySettingsMutation.isLoading) {
      updateCompanySettingsMutation.mutateAsync({
        companyId,
        data: {
          funding_approve_required: value,
        },
      });
      setFundingApproveRequired(value);
    }
  };

  const requeredReviewsValuesArray = useMemo(() => {
    if (!companyTeamsQuery.data) return [];
    const maxLength =
      companyTeamsQuery.data?.results.length < 5 ? companyTeamsQuery.data?.results.length : 5;
    return Array.from({ length: maxLength }, (_, index) => index + 1);
  }, [companyTeamsQuery.data]);

  const hasCompanyTeamsEditPermission = useMemo(
    () => isAllowed(PermissionNamesEnums.COMPANY_TEAMS_EDIT, permissions),
    [permissions],
  );

  return {
    state: getHookState(companyTeamsQuery),
    rolesInApprovalFlow: roles,
    reviewsQuantity,
    fundingApproveRequired,
    isSettingsMutating: updateCompanySettingsMutation.isLoading,
    handleQuantityClick,
    requeredReviewsValuesArray,
    handleFundingReviewClick,
    hasCompanyTeamsEditPermission,
    crud,
    editAvailable: isAllowed(PermissionNamesEnums.COMPANY_TEAMS_EDIT, permissions),
    isUpdating:
      updateRoleMutation.isLoading || addRoleMutation.isLoading || deleteRoleMutation.isLoading,
  };
};
