import React, { FC, useEffect, useMemo } from 'react';
import { useQuery } from 'react-query';

import {
  CustomAutocomplete,
  DateTimePickerWithTimezone,
  CustomTextField,
  PercentsInput,
  CustomSelect,
} from '@components';
import { useDateFieldModel, useDropdownFieldModel, useStringFieldModel } from '@models';
import { isValidDate, isPercentAllowed, getRoundNumber, getReasonsList } from '@utils';
import { EnumTypeForList, IWorkflowReason, QueryNamesEnums, WorkflowReasonEnum } from '@interfaces';
import { SummaryEditableDataEnum } from '../interface';
import { useDayJsFormatter } from '@hooks';
import { getWorkflowReasons } from '@globalService';

export const TextInput: FC<{
  type: SummaryEditableDataEnum;
  initValue: string;
  onValidChange: (isValid: boolean) => void;
  onChange: (value: string) => void;
  source?: string;
}> = ({ type, initValue, onValidChange, onChange, source }) => {
  const field = useStringFieldModel({
    initValue: initValue || '',
  });

  useEffect(() => {
    onValidChange(field.isValid);
    onChange(field.value);
  }, [field.value, field.isValid]);

  return (
    <CustomTextField
      field={field}
      multiline
      rows={4}
      inputProps={{
        'data-cy': `${source}_change_${type}_input`,
      }}
    />
  );
};

export const NumberInput: FC<{
  type: SummaryEditableDataEnum;
  initValue: string | number;
  onValidChange: (isValid: boolean) => void;
  onChange: (value: string | number) => void;
  source?: string;
}> = ({ type, initValue, onValidChange, onChange, source }) => {
  const field = useStringFieldModel({
    initValue: initValue.toString() || '1',
    validationRule: (value) => Number.isInteger(+value),
    validateOnChange: true,
  });

  useEffect(() => {
    onValidChange(field.isValid);
    onChange(field.value);
  }, [field.value, field.isValid]);

  return (
    <CustomTextField
      field={field}
      inputProps={{
        'data-cy': `${source}_change_${type}_input`,
      }}
    />
  );
};

export const RateInput: FC<{
  type: SummaryEditableDataEnum;
  initValue: string | number;
  onValidChange: (isValid: boolean) => void;
  onChange: (value: string | number) => void;
  source?: string;
}> = ({ type, initValue, onValidChange, onChange, source }) => {
  const field = useStringFieldModel({
    initValue: getRoundNumber(+initValue || 0, 2).toString() || '',
    validationRule: (value) => Number.isFinite(+value) && +value >= 0 && +value <= 100,
    validateOnChange: true,
  });

  useEffect(() => {
    onValidChange(field.isValid);
    onChange(field.value);
  }, [field.value, field.isValid]);

  return (
    <CustomTextField
      field={field}
      inputProps={{
        isAllowed: isPercentAllowed,
        decimalScale: 2,
        fixedDecimalScale: true,
        'data-cy': `${source}_change_${type}_input`,
      }}
      InputProps={{
        inputComponent: PercentsInput as any,
      }}
    />
  );
};

export const DateInput: FC<{
  value: Date;
  type: SummaryEditableDataEnum;
  title: string;
  onValidChange: (isValid: boolean) => void;
  onChange: (value: Date) => void;
  source?: string;
  validationRule?: (value: Date) => { value: boolean; reason: string };
  minDate?: Date;
  maxDate?: Date;
  addTimestamp?: boolean;
}> = ({
  value,
  type,
  title,
  onValidChange,
  onChange,
  source,
  validationRule,
  minDate,
  maxDate,
  addTimestamp = false,
}) => {
  const { getInitialValue } = useDayJsFormatter();

  const field = useDateFieldModel({
    initValue: getInitialValue({ date: value || null }),
    validationRule: (value) => {
      if (validationRule) return validationRule(value);
      return isValidDate(value);
    },
  });

  useEffect(() => {
    onValidChange(field.isValid);
    if (field.value && field.isValid) {
      onChange(field.value);
    }
  }, [field.value, field.isValid]);

  return (
    <DateTimePickerWithTimezone
      field={field}
      label={title}
      maxDate={maxDate}
      minDate={minDate}
      addTimestamp={addTimestamp}
      inputProps={{
        'data-cy': `${source}_change_${type}_date_picker`,
      }}
    />
  );
};

export const StatusInput: FC<{
  value: EnumTypeForList;
  type: SummaryEditableDataEnum;
  title: string;
  options: EnumTypeForList[];
  onValidChange: (isValid: boolean) => void;
  onChange: (value: EnumTypeForList) => void;
  source?: string;
}> = ({ value, type, title, options, onValidChange, onChange, source }) => {
  const field = useDropdownFieldModel({
    initValue: value || null,
  });

  useEffect(() => {
    onValidChange(field.isValid);
    onChange(field.value);
  }, [field.value, field.isValid]);

  return (
    <CustomAutocomplete
      field={field}
      options={options}
      label={title}
      handleTextFieldChange={(value: string) => {
        if (!value) {
          field.setValue(null);
        }
      }}
      inputProps={{
        'data-cy': `${source}_change_${type}_select`,
      }}
    />
  );
};

export const ReasonInput: FC<{
  reasons: string[];
  type: SummaryEditableDataEnum;
  onChange: (value: string[]) => void;
  source?: string;
  required?: boolean;
}> = ({ reasons, type, onChange, source, required }) => {
  const reasonsQuery = useQuery<IWorkflowReason[], Error>(
    [QueryNamesEnums.GET_WORKFLOW_REASONS],
    getWorkflowReasons.bind(this),
  );

  const completionDateReasonsList = useMemo(
    () => getReasonsList(reasonsQuery.data, WorkflowReasonEnum.COMPLETION_DATE_CHANGED),
    [reasonsQuery.data],
  );

  return (
    <CustomSelect
      label="Reason for update"
      optionsList={completionDateReasonsList}
      selectedOptions={reasons}
      handleSelectChange={(e) => onChange(e.target.value as string[])}
      required={required}
      error={required && !reasons.length}
      dataTestName={`${source}_change_${type}_reason_select`}
    />
  );
};
