import { useCallback } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import {
  IDrawRequest,
  IInspection,
  IItem,
  IMilestone,
  IProjectDocument,
  IServiceOrder,
  QueryNamesEnums,
} from '@interfaces';

import { replaceItemInPaginatedResponse, replaceObjectProperties } from '@utils';
import {
  getDrawRequest,
  getDrawRequestMilestone,
  getInspectionMilestone,
  getProjectDocumentById,
  getProjectInspectionById,
  getProjectMilestone,
  getServiceOrderById,
} from '@globalService';
import { useGraphQuery, useLaunchDarklyFlags } from '@context';

export interface HookInterface {
  updateCommentsPreviewInfo: CallableFunction;
}
export interface ComponentProps {
  projectId?: string;
  inspectionId?: string;
  serviceOrderId?: string;
  milestoneId?: string;
  drawRequestId?: string;
  documentId?: string;
}

export const useCommentsAndDocumentsPreview = ({
  projectId,
  inspectionId,
  serviceOrderId,
  milestoneId,
  drawRequestId,
  documentId,
}: ComponentProps): HookInterface => {
  const query = '{id,comments_preview,documents_preview}';
  const flags = useLaunchDarklyFlags();

  const project = useGraphQuery({
    type: QueryNamesEnums.GET_PROJECT,
    keys: ['comments_preview'],
    args: { project_id: projectId },
    options: { skip: true },
  });

  const queryClient = useQueryClient();
  const milestoneQuery = useQuery<IMilestone, Error>(
    [QueryNamesEnums.GET_PROJECT_MILESTONE, { projectId, milestoneId, query }],
    getProjectMilestone.bind(this, { projectId, milestoneId, query }),
    { enabled: false },
  );

  const drawRequestMilestoneQuery = useQuery<IMilestone, Error>(
    [QueryNamesEnums.GET_DRAW_REQUEST_MILESTONE, { projectId, drawRequestId, milestoneId, query }],
    getDrawRequestMilestone.bind(this, {
      projectId,
      drawRequestId,
      milestoneId,
      restQlParams: query,
    }),
    { enabled: false },
  );

  const inspectionMilestoneQuery = useQuery<IMilestone, Error>(
    [QueryNamesEnums.GET_INSPECTION_MILESTONE, { projectId, inspectionId, milestoneId, query }],
    getInspectionMilestone.bind(this, { projectId, inspectionId, milestoneId, query }),
    { enabled: false },
  );

  const drawRequestCommentsQuery = useQuery<IDrawRequest, Error>(
    [QueryNamesEnums.GET_DRAW_REQUEST, { projectId, drawRequestId, query }],
    getDrawRequest.bind(this, {
      projectId,
      drawRequestId,
      query,
    }),
    { enabled: false },
  );

  const inspectionQuery = useQuery<IInspection, Error>(
    [QueryNamesEnums.GET_PROJECT_INSPECTION_BY_ID, { projectId, inspectionId, query }],
    getProjectInspectionById.bind(this, { projectId, inspectionId, query }),
    { enabled: false },
  );

  const serviceOrderQuery = useQuery<IServiceOrder, Error>(
    [QueryNamesEnums.GET_PROJECT_SERVICE_ORDER_BY_ID, { projectId, serviceOrderId, query }],
    getServiceOrderById.bind(this, {
      projectId,
      serviceOrderId,
      restQlquery: query,
    }),
    { enabled: false },
  );

  const documentQuery = useQuery<IProjectDocument, Error>(
    [QueryNamesEnums.GET_PROJECT_DOCUMENT_BY_ID, { projectId, documentId, query }],
    getProjectDocumentById.bind(this, { projectId, documentId, query }),
    { enabled: false },
  );

  const updateMilestone = () => {
    milestoneQuery.refetch().then((result) => {
      queryClient.setQueriesData<{ results: IMilestone[] }>(
        {
          queryKey: [QueryNamesEnums.GET_PROJECT_MILESTONES, { projectId }],
          exact: false,
        },
        (old) => replaceItemInPaginatedResponse({ old, updatedItem: result?.data }),
      );
      if (drawRequestId) {
        queryClient.setQueriesData<{ results: IMilestone[] }>(
          {
            queryKey: [QueryNamesEnums.GET_DRAW_REQUEST_MILESTONES, { projectId, drawRequestId }],
            exact: false,
          },
          (old) => replaceItemInPaginatedResponse({ old, updatedItem: result?.data }),
        );
      }
      if (inspectionId) {
        queryClient.setQueriesData<{ results: IMilestone[] }>(
          {
            queryKey: [QueryNamesEnums.GET_INSPECTION_MILESTONES, { projectId, inspectionId }],
            exact: false,
          },
          (old) => replaceItemInPaginatedResponse({ old, updatedItem: result?.data }),
        );
      }
    });
  };

  const updateInspectionMilestone = () => {
    inspectionMilestoneQuery.refetch().then((result) => {
      queryClient.setQueriesData<{ results: IMilestone[] }>(
        {
          queryKey: [QueryNamesEnums.GET_INSPECTION_MILESTONES, { projectId, inspectionId }],
          exact: false,
        },
        (old) => replaceItemInPaginatedResponse({ old, updatedItem: result?.data }),
      );
    });
  };

  const updateDrawRequestMilestone = () => {
    drawRequestMilestoneQuery.refetch().then((result) => {
      queryClient.setQueriesData<{ results: IMilestone[] }>(
        {
          queryKey: [QueryNamesEnums.GET_DRAW_REQUEST_MILESTONES, { projectId, drawRequestId }],
          exact: false,
        },
        (old) => replaceItemInPaginatedResponse({ old, updatedItem: result?.data }),
      );
    });
  };

  const updateDrawRequest = () => {
    drawRequestCommentsQuery.refetch().then((result) => {
      queryClient.setQueriesData<{ results: IDrawRequest[] }>(
        { queryKey: [QueryNamesEnums.GET_DRAW_REQUEST_LIST], exact: false },
        (old) => replaceItemInPaginatedResponse({ old, updatedItem: result?.data }),
      );
      queryClient.setQueriesData<IDrawRequest>(
        {
          queryKey: [QueryNamesEnums.GET_DRAW_REQUEST, { projectId, drawRequestId }],
          exact: false,
        },
        (request) =>
          replaceObjectProperties({
            data: request,
            newData: { comments_preview: result?.data?.comments_preview },
          }),
      );
    });
  };

  const updateInspection = useCallback(() => {
    inspectionQuery.refetch().then((result) => {
      queryClient.setQueriesData<{ results: IItem[] }>(
        {
          queryKey: [QueryNamesEnums.GET_PROJECT_INSPECTIONS, { projectId }],
          exact: false,
        },
        (old) => replaceItemInPaginatedResponse({ old, updatedItem: result?.data }),
      );
      queryClient.setQueriesData<{ results: IItem[] }>(
        {
          queryKey: QueryNamesEnums.GET_INSPECTIONS_LIST,
          exact: false,
        },
        (old) => replaceItemInPaginatedResponse({ old, updatedItem: result?.data }),
      );
      if (drawRequestId) {
        queryClient.setQueriesData<{ results: IItem[] }>(
          {
            queryKey: [QueryNamesEnums.GET_DRAW_REQUEST_INSPECTIONS, { projectId, drawRequestId }],
            exact: false,
          },
          (old) => replaceItemInPaginatedResponse({ old, updatedItem: result?.data }),
        );
      }
      queryClient.setQueriesData<{ results: IItem[] }>(
        {
          queryKey: [QueryNamesEnums.GET_PROJECT_INSPECTION_BY_ID, { projectId, inspectionId }],
          exact: false,
        },
        (old) =>
          replaceObjectProperties({
            data: old,
            newData: { comments_preview: result?.data?.comments_preview },
          }),
      );
    });
  }, [inspectionQuery, projectId, drawRequestId]);

  const updateServiceOrder = useCallback(() => {
    serviceOrderQuery.refetch().then((result) => {
      queryClient.setQueriesData<{ results: IItem[] }>(
        {
          queryKey: [QueryNamesEnums.GET_PROJECT_SERVICE_ORDERS, { projectId }],
          exact: false,
        },
        (old) => replaceItemInPaginatedResponse({ old, updatedItem: result?.data }),
      );
      queryClient.setQueriesData<{ results: IItem[] }>(
        {
          queryKey: QueryNamesEnums.GET_SERVICE_ORDERS_LIST,
          exact: false,
        },
        (old) => replaceItemInPaginatedResponse({ old, updatedItem: result?.data }),
      );
      if (drawRequestId) {
        queryClient.setQueriesData<{ results: IItem[] }>(
          {
            queryKey: [
              QueryNamesEnums.GET_DRAW_REQUEST_SERVICE_ORDERS,
              { projectId, drawRequestId },
            ],
            exact: false,
          },
          (old) => replaceItemInPaginatedResponse({ old, updatedItem: result?.data }),
        );
      }
    });
  }, [inspectionQuery, projectId, drawRequestId]);

  const updateDocument = useCallback(() => {
    documentQuery.refetch().then((result) => {
      queryClient.setQueriesData<{ results: IItem[] }>(
        {
          queryKey: [QueryNamesEnums.GET_PROJECT_DOCUMENTS, { projectId }],
          exact: false,
        },
        (old) => replaceItemInPaginatedResponse({ old, updatedItem: result?.data }),
      );
      if (drawRequestId) {
        queryClient.setQueriesData<{ results: IItem[] }>(
          {
            queryKey: [QueryNamesEnums.GET_DRAW_REQUEST_DOCUMENTS, { projectId, drawRequestId }],
            exact: false,
          },
          (old) => replaceItemInPaginatedResponse({ old, updatedItem: result?.data }),
        );
      }
    });
  }, [inspectionQuery, projectId, drawRequestId]);

  const updateCommentsPreviewInfo = useCallback(() => {
    if (milestoneId) {
      if (flags?.['ENG_9292_redo_blue_dot_logic_for_comments']) {
        if (inspectionId) {
          updateInspectionMilestone();
        }

        if (drawRequestId) {
          updateDrawRequestMilestone();
        }

        if (!drawRequestId && !inspectionId) {
          updateMilestone();
        }
      } else {
        updateMilestone();
      }
    }

    if (inspectionId) {
      updateInspection();
    }

    if (serviceOrderId) {
      updateServiceOrder();
    }

    if (documentId) {
      updateDocument();
    }

    if (!milestoneId && !inspectionId && !serviceOrderId && !documentId && drawRequestId) {
      updateDrawRequest();
    }

    project.refetch();
  }, [
    milestoneId,
    drawRequestId,
    projectId,
    inspectionId,
    serviceOrderId,
    documentId,
    milestoneQuery,
    drawRequestCommentsQuery,
    flags,
  ]);

  return {
    updateCommentsPreviewInfo,
  };
};
