import ky from 'ky';

import { parsePathErrorDual, getRefreshTokenExpirationTime } from '@utils';
import { setHeaders } from './auth';
import { ServerErrorCodesEnum } from '@interfaces';
import * as Sentry from '@sentry/react';
import { refreshTokens } from '@globalService';

let isRefreshingToken = false;
let refreshTokenPromise = null;

export default (baseUrl, customHeaders = []) => {
  return ky.create({
    credentials: 'include',
    prefixUrl: `${baseUrl}`,
    timeout: 30000,
    retry: {
      limit: 2,
      methods: ['get', 'post', 'put', 'delete', 'patch'],
      statusCodes: [408, 413, 429, 502, 503, 504, 401],
      backoffLimit: 2000,
    },
    hooks: {
      beforeRequest: [setHeaders(customHeaders)],
      afterResponse: [
        async (_request, _options, response) => {
          if (!response.ok) {
            const error = await response.json();
            if (response?.status === 401) {
              // not to ask for refresh token if it's login auth error (wrong credentials)

              if (
                error?.code === ServerErrorCodesEnum.NOT_AUTHENTICATED ||
                error?.code === ServerErrorCodesEnum.TOKEN_NOT_VALID
              ) {
                await refreshTokenFunction();
                return ky(_request);
              } else throw error;
            }
            if (
              response?.status === 403 &&
              error?.code === ServerErrorCodesEnum.PROFILE_DEACTIVATED
            ) {
              localStorage.clear();
              sessionStorage.clear();
              window.location.replace(`${window.location.origin}/deactivated-account`);
              return;
            }

            const errorText = error?.message || parsePathErrorDual(error);
            Sentry.captureException(new Error(errorText));
            throw error;
          }
          return response;
        },
      ],
    },
  });
};

const refreshTokenFunction = async () => {
  if (window.location.href.includes('login')) return;

  if (isRefreshingToken) {
    return refreshTokenPromise;
  }

  isRefreshingToken = true;
  refreshTokenPromise = (async () => {
    try {
      await refreshTokens();
      localStorage.setItem('tokenExpirationTime', getRefreshTokenExpirationTime());
    } catch (error) {
      handleRefreshFailure();
    } finally {
      isRefreshingToken = false;
      refreshTokenPromise = null;
    }
  })();

  return refreshTokenPromise;
};

const handleRefreshFailure = () => {
  const originalUrl = JSON.parse(localStorage.getItem('originalUrl') || 'null');

  localStorage.clear();
  sessionStorage.clear();

  if (originalUrl) {
    localStorage.setItem('originalUrl', JSON.stringify(originalUrl));
  }

  window.location.reload();
};
