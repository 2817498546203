import { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import {
  DocumentSectionContextEnum,
  IDrawRequest,
  QueryNamesEnums,
  IInspection,
} from '@interfaces';
import { getDrawRequest, getProjectInspectionById } from '@globalService';
import { isChangeRequest, isRequestDraftOrInReview, isTruePicInspection } from '@utils';
import { ComponentProps } from './interface';

export const usePhotoPanel = ({
  prId,
  drawRequestId,
  milestoneId,
  inspectionId,
}): ComponentProps => {
  const { projectId } = useParams();
  const [totalDocCount, setTotalDocCount] = useState({
    [DocumentSectionContextEnum.PRIMARY as string]: -1,
    [DocumentSectionContextEnum.OTHER as string]: -1,
  });

  const noPhotos = useMemo(
    () =>
      totalDocCount[DocumentSectionContextEnum.PRIMARY] === 0 &&
      (!milestoneId || totalDocCount[DocumentSectionContextEnum.OTHER] === 0),
    [totalDocCount, milestoneId],
  );

  const isProjectLevel = useMemo(
    () => !drawRequestId && !inspectionId,
    [drawRequestId, inspectionId],
  );

  const drawRequestQuery = useQuery<IDrawRequest, Error>(
    [QueryNamesEnums.GET_DRAW_REQUEST, { projectId: projectId || prId, drawRequestId }],
    getDrawRequest.bind(this, { projectId: projectId || prId, drawRequestId }),
    { enabled: Boolean(drawRequestId) },
  );

  const queryInspectionFields = ['inspection_agency{service}'].join(',');
  const inspectionQuery = useQuery<IInspection, Error>(
    [
      QueryNamesEnums.GET_PROJECT_INSPECTION_BY_ID,
      { projectId, inspectionId, query: queryInspectionFields },
    ],
    getProjectInspectionById.bind(this, {
      projectId,
      inspectionId,
      query: queryInspectionFields,
    }),
    { enabled: Boolean(inspectionId) },
  );

  const canUploadPhoto = useMemo(() => {
    return (
      isRequestDraftOrInReview(drawRequestQuery?.data?.status) ||
      isProjectLevel ||
      (inspectionId && !isTruePicInspection(inspectionQuery?.data?.inspection_agency?.service))
    );
  }, [drawRequestQuery?.data?.status, isProjectLevel, inspectionId, inspectionQuery?.data]);

  const headerText = useMemo(() => {
    if (isProjectLevel) return 'Project';

    if (inspectionId) return 'Inspection';

    if (!drawRequestQuery?.data || !milestoneId) return '';
    return `${isChangeRequest(drawRequestQuery?.data) ? 'Change' : 'Draw'} #${drawRequestQuery?.data?.number}`;
  }, [isProjectLevel, drawRequestQuery, milestoneId, inspectionId]);

  const updateDocumentsCount = () => {
    setTotalDocCount((prev) => {
      return {
        ...prev,
        [DocumentSectionContextEnum.PRIMARY]: prev[DocumentSectionContextEnum.PRIMARY] + 1,
      };
    });
  };

  return {
    headerText,
    canUploadPhoto,
    setTotalDocCount,
    noPhotos,
    updateDocumentsCount,
  };
};
