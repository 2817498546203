import { useEffect, useMemo } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';

import { useRequestPaymentData, useSafeSnackbar } from '@hooks';
import {
  IDrawRequest,
  PatchDrawRequestParam,
  PaymentConfiguration,
  QueryNamesEnums,
  UpdateDrawRequestFSParam,
} from '@interfaces';
import { useStringFieldModel } from '@models';
import { calculateFraction, validationShareRule } from '@utils';
import { getDrawRequest, patchDrawRequest, updateDrawRequestFundingSource } from '@globalService';
import { ComponentProps, ControllerInterface } from './interface';

export const useEditRequestPaymentConfigurationV2 = ({
  drawRequestId,
  onClose,
}: ComponentProps): ControllerInterface => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSafeSnackbar();
  const { projectId } = useParams();
  const { getRequestOriginalValuePieChartData, constructionHoldbackFS, borrowerEquityFS } =
    useRequestPaymentData();

  const drawRequestQuery = useQuery<IDrawRequest, Error>(
    [QueryNamesEnums.GET_DRAW_REQUEST, { projectId, drawRequestId, query: '{approved_amount}' }],
    getDrawRequest.bind(this, {
      projectId,
      drawRequestId,
      query: '{approved_amount}',
    }),
    { enabled: Boolean(drawRequestId) },
  );

  const totalAmount = useMemo(() => {
    return drawRequestQuery.data?.approved_amount || 0;
  }, [drawRequestQuery.data?.approved_amount]);

  const constructionHoldback = useStringFieldModel({
    initValue: '0',
    validateOnChange: true,
    validationRule: (value) => +value <= totalAmount && +value >= 0,
    initError:
      'Construction holdback for the current request should not exceed approved amount and be greater than 0',
  });
  const constructionHoldbackFraction = useStringFieldModel({
    initValue: '100',
    validationRule: validationShareRule,
    validateOnChange: true,
  });
  const borrowerEquityFraction = useStringFieldModel({
    initValue: '0',
    validationRule: validationShareRule,
    validateOnChange: true,
  });
  const borrowerEquity = useStringFieldModel({
    initValue: '0',
    validateOnChange: true,
    validationRule: (value) => +value <= totalAmount && +value >= 0,
    initError:
      'Borrower equity for the current request should not exceed approved amount and be greater than 0',
  });

  useEffect(() => {
    if (!constructionHoldbackFS || !borrowerEquityFS) return;
    const constructionHoldbackAmount = constructionHoldbackFS?.amount || 0;
    const borrowerEquityAmount = borrowerEquityFS?.amount || 0;
    const constructionHoldbackRate = calculateFraction(constructionHoldbackAmount, totalAmount);
    const borrowerEquityRate = calculateFraction(borrowerEquityAmount, totalAmount);

    constructionHoldbackFraction.setValue(constructionHoldbackRate.toFixed(2));
    borrowerEquityFraction.setValue(borrowerEquityRate.toFixed(2));
    constructionHoldback.setValue(constructionHoldbackAmount.toString());
    borrowerEquity.setValue(borrowerEquityAmount.toString());
  }, [constructionHoldbackFS, borrowerEquityFS]);

  const updatePaymentConfiguration = useMutation<Response, Error, PatchDrawRequestParam>(
    patchDrawRequest,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QueryNamesEnums.GET_DRAW_REQUEST);
        queryClient.invalidateQueries(QueryNamesEnums.GET_PROJECT_PROGRESS);
      },
      onError: (error) => {
        enqueueSnackbar(error.message, { variant: 'error' });
      },
    },
  );

  const updateDrawRequestFSMutation = useMutation<Response, Error, UpdateDrawRequestFSParam>(
    updateDrawRequestFundingSource,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QueryNamesEnums.GET_DRAW_REQUEST_FUNDING_SOURCES);
      },
    },
  );

  const handleSubmit = async () => {
    if (!constructionHoldbackFraction.value && !borrowerEquityFraction.value) return;
    await updatePaymentConfiguration.mutateAsync({
      id: projectId,
      drawRequest: drawRequestId,
      payment_configuration_type: PaymentConfiguration.PER_DRAW_REQUEST,
    });

    await updateDrawRequestFSMutation.mutateAsync({
      projectId,
      drawRequestId,
      fundingSourceId: constructionHoldbackFS?.id,
      json: {
        amount: +constructionHoldback.value,
        id: constructionHoldbackFS?.id,
      },
    });

    await updateDrawRequestFSMutation.mutateAsync({
      projectId,
      drawRequestId,
      fundingSourceId: borrowerEquityFS?.id,
      json: {
        amount: +borrowerEquity.value,
        id: borrowerEquityFS?.id,
      },
    });

    onClose();
  };

  return {
    constructionHoldback,
    constructionHoldbackFraction,
    borrowerEquityFraction,
    borrowerEquity,
    handleSubmit,
    disableSaveButton: [
      constructionHoldback,
      borrowerEquity,
      constructionHoldbackFraction,
      borrowerEquityFraction,
    ].some(({ isValid }) => !isValid),
    originalValuePieChartData: getRequestOriginalValuePieChartData(),
    totalAmount,
  };
};
