import { useCallback, useMemo } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import {
  HookState,
  IProjectFundingSource,
  IPieChartData,
  IProjectProgress,
  IProjectProgressItem,
  QueryNamesEnums,
} from '@interfaces';
import { getProjectProgress, getProjectFundingSources } from '@globalService';
import { calculateFraction, currencyFormatter, getHookState, percentFormatter } from '@utils';
import { colors } from '@theme';

interface ControllerInterface {
  state: HookState;
  projectProgressItems: {
    originalScheduledValue: Partial<IProjectProgressItem>;
  };
  getOriginalValuePieChartData: () => IPieChartData[];
  projectFundingSources: IProjectFundingSource[];
  borrowerEquityFraction: number;
  constructionHoldbackFraction: number;
}

export const useProjectPaymentData = (): ControllerInterface => {
  const { projectId } = useParams();

  const progressQuery = useQuery<IProjectProgress, Error>(
    [QueryNamesEnums.GET_PROJECT_PROGRESS, { projectId }],
    getProjectProgress.bind(this, projectId),
  );

  const projectFundingSourcesQuery = useQuery<{ results: IProjectFundingSource[] }, Error>(
    [QueryNamesEnums.GET_PROJECT_FUNDING_SOURCES, { projectId }],
    getProjectFundingSources.bind(this, projectId),
    { enabled: Boolean(projectId) },
  );

  const projectFundingSources = useMemo(
    () =>
      projectFundingSourcesQuery.data?.results
        ?.filter((fundingSource) => fundingSource.is_active)
        .sort((a, b) => a.index - b.index),
    [projectFundingSourcesQuery.data],
  );

  const constructionHoldbackFS = useMemo(
    () => projectFundingSources?.find((fundingSource) => fundingSource.is_transactable),
    [projectFundingSources],
  );

  const borrowerEquityFS = useMemo(
    () => projectFundingSources?.find((fundingSource) => !fundingSource.is_transactable),
    [projectFundingSources],
  );

  const scheduledValueTotal = useMemo(
    () => borrowerEquityFS?.total + constructionHoldbackFS?.total,
    [constructionHoldbackFS?.total, borrowerEquityFS?.total],
  );

  const projectProgressItems = useMemo(() => {
    const beforeValue = progressQuery.data?.total_original_construction_budget.before_value;
    const before = progressQuery.data?.total_original_construction_budget.before;
    const total = progressQuery.data?.total_original_construction_budget.total;
    return {
      originalScheduledValue: {
        ...progressQuery.data?.total_original_construction_budget,
        contributedText: `${currencyFormatter(beforeValue)} / ${percentFormatter({
          value: before,
          roundTo: 2,
        })}`,
        remainingText: `${currencyFormatter(total - beforeValue)} / ${percentFormatter({
          value: 100 - before,
          roundTo: 2,
        })}`,
        barValues: [beforeValue],
      },
    };
  }, [progressQuery.data]);

  const borrowerEquityFraction = useMemo(
    () => calculateFraction(borrowerEquityFS?.total, scheduledValueTotal),
    [borrowerEquityFS?.total, scheduledValueTotal],
  );

  const constructionHoldbackFraction = useMemo(
    () => calculateFraction(constructionHoldbackFS?.total, scheduledValueTotal),
    [constructionHoldbackFS?.total, scheduledValueTotal],
  );

  const getOriginalValuePieChartData = useCallback(
    () => [
      {
        label: `Original borrower equity (${percentFormatter({ value: borrowerEquityFraction })})`,
        color: colors.status.orange.medium,
        value: borrowerEquityFS?.total,
      },
      {
        label: `Construction holdback (${percentFormatter({ value: constructionHoldbackFraction })})`,
        color: colors.status.information.medium,
        value: constructionHoldbackFS?.total,
      },
    ],
    [constructionHoldbackFraction, borrowerEquityFraction, scheduledValueTotal],
  );

  return {
    state: getHookState(projectFundingSourcesQuery),
    projectProgressItems,
    getOriginalValuePieChartData,
    projectFundingSources,
    borrowerEquityFraction,
    constructionHoldbackFraction,
  };
};
