import { useContext, useEffect, useMemo, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { matchPath, useLocation, useParams } from 'react-router-dom';
import find from 'lodash/find';
import map from 'lodash/map';

import { getHookState, isRequestDraft, isRequestInReview } from '@utils';
import {
  DeletePhotoPayload,
  DocumentTabEnums,
  HookState,
  IDrawRequest,
  IMilestonePhotos,
  IPhoto,
  LineItemFilterEnum,
  QueryNamesEnums,
  TableKeyEnum,
  UpdateProjectPhotoPayload,
} from '@interfaces';
import { AuthContext, SettingsContext } from '@context';
import {
  deleteDrawRequestPhoto,
  getDrawRequest,
  getDrawRequestPhotos,
  getProjectPhotos,
  postThumbToProject,
} from '@globalService';
import {
  ConfirmModalHookInterface,
  useConfirmationModal,
  useLineItemsFilter,
  useSafeSnackbar,
  useUpdateUiSettings,
  useUrlParams,
} from '@hooks';
import { LineItemFilterValues } from '@constants';

const VIEWS_TYPES = {
  LIST: { label: 'List view', value: 'list' },
  GALLERY: { label: 'Gallery view', value: 'gallery' },
};

interface ViewType {
  label: string;
  value: string;
}
export interface ControllerInterface {
  state: HookState;
  photos: IPhoto[];
  photosByMilestone: Partial<IMilestonePhotos>[];
  VIEWS_TYPES: {
    [key: string]: ViewType;
  };
  handleViewType: (_, type: string) => void;
  projectId: string;
  drawRequestId: string;
  isAddPhotoAvailable: boolean;
  handlePhotoClick: (id: string) => void;
  activePhotoId: string;
  activePhoto: IPhoto;
  closeCallback: () => void;
  photosForSlider: IPhoto[];
  callbackOnPhotoChange: (id: string) => void;
  handlePhotoDelete: (photoId: string) => void;
  isPhotoDeleting: boolean;
  userId: string;
  handleFiltersChange: (filterValue: string) => void;
  filterOptions: string[];
  filterValue: string;
  handleProjectImageUpdate: () => void;
  confirmUpdateProjectCoverModal: ConfirmModalHookInterface;
  milestoneFilterValue: string;
  handleMilestoneFilterClick: (value: string) => void;
  milestonesOptions: string[];
  isListView: boolean;
}

export const usePhotos = (): ControllerInterface => {
  const { user } = useContext(AuthContext);
  const { projectId } = useParams();
  const { pathname } = useLocation();
  const match = matchPath('/projects/:projectId/:tab/draw-requests/:drawRequestId', pathname);
  const matchTab = matchPath('/projects/:projectId/:tab/*', pathname);
  const tabPathname = matchTab?.params['*']?.split('/')[0];
  const drawRequestId = match?.params?.drawRequestId;
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSafeSnackbar();
  const { settings } = useContext(SettingsContext);
  const { updateSettings } = useUpdateUiSettings();
  const confirmUpdateProjectCoverModal = useConfirmationModal();
  const [filterOptions, setFilterOptions] = useState([]);
  const isProjectTab = tabPathname === DocumentTabEnums.PROJECT;

  const {
    filterValue: milestoneFilterValue,
    handleFilterClick: handleMilestoneFilterClick,
    defaultOptions: defaultMilestonesOptions,
    setFilterValue: setMilestoneFilterValue,
  } = useLineItemsFilter({
    defaultState: LineItemFilterValues.ALL.filterValue,
    tableKey: TableKeyEnum.PHOTOS,
  });

  const [viewType, setViewType] = useUrlParams(
    settings.personal_setting?.PHOTO_TAB?.view_type || VIEWS_TYPES.LIST.value,
    'view',
    (s) => s.toString(),
    (s) => s.toString(),
  );

  const handleViewType = (_, type: string) => {
    if (!type) return;
    setViewType(type);
    updateSettings({
      personal_setting: {
        PHOTO_TAB: { view_type: type },
      },
    });
  };

  useEffect(() => {
    const savedViewType = settings.personal_setting?.PHOTO_TAB?.view_type;
    if (savedViewType && savedViewType !== viewType) setViewType(savedViewType);
  }, [settings.personal_setting?.PHOTO_TAB?.view_type]);

  const drawRequestQuery = useQuery<IDrawRequest, Error>(
    [QueryNamesEnums.GET_DRAW_REQUEST, { projectId, drawRequestId }],
    getDrawRequest.bind(this, { projectId, drawRequestId }),
    { enabled: Boolean(drawRequestId) },
  );

  const drawRequestPhotosQuery = useQuery<IMilestonePhotos[], Error>(
    [QueryNamesEnums.GET_DRAW_REQUEST_PHOTOS, { projectId, drawRequestId }],
    getDrawRequestPhotos.bind(this, projectId, drawRequestId),
    { enabled: Boolean(drawRequestId) },
  );

  const projectDocsQuery = isProjectTab ? 'include_draw_request_documents=false' : '';
  const projectPhotosQuery = useQuery<IMilestonePhotos[], Error>(
    [QueryNamesEnums.GET_PROJECT_PHOTOS, { projectId, query: projectDocsQuery }],
    getProjectPhotos.bind(this, { projectId, query: projectDocsQuery }),
    { enabled: Boolean(projectId) },
  );

  const [photos, setPhotos] = useState([]);

  const findPhotoById = (id) => find(photos, { id });

  useEffect(() => {
    const data = drawRequestId ? drawRequestPhotosQuery.data : projectPhotosQuery.data;
    if (data?.length) {
      const imagesMap = new Map();
      data.forEach((item) => {
        if (item?.photos?.length) {
          item.photos.forEach((photo) => {
            const existingPhoto = imagesMap.get(photo.id);
            if (existingPhoto) {
              existingPhoto.milestones.push({ ...item.milestone });
              imagesMap.set(photo.id, existingPhoto);
            } else {
              imagesMap.set(photo.id, {
                ...photo,
                milestones: [{ ...item.milestone }],
              });
            }
          });
        }
      });

      const images = Array.from(imagesMap.values());

      setPhotos(images);
      setFilterOptions(['All', ...new Set(images.map((o) => o.source_label))]);
    }
  }, [drawRequestPhotosQuery.data, projectPhotosQuery.data, drawRequestId]);

  useEffect(() => {
    // 'All' tab doesn't have 'Current request only' option so should be switched to 'All' manually
    if (
      !drawRequestId &&
      milestoneFilterValue === LineItemFilterValues.CURRENT_REQUEST_ONLY.filterValue
    )
      setMilestoneFilterValue(LineItemFilterValues.ALL.filterValue);
  }, [milestoneFilterValue, drawRequestId]);

  // handle photo click
  const [activePhotoId, setActivePhotoId] = useUrlParams(
    null,
    'photoId',
    (s) => s.toString(),
    (s) => s.toString(),
  );
  const [activePhoto, setActivePhoto] = useState(null);

  const handlePhotoClick = (id) => () => setActivePhotoId(id);

  useEffect(() => {
    if (!activePhotoId) setActivePhoto(null);
    photos?.length && activePhotoId && setActivePhoto(findPhotoById(activePhotoId));
  }, [activePhotoId, photos]);

  const callbackOnPhotoChange = (id) => setActivePhotoId(id);

  // remove photo functionality
  const deletePhotoMutation = useMutation<Response, Error, DeletePhotoPayload>(
    deleteDrawRequestPhoto,
    {
      onSuccess: () => {
        queryClient.invalidateQueries([
          QueryNamesEnums.GET_DRAW_REQUEST,
          { projectId, drawRequestId },
        ]);
        queryClient.invalidateQueries([QueryNamesEnums.GET_PROJECT_PHOTOS, { projectId }]);
        queryClient.invalidateQueries([
          QueryNamesEnums.GET_DRAW_REQUEST_PHOTOS,
          { projectId, drawRequestId },
        ]);
        queryClient.invalidateQueries([QueryNamesEnums.GET_INSPECTION_DOCUMENTS, { projectId }]);
        enqueueSnackbar('Photo deleted', { variant: 'success' });
        setActivePhotoId(null);
      },
      onError: (error) => {
        enqueueSnackbar(error.message, { variant: 'error' });
      },
    },
  );

  const handlePhotoDelete = async (photoId) => {
    await deletePhotoMutation.mutateAsync({
      projectId,
      photoId,
    });
  };

  // filter by source document
  const [filterValue, setFilterValue] = useUrlParams(
    'All',
    'filter',
    (s) => s.toString(),
    (s) => s.toString(),
  );

  const handleFiltersChange = (value) => {
    setFilterValue(value);
  };

  const filterKey = useMemo(
    () =>
      find(defaultMilestonesOptions, {
        filterValue: milestoneFilterValue,
      })?.filterKey,
    [milestoneFilterValue],
  );

  const filterByMilestoneFunction = (item) => {
    // Check if the filter is set to 'All'
    if (milestoneFilterValue === LineItemFilterValues.ALL.filterValue) {
      return true;
    }

    // If the item has a milestone with an id, check the milestone key
    if (
      item.milestone?.id ||
      filterKey === LineItemFilterValues[LineItemFilterEnum.HAS_PHOTOS].filterKey
    ) {
      return Boolean(item.milestone[filterKey]);
    }

    // this condition is for general inspection photos (they don't have milestone)
    return true;
  };

  const filterBySourceFunction = (photos, filterValue) =>
    photos?.filter((item) => filterValue === 'All' || item.source_label === filterValue);

  const filteredPhotos = useMemo(
    () => filterBySourceFunction(photos, filterValue),
    [filterValue, photos],
  );

  const filteredPhotosByMilestone = useMemo(() => {
    const data = drawRequestId ? drawRequestPhotosQuery.data : projectPhotosQuery.data;

    if (!data?.length) return [];
    return data
      .map((item) => ({
        ...item,
        photos: filterBySourceFunction(item.photos, filterValue).map((photo) => ({
          ...photo,
          milestones: findPhotoById(photo.id)?.milestones || [{ ...item.milestone }],
        })),
        milestone: { ...item.milestone, has_photos: Boolean(item.photos?.length) },
      }))
      .filter(filterByMilestoneFunction);
  }, [
    filterValue,
    milestoneFilterValue,
    drawRequestPhotosQuery.data,
    projectPhotosQuery.data,
    filterKey,
  ]);

  const updateProjectImageMutation = useMutation<Response, Error, UpdateProjectPhotoPayload>(
    postThumbToProject,
    {
      onSuccess: () => {
        queryClient.invalidateQueries([QueryNamesEnums.GET_PROJECT, { project_id: projectId }]);
        queryClient.invalidateQueries([QueryNamesEnums.GET_PROJECTS_LIST]);
        enqueueSnackbar('Project cover photo updated', { variant: 'success' });
      },
      onError: (error) => {
        enqueueSnackbar(error.message, { variant: 'error' });
      },
    },
  );

  const handleProjectImageUpdate = () => {
    updateProjectImageMutation.mutateAsync({
      projectId,
      file_representations: activePhoto.file_representations,
    });
  };

  const milestonesOptions = useMemo(
    () =>
      drawRequestId
        ? map(defaultMilestonesOptions, 'filterValue')
        : map(
            defaultMilestonesOptions.filter(
              (o) => o.filterValue !== LineItemFilterValues.CURRENT_REQUEST_ONLY.filterValue,
            ),
            'filterValue',
          ),
    [drawRequestId],
  );

  return {
    state: getHookState(drawRequestId ? drawRequestPhotosQuery : projectPhotosQuery),
    photos: filteredPhotos,
    photosByMilestone: filteredPhotosByMilestone,
    VIEWS_TYPES,
    handleViewType,
    projectId,
    drawRequestId,
    isAddPhotoAvailable:
      isProjectTab ||
      isRequestInReview(drawRequestQuery.data?.status) ||
      isRequestDraft(drawRequestQuery.data?.status),
    handlePhotoClick,
    activePhoto,
    activePhotoId,
    closeCallback: () => setActivePhotoId(null),
    photosForSlider: [],
    callbackOnPhotoChange,
    handlePhotoDelete,
    isPhotoDeleting: deletePhotoMutation.isLoading,
    userId: user?.id,
    handleFiltersChange,
    filterOptions,
    filterValue,
    handleProjectImageUpdate,
    confirmUpdateProjectCoverModal,
    milestoneFilterValue,
    handleMilestoneFilterClick,
    milestonesOptions,
    isListView: viewType === VIEWS_TYPES.LIST.value,
  };
};
