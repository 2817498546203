import { Dispatch, ReactElement, SetStateAction, useContext, useMemo, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import {
  checkIfHistoricalRequestEditable,
  checkIsApprovedNegativeAmount,
  checkIsApprovedReallocationOutOfEstimate,
  checkIsCreator,
  checkIsCreditInDraw,
  checkIsReallocateComplete,
  checkIsReallocateCompleteByLender,
  checkIsRequestedReallocationOutOfEstimate,
  getHookState,
  getLastRelevantInspection,
  getTeamRole,
  isDrawRequest,
  isReallocationEnabled,
  isRequestApproved,
  isRequestCompleted,
  isRequestDraft,
  isRequestHistorical,
  isRequestInReview,
  isAllowed,
  replaceObjectProperties,
  replaceRequestTotals,
  useBlockerFooter,
} from '@utils';
import { AuthContext, PermissionsContext, useGraphQuery } from '@context';
import {
  useExpandedState,
  useProjectTeamsAndCompanies,
  useSafeSnackbar,
  useDayJsFormatter,
} from '@hooks';
import {
  HookState,
  IDrawRequest,
  IInspection,
  ILoanInfo,
  IProjectProgress,
  ITeam,
  PermissionNamesEnums,
  QueryNamesEnums,
  TableKeyEnum,
  UpdateRequestDataPayload,
} from '@interfaces';
import {
  getDrawRequest,
  getDrawRequestInspectionsList,
  getProjectDrawRequestsList,
  getProjectProgress,
  updateDrawRequestData,
} from '@globalService';
import { REQUEST_SUMMARY_ERROR_TEXTS, TEAM_ROLES } from '@constants';
import { IChangedData, SummaryEditableDataEnum } from '../interface';

export type ControllerInterface = {
  state: HookState;
  drawRequest: IDrawRequest;
  isInReview: boolean;
  isCompleted: boolean;
  isApproved: boolean;
  isDraft: boolean;
  isApprovedOrCompleted: boolean;
  isUpdating: boolean;
  isHistoricalRequestEditable: boolean;
  isHistorical: boolean;
  approvedReallocation: number;
  retainageRate: number;
  projectLoanData: ILoanInfo;
  updateRequestData: (key: SummaryEditableDataEnum, value: Date) => void;
  lastInspection: IInspection;
  isDRFetching: boolean;
  localIsUserCreator: boolean;
  isDrawRequest: boolean;
  budgetChangeError: string;
  changedData: IChangedData | null;
  setChangedData: Dispatch<SetStateAction<IChangedData | null>>;
  expanded: boolean;
  getExpandButton: () => ReactElement;
  teamsList: ITeam[];
  isInspectionViewProhibited: boolean;
  isInspectionAllowanceEditable: boolean;
  isApproveReallocateComplete: boolean;
  isRequestReallocateComplete: boolean;
  isReallocationAllowed?: boolean;
  isDisbursementDateEditable: boolean;
  outOfBudgetError?: string;
};

export const useRequestSummary = (
  projectId: string,
  drawRequestId: string,
): ControllerInterface => {
  const { getFormattedToTimezoneStringValue } = useDayJsFormatter();
  const [changedData, setChangedData] = useState(null);

  const { expanded, getExpandButton } = useExpandedState({
    tableKey: TableKeyEnum.DRAW_REQUEST,
    initialState: true,
  });

  const { teamsList } = useProjectTeamsAndCompanies({});

  const { permissions } = useContext(PermissionsContext);
  const { enqueueSnackbar } = useSafeSnackbar();
  const queryClient = useQueryClient();
  const { user } = useContext(AuthContext);
  const teamRole = getTeamRole(user);
  const project = useGraphQuery({
    type: QueryNamesEnums.GET_PROJECT,
    keys: ['is_reallocation_enabled', 'retainage_rate', 'loan'],
    args: { project_id: projectId },
  });

  const drawRequestData = useQuery<IDrawRequest, Error>(
    [QueryNamesEnums.GET_DRAW_REQUEST, { projectId, drawRequestId }],
    getDrawRequest.bind(this, { projectId, drawRequestId }),
    { enabled: Boolean(drawRequestId) },
  );

  const drawRequestsQuery = useQuery<{ results: IDrawRequest[] }, Error>(
    [QueryNamesEnums.GET_PROJECT_DRAW_REQUEST_LIST, { projectId }],
    getProjectDrawRequestsList.bind(this, projectId),
    { enabled: !!projectId },
  );

  const updateRequestDataMutation = useMutation<IDrawRequest, Error, UpdateRequestDataPayload>(
    updateDrawRequestData,
    {
      onSuccess: (data, vars) => {
        if (vars?.inspector_allowance_rate) {
          queryClient.invalidateQueries([
            QueryNamesEnums.GET_DRAW_REQUEST,
            { projectId, drawRequestId },
          ]);
        } else {
          queryClient.setQueriesData<IDrawRequest>(
            {
              queryKey: [QueryNamesEnums.GET_DRAW_REQUEST, { projectId, drawRequestId }],
              exact: false,
            },
            (request) => {
              const newRequest = replaceObjectProperties({
                data: request,
                newData: {
                  counter_per_request_type: data.counter_per_request_type,
                  submitted_at: data.submitted_at,
                  approved_at: data.approved_at,
                  inspector_allowance_rate: data.inspector_allowance_rate,
                  estimated_disbursement_date: data.estimated_disbursement_date,
                  disbursed_at: data.disbursed_at,
                },
              });
              return replaceRequestTotals(newRequest, {
                inspector_allowance_rate: data.inspector_allowance_rate,
              });
            },
          );
        }

        queryClient.invalidateQueries([QueryNamesEnums.GET_PROJECT_DRAW_REQUEST_LIST]);
        queryClient.invalidateQueries([QueryNamesEnums.GET_DRAW_REQUEST_LIST]);
        queryClient.invalidateQueries([
          QueryNamesEnums.GET_DRAW_REQUEST_INSPECTIONS,
          { projectId, drawRequestId },
        ]);
        queryClient.invalidateQueries([QueryNamesEnums.GET_PROJECT_INSPECTIONS, { projectId }]);
      },
      onError: (error) => {
        enqueueSnackbar(error.message, { variant: 'error' });
      },
    },
  );

  const inspectionQuery = useQuery<{ results: IInspection[] }, Error>(
    [QueryNamesEnums.GET_DRAW_REQUEST_INSPECTIONS, { projectId, drawRequestId }],
    getDrawRequestInspectionsList.bind(this, { projectId, drawRequestId }),
    {
      enabled:
        isAllowed(PermissionNamesEnums.INSPECTIONS_VIEW, permissions) &&
        Boolean(drawRequestId && projectId),
    },
  );

  const lastInspection = useMemo(
    () => getLastRelevantInspection(inspectionQuery.data?.results),
    [inspectionQuery?.data],
  );

  const progressQuery = useQuery<IProjectProgress, Error>(
    [QueryNamesEnums.GET_PROJECT_PROGRESS, { projectId }],
    getProjectProgress.bind(this, projectId),
  );

  const updateRequestData = (key, value) => {
    updateRequestDataMutation.mutate({
      project: projectId,
      drawRequest: drawRequestId,

      [key]:
        key === 'estimated_disbursement_date' ? getFormattedToTimezoneStringValue(value) : value,
    });
  };

  const isHistorical = useMemo(
    () => isRequestHistorical(drawRequestData.data?.source),
    [drawRequestData.data],
  );

  const isHistoricalRequestEditable = useMemo(
    () =>
      checkIfHistoricalRequestEditable({
        request: drawRequestData.data,
        project: project.data,
        projectRequestsList: drawRequestsQuery.data?.results,
      }),
    [drawRequestData.data, project.data, drawRequestsQuery.data?.results],
  );

  const isDisbursementDateEditable = useMemo(
    () => !isRequestCompleted(drawRequestData.data?.status) || isHistoricalRequestEditable,
    [drawRequestData.data, isHistoricalRequestEditable],
  );

  const isInspectionAllowanceEditable = useMemo(
    () => !lastInspection || lastInspection.is_single_value,
    [lastInspection],
  );

  const isApproveReallocateComplete = useMemo(
    () => checkIsReallocateCompleteByLender(drawRequestData.data),
    [drawRequestData.data],
  );
  const isRequestReallocateComplete = useMemo(
    () => checkIsReallocateComplete(drawRequestData.data),
    [drawRequestData.data],
  );

  const isReallocationAllowed = useMemo(
    () => isReallocationEnabled(drawRequestData.data, project.data),
    [drawRequestData.data, project.data],
  );

  const budgetChangeError = useMemo(() => {
    if (
      !isApproveReallocateComplete ||
      checkIsApprovedReallocationOutOfEstimate(drawRequestData.data)
    )
      return REQUEST_SUMMARY_ERROR_TEXTS.APPROVED;
    if (
      !isRequestReallocateComplete ||
      checkIsRequestedReallocationOutOfEstimate(drawRequestData.data)
    )
      return REQUEST_SUMMARY_ERROR_TEXTS.REQUESTED;
    return '';
  }, [isApproveReallocateComplete, isRequestReallocateComplete, drawRequestData.data]);

  const outOfBudgetError = useMemo(() => {
    if (
      checkIsApprovedNegativeAmount(drawRequestData.data) &&
      checkIsCreditInDraw(drawRequestData.data)
    ) {
      return REQUEST_SUMMARY_ERROR_TEXTS.CREDIT;
    }

    if (drawRequestData.data?.lender_allowance_rate_incremental > 100) {
      return REQUEST_SUMMARY_ERROR_TEXTS.GENERAL;
    }

    return undefined;
  }, [drawRequestData.data]);

  return {
    state: getHookState(drawRequestData),
    drawRequest: drawRequestData.data,
    isInReview: isRequestInReview(drawRequestData.data?.status),
    isCompleted: isRequestCompleted(drawRequestData.data?.status),
    isApproved: isRequestApproved(drawRequestData.data?.status),
    isDraft: isRequestDraft(drawRequestData.data?.status),
    isApprovedOrCompleted:
      isRequestCompleted(drawRequestData.data?.status) ||
      isRequestApproved(drawRequestData.data?.status),
    isHistoricalRequestEditable,
    isHistorical,
    isUpdating: useBlockerFooter(),
    isDRFetching: drawRequestData.isFetching,
    retainageRate: project.data?.retainage_rate,
    projectLoanData: project.data?.loan,
    approvedReallocation: progressQuery.data?.approved_reallocation,
    updateRequestData,
    lastInspection,
    localIsUserCreator:
      checkIsCreator(drawRequestData.data, teamRole) && teamRole !== TEAM_ROLES.Owner,
    isDrawRequest: isDrawRequest(drawRequestData.data),
    budgetChangeError,
    changedData,
    setChangedData,
    expanded,
    getExpandButton,
    teamsList,
    isInspectionViewProhibited: !isAllowed(PermissionNamesEnums.INSPECTIONS_VIEW, permissions),
    isInspectionAllowanceEditable,
    isApproveReallocateComplete,
    isRequestReallocateComplete,
    isReallocationAllowed,
    isDisbursementDateEditable,
    outOfBudgetError,
  };
};
