import { useMemo, useContext } from 'react';
import { useParams, useNavigate, useSearchParams } from 'react-router-dom';
import { useQuery } from 'react-query';

import {
  QueryNamesEnums,
  IDrawRequest,
  IInspection,
  PermissionNamesEnums,
  ServiceTypeEnum,
} from '@interfaces';
import {
  isAllowed,
  getActiveDrawRequestFromList,
  getCurrentInspection,
  isCancelRequestedService,
  getServiceTypeDisplayName,
  Override,
} from '@utils';
import { PermissionsContext, useGraphQuery, SettingsContext } from '@context';
import { getProjectDrawRequestsList, getProjectInspectionsList } from '@globalService';
import { TOOLTIP_TEXTS } from '@constants';

export type ControllerInterface = Override<
  ReturnType<typeof useOrderService>,
  {
    goBack: () => void;
    drawRequestInProgress: IDrawRequest;
    isRequestsListLoaded: boolean;
    inspectionDisabledTooltipText: string;
    serviceTypeDisplayName: string;
    service_type: ServiceTypeEnum;
  }
>;

export const useOrderService = () => {
  const navigate = useNavigate();
  const { projectId } = useParams();
  const { permissions } = useContext(PermissionsContext);
  const { settings } = useContext(SettingsContext);
  const [searchParams] = useSearchParams();
  const service_type = searchParams.get('service_type') as ServiceTypeEnum;
  const serviceTypeDisplayName = useMemo(() => {
    return getServiceTypeDisplayName({
      serviceTypesMap: settings?.display?.service_types,
      serviceType: service_type,
    });
  }, [settings?.display?.service_types, service_type]);

  const drawRequestsQuery = useQuery<{ results: IDrawRequest[] }, Error>(
    [QueryNamesEnums.GET_PROJECT_DRAW_REQUEST_LIST, { projectId }],
    getProjectDrawRequestsList.bind(this, projectId),
  );

  const projectInspectionsQuery = useQuery<{ results: IInspection[] }, Error>(
    [QueryNamesEnums.GET_PROJECT_INSPECTIONS, { projectId }],
    getProjectInspectionsList.bind(this, { projectId }),
    {
      enabled: isAllowed(PermissionNamesEnums.INSPECTIONS_VIEW, permissions),
    },
  );

  const drawRequestInProgress = useMemo(
    () => getActiveDrawRequestFromList(drawRequestsQuery.data?.results),
    [drawRequestsQuery.data],
  );

  const inProgressInspection = useMemo(
    () => getCurrentInspection(projectInspectionsQuery.data?.results),
    [projectInspectionsQuery.data?.results],
  );
  const isInspectionCancelRequested = useMemo(
    () => isCancelRequestedService(inProgressInspection?.status),
    [inProgressInspection],
  );
  const project = useGraphQuery({
    type: QueryNamesEnums.GET_PROJECT,
    keys: ['name', 'status', 'status_change_reason', 'id'],
    args: { project_id: projectId },
  });

  const inspectionDisabledTooltipText = useMemo(() => {
    if (!inProgressInspection) return '';
    if (isInspectionCancelRequested) {
      return TOOLTIP_TEXTS.inspectionCancelRequested;
    }
    return TOOLTIP_TEXTS.inspectionOrdered;
  }, [inProgressInspection, isInspectionCancelRequested]);

  return {
    goBack: () => navigate(`/projects/${projectId}/services/`),
    project: project.data,
    drawRequestInProgress,
    isRequestsListLoaded: drawRequestsQuery.isSuccess,
    inspectionDisabledTooltipText,
    serviceTypeDisplayName,
    service_type,
  };
};
