import React, { FC, useCallback, useMemo, useState } from 'react';
import { Button, Stack } from '@mui/material';
import { Popup } from '@components';
import { EnumTypeForList } from '@interfaces';
import { SummaryEditableDataEnum, TChangedData } from '../interface';
import {
  DateInput,
  NumberInput,
  RateInput,
  ReasonInput,
  StatusInput,
  TextInput,
} from './components';
import { useDayJsFormatter } from '@hooks';
import { useLaunchDarklyFlags } from '@context';
interface ChangeDataPopupProps {
  onClose: () => void;
  onSave: (value: TChangedData, reasons?: string[]) => void;
  initData?: TChangedData;
  title: string;
  type: SummaryEditableDataEnum;
  options?: EnumTypeForList[];
  source: string;
  validationRule?: (value: Date) => { value: boolean; reason: string };
  minDate?: Date;
  maxDate?: Date;
}

const ChangeDataPopup: FC<ChangeDataPopupProps> = ({
  onClose,
  onSave,
  initData,
  title,
  type,
  options = [],
  source,
  validationRule,
  minDate,
  maxDate,
}) => {
  const [isValid, setIsValid] = useState(false);
  const [value, setValue] = useState<TChangedData>(initData);
  const [reasons, setReasons] = useState<string[]>([]);
  const { areEqualDates } = useDayJsFormatter();
  const flags = useLaunchDarklyFlags();

  const renderInput = useCallback(() => {
    switch (type) {
      case SummaryEditableDataEnum.SUBMITTED:
      case SummaryEditableDataEnum.APPROVED:
      case SummaryEditableDataEnum.DISBURSED_AT:
      case SummaryEditableDataEnum.ESTIMATED_DISBURSEMENT_DATE:
      case SummaryEditableDataEnum.EXTENDED_MATURITY_DATE:
        return (
          <DateInput
            value={initData as Date}
            type={type}
            title={title}
            source={source}
            onValidChange={setIsValid}
            onChange={setValue}
            validationRule={validationRule}
            minDate={minDate}
            maxDate={maxDate}
            addTimestamp={
              type !== SummaryEditableDataEnum.ESTIMATED_DISBURSEMENT_DATE &&
              type !== SummaryEditableDataEnum.DISBURSED_AT
            }
          />
        );
      case SummaryEditableDataEnum.ESTIMATED_COMPLETION_DATE:
        return (
          <Stack spacing={3}>
            <DateInput
              value={initData as Date}
              type={type}
              title={title}
              source={source}
              onValidChange={setIsValid}
              onChange={setValue}
              validationRule={validationRule}
              minDate={minDate}
              maxDate={maxDate}
            />
            {flags?.['ENG_9779_estimated_completion_date_reason_on_overview'] && (
              <ReasonInput
                reasons={reasons}
                type={type}
                source={source}
                required={!areEqualDates({ value1: value as Date, value2: initData as Date })}
                onChange={setReasons}
              />
            )}
          </Stack>
        );
      case SummaryEditableDataEnum.NUMBER:
        return (
          <NumberInput
            type={type}
            initValue={initData as string | number}
            onValidChange={setIsValid}
            onChange={setValue}
            source={source}
          />
        );
      case SummaryEditableDataEnum.RATE:
        return (
          <RateInput
            type={type}
            initValue={initData as string | number}
            onValidChange={setIsValid}
            onChange={setValue}
            source={source}
          />
        );
      case SummaryEditableDataEnum.SERVICING_STATUS:
        return (
          <StatusInput
            value={options.find((option) => option.name === initData) as EnumTypeForList}
            type={type}
            title={title}
            options={options}
            onValidChange={setIsValid}
            onChange={setValue}
          />
        );
      case SummaryEditableDataEnum.SCOPE_OF_WORK:
        return (
          <TextInput
            type={type}
            initValue={initData as string}
            onValidChange={setIsValid}
            onChange={setValue}
            source={source}
          />
        );
      default:
        return null;
    }
  }, [initData, title, type, options, source, validationRule, minDate, maxDate, reasons]);

  const disableSave = useMemo(() => {
    if (
      type === SummaryEditableDataEnum.ESTIMATED_COMPLETION_DATE &&
      flags?.['ENG_9779_estimated_completion_date_reason_on_overview']
    ) {
      return (
        !isValid ||
        areEqualDates({ value1: value as Date, value2: initData as Date }) ||
        !reasons.length
      );
    }
    return !isValid;
  }, [isValid, reasons, type, initData, value, flags]);

  const handleSave = () => {
    if (disableSave) return;

    if (type === SummaryEditableDataEnum.NUMBER || type === SummaryEditableDataEnum.RATE) {
      onSave(+value);
    } else {
      onSave(value, reasons);
    }
  };

  return (
    <Popup open maxWidth="md" title={title}>
      <Stack sx={{ width: '100%' }}>
        {renderInput()}
        <Stack direction="row" justifyContent="flex-end" alignItems="center" sx={{ mt: 7 }}>
          <Button variant="text" onClick={onClose}>
            Cancel
          </Button>
          <Button
            sx={{ ml: 1 }}
            disabled={disableSave}
            onClick={handleSave}
            data-cy={`draw_change_${type}_save_button`}
          >
            Save
          </Button>
        </Stack>
      </Stack>
    </Popup>
  );
};

export default ChangeDataPopup;
