import { QueryNamesEnums } from '@interfaces';
import { FilterObject } from '../interface';

export const teamsFilter: FilterObject = {
  title: 'Team',
  filterKey: 'teams',
  needToUpdateUrl: false,
  needToUpdateSetting: false,
  getDataParamsPaginated: (pagination, q, skip) => ({
    type: QueryNamesEnums.GET_PROJECT_TEAMS,
    keys: ['id', 'name'],
    args: { pagination, q },
    options: {
      skip,
      paginate: true,
    },
  }),
  strictSerialize: (data) =>
    data?.map((item) => ({
      value: item.id,
      label: item.name,
    })),
  cypressSelector: 'filters__teams',
  withPagination: true,
};
