import React, { FC, useContext, useMemo } from 'react';

import { Button, Stack, Typography } from '@mui/material';
import { checkIsCustomerSuccess, checkIsSuperUser, isCustomer } from '@utils';
import { IActivityLogItem } from '@interfaces';
import { COMPANY_DATA } from '@constants';
import { AuthContext } from '@context';
import { styled } from '@mui/material/styles';
import { colors, typography } from '@theme';
import { useDayJsFormatter } from '@hooks';

const StyledTypography = styled(Typography)`
  .status-chip {
    ${{
      ...typography.labelSemiBold,
      paddingLeft: 8,
      paddingRight: 8,
      paddingTop: 4,
      paddingBottom: 4,
      verticalAlign: 'middle',
      lineHeight: '27px',
    }}
  }

  .status-chip-blue {
    background-color: ${colors.status.information.lighter};
    color: ${colors.status.information.medium};
  }

  .status-chip-red {
    background-color: ${colors.status.error.lighter};
    color: ${colors.status.error.medium};
  }

  .status-chip-green {
    background-color: ${colors.status.success.lighter};
    color: ${colors.status.success.medium};
  }

  .status-chip-yellow {
    background-color: ${colors.status.warning.lighter};
    color: ${colors.status.warning.medium};
  }
`;

const AuditLogItem: FC<{
  item: IActivityLogItem;
  showFullMessage?: boolean;
  source: string;
}> = ({ item, showFullMessage, source }) => {
  const { dateFormatter } = useDayJsFormatter();
  const { user } = useContext(AuthContext);
  const headerText = () => {
    if (!item.user) return '';
    if (checkIsCustomerSuccess(item.user?.team?.role) || checkIsSuperUser(item.user?.role))
      return COMPANY_DATA.CUSTOMER_SUCCESS;

    const userName = `${item.user?.first_name} ${item.user?.last_name}`;
    return isCustomer(user)
      ? userName
      : `${userName}${item.user?.company?.name ? ' / ' + item.user?.company?.name : ''}`;
  };
  const [showMore, setShowMore] = React.useState(false);
  const handleClick = () => setShowMore(!showMore);

  const isLongText = !showFullMessage && item.display_name?.length > 120;
  const displayText = useMemo(
    () =>
      showMore || !isLongText
        ? item.display_name_html
        : item.display_name_html.substring(0, 110) + '...',
    [showMore],
  );

  return (
    <Stack>
      <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ mb: 1 }}>
        <Typography variant="labelSemiBold" data-cy={`${source}__item__actor`}>
          {headerText()}
        </Typography>
        <Typography variant="label" color={colors.text.medium} data-cy={`${source}__item__date`}>
          {dateFormatter({ date: item.created_at, withTime: true })}
        </Typography>
      </Stack>
      <Stack>
        <StyledTypography
          variant="body2"
          dangerouslySetInnerHTML={{ __html: displayText }}
          data-cy={`${source}__item__text`}
        />
        {isLongText && (
          <Stack justifyContent="space-between" alignItems="flex-end">
            <Button variant="text" size="small" sx={{ padding: 0 }} onClick={handleClick}>
              {showMore ? 'Show less' : 'Read more'}
            </Button>
          </Stack>
        )}
      </Stack>
    </Stack>
  );
};

export default AuditLogItem;
