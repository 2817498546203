import { useCallback, useContext, useMemo } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import pipe from 'lodash/fp/pipe';
import prop from 'lodash/fp/prop';
import last from 'lodash/fp/last';
import filter from 'lodash/fp/filter';
import defaultTo from 'lodash/fp/defaultTo';
import {
  checkIsInvestor,
  checkIsLender,
  checkIsOwner,
  getChecklistIdByRole,
  getCheckListItemsByRole,
  getDrawRequestForApproval,
  getHookState,
  getTeamRole,
  isChangeRequest,
  isCreatedProject,
  isRequestApproved,
  isRequestDraft,
  isRequestHistorical,
  isRequestInReview,
  isAllowed,
} from '@utils';
import {
  ChecklistParam,
  DrawRequestStatus,
  HookState,
  IDrawRequest,
  IProjectChecklist,
  ItemStatusesEnum,
  PermissionNamesEnums,
  PostChecklistParam,
  PostProjectChecklistParam,
  ProjectChecklistParam,
  QueryNamesEnums,
  UpdateChecklistItemParam,
} from '@interfaces';
import { AuthContext, PermissionsContext, SettingsContext, useGraphQuery } from '@context';
import { useSafeSnackbar } from '@hooks';
import isEmpty from 'lodash/isEmpty';
import {
  deleteChecklistItem,
  deleteProjectChecklistItem,
  getDrawRequestItemChecklist,
  getProjectChecklist,
  getProjectDrawRequestsList,
  postChecklistItem,
  postProjectChecklistItem,
  updateChecklistItem,
} from '@globalService';

// TODO: rework this holy mother intercessor
const getDRListFromResponse = pipe(prop('data.results'), defaultTo([]));

const getlastDrawRequestId = pipe(
  getDRListFromResponse,
  defaultTo([]),
  filter(
    (dr) => dr?.status !== DrawRequestStatus.COMPLETED && dr?.status !== DrawRequestStatus.DRAFT,
  ),
  last,
  prop('id'),
);

const CHECKLIST_TYPES = {
  PROJECT: 'PROJECT',
  DR: 'DR',
};

export const useProjectChecklist = (projectId: string, requestType: string) => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSafeSnackbar();
  const { user } = useContext(AuthContext);
  const { permissions } = useContext(PermissionsContext);
  const { isCurrentProjectArchived } = useContext(SettingsContext);
  const teamRole = getTeamRole(user);

  const project = useGraphQuery({
    type: QueryNamesEnums.GET_PROJECT,
    keys: ['id', 'status', 'is_underwriting_enabled', 'is_budget_locked'],
    args: { project_id: projectId },
  });

  const projectChecklist = useQuery<IProjectChecklist[], Error>(
    [QueryNamesEnums.GET_PROJECT_CHECKLIST, { projectId }],
    getProjectChecklist.bind(this, projectId),
  );

  const projectDRList = useQuery<{ results: IDrawRequest[] }, Error>(
    [QueryNamesEnums.GET_PROJECT_DRAW_REQUEST_LIST, { projectId }],
    getProjectDrawRequestsList.bind(this, projectId),
  );

  const drawRequestForApproval = getDrawRequestForApproval(projectDRList.data?.results);

  const drawRequestInReview = useMemo(
    () => projectDRList.data?.results?.find((dr) => isRequestInReview(dr.status)),
    [projectDRList.data?.results],
  );

  const isRequestsHistorical = useMemo(
    () => projectDRList.data?.results?.every((dr) => isRequestHistorical(dr.source)),
    [projectDRList.data?.results],
  );

  // if requestId exists then it is DR submission, and we use it as draft DR id
  const lastDrawRequestId = useMemo(
    () =>
      !isRequestsHistorical
        ? drawRequestForApproval?.id || getlastDrawRequestId(projectDRList)
        : null,
    [isRequestsHistorical, drawRequestForApproval, projectDRList],
  );

  const DRChecklist = useQuery<IProjectChecklist[], Error>(
    [
      QueryNamesEnums.GET_DRAW_REQUEST_ITEM_CHECKLIST,
      { projectId, drawRequestId: lastDrawRequestId },
    ],
    getDrawRequestItemChecklist.bind(this, projectId, lastDrawRequestId),
    { enabled: !!lastDrawRequestId },
  );

  const activeChecklistType = useMemo(
    () =>
      isEmpty(DRChecklist.data) || isRequestsHistorical
        ? CHECKLIST_TYPES.PROJECT
        : CHECKLIST_TYPES.DR,
    [DRChecklist.data, isRequestsHistorical],
  );

  const updateChecklistItemMutation = useMutation<Response, Error, UpdateChecklistItemParam>(
    updateChecklistItem,
    {
      onSuccess: () => {
        activeChecklistType === CHECKLIST_TYPES.PROJECT &&
          queryClient.invalidateQueries(QueryNamesEnums.GET_PROJECT_CHECKLIST);
        activeChecklistType === CHECKLIST_TYPES.DR &&
          queryClient.invalidateQueries(QueryNamesEnums.GET_DRAW_REQUEST_ITEM_CHECKLIST);
      },
      onError: (error) => {
        enqueueSnackbar(error.message, { variant: 'error' });
      },
    },
  );

  const checklistToProcess = useMemo(() => {
    if (DRChecklist?.isSuccess && DRChecklist?.data && !isRequestsHistorical) {
      if (isChangeRequest(drawRequestForApproval)) {
        return { items: DRChecklist.data, title: 'Change approval' };
      }
      if (!checkIsOwner(teamRole)) {
        return { items: DRChecklist.data, title: 'Draw approval' };
      }
    }

    //Do not show Project Checklist after first DR (not historical)
    if (
      projectChecklist?.isSuccess &&
      projectDRList?.isSuccess &&
      !isEmpty(projectChecklist?.data) &&
      !projectDRList.data?.results.find(
        (dr) => !isRequestDraft(dr.status) && !isRequestHistorical(dr.source),
      )
    ) {
      return { items: projectChecklist.data, title: 'Project checklist' };
    }
    return null;
  }, [
    projectDRList.data,
    DRChecklist,
    projectChecklist,
    drawRequestForApproval,
    requestType,
    isRequestsHistorical,
    teamRole,
  ]);

  const checklistId = useMemo(() => {
    if (!checklistToProcess?.items) return null;
    return getChecklistIdByRole(checklistToProcess?.items, teamRole);
  }, [checklistToProcess, teamRole]);

  const checklistItems = useMemo(() => {
    if (!checklistToProcess?.items) return [];
    return getCheckListItemsByRole({
      policies: checklistToProcess?.items,
      teamRole,
      teamId: user?.active_team?.id,
      itemsCanBeDeleted: isAllowed(
        PermissionNamesEnums.POLICIES_OWN_ADDITIONAL_ITEMS_EDIT,
        permissions,
      ),
    });
  }, [projectChecklist.data, DRChecklist.data, permissions, checklistToProcess?.items, teamRole]);
  const handleItemClick = useCallback(
    (item) =>
      updateChecklistItemMutation.mutate({
        projectId,
        checklistId,
        checklistItemId: item.id,
        status: item.checked ? ItemStatusesEnum.COMPLETED : ItemStatusesEnum.NOT_STARTED,
        ...(lastDrawRequestId && { drawRequestId: lastDrawRequestId }),
      }),
    [checklistItems, checklistId, lastDrawRequestId],
  );

  const progress =
    (checklistItems?.filter((i) => i.checked).length / checklistItems.length) * 100 || 0;

  const showLenderChecklist = useMemo(
    () => checklistId && (checkIsLender(teamRole) || checkIsInvestor(teamRole)),
    [checklistId, teamRole],
  );

  const postItem = useMutation<Response, Error, PostChecklistParam>(postChecklistItem, {
    onSuccess: () => {
      queryClient.invalidateQueries(QueryNamesEnums.GET_DRAW_REQUEST_ITEM_CHECKLIST);
    },
    onError: (error) => {
      enqueueSnackbar(error.message, { variant: 'error' });
    },
  });
  const postProjectItem = useMutation<Response, Error, PostProjectChecklistParam>(
    postProjectChecklistItem,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QueryNamesEnums.GET_PROJECT_CHECKLIST);
      },
      onError: (error) => {
        enqueueSnackbar(error.message, { variant: 'error' });
      },
    },
  );
  const addChecklistItem = (item: string, description?: string) =>
    lastDrawRequestId
      ? postItem.mutateAsync({
          projectId,
          drawRequestId: lastDrawRequestId,
          checklistId,
          value: {
            name: item,
            created_by_team: user?.active_team?.id,
            ...(description && { description }),
          },
        })
      : postProjectItem.mutateAsync({
          projectId,
          checklistId,
          value: {
            name: item,
            created_by_team: user?.active_team?.id,
            ...(description && { description }),
          },
        });

  const deleteItem = useMutation<Response, Error, ChecklistParam & { itemId: string }>(
    deleteChecklistItem,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QueryNamesEnums.GET_DRAW_REQUEST_ITEM_CHECKLIST);
      },
      onError: (error) => {
        enqueueSnackbar(error.message, { variant: 'error' });
      },
    },
  );
  const deleteProjectItem = useMutation<
    Response,
    Error,
    ProjectChecklistParam & { itemId: string }
  >(deleteProjectChecklistItem, {
    onSuccess: () => {
      queryClient.invalidateQueries(QueryNamesEnums.GET_PROJECT_CHECKLIST);
    },
    onError: (error) => {
      enqueueSnackbar(error.message, { variant: 'error' });
    },
  });
  const removeChecklistItem = (itemId: string) =>
    lastDrawRequestId
      ? deleteItem.mutateAsync({ projectId, drawRequestId: lastDrawRequestId, checklistId, itemId })
      : deleteProjectItem.mutateAsync({ projectId, checklistId, itemId });

  const checkQueryLoaded = (hookState) =>
    hookState === HookState.SUCCESS || hookState === HookState.EMPTY;

  const isQueriesLoaded = lastDrawRequestId
    ? checkQueryLoaded(getHookState(projectChecklist)) &&
      checkQueryLoaded(getHookState(DRChecklist))
    : checkQueryLoaded(getHookState(projectChecklist)) &&
      checkQueryLoaded(getHookState(projectDRList));

  const approvedDrawRequest = useMemo(
    () => projectDRList.data?.results?.find(({ status }) => isRequestApproved(status)),
    [projectDRList.data?.results],
  );

  const isDrawRequestDisbursable = useMemo(
    () => isAllowed(PermissionNamesEnums.PAYMENTS_MARK_AS_PAID, permissions) && approvedDrawRequest,
    [projectDRList.data?.results, permissions],
  );

  const isVisibleChecklistForNonTeam = useMemo(
    () =>
      isAllowed(PermissionNamesEnums.DRAWREQUESTS_REVIEWER_TEAM_EDIT, permissions) &&
      drawRequestInReview &&
      checklistItems?.length,
    [drawRequestInReview, checklistItems, permissions],
  );

  const isApprovalProcessActive = useMemo(
    () => drawRequestForApproval && !isRequestHistorical(drawRequestForApproval.status),
    [drawRequestForApproval],
  );

  const underwritingEnabled = useMemo(
    () => project.data?.is_underwriting_enabled && !project.data?.is_budget_locked,
    [project.data?.is_underwriting_enabled, project.data?.is_budget_locked],
  );

  const isProjectLevelChecklistVisible = useMemo(
    () =>
      (!projectDRList.data?.results?.length || isRequestsHistorical) &&
      (!checkIsOwner(teamRole) || underwritingEnabled) &&
      projectChecklist.data?.length &&
      (isAllowed(PermissionNamesEnums.PROJECT_BUDGET_EDIT, permissions) ||
        isAllowed(PermissionNamesEnums.PROJECT_ONBOARDING, permissions)) &&
      isCreatedProject(project.data?.status),
    [
      projectDRList.data?.results,
      teamRole,
      projectChecklist.data,
      project.data?.status,
      underwritingEnabled,
      isRequestsHistorical,
      permissions,
    ],
  );

  const shouldShowComponent = useMemo(
    () =>
      isQueriesLoaded &&
      !isCurrentProjectArchived &&
      (isDrawRequestDisbursable ||
        isApprovalProcessActive ||
        isVisibleChecklistForNonTeam ||
        isProjectLevelChecklistVisible),
    [
      isDrawRequestDisbursable,
      isApprovalProcessActive,
      isVisibleChecklistForNonTeam,
      isProjectLevelChecklistVisible,
      isQueriesLoaded,
      isCurrentProjectArchived,
    ],
  );

  const hideAllChecklists = useMemo(
    () => Boolean(approvedDrawRequest) || isRequestHistorical(drawRequestForApproval?.source),
    [approvedDrawRequest, drawRequestForApproval],
  );

  return {
    shouldShowComponent,
    showLenderChecklist,
    items: checklistItems,
    progress,
    handleItemClick,
    addChecklistItem,
    deleteChecklistItem: removeChecklistItem,
    totalItems: hideAllChecklists ? 0 : checklistItems?.length,
    checkedItems: checklistItems?.filter((i) => i.checked).length,
    checklistTitle: approvedDrawRequest
      ? 'Draw disbursement'
      : checklistToProcess?.title || 'Draw approval',
    drawRequestId:
      drawRequestForApproval?.id || approvedDrawRequest?.id || getlastDrawRequestId(projectDRList),
    hideActonButtons:
      !drawRequestForApproval?.id && !approvedDrawRequest?.id && !isProjectLevelChecklistVisible,
    hideAllChecklists,
    project: project.data,
  };
};
