import React, { FC } from 'react';
import { Box, Stack, Typography } from '@mui/material';
import { IProjectDocument, MediaFile } from '@interfaces';
import { colors, typography } from '@theme';
import { LabelAndValue } from '@components';
import { StatusProcessedIcon, StatusUnProcessedIcon } from '@svgAsComponents';
import { currencyFormatter } from '@utils';
import { useDayJsFormatter } from '@hooks';

const DocumentSummary: FC<{
  row: IProjectDocument | MediaFile;
  source: string;
  canBeProcessed: boolean;
  openPdfViewer?: (file: IProjectDocument | MediaFile) => void;
}> = ({ row, source, canBeProcessed, openPdfViewer }) => {
  const { name, metadata, has_smart_summary } = row;
  const { dateFormatter } = useDayJsFormatter();
  const isFllMetadata =
    metadata?.date && metadata?.amount && metadata?.document_type && metadata?.vendor_name;

  return (
    <>
      <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={1} mt={3}>
        {Boolean(openPdfViewer) && canBeProcessed ? (
          <Typography
            variant="body1SemiBold"
            color={colors.text.link}
            data-cy={`${source}__name__link`}
            sx={{ cursor: 'pointer' }}
            onClick={() => openPdfViewer(row)}
          >
            {name}
          </Typography>
        ) : (
          <Typography variant="body1SemiBold" color={colors.text.link} data-cy={`${source}__name`}>
            {name}
          </Typography>
        )}
        {canBeProcessed && has_smart_summary && metadata && (
          <Box
            sx={{
              minWidth: 16,
              width: 16,
              height: 16,
              borderRadius: '2px',
              backgroundColor: isFllMetadata ? colors.status.success.medium : colors.neutral.medium,
            }}
          />
        )}
        {!canBeProcessed && (
          <Typography variant="body2" color={colors.neutral.medium}>
            Unprocessed
          </Typography>
        )}
      </Stack>
      {canBeProcessed && has_smart_summary && metadata && (
        <>
          {metadata.summary && (
            <Stack mt={1}>
              <Typography variant="body3" data-cy={`${source}__summary`}>
                {metadata.summary}
              </Typography>
            </Stack>
          )}
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={1}
            mt={3}
          >
            <Stack
              justifyContent="space-between"
              alignItems="flex-start"
              spacing={2}
              mt={3}
              width="40%"
            >
              <LabelAndValue
                label="Type:"
                icon={metadata.document_type ? <StatusProcessedIcon /> : <StatusUnProcessedIcon />}
                iconPosition="left"
                text={metadata.document_type || '-'}
                size="body2"
                textStyle={{ ...typography.body2SemiBold }}
                marginRightAfterLabel={false}
                textDataTestName={`${source}__document_type`}
              />
              <LabelAndValue
                label="Date:"
                icon={metadata.date ? <StatusProcessedIcon /> : <StatusUnProcessedIcon />}
                iconPosition="left"
                text={dateFormatter({ date: metadata.date }) || '-'}
                size="body2"
                textStyle={{ ...typography.body2SemiBold }}
                marginRightAfterLabel={false}
                textDataTestName={`${source}__date`}
              />
            </Stack>
            <Stack
              justifyContent="space-between"
              alignItems="flex-start"
              spacing={2}
              mt={3}
              width="60%"
            >
              <LabelAndValue
                label="Total amount:"
                icon={metadata.amount ? <StatusProcessedIcon /> : <StatusUnProcessedIcon />}
                iconPosition="left"
                text={currencyFormatter(metadata.amount, '-')}
                size="body2"
                textStyle={{ ...typography.body2SemiBold }}
                marginRightAfterLabel={false}
                textDataTestName={`${source}__amount`}
              />
              <LabelAndValue
                label="Vendor:"
                icon={metadata.vendor_name ? <StatusProcessedIcon /> : <StatusUnProcessedIcon />}
                iconPosition="left"
                text={metadata.vendor_name || '-'}
                size="body2"
                textStyle={{ ...typography.body2SemiBold }}
                marginRightAfterLabel={false}
                textDataTestName={`${source}__vendor`}
              />
            </Stack>
          </Stack>
        </>
      )}
    </>
  );
};

export default DocumentSummary;
