import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useGraphQuery } from '@context';
import { useTablePagination } from '@hooks';
import { UseFilterData } from './interface';

interface UseFilterButtonProps {
  initValues: string[];
  withPagination?: boolean;
  getDataParamsPaginated: (params: any, q?: string, skip?: boolean) => any;
  strictSerialize?: (data: Array<any>) => Array<any>;
}

export const useAsyncFilterData = ({
  initValues,
  withPagination,
  getDataParamsPaginated,
  strictSerialize,
}: UseFilterButtonProps): UseFilterData => {
  const [maxCount, setMaxCount] = useState<number>(50);
  const [q, setQ] = useState<string>('');
  const nonExistingValues = useRef<string[]>([]);

  const { page, rowsPerPage, nextPage, setPage } = useTablePagination({
    initialRowsPerPage: withPagination ? 50 : 9999,
  });

  const { isLoading, data: dataValues } = useGraphQuery(
    getDataParamsPaginated(
      {
        page,
        rowsPerPage,
      },
      q,
    ),
  );

  const { data: pendingData } = useGraphQuery(
    getDataParamsPaginated(
      { id: nonExistingValues.current, page: 0, rowsPerPage: 10 },
      q,
      // the second condition is to avoid the case where we use smt like 'Unassigned' option which is null and shouldn't be asked
      !nonExistingValues.current.length || nonExistingValues.current?.[0] === 'null',
    ),
  );

  const pendingValues = useMemo(() => {
    return strictSerialize
      ? strictSerialize(pendingData?.['results'] || [])
      : pendingData?.['results'];
  }, [pendingData, strictSerialize]);

  const valuesList = useMemo(() => {
    return strictSerialize
      ? strictSerialize(dataValues?.['results'] || [])
      : dataValues?.['results'];
  }, [dataValues, strictSerialize]);

  const valuesWithExistingData = useMemo(() => {
    const existingValue = valuesList.filter(
      (item) => !pendingValues.find((pv) => pv.id === item.value || pv.value === item.value), // for value: 'null' like 'Unassigned' option
    );
    return [...pendingValues, ...existingValue];
  }, [valuesList, pendingValues]);

  const stringToShow = useMemo(
    () =>
      valuesWithExistingData
        ?.filter((item) => initValues.includes(item.value))
        ?.map((item) => item.label || item.id)
        ?.join(','),
    [valuesWithExistingData, initValues],
  );

  const nextPageCallback = useCallback(
    () => (Math.ceil(maxCount / rowsPerPage) - 1 > page ? nextPage() : null),
    [maxCount, rowsPerPage, page, nextPage],
  );

  useEffect(() => {
    if (dataValues?.['count']) {
      setMaxCount(dataValues?.['count']);
    }
  }, [dataValues?.['count']]);

  useEffect(() => {
    if (valuesList?.length && !nonExistingValues.current.length) {
      const initialNonExistingValues = initValues.filter((value) => {
        return !valuesList.find((item) => item.value === value);
      });
      nonExistingValues.current = initialNonExistingValues;
    }
  }, [valuesList, initValues]);

  return {
    setQ,
    setPage,
    nextPageCallback,
    isLoading,
    valuesWithExistingData,
    stringToShow,
  };
};
