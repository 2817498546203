import { TableKeyEnum } from '@interfaces';
import { isDrawRequest } from '@utils';

export const getInitColumns = ({
  isSubmissionProcess,
  isReallocationAllowed,
  drawRequest,
  retainageRate,
  flags,
}) => [
  'productionBuildExpand',
  'nameV2',
  ...(flags?.[`ENG_7895_table_v3__${TableKeyEnum.PHB_REQUEST_LINE_ITEMS}`] ? ['externalId'] : []),
  'costType',
  ...(isSubmissionProcess
    ? ['prefundingCost', 'originalEstimate']
    : [
        'originalConstructionBudget',
        'prefundingCost',
        'originalEstimate',
        ...(flags?.[`ENG_7895_table_v3__${TableKeyEnum.PHB_REQUEST_LINE_ITEMS}`]
          ? ['revisedConstructionBudgetInReview']
          : ['revisedConstructionBudget']),
      ]),
  'previousChanges',
  'previousChangesRate',
  'revisedEstimate',
  ...(isReallocationAllowed
    ? [
        ...(flags?.['ENG_9922_allow_lender_to_change_request_amounts']
          ? ['requestedAdjustmentsV2']
          : ['requestedAdjustments']),
        'requestedAdjustmentsRate',
        'requestedRevisedEstimate',
      ]
    : []),
  ...(isReallocationAllowed && !isSubmissionProcess
    ? ['approvedAdjustments', 'adjustmentsRate']
    : []),
  ...(isDrawRequest(drawRequest)
    ? [
        ...(flags?.['ENG_9922_allow_lender_to_change_request_amounts']
          ? ['requestedAmountV2']
          : ['requestedAmount']),
        'requestedAmountRelative',
      ]
    : []),
  ...(isSubmissionProcess
    ? []
    : [
        'inspectorAllowance',
        'inspectorAllowanceRate',
        'inspectorAllowanceIncremental',
        'inspectorAllowanceRateIncremental',
        'approvedAmountCumulative',
        'lenderAllowanceRate',
        ...(isDrawRequest(drawRequest) ? ['approvedAmount', 'approvedAmountRelative'] : []),
      ]),
  'previousApprovedAmountCumulative',
  'previousLenderAllowanceRate',
  'balanceToFinish',
  'balanceToFinishRate',
  ...(retainageRate ? ['retainageRateBudget'] : []),
  ...(isSubmissionProcess ? [] : ['varianceToLenderAllowance', 'varianceToLenderAllowanceRate']),
  'spaceItem',
  ...(isDrawRequest(drawRequest) ? [isSubmissionProcess ? 'submitCredit' : 'approveCredit'] : []),
  'documentsPhotosUploaderMenuV2',
  'documentsPhotosGalleryMenuV2',
  'comments',
];
