import React from 'react';
import {
  ActionButton,
  CellRenderWithError,
  gridCellSet,
  MilestoneListColumnTypeV2,
} from './common';
import {
  calculateRequestedAdjustment,
  currencyFormatter,
  currencyToNumber,
  isAllowed,
  notAllowNegative,
} from '@utils';
import { CurrencyInput } from '@components';
import { CommonRowType, PermissionNamesEnums } from '@interfaces';
import { GridEditInputCell } from '@mui/x-data-grid-premium';
import { InputBaseComponentProps } from '@mui/material';

const editableByMilestone = (row: CommonRowType) =>
  (row.localIsUserCreator || row.isSubmission) && row.isEditableV2 && row.requested_amount >= 0;

const actionButtons: ActionButton[] = [
  {
    variant: 'text',
    size: 'small',
    onClickCustom(api, row) {
      gridCellSet(api, row, 'requested_amount', 0);
    },
    label: 'Clear input',
  },
  {
    color: 'secondary',
    variant: 'new',
    size: 'small',
    onClickCustom(api, row) {
      const requested_adjustments = calculateRequestedAdjustment(row, +row.requested_amount);
      gridCellSet(api, row, 'requested_adjustments', requested_adjustments);
    },
    hideButton: (row) => !row.isReallocationAllowed,
    label: 'Increase change',
  },
  {
    color: 'secondary',
    variant: 'new',
    size: 'small',
    onClickCustom(api, row) {
      const requested_amount = row.balance_to_finish;
      gridCellSet(api, row, 'requested_amount', requested_amount);
    },
    label: 'Adjust draw',
  },
];

const requestedAmount: MilestoneListColumnTypeV2 = {
  field: 'requested_amount',
  headerAlign: 'right',
  headerClassName: 'number',
  cellClassName: 'number',
  headerName: 'Requested draw ($)',
  description: 'Amount requested by borrower for this draw',
  valueParser: (value) => currencyToNumber(value),
  valueFormatter: (value) => currencyFormatter(value, ''),
  renderCell: (params) => (
    <CellRenderWithError persistTooltip params={params} actionButtons={actionButtons} />
  ),
  renderEditCell: (props) => (
    <GridEditInputCell
      {...props}
      inputProps={{
        isAllowed: notAllowNegative,
      }}
      inputComponent={CurrencyInput as unknown as React.ElementType<InputBaseComponentProps>}
    />
  ),
  editableByMilestone,
  editableByPermissions: (permissions) =>
    isAllowed(PermissionNamesEnums.DRAWREQUESTS_CREATE, permissions) ||
    isAllowed(PermissionNamesEnums.DRAWREQUESTS_MANUAL_CREATE, permissions),
  minWidth: 120,
};

export default requestedAmount;
