import { useIsFetching, useIsMutating } from 'react-query';
// import { useIsFetching, useIsMutating, UseQueryResult } from 'react-query';
import { HookState, MutationKeyEnum, QueryNamesEnums } from '@interfaces';
import { getDeepIsEmpty } from './decorators';
import { useParams } from 'react-router-dom';
import { useEffect, useState, useRef } from 'react';

import { DataProviderResultType } from '../context/dataLayer/types';

export function getHookState(result: any) {
  if (Array.isArray(result)) {
    if (result.some(({ isLoading }) => isLoading)) {
      return HookState.FETCHING;
    }
    if (result.some(({ isError }) => isError)) {
      return HookState.ERROR;
    }
    if (result.every(({ isSuccess }) => isSuccess)) {
      return HookState.SUCCESS;
    }
  } else {
    if (result.isLoading) {
      return HookState.FETCHING;
    }
    if (result.isError) {
      return HookState.ERROR;
    }
    if (result.isSuccess && getDeepIsEmpty(result.data)) {
      return HookState.EMPTY;
    }
    if (result.isIdle) {
      return HookState.IDLE;
    }
    if (result.isSuccess) {
      return HookState.SUCCESS;
    }
  }
}

export function getDataLayerHookState(result: DataProviderResultType<any>) {
  if (Array.isArray(result)) {
    if (result.some(({ status }) => status === HookState.FETCHING)) {
      return HookState.FETCHING;
    }
    if (result.some(({ status }) => status === HookState.ERROR)) {
      return HookState.ERROR;
    }
    if (result.every(({ status }) => status === HookState.SUCCESS)) {
      return HookState.SUCCESS;
    }
  } else {
    return result?.status as HookState;
  }
}

export const useBlockerFooter = () => {
  const { projectId, requestId } = useParams();
  const isBlockedByFetchingTotals = useIsFetching([
    QueryNamesEnums.GET_DRAW_REQUEST_TOTALS,
    { projectId, drawRequestId: requestId },
  ]);
  const isBlockedByFetchingDr = useIsFetching([
    QueryNamesEnums.GET_DRAW_REQUEST,
    { projectId, drawRequestId: requestId },
  ]);

  return Boolean(isBlockedByFetchingTotals || isBlockedByFetchingDr);
};

export const useBlockerCell = ({
  milestoneId,
  key,
  tags,
}: {
  milestoneId: string;
  key?: string;
  tags?: string;
}) => {
  const checkMsBlock = (variables) => {
    const isCurrentMs =
      (variables.milestone && variables.milestone === milestoneId) ||
      (variables.tags && variables.tags === tags);
    const isCurrentField = key && variables.json?.[key];

    if (isCurrentMs) return !isCurrentField;

    const updatedTagsArr = variables.tags?.split(',');
    const msTagsArr = tags?.split(',');
    // nested milestones have 2 tags in variables
    const isNestedMsWasUpdated = updatedTagsArr?.length > 1;
    const isNestedMs = msTagsArr?.length > 1 && msTagsArr.includes(updatedTagsArr?.[0]);
    const isParentMs = updatedTagsArr?.includes(tags);

    return Boolean(isCurrentField && (isParentMs || (isNestedMs && !isNestedMsWasUpdated)));
  };

  const isBlockedByMutation = useIsMutating({
    predicate: ({ options }) => {
      return (
        options.mutationKey === MutationKeyEnum.MILESTONE_DELETE ||
        options.mutationKey === MutationKeyEnum.DRAW_REQUEST_ADD_ITEM ||
        options.mutationKey === MutationKeyEnum.MILESTONE_PATCH_BULK ||
        (options.mutationKey === MutationKeyEnum.MILESTONE_PATCH && checkMsBlock(options.variables))
      );
    },
  });

  return Boolean(isBlockedByMutation);
};

export const useCallbackByConditions = ({
  callback,
  conditions,
}: {
  callback: () => void;
  conditions: boolean;
}) => {
  const [isWaiting, setIsWaiting] = useState<boolean>(false);
  useEffect(() => {
    if (conditions && isWaiting) {
      setIsWaiting(false);
      callback();
    }
  }, [isWaiting, conditions]);
  return {
    isWaiting,
    run: () => setIsWaiting(true),
  };
};

function findDifferences(
  prev: any,
  current: any,
  path = '',
  seen = new WeakMap(),
  depth = 0,
): Record<string, { previous: any; current: any }> {
  const changes: Record<string, { previous: any; current: any }> = {};

  // recursion limit
  if (depth > 10) {
    return changes;
  }
  if (!prev || !current || typeof prev !== 'object' || typeof current !== 'object') {
    if (prev !== current) {
      changes[path || 'value'] = { previous: prev, current };
    }
    return changes;
  }

  if (seen.has(prev)) {
    return changes;
  }
  seen.set(prev, true);

  try {
    const keys = new Set([...Object.keys(prev), ...Object.keys(current)]);

    const skipKeys = new Set([
      '_owner',
      '_store',
      'ref',
      'key',
      '__proto__',
      'constructor',
      'props',
      'actualDuration',
      'baseDuration',
      'startTime',
      'commitTime',
      'interactions',
    ]);

    keys.forEach((key) => {
      if (skipKeys.has(key) || key.startsWith('__') || key.startsWith('_reactFragment')) {
        return;
      }

      const fullPath = path ? `${path}.${key}` : key;
      const prevValue = prev[key];
      const currentValue = current[key];

      if (
        prevValue instanceof Element ||
        currentValue instanceof Element ||
        prevValue instanceof Node ||
        currentValue instanceof Node
      ) {
        return;
      }

      if (typeof prevValue === 'function' && typeof currentValue === 'function') {
        return; // Пропускаем сравнение функций
      }

      if (
        typeof prevValue === 'object' &&
        prevValue !== null &&
        typeof currentValue === 'object' &&
        currentValue !== null
      ) {
        Object.assign(changes, findDifferences(prevValue, currentValue, fullPath, seen, depth + 1));
      } else if (!Object.is(prevValue, currentValue)) {
        changes[fullPath] = { previous: prevValue, current: currentValue };
      }
    });
  } catch (error) {
    console.warn('Error in findDifferences:', error);
  }

  return changes;
}

export function useDeepTraceUpdates(props: Record<string, any>, name: string) {
  const prevProps = useRef(props);

  useEffect(() => {
    try {
      const changes = findDifferences(prevProps.current, props);
      if (Object.keys(changes).length > 0) {
        console.log(`Component ${name} updated with changes:`, changes);
      }
      prevProps.current = { ...props };
    } catch (error) {
      console.warn('Error in useDeepTraceUpdates:', error);
    }
  }, [props, name]);
}

export function useDidValueChange(value) {
  const [hasChanged, setHasChanged] = useState<boolean>(false);
  const previousValue = useRef(value);

  useEffect(() => {
    if (previousValue.current !== value) {
      setHasChanged(true);
      previousValue.current = value;
    } else {
      setHasChanged(false);
    }
  }, [value]);

  return { hasChanged, setHasChanged };
}
