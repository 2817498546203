import { useContext } from 'react';
import find from 'lodash/find';

import { getHookState, Override } from '@utils';
import { QueryNamesEnums, HookState } from '@interfaces';
import { SettingsContext, useGraphQuery } from '@context';

export type ControllerInterface = Override<
  ReturnType<typeof useProject>,
  {
    state: HookState;
    servicingStatus: string;
    loanType: string;
  }
>;

export const useProject = (projectId: string) => {
  const { settings } = useContext(SettingsContext);
  const { loan_servicing_statuses, loan_types } = settings?.display || {};

  const project = useGraphQuery({
    type: QueryNamesEnums.GET_PROJECT,
    keys: [
      'estimated_start_date',
      'start_date',
      'original_completion_date',
      'estimated_completion_date',
      'loan',
      'retainage_rate',
    ],
    args: { project_id: projectId },
  });

  return {
    project: project.data,
    state: getHookState(project),
    servicingStatus: find(loan_servicing_statuses, {
      name: project.data?.loan?.servicing_status,
    })?.name_display,
    loanType: find(loan_types, {
      name: project.data?.loan?.type,
    })?.name_display,
  };
};
