import { PermissionNamesEnums, QueryNamesEnums } from '@interfaces';
import { FilterObject } from '../interface';
import { getUserFullName } from '@utils';

export const coordinatorFilter: FilterObject = {
  title: 'Coordinator',
  filterKey: 'coordinator',
  permissionKey: PermissionNamesEnums.DRAWREQUESTS_COORDINATOR_VIEW,
  getDataParamsPaginated: (pagination, q, skip) => ({
    type: QueryNamesEnums.GET_COORDINATORS,
    keys: ['id', 'first_name', 'last_name'],
    args: {
      is_draw_request_coordinator: 'true',
      pagination,
      q,
    },
    options: {
      skip,
      paginate: true,
    },
  }),
  strictSerialize: (data) => [
    {
      value: 'null',
      label: 'Unassigned',
    },
    ...data.map((item) => ({
      value: item.id,
      label: getUserFullName(item),
    })),
  ],
  cypressSelector: 'filters__coordinator',
  needToUpdateUrl: true,
  needToUpdateSetting: true,
  withPagination: true,
};
