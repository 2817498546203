export type UnwrapPromise<T> = T extends Promise<infer R> ? R : T;
export type FunctionArguments<T> = T extends (args: infer A) => any ? A : never;
export type FunctionReturnType<T> = T extends (...args: any[]) => infer R
  ? UnwrapPromise<R>
  : never;

// Helper type to get nested paths (max 3 levels)
type NestedPaths<T, Level extends 1 | 2 | 3 = 1, Prefix extends string = ''> = {
  [K in keyof T]: T[K] extends object
    ? T[K] extends Array<any>
      ? never
      : Level extends 3
        ? never
        : Prefix extends ''
          ? K | NestedPaths<T[K], Level extends 1 ? 2 : 3, `${K & string}`>
          :
              | `${Prefix}.${K & string}`
              | NestedPaths<T[K], Level extends 1 ? 2 : 3, `${Prefix}.${K & string}`>
    : never;
}[keyof T];

// Helper type to get type by path
type TypeByPath<T, P extends string> = P extends keyof T
  ? T[P]
  : P extends `${infer K}.${infer R}`
    ? K extends keyof T
      ? TypeByPath<T[K], R>
      : never
    : never;

export type NestedKeys<T> = {
  [P in NestedPaths<T>]?: TypeByPath<T, P & string> extends object
    ? (keyof TypeByPath<T, P & string>)[]
    : never;
};

export type DataProviderResultType<T> = T extends { results: (infer R)[] } ? R : T;
export type FilteredData<T, K extends keyof T> = Pick<T, K>;
export type ListDataProviderResultType<T> = T extends { results: (infer R)[] } ? R : never;
export type SingleDataProviderResultType<T> = T;

export type NestedStructure = {
  [key: string]: string[] | NestedStructure;
};

export interface GraphState {
  currentGraph: string;
  loadedGraph: string;
  readyToInit: boolean;
}

export interface QueryGraphLike {
  requestedGraph: string;
  loadedGraph: string;
  readyToInit?: boolean;
}

export type FilteredDataByGraph<T, K extends keyof T = never, N extends keyof T = never> = Pick<
  T,
  K
> & {
  [P in N]: T[P] extends (infer U)[]
    ? FilteredDataByGraph<U>[]
    : T[P] extends object
      ? FilteredDataByGraph<T[P]>
      : T[P];
};

export function normalizeGraph(keys: string[] = [], nested: Record<string, string[]> = {}): string {
  const structure: NestedStructure = {};

  // Sort regular keys
  [...keys].sort().forEach((key) => {
    if (!key.includes('.')) {
      structure[key] = [];
    }
  });

  // Sort and add nested keys
  Object.entries(nested)
    .sort(([a], [b]) => a.localeCompare(b))
    .forEach(([path, fields]) => {
      const parts = path.split('.');
      let current = structure;

      for (let i = 0; i < parts.length - 1; i++) {
        const part = parts[i];
        current[part] = current[part] || {};
        current = current[part] as NestedStructure;
      }

      const lastPart = parts[parts.length - 1];
      current[lastPart] = [...fields].sort();
    });

  return JSON.stringify(structure);
}

export function buildGraphQLQuery(
  keys: string[] = [],
  nested: Record<string, string[]> = {},
): string {
  // Convert the nested object into a structure
  const structure: NestedStructure = {};

  // Process regular keys
  keys.forEach((key) => {
    if (!key.includes('.')) {
      structure[key] = [];
    }
  });

  // Process nested keys
  Object.entries(nested).forEach(([path, fields]) => {
    const parts = path.split('.');
    let current = structure;

    // Create or get intermediate objects
    for (let i = 0; i < parts.length - 1; i++) {
      const part = parts[i];
      current[part] = current[part] || {};
      current = current[part] as NestedStructure;
    }

    // Add fields for the last level
    const lastPart = parts[parts.length - 1];
    current[lastPart] = fields;
  });

  // Function to recursively build the query string
  function stringifyStructure(obj: NestedStructure): string {
    return Object.entries(obj)
      .map(([key, value]) => {
        if (Array.isArray(value)) {
          return value.length ? `${key}{${value.join(',')}}` : key;
        }
        return `${key}{${stringifyStructure(value)}}`;
      })
      .join(',');
  }

  return `{${stringifyStructure(structure)}}`;
}

export function areGraphsEqual(
  prevGraph: { keys?: string[]; nested?: Record<string, string[]> },
  nextGraph: { keys?: string[]; nested?: Record<string, string[]> },
): boolean {
  return (
    normalizeGraph(prevGraph.keys, prevGraph.nested) ===
    normalizeGraph(nextGraph.keys, nextGraph.nested)
  );
}

export function mergeGraphs(graph1: string, graph2: string): string {
  const structure1 = JSON.parse(graph1) as NestedStructure;
  const structure2 = JSON.parse(graph2) as NestedStructure;

  function mergeStructures(target: NestedStructure, source: NestedStructure): NestedStructure {
    const result = { ...target };

    Object.entries(source).forEach(([key, value]) => {
      if (Array.isArray(value)) {
        // For arrays of fields, we perform a union
        const existingFields = Array.isArray(result[key]) ? (result[key] as string[]) : [];
        result[key] = [...new Set([...existingFields, ...value])].sort();
      } else {
        // For nested objects, we recursively merge
        const existingValue = result[key];
        if (existingValue && !Array.isArray(existingValue)) {
          result[key] = mergeStructures(existingValue as NestedStructure, value);
        } else {
          result[key] = value;
        }
      }
    });

    return result;
  }

  return JSON.stringify(mergeStructures(structure1, structure2));
}

export function isEmptyGraph(graph?: string): boolean {
  if (!graph) return true;
  const structure = JSON.parse(graph);
  return Object.keys(structure).length === 0;
}

export function getDeltaGraph(
  currentGraph: string = normalizeGraph([], {}),
  loadedGraph: string = normalizeGraph([], {}),
): string {
  const current = JSON.parse(currentGraph) as NestedStructure;
  const loaded = JSON.parse(loadedGraph) as NestedStructure;
  const delta: NestedStructure = {};

  function compareStructures(
    target: NestedStructure,
    source: NestedStructure,
    result: NestedStructure,
  ) {
    Object.entries(target).forEach(([key, targetValue]) => {
      const sourceValue = source[key];

      if (!sourceValue) {
        // Field is missing in source - add it completely
        result[key] = targetValue;
      } else if (Array.isArray(targetValue)) {
        // For arrays of fields, find new ones
        const sourceFields = Array.isArray(sourceValue) ? sourceValue : [];
        const newFields = targetValue.filter((field) => !sourceFields.includes(field));
        if (newFields.length) {
          result[key] = newFields;
        }
      } else if (typeof targetValue === 'object') {
        // For nested objects, recursively compare
        if (typeof sourceValue === 'object' && !Array.isArray(sourceValue)) {
          const nestedDelta = {};
          compareStructures(targetValue, sourceValue, nestedDelta);
          if (Object.keys(nestedDelta).length) {
            result[key] = nestedDelta;
          }
        } else {
          result[key] = targetValue;
        }
      }
    });
  }

  compareStructures(current, loaded, delta);
  return JSON.stringify(delta);
}

export function filterDataByGraph<T extends object>(
  data: T | null | undefined,
  graph: string,
): FilteredDataByGraph<NonNullable<T>> {
  if (!data) return {} as FilteredDataByGraph<NonNullable<T>>;

  const structure = JSON.parse(graph) as NestedStructure;
  const result = {} as FilteredDataByGraph<NonNullable<T>>;

  function filterByStructure(source: any, struct: NestedStructure, target: any) {
    Object.entries(struct).forEach(([key, value]) => {
      if (!source || !(key in source)) {
        return;
      }

      if (Array.isArray(value)) {
        if (value.length === 0) {
          target[key] = source[key];
        } else {
          target[key] = value.reduce((acc, prop) => {
            acc[prop] = source[key][prop];
            return acc;
          }, {});
        }
      } else if (typeof value === 'object') {
        if (Array.isArray(source[key])) {
          target[key] = source[key].map((item: any) => {
            const nestedTarget = {};
            filterByStructure(item, value, nestedTarget);
            return nestedTarget;
          });
        } else {
          target[key] = {};
          filterByStructure(source[key], value, target[key]);
        }
      }
    });
  }

  // Handle { results: [] } format
  if ('results' in data && Array.isArray((data as any).results)) {
    const resultData = data as { results: any[] };
    (result as any).results = resultData.results.map((item: any) => {
      const itemResult = {};
      filterByStructure(item, structure, itemResult);
      return itemResult;
    });
    return result;
  }

  filterByStructure(data, structure, result);
  return result;
}

export const getArgumentsForGetterByGraph = <T>(graph: string, args: T) => {
  const structure = JSON.parse(graph) as NestedStructure;

  function stringifyStructure(obj: NestedStructure): string {
    return Object.entries(obj)
      .map(([key, value]) => {
        if (Array.isArray(value)) {
          return value.length ? `${key}{${value.join(',')}}` : key;
        }
        return `${key}{${stringifyStructure(value)}}`;
      })
      .join(',');
  }

  return {
    ...args,
    restQlParams: `{${stringifyStructure(structure)}}`,
  };
};

/**
 * Creates a filter graph by merging keys and nested graphs
 * @param keys - Array of keys to include in the graph
 * @param nested - Record of nested keys and their values
 * @returns Merged graph string
 */
export function createFilterGraph(
  keys: string[] = [],
  nested: Record<string, string[]> = {},
): string {
  const keysGraph = normalizeGraph(keys, {});
  const nestedGraph = normalizeGraph([], nested);
  const result = mergeGraphs(keysGraph, nestedGraph);

  return result;
}
