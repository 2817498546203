import { useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useSafeSnackbar } from '@hooks';
import { LineItemModalTypeEnums } from '@interfaces';
import fieldsConfigs from './fields';
import mutationConfigs from './mutations';
import { useStringFieldModel } from '@models';
import { useMutation, useQueryClient } from 'react-query';
import { LineItemEditControllerProps } from './interface';

export const useLineItemEdit = ({
  modalConfig,
  setLineItemModal,
  requestId,
}: LineItemEditControllerProps) => {
  const { projectId } = useParams();
  const { fields, submitAction, deleteAction, lineItem } = modalConfig;
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSafeSnackbar();

  const mutationConfig = mutationConfigs[submitAction](projectId, requestId);
  const deleteMutationConfig = mutationConfigs[deleteAction]?.(projectId, requestId);

  const submitMutation = useMutation<
    Parameters<typeof mutationConfig.api>[0],
    Error,
    Awaited<ReturnType<typeof mutationConfig.api>>
  >(mutationConfig.api, {
    onSuccess: () => {
      Object.keys(mutationConfig.invalidation).forEach((key) => {
        queryClient.invalidateQueries([key, { ...mutationConfig.invalidation[key] }]);
      });
      onClose();
    },
    onError: (error) => {
      enqueueSnackbar(error.message, { variant: 'error' });
    },
  });

  const deleteMutation = useMutation(deleteMutationConfig?.api, {
    onSuccess: () => {
      Object.keys(deleteMutationConfig?.invalidation).forEach((key) => {
        queryClient.invalidateQueries([key, { ...deleteMutationConfig?.invalidation[key] }]);
      });
      onClose();
    },
    onError: (error) => {
      enqueueSnackbar(error['message'], { variant: 'error' });
    },
  });

  const fieldsModels = fields.reduce(
    (models, field) => ({
      ...models,
      [field]: useStringFieldModel(fieldsConfigs[field](lineItem).modelProps),
    }),
    {},
  );

  const handleSubmitClick = useCallback(() => {
    const mutationVariables = { projectId };
    if (requestId) mutationVariables['drawRequestId'] = requestId;
    if (lineItem?.id) mutationVariables['milestoneId'] = lineItem?.id;

    fields.forEach((field) => {
      mutationVariables[field] = fieldsModels[field].value;
    });

    submitMutation.mutateAsync({ ...mutationVariables });
  }, [requestId, projectId, fieldsModels, fields]);

  const onClose = () => {
    setLineItemModal({ open: false, type: LineItemModalTypeEnums.ADD, lineItem: null });
  };

  const handleDeleteClick = useCallback(() => {
    if (!deleteMutationConfig) return;
    const deleteVariables = { projectId };
    if (requestId) deleteVariables['drawRequestId'] = requestId;
    if (lineItem?.id) deleteVariables['milestoneId'] = lineItem?.id;
    deleteMutation.mutateAsync(deleteVariables as any);
  }, [lineItem, requestId, projectId, deleteMutation]);

  const isValidRequest = useMemo(() => {
    if (!mutationConfig.validationRule) return true;
    const validationVariables = {};
    fields.forEach((field) => {
      validationVariables[field] = fieldsModels[field].value;
    });
    return mutationConfig.validationRule
      ? mutationConfig.validationRule(validationVariables)
      : true;
  }, [fieldsModels, mutationConfig]);

  return {
    onClose,
    handleSubmitClick,
    handleDeleteClick,
    isSubmitting: false,
    isDeleting: false,
    fieldsModels,
    isValidRequest,
  };
};
