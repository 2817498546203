import { FunctionArguments, FunctionReturnType, NestedKeys, FilteredDataByGraph } from '../types';
import { dataProviders } from '../provider';
import { useMemo, useEffect, useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { filterDataByGraph, createFilterGraph, QueryGraphLike } from '@utils';
import isEqual from 'lodash/isEqual';

function useGraphReady(queryKey: unknown[]) {
  const [isReady, setIsReady] = useState<Record<string, boolean>>({});
  const queryClient = useQueryClient();
  const graphQueryKey = queryClient.getGraphQueryKey(queryKey);
  const query = queryClient.getQueryCache();
  const shortKey = useMemo<string>(() => JSON.stringify(graphQueryKey), [queryKey]);

  useEffect(() => {
    const unsubscribe = query.subscribe((event) => {
      if (event.type === 'queryUpdated' && event.query.queryKey[0] === '_graphState') {
        if (isEqual(graphQueryKey, event.query.queryKey)) {
          const graphState = event.query.state.data as QueryGraphLike;
          if (graphState.readyToInit) {
            setIsReady((data) => ({ ...data, [shortKey]: true }));
          }
        }
      }
    });

    return () => {
      unsubscribe();
    };
  }, [shortKey]);

  return Boolean(isReady[shortKey]);
}

export function useGraphQuery<
  P extends keyof typeof dataProviders,
  ReturnType = FunctionReturnType<(typeof dataProviders)[P]>,
  IsListType = ReturnType extends { results: any[] } ? true : false,
  DataType = IsListType extends true
    ? ReturnType extends { results: (infer R)[] }
      ? R
      : never
    : ReturnType,
  K extends keyof DataType = keyof DataType,
  O extends { skip?: boolean } = { skip?: boolean },
  A extends FunctionArguments<(typeof dataProviders)[P]> = FunctionArguments<
    (typeof dataProviders)[P]
  >,
>({
  type,
  keys,
  args,
  nested,
  options,
}: {
  type: P;
  keys?: K[];
  nested?: NestedKeys<DataType>;
  args: A;
  options?: O;
}) {
  type FilteredType = FilteredDataByGraph<DataType, K>;
  type ReturnDataType = IsListType extends true ? { results: FilteredType[] } : FilteredType;

  const queryClient = useQueryClient();
  const queryKey = useMemo<[P, A]>(() => [type, args], [type, args]);
  const shortKey = useMemo<string>(() => JSON.stringify(queryKey), [queryKey]);

  const isReady = useGraphReady(queryKey);

  const filterGraph = useMemo(() => {
    const graph = createFilterGraph(keys as string[], nested as Record<string, string[]>);
    return graph;
  }, [keys, nested]);

  const { dataUpdatedAt, ...queryData } = useQuery(
    queryKey,
    queryClient.getGraphBoundFunction(queryKey),
    {
      enabled: isReady && !options?.skip,
      select: (data) => filterDataByGraph(data as object, filterGraph) as ReturnDataType,
    },
  );

  useEffect(() => {
    queryClient.graphRegister(
      queryKey,
      {
        keys: keys as string[],
        nested: nested as Record<string, string[]>,
      },
      {
        automaticReady: !options?.skip,
      },
    );
  }, [options?.skip, shortKey]);

  return queryData;
}
