import { useContext, useMemo } from 'react';
import { useQueries } from 'react-query';
import {
  HookState,
  PaymentConfiguration,
  PermissionNamesEnums,
  QueryNamesEnums,
} from '@interfaces';
import { getProjectFunds } from '@globalService';
import { useNavigate, useParams } from 'react-router-dom';
import {
  getAmountFromSource,
  getHookState,
  isCreatedProject,
  isAllowed,
  percentFormatter,
} from '@utils';
import { PermissionsContext, useGraphQuery } from '@context';

export interface ControllerInterface {
  state: HookState;
  originalBorrowerEquity: number;
  constructionHoldback: number;
  originalEstimate: number;
  revisedEstimate: number;
  feesAmount: number;
  paymentModelText: string;
  navigateToPaymentSettings: (navigateTo: string) => void;
  canEditFees: boolean;
  canEditProjectModel: boolean;
}

export const useProjectPaymentSummary = (): ControllerInterface => {
  const { projectId } = useParams();
  const navigate = useNavigate();
  const { permissions } = useContext(PermissionsContext);

  const project = useGraphQuery({
    type: QueryNamesEnums.GET_PROJECT,
    keys: [
      'original_estimate',
      'revised_estimate',
      'fees',
      'payment_configuration_type',
      'construction_holdback_rate',
      'borrower_equity_rate',
      'status',
    ],
    args: { project_id: projectId },
    options: {
      skip: !projectId,
    },
  });

  const requestedProjectQueries = useQueries([
    {
      queryKey: [QueryNamesEnums.GET_PROJECT_FUNDS, { projectId }],
      queryFn: getProjectFunds.bind(this, projectId),
      enabled: Boolean(projectId),
    },
  ]);

  const sources = useMemo(
    () => requestedProjectQueries[0]?.data?.sources?.items,
    [requestedProjectQueries[0]?.data?.sources?.items],
  );

  const originalBorrowerEquity = useMemo(
    () => getAmountFromSource(sources, 'BORROWER_EQUITY'),
    [sources],
  );

  const constructionHoldback = useMemo(
    () => getAmountFromSource(sources, 'CONSTRUCTION_HOLDBACK'),
    [sources],
  );

  const originalEstimate = useMemo(() => project.data?.original_estimate, [project.data]);
  const revisedEstimate = useMemo(() => project.data?.revised_estimate, [project.data]);
  const feesAmount = useMemo(
    () => project.data?.fees?.reduce((prev, curr) => prev + curr.amount, 0),
    [project.data?.fees],
  );

  const paymentModelText = useMemo(() => {
    const configurationType =
      project.data?.payment_configuration_type === PaymentConfiguration.PER_DRAW_REQUEST
        ? 'Partial'
        : 'Sequential';
    return `${percentFormatter({ value: project.data?.construction_holdback_rate })} / ${percentFormatter({ value: project.data?.borrower_equity_rate })} Loan to equity, ${configurationType}`;
  }, [project.data]);

  const canEditFees = useMemo(
    () => isAllowed(PermissionNamesEnums.PAYMENTS_MARK_AS_PAID, permissions),
    [permissions],
  );

  const canEditProjectModel = useMemo(
    () =>
      isCreatedProject(project.data?.status) &&
      isAllowed(PermissionNamesEnums.PROJECT_ONBOARDING, permissions),
    [permissions, project.data?.status],
  );

  const navigateToPaymentSettings = (navigateTo: string) => {
    navigate(`/projects/${projectId}/settings/${navigateTo}`, {
      state: { prevUrl: `/projects/${projectId}/payments/all` },
    });
  };

  return {
    state: getHookState(requestedProjectQueries),
    originalBorrowerEquity,
    constructionHoldback,
    originalEstimate,
    revisedEstimate,
    feesAmount,
    paymentModelText,
    navigateToPaymentSettings,
    canEditFees,
    canEditProjectModel,
  };
};
