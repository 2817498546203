/* eslint-disable @typescript-eslint/no-unused-vars */

export const colors = {
  primaryLight: '#00D394',
  secondary: '#113858',
  secondaryLight: '#2D9CDB',
  white: 'white',
  disabled: '#E0E0E0',
  alert: '#EB5757',
  alert2: '#F2994A',
  border: '#E4E7EB',
  uploaderBackground: '#f4f4f4',
  text: {
    light: '#8A8A8A',
    medium: '#585858',
    dark: '#262626',
    heading: '#1F4739',
    link: '#577A69',
  },
  status: {
    warning: {
      dark: '#C78F00',
      medium: '#EBA800',
      light: '#FFE194',
      lighter: '#FFFCF5',
    },
    success: {
      dark: '#00772E',
      medium: '#28C366',
      light: '#A8FFCA',
      lighter: '#F0FFF5',
    },
    main: {
      dark: '#143E2F',
      medium: '#1F4739',
      light: '#5E8376',
      lighter: '#F0F5F3',
    },
    error: {
      dark: '#770000',
      medium: '#D02C2B',
      light: '#FFA8A8',
      lighter: '#FFF0F0',
    },
    information: {
      dark: '#004995',
      medium: '#2778C4',
      light: '#BDDDFA',
      lighter: '#F0F7FF',
      lightest: '#FAFCFF',
    },
    orange: {
      dark: '#C76300',
      medium: '#EB7500',
      light: '#FFC994',
      lighter: '#FFF5EB',
    },
    violet: {
      medium: '#8B4188',
      lighter: '#FAF0F9',
      light: '#D49BD2',
    },
  },
  neutral: {
    darkest: '#262626',
    darker: '#585858',
    dark: '#8A8A8A',
    medium: '#BCBCBC',
    light: '#D2D2D2',
    lighter: '#E8E8E8',
    lightest: '#F7F7F7',
    white: '#FFFFFF',
  },
  background: {
    gray: '#F7F7F7',
    white: '#FFFFFF',
    lightest: '#F0F5F3',
  },
  main: {
    primary: {
      darkest: '#042419',
      darker: '#0B3325',
      dark: '#143E2F',
      main: '#1F4739',
      light: '#5E8376',
      lighter: '#A6BFB6',
      lightest: '#F0F5F3',
    },
  },
  icons: {
    gray: '#5A5A5A',
    green: '#1F4739',
  },
  button: {
    gradient: 'linear-gradient(to right, #042419, 75%, #A6BFB6)',
  },
};

// Superset color palletes, don't remove
const qualitativePalette = [
  '#A9C3B9',
  '#61877A',
  '#204B3C',
  '#D2D2D2',
  '#8A8A8A',
  '#585858',
  '#EEBE86',
  '#B97F3C',
  '#954F00',
];
export const sequentialMultiHuePalette = [
  '#1F4739',
  '#3B5542',
  '#54634B',
  '#6D7255',
  '#86815E',
  '#9F9068',
  '#B99F72',
  '#D3AE7C',
  '#EEBE86',
  '#B97F3C',
  '#C08C55',
  '#C69A6D',
  '#CBA886',
  '#CEB69F',
  '#D1C4B8',
  '#D2D2D2',
  '#D2D2D2',
  '#B2B9B6',
  '#94A19B',
  '#768A81',
  '#587368',
  '#3C5D50',
  '#1F4739',
];

const primaryColor10 = [
  '#2F5C4D',
  '#386656',
  '#416F60',
  '#4B7869',
  '#568071',
  '#61877A',
  '#779D90',
  '#8EB3A6',
  '#A7C7BC',
  '#C1D9D1',
  '#DCEBE6',
];

const primaryColor20 = [
  '#2F5C4D',
  '#336152',
  '#386656',
  '#3C6B5B',
  '#416F60',
  '#467464',
  '#4B7869',
  '#507C6D',
  '#568071',
  '#5B8376',
  '#61877A',
  '#6C9285',
  '#779D90',
  '#83A89B',
  '#8EB3A6',
  '#9BBDB1',
  '#A7C7BC',
  '#B4D0C6',
  '#C1D9D1',
  '#CEE2DB',
  '#DCEBE6',
];
