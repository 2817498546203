import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useParams } from 'react-router-dom';
import cloneDeep from 'lodash/cloneDeep';
import { Typography } from '@mui/material';

import {
  IDrawRequest,
  IFilterOption,
  IMenuItem,
  IMilestone,
  IMilestoneTotal,
  IPHBTableItem,
  IRightMenu,
  LineItemFilterEnum,
  MediaFile,
  PermissionNamesEnums,
  TableKeyEnum,
} from '@interfaces';
import {
  autofillCanBeShown,
  canAddNewLine,
  canDeleteRequest,
  checkIfHistoricalRequestEditable,
  checkIsCreator,
  checkIsOwner,
  checkIsTableEdit,
  getActivePHBGrouping,
  getItemLocalHighlight,
  getPHBTableItemsByActiveView,
  getPHBViewTypes,
  getTeamRole,
  getTooltipText,
  getTypeFilterValue,
  isAllowed,
  isCurrentPHBViewLoading,
  isDrawRequest,
  isLineItemView,
  isReallocationEnabled,
  isReallocationEnabledBasedOnCRMode,
  isRequestInReview,
  setDefaultPHBView,
  useExpandCollapseTable,
  useLoadingSkeleton,
  usePHBFilters,
  usePHBGrouping,
  usePHBNaming,
} from '@utils';
import {
  useCommentsAndDocumentsPreview,
  useImagePicker,
  useRightMenu,
  useSafeSnackbar,
  useUpdateUiSettings,
  useUrlParams,
} from '@hooks';
import { AuthContext, SettingsContext, useLaunchDarklyFlags, PermissionsContext } from '@context';
import { getInitColumns } from './getColumns';
import { useGetQueries } from './queries';
import { LineItemFilterValues } from '@constants';
import { colors } from '@theme';
import { AddProofpointIcon, DeleteIcon } from '@svgAsComponents';
import { postDrawRequestReport } from '@globalService';

export type RequestTableControllerInterface = {
  initColumns: string[];
  tableItems: IPHBTableItem[];
  onExpandClick: (id: string, isExpanded: boolean) => void;
  filterOptions: IFilterOption[];
  filterValue: string;
  handleShowFilterClick: (value: string) => void;
  patchMsGroup: (params) => void;
  isLoading: boolean;
  showDeleteModal: boolean;
  deleteRequest: () => Promise<Response>;
  isDeleting: boolean;
  showAutofillButton: boolean;
  drawRequest: IDrawRequest;
  handleAutofillLenderAllowance: (autofillValue: string) => void;
  isAutofillLoading: boolean;
  totals?: IMilestoneTotal;
  groupByKeys: string;
  updateRightDrawer: () => () => void;
  rightMenu: IRightMenu;
  onMilestoneUpdate: (data: IMilestone) => void;
  activeView: string;
  setActiveView: (state: string) => void;
  isLineItemsView: boolean;
  viewTypes: { label: string; value: string }[];
  typeFilterValues: string[];
  setTypeFilterValues: Dispatch<SetStateAction<string[]>>;
  openLumpSumModal: boolean;
  setOpenLumpSumModal: Dispatch<SetStateAction<boolean>>;
  isLumpSumEnabled: boolean;
  invalidationAfterBulkUpdate: () => void;
  isAddLineItemPopupOpen: boolean;
  setIsAddLineItemPopupOpen: Dispatch<SetStateAction<boolean>>;
  menuItems: IMenuItem[];
  setShowDeleteModal: Dispatch<SetStateAction<boolean>>;
  onNewLineItemAdded: () => Promise<void>;
  canViewReport: boolean;
  openPdfReport: () => void;
  reportCallback: (e: React.MouseEvent) => Promise<void>;
  pdf: MediaFile[];
  closeReport: () => void;
};

export const useRequestTable = (isSubmissionProcess = false): RequestTableControllerInterface => {
  const { projectId, requestId: drawRequestId, action } = useParams();
  const { user } = useContext(AuthContext);
  const { permissions } = useContext(PermissionsContext);
  const teamRole = getTeamRole(user);
  const isEditTable = useMemo(() => checkIsTableEdit(action), [action]);
  const flags = useLaunchDarklyFlags();
  const [openLumpSumModal, setOpenLumpSumModal] = useState(false);
  const { settings } = useContext(SettingsContext);
  const { updateSettings } = useUpdateUiSettings();
  const [unitsTableItems, setUnitsTableItems] = useState<IPHBTableItem[]>([]);
  const [lineItemsTableItems, setLineItemsTableItems] = useState<IPHBTableItem[]>([]);
  const [modelsTableItems, setModelsTableItems] = useState<IPHBTableItem[]>([]);
  const [typeFilterValues, setTypeFilterValues] = React.useState<string[]>([
    'is_horizontal=true',
    'is_vertical=true',
  ]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [isAddLineItemPopupOpen, setIsAddLineItemPopupOpen] = useState(false);
  const { showLoadingSkeleton, showTemporaryLoadingSkeleton } = useLoadingSkeleton();
  const { unitName, modelName } = usePHBNaming();
  const { isCurrentProjectArchived } = useContext(SettingsContext);
  const { enqueueSnackbar } = useSafeSnackbar();

  const viewTypes = useMemo(() => getPHBViewTypes({ unitName, modelName }), [unitName, modelName]);
  const [activeView, setActiveView] = useUrlParams(
    settings.personal_setting?.PHB_TABLE_VIEW?.view_type || viewTypes[0].value,
    'view',
    (s) => s.toString(),
    (s) => s.toString(),
  );
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  useEffect(() => {
    setDefaultPHBView({
      settings,
      isFirstLoad,
      setIsFirstLoad,
      viewTypes,
      activeView,
      setActiveView,
    });
  }, [settings.personal_setting?.PHB_TABLE_VIEW?.view_type, isFirstLoad, activeView]);
  const isLineItemsView = useMemo(() => isLineItemView(activeView), [activeView]);

  const { updateCommentsPreviewInfo } = useCommentsAndDocumentsPreview({
    projectId,
    drawRequestId,
  });
  const { handleRightDrawerOpenerClick, ...rightMenu } = useRightMenu({
    onClose: updateCommentsPreviewInfo,
  });
  const updateRightDrawer = () => () => {
    handleRightDrawerOpenerClick({ title: 'Comments' });
  };

  const createTableObject = ({
    item,
    isExpanded = false,
    isNested = false,
    index,
    isModelLevel = false,
  }: {
    item: IMilestone;
    isExpanded?: boolean;
    isNested?: boolean;
    index: number | string;
    isModelLevel?: boolean;
  }): IPHBTableItem => ({
    ...item,
    activeToEdit,
    localNew: false,
    canAddPhotos: activeToEdit,
    localHighlight: getItemLocalHighlight(item),
    canBeExpanded: !isModelLevel && item?.milestone_groups?.length > 0,
    localIsUserCreator: checkIsCreator(drawRequest, teamRole),
    isExpanded,
    isNested,
    index,
    isSubmission: isSubmissionProcess,
    project_milestone: { ...item?.project_milestone, index },
  });

  const unitsNestedTableProps = useExpandCollapseTable({
    setListItems: setUnitsTableItems,
    createTableObject,
  });

  const lineItemsNestedTableProps = useExpandCollapseTable({
    setListItems: setLineItemsTableItems,
    createTableObject,
  });

  const { filterValue, handleFilterClick, filterOptions, filterTotalKey, filterKey } =
    usePHBFilters({
      tableKey: TableKeyEnum.PHB_REQUEST_LINE_ITEMS,
    });

  const {
    requestedDataQueries,
    deleteDrawRequestMutation,
    unitsQuery,
    lineItemsQuery,
    drawRequestsQuery,
    bulkMilestoneMutation,
    onMilestoneUpdate,
    patchMSGroupMutation,
    modelsQuery,
    invalidationAfterBulkUpdate,
  } = useGetQueries({
    filterTotalKey,
    filterKey,
    updateUnitsListItemsWithMsList: unitsNestedTableProps.updateListItemsWithMsList,
    updateLineItemsListItemsWithMsList: lineItemsNestedTableProps.updateListItemsWithMsList,
    isSubmissionProcess,
    updateUnitsListItemsWithParentGroup: unitsNestedTableProps.updateListItemsWithParentGroup,
    updateLineItemsListItemsWithParentGroup:
      lineItemsNestedTableProps.updateListItemsWithParentGroup,
    typeFilterValue: getTypeFilterValue(typeFilterValues),
    setUnitsTableItems,
    setLineItemsTableItems,
    setModelsTableItems,
    activeView,
  });
  const { unitLineItemGrouping, lineItemUnitGrouping, modelUnitsGrouping } = usePHBGrouping();
  const activeGroupBy = useMemo(
    () =>
      getActivePHBGrouping({
        activeView,
        unitLineItemGrouping,
        lineItemUnitGrouping,
        modelUnitsGrouping,
      }),
    [activeView, unitLineItemGrouping, lineItemUnitGrouping, modelUnitsGrouping],
  );

  const project = requestedDataQueries[0].data;
  const drawRequest = requestedDataQueries[1].data;
  const requestMilestones = unitsQuery.data;

  const isLumpSumEnabled = useMemo(() => project?.data?.is_lump_sum_enabled, [project]);

  const retainageRate = useMemo(() => project?.data?.retainage_rate, [project]);

  const isReallocationAllowed = useMemo(() => {
    if (flags?.['ENG_9804_lock_joint_request_based_on_CR_mode']) {
      return isReallocationEnabledBasedOnCRMode(drawRequest, project);
    }
    return isReallocationEnabled(drawRequest, project);
  }, [drawRequest, project, flags]);

  const isHistoricalRequestEditable = useMemo(
    () =>
      checkIfHistoricalRequestEditable({
        request: drawRequest,
        project: project,
        projectRequestsList: drawRequestsQuery.data?.results,
      }),
    [drawRequest, project, drawRequestsQuery.data?.results],
  );

  const initColumns = useMemo(
    () =>
      getInitColumns({
        isSubmissionProcess,
        isReallocationAllowed,
        drawRequest,
        retainageRate,
        flags,
      }),
    [isSubmissionProcess, retainageRate, isReallocationAllowed, drawRequest, project, flags],
  );

  const activeToEdit = useMemo(
    () =>
      (drawRequest?.waits_current_user_approval && !checkIsOwner(teamRole)) ||
      (isAllowed(PermissionNamesEnums.CUSTOMER_SUCCESS_ACCESS, permissions) &&
        isRequestInReview(drawRequest?.status)) ||
      isHistoricalRequestEditable ||
      isEditTable,
    [drawRequest, teamRole, isEditTable, isHistoricalRequestEditable, permissions],
  );

  useEffect(() => {
    setUnitsTableItems(null);
    setModelsTableItems(null);
  }, [filterValue, drawRequestId]);

  useEffect(() => {
    setLineItemsTableItems(null);
  }, [typeFilterValues, drawRequestId]);

  useEffect(() => {
    if (!requestMilestones?.results || unitsTableItems?.length || !drawRequest) return;
    const clonedMilestones = cloneDeep(requestMilestones.results);
    setUnitsTableItems(
      clonedMilestones.map((item: IMilestone, index: number) => createTableObject({ item, index })),
    );
  }, [requestMilestones?.results, drawRequest, unitsTableItems]);

  useEffect(() => {
    if (!lineItemsQuery.data?.results || lineItemsTableItems?.length || !drawRequest) return;
    const clonedMilestones = cloneDeep(lineItemsQuery.data?.results);
    setLineItemsTableItems(
      clonedMilestones.map((item: IMilestone, index: number) => createTableObject({ item, index })),
    );
  }, [lineItemsQuery.data?.results, drawRequest, lineItemsTableItems]);

  useEffect(() => {
    if (!modelsQuery.data?.results || modelsTableItems?.length || !drawRequest) return;
    const clonedMilestones = cloneDeep(modelsQuery.data?.results);
    setModelsTableItems(
      clonedMilestones.map((item: IMilestone, index: number) =>
        createTableObject({ item, index, isModelLevel: true }),
      ),
    );
  }, [modelsQuery.data?.results, drawRequest, modelsTableItems]);

  const patchMsGroup = useCallback(
    (params) =>
      patchMSGroupMutation.mutate({
        project: projectId,
        drawRequest: drawRequestId,
        milestoneId: params.milestoneId,
        group_by: activeGroupBy,
        json: params.json,
      }),
    [drawRequestId, projectId, activeGroupBy, patchMSGroupMutation],
  );

  const deleteRequest = useCallback(
    () =>
      deleteDrawRequestMutation.mutateAsync({
        project: projectId,
        drawRequest: drawRequestId,
      }),
    [deleteDrawRequestMutation, projectId, drawRequestId],
  );

  const handleAutofillLenderAllowance = (autofillValue) => {
    const json = {
      autofill_key: autofillValue,
    };
    bulkMilestoneMutation.mutate({
      projectId,
      drawRequestId,
      json,
    });
  };

  const showAutofillButton = useMemo(
    () => autofillCanBeShown(drawRequest, teamRole),
    [drawRequest, teamRole],
  );

  const tableItems = useMemo(
    () =>
      getPHBTableItemsByActiveView({
        unitsTableItems,
        modelsTableItems,
        lineItemsTableItems,
        activeView,
      }),
    [lineItemsTableItems, unitsTableItems, modelsTableItems, activeView],
  );

  const isCurrentViewFetching = useMemo(
    () => isCurrentPHBViewLoading({ activeView, lineItemsQuery, unitsQuery, modelsQuery }),
    [activeView, lineItemsQuery, unitsQuery, modelsQuery],
  );

  const isLoading = useMemo(
    () => showLoadingSkeleton || isCurrentViewFetching || unitsQuery.isIdle,
    [showLoadingSkeleton, isCurrentViewFetching, unitsQuery.isIdle],
  );

  const showAddNewLine = useMemo(
    () => canAddNewLine({ drawRequest, permissions, teamRole, isReallocationAllowed }),
    [drawRequest, permissions, teamRole, isReallocationAllowed],
  );

  const menuItems = useMemo(
    () => [
      ...(canDeleteRequest(drawRequest)
        ? [
            {
              action: () => setShowDeleteModal(true),
              text: (
                <Typography variant="body3SemiBold" sx={{ color: colors.status.error.medium }}>
                  Delete request
                </Typography>
              ),
              icon: <DeleteIcon size={16} color={colors.status.error.medium} />,
              disabled: isCurrentProjectArchived,
              disabledTooltipText: getTooltipText({ isCurrentProjectArchived }),
              dataTestName: 'delete_request__menu_item',
            },
          ]
        : []),
      ...(showAddNewLine && flags?.['ENG_9261_add_phb_line_item']
        ? [
            {
              text: <Typography variant="body3SemiBold">Add line item</Typography>,
              icon: <AddProofpointIcon size={16} />,
              action: () => setIsAddLineItemPopupOpen(true),
              dataTestName: 'request__add_line_item__button',
            },
          ]
        : []),
    ],
    [drawRequest, isCurrentProjectArchived, flags, showAddNewLine],
  );

  const onNewLineItemAdded = async () => {
    await unitsQuery.refetch();
    await lineItemsQuery.refetch();
    await modelsQuery.refetch();
    setUnitsTableItems(null);
    setLineItemsTableItems(null);
    setModelsTableItems(null);
  };

  const canViewReport = useMemo(
    () =>
      isDrawRequest(drawRequest) &&
      !isSubmissionProcess &&
      isAllowed(PermissionNamesEnums.PROJECTS_REPORT_VIEW, permissions),
    [permissions, drawRequest, isSubmissionProcess],
  );

  const { pdf, open, close } = useImagePicker();

  const openPdfReport = useCallback(() => {
    open([drawRequest.report]);
  }, [open, drawRequest]);

  const reportCallback = useCallback(
    async (e: React.MouseEvent) => {
      try {
        e?.stopPropagation?.();
        await postDrawRequestReport({ project: projectId, drawRequest: drawRequestId });
        enqueueSnackbar('Report generation has started. It will be available in a few minutes.', {
          variant: 'info',
        });
      } catch (e) {
        console.log({ e });
        enqueueSnackbar('Generate Error', { variant: 'error' });
      }
    },
    [drawRequestId, projectId],
  );

  return {
    initColumns,
    tableItems,
    onExpandClick: isLineItemsView
      ? lineItemsNestedTableProps.onExpandClick
      : unitsNestedTableProps.onExpandClick,
    filterOptions,
    filterValue,
    handleShowFilterClick: (value: string) => {
      handleFilterClick(value);
      showTemporaryLoadingSkeleton();
    },
    patchMsGroup,
    isLoading,
    showDeleteModal,
    deleteRequest,
    isDeleting: deleteDrawRequestMutation.isLoading,
    showAutofillButton,
    drawRequest,
    handleAutofillLenderAllowance,
    isAutofillLoading: bulkMilestoneMutation.isLoading,
    totals: isLineItemsView
      ? undefined
      : drawRequest?.totals?.[LineItemFilterValues[LineItemFilterEnum.VERTICAL_COST].totalKey],
    groupByKeys: unitLineItemGrouping,
    updateRightDrawer,
    rightMenu,
    onMilestoneUpdate,
    activeView,
    setActiveView: (view) => {
      setActiveView(view);
      updateSettings({
        personal_setting: {
          PHB_TABLE_VIEW: { view_type: view },
        },
      });
    },
    isLineItemsView,
    viewTypes,
    typeFilterValues,
    setTypeFilterValues,
    openLumpSumModal,
    setOpenLumpSumModal,
    isLumpSumEnabled,
    invalidationAfterBulkUpdate,
    isAddLineItemPopupOpen,
    setIsAddLineItemPopupOpen,
    menuItems,
    setShowDeleteModal,
    onNewLineItemAdded,
    canViewReport,
    openPdfReport,
    reportCallback,
    pdf,
    closeReport: close,
  };
};
