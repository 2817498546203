import { useContext, useMemo } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useSnackbar } from 'notistack';

import {
  EnumTypeForList,
  ErrorDual,
  IProject,
  IProjectDetailsFields,
  IProjectProperty,
  IPropertyDetail,
  PatchBuildingParam,
  QueryNamesEnums,
} from '@interfaces';
import {
  createProjectBuilding,
  getProjectModels,
  patchProjectBuilding,
  updateProjectFields,
} from '@globalService';
import { useProjectDetailsFields } from '@hooks';
import { findIdInArrByKey, parsePathErrorDual, Override } from '@utils';
import { SettingsContext, useGraphQuery } from '@context';
import { useParams } from 'react-router-dom';

export type ControllerInterface = Override<
  ReturnType<typeof useGeneralSettings>,
  {
    handleSubmitClick: () => Promise<boolean>;
    isSubmitting: boolean;
    isUpdated: boolean;
    exitPath: string;
    projectDetailsFields: IProjectDetailsFields;
    exitStrategiesList: EnumTypeForList[];
    projectTypesList: EnumTypeForList[];
    propertyTypesList: EnumTypeForList[];
    propertyDetails: any;
    isAllProjectDataValid: boolean;
    isCreateByModels: boolean;
    isLoading: boolean;
  }
>;

export const useGeneralSettings = () => {
  const { isPHBProject } = useContext(SettingsContext);
  const { projectId } = useParams();

  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  const project = useGraphQuery({
    type: QueryNamesEnums.GET_PROJECT,
    keys: [
      'name',
      'scope_of_work',
      'address',
      'exit_strategy',
      'type',
      'property_existing_type',
      'property_proposed_type',
      'scope_of_work',
      'id',
      'thumb_representations',
      'units_number',
    ],
    args: { project_id: projectId },
  });

  const {
    projectDetailsFields,
    exitStrategiesList,
    isProjectDetailsUpdated,
    projectTypesList,
    propertyTypesList,
    propertyDetails,
    isAllProjectDataValid,
  } = useProjectDetailsFields({
    project: project?.data,
  });

  const {
    projectName,
    scopeOfWork,
    exitStrategy,
    projectType,
    existingPropertyType,
    proposedPropertyType,
    address_1,
    city,
    state,
    zipCode,
  } = projectDetailsFields;

  const projectModelsQuery = useQuery<{ results: IProjectProperty[]; count: number }, Error>(
    [QueryNamesEnums.GET_PROJECT_BUILDING_MODELS, { projectId }],
    getProjectModels.bind(this, projectId),
    { enabled: isPHBProject },
  );

  const projectMutation = useMutation<
    Response,
    ErrorDual,
    { projectId: string; json: Partial<IProject> }
  >(updateProjectFields, {
    onError: (error) => {
      enqueueSnackbar(
        parsePathErrorDual(error) || 'Something went wrong while updating project info',
        { variant: 'error' },
      );
    },
  });

  const patchBuildingMutation = useMutation<Response, ErrorDual, PatchBuildingParam>(
    patchProjectBuilding,
    {
      onError: (error) => {
        enqueueSnackbar(parsePathErrorDual(error), { variant: 'error' });
      },
      onSuccess: () => {
        queryClient.invalidateQueries(QueryNamesEnums.GET_PROJECT_BUILDING);
      },
    },
  );

  const addBuildingMutation = useMutation<
    Response,
    Error,
    {
      projectId: string;
      details?: IPropertyDetail[];
    }
  >(createProjectBuilding, {
    onSuccess: () => {
      queryClient.invalidateQueries([QueryNamesEnums.GET_PROJECT_BUILDING, { projectId }]);
    },
    onError: (error) => {
      enqueueSnackbar(error.message, { variant: 'error' });
    },
  });

  const getPropertyDataForPayload = () =>
    propertyDetails?.list
      .filter((item) => item.name)
      .map(({ name_display, id, name, ...rest }) => ({ name: id, ...rest }));

  const handleSubmitClick = async () => {
    if (propertyDetails?.isEmptyDetailExist) {
      enqueueSnackbar('Property details should be filled', { variant: 'error' });
      return false;
    }

    if (!projectDetailsFields.projectName.validate()) return false;

    if (!isPHBProject && propertyDetails?.isChanged) {
      if (propertyDetails?.id) {
        await patchBuildingMutation.mutateAsync({
          project: projectId,
          building: propertyDetails.id.toString(),
          json: { details: getPropertyDataForPayload() },
        });
      } else {
        await addBuildingMutation.mutateAsync({
          projectId,
          details: getPropertyDataForPayload(),
        });
      }
    }

    const projectPayload = {
      ...(scopeOfWork.isChanged && { scope_of_work: scopeOfWork.value }),
      ...(projectName.isChanged && { name: projectName.value }),
      ...(exitStrategy.isChanged && {
        exit_strategy: findIdInArrByKey({
          arr: exitStrategiesList,
          searchedVal: exitStrategy.value?.name_display,
        }),
      }),
      ...(projectType.isChanged && {
        type: findIdInArrByKey({
          arr: projectTypesList,
          searchedVal: projectType.value?.name_display,
        }),
      }),
      ...(existingPropertyType.isChanged && {
        property_existing_type: findIdInArrByKey({
          arr: propertyTypesList,
          searchedVal: existingPropertyType.value?.name_display,
        }),
      }),
      ...(proposedPropertyType.isChanged && {
        property_proposed_type: findIdInArrByKey({
          arr: propertyTypesList,
          searchedVal: proposedPropertyType.value?.name_display,
        }),
      }),
      ...(address_1.isChanged || city.isChanged || state.isChanged || zipCode.isChanged
        ? {
            address: {
              address_1: address_1.value,
              city: city.value,
              state: state.value?.name,
              zip_code: zipCode.value,
              id: project?.data?.address?.id,
            },
          }
        : {}),
    };
    await projectMutation.mutateAsync({
      projectId,
      json: projectPayload,
    });
    await invalidateQueries();
    return true;
  };

  const invalidateQueries = async () => {
    await queryClient.invalidateQueries([QueryNamesEnums.GET_PROJECT, { project_id: projectId }]);
    await queryClient.invalidateQueries([
      QueryNamesEnums.GET_PROJECT_PROPERTY_DETAILS_NAMES,
      { projectId },
    ]);
  };

  const exitPath = useMemo(() => `/projects/${projectId}/overview`, [projectId]);

  return {
    projectDetailsFields,
    exitStrategiesList,
    handleSubmitClick,
    isSubmitting: projectMutation.isLoading,
    isUpdated: isProjectDetailsUpdated,
    exitPath,
    projectTypesList,
    propertyTypesList,
    propertyDetails,
    isAllProjectDataValid,
    isCreateByModels: !!projectModelsQuery.data?.count,
    project: project?.data,
    isLoading: project?.isLoading,
  };
};
