import { getArrayFromObject } from '@utils';
import { FilterObject } from '../interface';
import { AuditLogTags } from '@constants';

export const auditLogTypeFilter: FilterObject = {
  title: 'Type',
  filterKey: 'tags',
  needToUpdateSetting: false,
  needToUpdateUrl: false,
  getStaticValues: () =>
    getArrayFromObject(AuditLogTags, 'value', 'label') as unknown as Array<{
      label: string;
      value: string;
    }>,
  cypressSelector: 'project__right_drawer__switcher__audit_log__type_filter__input',
};
