import React, { FC } from 'react';
import { colors } from '@theme';
import { Stack } from '@mui/material';

import { PaymentGraphs, PaymentGraphsV2, PaymentSummary } from '../components';
import { useLaunchDarklyFlags } from '@context';

const RequestPaymentInfoV2: FC = () => {
  const flags = useLaunchDarklyFlags();

  return (
    <Stack sx={{ backgroundColor: colors.background.gray }}>
      <PaymentSummary />
      {flags?.['ENG_9697_funding_source_payment_tab'] ? <PaymentGraphsV2 /> : <PaymentGraphs />}
    </Stack>
  );
};

export default RequestPaymentInfoV2;
