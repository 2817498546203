import * as React from 'react';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { SxProps, Theme } from '@mui/material/styles';
import { Stack } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { useQuery } from 'react-query';

import { ICompanyFull, QueryNamesEnums } from '@interfaces';
import { getMyCompany } from '@globalService';
import { DateFieldModel } from '@models';

dayjs.extend(utc);
dayjs.extend(timezone);

interface DatePickerWithTimezoneProps {
  field: DateFieldModel;
  label: string;
  validationText?: string;
  minDate?: Date;
  maxDate?: Date;
  sx?: SxProps<Theme>;
  inputStyles?: SxProps<Theme>;
  validateOnLoad?: boolean;
  disabled?: boolean;
  dataTestName?: string;
  required?: boolean;
  onChange?: (e: any) => void;
  inputProps?: object;
  addTimestamp?: boolean;
}

const DateTimePickerWithTimezone = ({
  field,
  label,
  validationText = 'Invalid date',
  inputStyles,
  validateOnLoad = true,
  required,
  inputProps,
  onChange,
  dataTestName,
  addTimestamp = false,
  maxDate,
  minDate,
  sx,
  ...props
}: DatePickerWithTimezoneProps) => {
  const [isTouched, setTouched] = React.useState(validateOnLoad);
  const companyQuery = useQuery<ICompanyFull, Error>(
    [QueryNamesEnums.GET_MY_COMPANY],
    getMyCompany.bind(this),
  );

  const timezone = companyQuery?.data?.timezone || 'US/Pacific';

  const dateValue = field.value ? dayjs(field.value).tz(timezone) : null;

  const handleDateChange = (newDate: any) => {
    const jsDate = newDate ? newDate.toDate() : null;
    if (onChange) {
      onChange(jsDate);
    } else {
      field.handleChangePicker(jsDate);
    }
  };

  const getHelperText = () => {
    if (field.isValid || !isTouched) return '';
    if (field.value) return field.validationError || validationText;
    return `${label} is required`;
  };

  const transformedMaxDate = maxDate ? dayjs(maxDate).tz(timezone).endOf('day') : undefined;
  const transformedMinDate = minDate ? dayjs(minDate).tz(timezone).startOf('day') : undefined;

  const getPickerProps = () => {
    return {
      label: `${label} (${timezone})`,
      timezone,
      value: dateValue,
      onChange: handleDateChange,
      maxDate: transformedMaxDate,
      minDate: transformedMinDate,
      slotProps: {
        textField: {
          fullWidth: true,
          variant: 'outlined' as const,
          helperText: getHelperText(),
          error: !field.isValid && isTouched,
          size: 'small' as const,
          sx: inputStyles,
          onBlur: () => setTouched(true),
          required,
          inputProps: {
            'data-cy': dataTestName,
            'data-timezone': timezone,
            ...inputProps,
          },
        },
        openPickerIcon: {
          'data-cy': 'calendar-icon',
        },
      },
    };
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Stack spacing={2} sx={sx}>
        {addTimestamp ? (
          <DateTimePicker {...getPickerProps()} {...props} />
        ) : (
          <DatePicker {...getPickerProps()} {...props} />
        )}
      </Stack>
    </LocalizationProvider>
  );
};

export default DateTimePickerWithTimezone;
