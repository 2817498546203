import { useContext, useEffect, useMemo, useState } from 'react';
import {
  IRightDrawerParams,
  PermissionNamesEnums,
  QueryNamesEnums,
  IDocument,
  IProjectComment,
  TPolicies,
} from '@interfaces';
import { PermissionsContext, useGraphQuery } from '@context';

import { isAllowed, Override } from '@utils';

export type ControllerInterface = Override<
  ReturnType<typeof useRequestQueueRightModal>,
  {
    tab: string;
    tabs: { label: string; value: string }[];
    documents?: { results: IDocument[]; count: number };
    comments?: IProjectComment[];
    policies?: TPolicies;
  }
>;

const TABS = {
  policies: { label: 'Policies', value: 'policies' },
  documents: { label: 'Documents', value: 'documents' },
  photos: { label: 'Progress photos', value: 'photos' },
  comments: { label: 'Comments', value: 'comments' },
};

export const useRequestQueueRightModal = ({
  projectId,
  activeTab,
  requestId,
  serviceOrderId,
  inspectionId,
}: IRightDrawerParams) => {
  const { permissions } = useContext(PermissionsContext);
  const project = useGraphQuery({
    type: QueryNamesEnums.GET_PROJECT,
    keys: [
      'name',
      'status',
      'status_change_reason',
      'id',
      'is_budget_locked',
      'is_underwriting_enabled',
    ],
    args: { project_id: projectId },
  });

  const [tab, setTab] = useState(activeTab || TABS.comments.value);
  const handleChange = (_event: React.SyntheticEvent, newValue: string) => {
    setTab(newValue);
  };

  useEffect(() => {
    if (activeTab) setTab(activeTab);
  }, [activeTab]);

  const withComments = isAllowed(PermissionNamesEnums.COMMENTS_VIEW, permissions);

  const tabs = useMemo(
    () => [
      ...(isAllowed(PermissionNamesEnums.POLICIES_VIEW, permissions) &&
      requestId &&
      !(inspectionId || serviceOrderId)
        ? [
            {
              label: `${TABS.policies.label}`,
              value: TABS.policies.value,
            },
          ]
        : []),
      {
        label: `${TABS.documents.label}`,
        value: TABS.documents.value,
      },
      ...(!serviceOrderId
        ? [
            {
              label: `${TABS.photos.label}`,
              value: TABS.photos.value,
            },
          ]
        : []),
      ...(withComments
        ? [
            {
              label: `${TABS.comments.label}`,
              value: TABS.comments.value,
            },
          ]
        : []),
    ],
    [permissions, requestId, serviceOrderId, inspectionId],
  );

  return {
    project: project?.data,
    tab,
    tabs,
    handleChange,
    TABS,
  };
};
