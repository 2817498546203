import { Dispatch, Ref, SetStateAction } from 'react';
import {
  ChecklistItemLocal,
  HookState,
  IChecklistMetadata,
  IDocumentType,
  IDrawRequest,
  IHandleRightDrawerOpenerParams,
  IInspectionFields,
  ILineItemModal,
  IMilestone,
  IMilestoneTotal,
  IRightMenu,
} from '@interfaces';
import { DateFieldModel, StringFieldModel } from '@models';
import { IFileUploader } from '@hooks';
import { Override } from '@utils';
import { useRequest } from './controllers/requestController';

export type IRequestController = Override<
  ReturnType<typeof useRequest>,
  {
    drawRequest: IDrawRequest;
    state: HookState;
    error: Error;
    mainRef: Ref<HTMLDivElement>;
    documentsSummary: {
      [key: string]: number;
    };
    feesAmount: number;
    showDocumentsSection: boolean;
    totalRetainageRequested: number;
    isReallocationAllowed: boolean;
    allMilestones: IMilestone[];
    isRejectionResubmit: boolean;
    handleRightDrawerOpenerClick: (param: IHandleRightDrawerOpenerParams) => void;
    rightMenu: IRightMenu;
    returnedRequestNotes: string;
    openLumpSumModal: boolean;
    setOpenLumpSumModal: Dispatch<SetStateAction<boolean>>;
    isLumpSumEnabled: boolean;
  }
>;

export interface IFieldsController {
  inspectionComment: StringFieldModel;
  comment: StringFieldModel;
  completionDate: DateFieldModel;
  completionDateComment: StringFieldModel;
  completionDateReasons: string[];
  completionDateReasonsList: string[];
  isCompletionDateConfirmed: string;
  setCompletionDateConfirmed: (value: string) => void;
  currentCompletionDate: Date | null;
  handleCompletionDateReasonChange: (reasons: string[]) => void;
  showCompletionDateReasons: boolean;
  isCompletionDateReasonsValid: boolean;
  isInspectionValid: boolean;
  startDate: Date | null;
  inspectionFields: IInspectionFields;
  isCompletionDateChanged: boolean;
  isInitMetadataUsed: boolean;
  resetCompletionDate: () => void;
  completionDateValid: boolean;
}

export interface IButtonController {
  onStepClick: () => void;
  onResubmit: () => void;
  isConfirmationStep: boolean;
  showSubmit: boolean;
  completionDateDone: (shouldComplete?: boolean) => void;
  inspectionDone: () => void;
  onSaveAsDraft: () => void;
}

export interface ICancelRequestController {
  requestCancel: () => void;
  goBack: () => void;
  setCancelModalShow: Dispatch<SetStateAction<ConfirmationModalTypeEnums>>;
  isCancelModalShow: ConfirmationModalTypeEnums;
  isCanceling: boolean;
}

export interface ISubmissionController {
  drawRequestSubmit: () => void;
  showSuccessPopup: string;
  onCloseSuccessModal: () => void;
  isSubmitting: boolean;
}

export interface IUndoneSection {
  title: string;
  id: string;
  itemRef: () => void;
}

export interface ISectionButtonsClick {
  sectionKey: string;
  key: 'isSkipped' | 'isDone' | 'isValid';
  isChecklist?: boolean;
  item?: ChecklistItemLocal;
}

export interface IValidationController {
  validatedSection: ISections;
  isAllDone: boolean;
  onSectionButtonsClick: (args: ISectionButtonsClick) => void;
  validatedChecklist: IChecklistSections;
  barChartInfo: { completedSteps: number; incompletedSteps: number; allSteps: number };
  isAdditionalRequirementResubmit: boolean;
  isEditTable: boolean;
  undoneSections: IUndoneSection[];
  handleNextClick: () => void;
  onUploadComplete: (id: string) => void;
  onDocumentDelete: (id: string) => void;
  isAnyItemChanged: boolean;
  isLumpSumTableMode: boolean;
  setIsLumpSumTableMode: Dispatch<SetStateAction<boolean>>;
}

export interface IChecklistController {
  state: HookState;
  handleDelete: ({
    documentId,
    checklistItemId,
  }: {
    documentId: string;
    checklistItemId: string;
  }) => Promise<Response>;
  isLoading: boolean;
  checklistItems: ChecklistItemLocal[];
  isChecklistModalShow: boolean;
  setChecklistModalShow: Dispatch<SetStateAction<boolean>>;
  drawRequest: IDrawRequest;
  handleSkip: (checklistItemId: string, sectionKey: string, shouldSkip: boolean) => void;
  handleDone: (params: IHandleDoneParams) => void;
  selectedItem: ChecklistItemLocal;
  fileUploader: IFileUploader;
  photoUploader: IFileUploader;
  startUploading: (checklistItemId: string) => void;
  startPhotoUploading: (checklistItemId: string) => void;
  documentTypes: IDocumentType[];
  projectId: string;
  handleNavigate: (id: string) => void;
  selectedFileName: string;
  setSelectedFileName: Dispatch<SetStateAction<string>>;
  progressPhotoTypeId: string;
}

export interface IHandleDoneParams {
  checklistItemId: string;
  comment?: string;
  shouldComplete: boolean;
  metadata?: IChecklistMetadata;
}

export interface ISections {
  line_items_table: IValidateSection;
  docs: IValidateSection;
  account?: IValidateSection;
  inspection?: IValidateSection;
  completion?: IValidateSection;
  comment?: IValidateSection;
}

export interface IChecklistSections {
  key: {
    checklistItem: ChecklistItemLocal;
    isValid: boolean;
    isDone: boolean;
    isSkipped: boolean;
    isChecklist: boolean;
  };
}

interface IValidateSection {
  isValid: boolean;
  isDone: boolean;
  isSkipped?: boolean;
}

export type ChecklistItemProps = {
  checklistController: IChecklistController;
  checklistItem: ChecklistItemLocal;
  validationController: IValidationController;
  askConfirm?: (info) => void;
  index: number;
  innerRef?: (node) => void;
};

export type MilestoneData = IMilestone & {
  handleAddMediaClick?: () => void;
  activeToEdit?: boolean;
};

export interface IMilestoneList {
  initColumns: string[];
  filteredMilestones: MilestoneData[];
  drawRequest: IDrawRequest;
  project: string;
  refetch?: ({ isMilestonesUpdate }?: { isMilestonesUpdate?: boolean }) => void;
  addLineList: () => void;
  isReallocateComplete: boolean;
  isDrawRequest: boolean;
  isRequestChanged: boolean;
  isEditable: boolean;
  lineItemModal: ILineItemModal;
  setLineItemModal: ({ open, type, lineItem }: ILineItemModal) => void;
  openEditMilestoneModal?: (milestone: IMilestone) => void;
  filterValue: string;
  handleFiltersChange: (filterValue: string) => void;
  filterOptions: string[];
  isMilestoneMutatingOrFetching: boolean;
  totals: IMilestoneTotal;
}

export interface ButtonHandlerProps {
  completionDate: DateFieldModel;
  completionDateComment: StringFieldModel;
  completionDateReasons: string[];
  isCompletionDateConfirmed: string;
  inspectionComment: StringFieldModel;
  validatedSection: ISections;
  checklistItems: ChecklistItemLocal[];
  handleDone: (params: IHandleDoneParams) => void;
  goBack: () => void;
  inspectionFields: IInspectionFields;
  isAnyItemChanged: boolean;
  setCancelModalShow: Dispatch<SetStateAction<ConfirmationModalTypeEnums>>;
}

export interface SubmissionControllerProps {
  completionDate: DateFieldModel;
  completionDateComment: StringFieldModel;
  completionDateReasons: string[];
  comment: StringFieldModel;
  inspectionComment: StringFieldModel;
  inspectionFields: IInspectionFields;
}

export enum ConfirmationModalTypeEnums {
  CANCEL = 'cancel',
  AUTO_DELETE = 'auto_delete',
}
