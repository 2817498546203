import React, { FC } from 'react';
import { Stack, Typography } from '@mui/material';
import { colors } from '@theme';
import { currencyFormatter, getAddress, getStatusChip } from '@utils';
import { LabelAndValue, StyledBox, ToolTipLine } from '@components';
import { useDayJsFormatter } from '@hooks';
import * as Controller from './controller';
import { HookState } from '@interfaces';
import { useLaunchDarklyFlags } from '@context';

const DisbursementStatement: FC<{ drawRequestId?: string }> = ({ drawRequestId }) => {
  const { state, project, drawRequestData } = Controller.useDisbursementStatement(drawRequestId);
  const source = 'payments_tab__disbursement_statement_modal';
  const { dateFormatter } = useDayJsFormatter();
  const flags = useLaunchDarklyFlags();
  if (state === HookState.SUCCESS) {
    return (
      <Stack position="relative" maxWidth={900} width={'100%'} direction="column" spacing={2}>
        <StyledBox>
          <Stack spacing={1} justifyContent="center">
            <Typography textAlign="center" variant="body2">
              {getAddress(project?.address)}
            </Typography>
            <Typography textAlign="center" variant="body1SemiBold">
              Disbursement statement
            </Typography>
          </Stack>
        </StyledBox>
        <StyledBox>
          <Stack direction="row" spacing={2}>
            <Stack direction="column" flex={1} spacing={1}>
              <LabelAndValue label="Project ID" text={project?.id} />
              <LabelAndValue label="Loan ID" text={project?.loan.external_id || '-'} />
              <LabelAndValue
                label="Construction budget"
                text={currencyFormatter(drawRequestData?.totals?.all?.revised_estimate)}
                textDataTestName={`${source}__construction_budget__value`}
              />
              <LabelAndValue
                label="Date lender approved"
                text={dateFormatter({ date: drawRequestData?.approved_at })}
              />
              <LabelAndValue
                label="Construction holdback draw"
                text={currencyFormatter(drawRequestData?.construction_holdback)}
                textDataTestName={`${source}__construction_holdback_draw__value`}
              />
            </Stack>
            <Stack direction="column" flex={1} spacing={1}>
              <LabelAndValue label={`Draw #${drawRequestData?.counter_per_request_type} status`}>
                <>{getStatusChip(drawRequestData)}</>
              </LabelAndValue>
              <LabelAndValue
                label="Date disbursed"
                text={dateFormatter({ date: drawRequestData?.disbursed_at })}
              />
              <LabelAndValue
                label="Total net funds to disburse"
                text={
                  flags?.['ENG_9698_funding_sources_usage_in_DR_metrics']
                    ? currencyFormatter(drawRequestData?.total_transactable_amount)
                    : currencyFormatter(drawRequestData?.construction_holdback_with_fees)
                }
                textDataTestName={`${source}__total_net_funds_to_disburse__value`}
              />
              <LabelAndValue
                colorLabel={colors.status.information.medium}
                color={colors.status.information.medium}
                label="Reference #"
                text={drawRequestData?.confirmation_number}
              />
            </Stack>
          </Stack>
        </StyledBox>
        <StyledBox>
          <Stack direction="column" flex={1} spacing={1}>
            <LabelAndValue label="Borrower" text={project?.loan.borrower_name} />
            <LabelAndValue label="Project address" text={getAddress(project?.address)} />
          </Stack>
        </StyledBox>
        <StyledBox>
          <Stack direction="column" flex={1} spacing={1}>
            <Typography variant="h4">Draw fees</Typography>
            {drawRequestData?.fees.map((fee) => (
              <LabelAndValue key={fee.id} label={fee.name} text={currencyFormatter(fee.amount)} />
            ))}
            <LabelAndValue
              colorLabel={colors.text.dark}
              label="Total draw fees"
              text={currencyFormatter(drawRequestData?.fees_amount || 0)}
            />
          </Stack>
        </StyledBox>
        <StyledBox>
          <Stack direction="column" flex={1} spacing={1}>
            <Typography variant="h4">Net funds to disburse</Typography>
            <LabelAndValue
              colorLabel={colors.text.dark}
              label="Total net funds to disburse"
              text={
                flags?.['ENG_9698_funding_sources_usage_in_DR_metrics']
                  ? currencyFormatter(drawRequestData?.total_transactable_amount)
                  : currencyFormatter(drawRequestData?.construction_holdback_with_fees)
              }
            />
            <ToolTipLine
              typographyVariant="body1"
              text="Disbursement method for this draw is outside of the TrustPoint system."
            />
          </Stack>
        </StyledBox>
      </Stack>
    );
  }

  return null;
};

export default DisbursementStatement;
