import React, { FC } from 'react';
import { Button, Grid2, Stack, Typography } from '@mui/material';
import { useParams, useSearchParams } from 'react-router-dom';

import {
  ButtonWithTooltipOnClick,
  CenteredStyledBox,
  CustomRadioGroup,
  FullScreenDialog,
  LabelAndValue,
  LoadingSkeleton,
  ServiceRelatedDocuments,
  StyledBox,
  SuccessModal,
  WysiwygEditor,
} from '@components';
import { colors } from '@theme';
import * as Controller from './controller';
import { ServiceTypeEnum } from '@interfaces';
import { ArrowRightIcon, TutorialIcon } from '@svgAsComponents';
import { useDayJsFormatter } from '@hooks';

const ServiceComponent: FC<{
  drawRequestId?: string;
  onClose: () => void;
}> = ({ drawRequestId, onClose }) => {
  const { dateFormatter } = useDayJsFormatter();
  const { projectId } = useParams();
  const [searchParams] = useSearchParams();
  const service_type = searchParams.get('service_type');

  const {
    serviceAgenciesList,
    serviceAgenciesListIsLoading,
    agencySelected,
    setAgencySelected,
    serviceOrder,
    handleServiceOrderRequest,
    isSubmitting,
    isSubmitDisabled,
    isSuccessModalOpen,
    commentField,
    project,
    isOptionsPopupOpen,
    setOptionsPopupOpen,
  } = Controller.useService({
    projectId,
    onClose,
    drawRequestId,
    serviceType: service_type as ServiceTypeEnum,
  });

  return (
    <Stack sx={{ flex: 1, width: '100%', alignItems: 'center' }}>
      <Stack justifyContent="space-between" sx={{ flex: 1 }}>
        <Stack spacing={2}>
          <CenteredStyledBox>
            <Stack spacing={2} direction="row" alignItems="center" mb={4}>
              <Typography variant="h3">Service provider</Typography>

              {!!serviceAgenciesList?.length && (
                <Stack
                  onClick={() => setOptionsPopupOpen(true)}
                  direction="row"
                  alignItems="center"
                  sx={{
                    backgroundColor: colors.status.information.lighter,
                    borderRadius: 0.5,
                    minHeight: 24,
                    py: 0.5,
                    px: 1,
                    cursor: 'pointer',
                  }}
                  spacing={0.5}
                >
                  <TutorialIcon color={colors.status.information.medium} size={16} />
                  <Typography
                    variant="label"
                    sx={{ color: colors.status.information.medium, fontWeight: 600 }}
                  >
                    What is the best option?
                  </Typography>
                </Stack>
              )}
            </Stack>

            {serviceAgenciesListIsLoading ? (
              <LoadingSkeleton />
            ) : (
              <CustomRadioGroup
                dataTestName="order_service__service_agencies__radio__group"
                value={agencySelected || ''}
                onChange={(e) => setAgencySelected(e.target.value)}
                options={serviceAgenciesList}
              />
            )}
          </CenteredStyledBox>

          <CenteredStyledBox sx={{ flex: 1 }}>
            <Stack spacing={1}>
              <LabelAndValue
                label="Property type"
                text={serviceOrder?.project?.property_existing_type}
              >
                <Stack direction="row" alignItems="center" spacing={0.5}>
                  <Typography variant="body3SemiBold">{project.property_existing_type}</Typography>
                  {project.property_proposed_type && (
                    <>
                      <ArrowRightIcon size={10} />
                      <Typography variant="body3SemiBold">
                        {project.property_proposed_type}
                      </Typography>
                    </>
                  )}
                </Stack>
              </LabelAndValue>
              <LabelAndValue label="Ordered date" text={dateFormatter({ date: new Date() })} />
            </Stack>
          </CenteredStyledBox>

          {serviceOrder?.id && agencySelected && (
            <ServiceRelatedDocuments
              drawRequestId={drawRequestId}
              serviceOrderId={serviceOrder?.id}
              serviceType={service_type as ServiceTypeEnum}
              source="order_service__related_documents"
            />
          )}

          <CenteredStyledBox>
            <Stack spacing={2}>
              <Typography variant="h3">Special instructions</Typography>

              <WysiwygEditor editField={commentField} source="order_service__comments" />
            </Stack>
          </CenteredStyledBox>

          <CenteredStyledBox>
            <Typography variant="body3">
              <span style={{ color: colors.status.information.medium, fontWeight: 700 }}>
                Disclaimer.{' '}
              </span>
              By clicking “Order” I understand and agree that I am ordering a product or service
              from a third-party provider, not TrustPoint. This order is subject to the provider’s,
              and not TrustPoint’s, terms and conditions. I understand that I can review the
              provider’s terms and conditions on the provider’s website, and I agree to abide by and
              be bound by the provider’s terms and conditions.
            </Typography>
          </CenteredStyledBox>

          {isSuccessModalOpen && (
            <SuccessModal text="Service has been ordered." open onClose={() => onClose()} />
          )}
        </Stack>
      </Stack>
      <Stack
        direction="row"
        justifyContent="flex-end"
        alignItems="center"
        sx={{
          backgroundColor: colors.background.white,
          mt: 2,
          padding: '1.5rem',
          width: '100%',
        }}
      >
        <Button
          variant="new"
          sx={{ mr: '0.5rem' }}
          onClick={() => onClose()}
          data-cy="order_service__cancel__button"
        >
          Cancel
        </Button>
        <ButtonWithTooltipOnClick
          onClick={handleServiceOrderRequest}
          loading={isSubmitting}
          disabled={isSubmitting || !serviceOrder?.id || !agencySelected}
          conditionallyDisabled={isSubmitDisabled}
          dataTestName="order_service__submit__button"
        >
          {isSubmitting ? 'Ordering...' : 'Order'}
        </ButtonWithTooltipOnClick>
      </Stack>

      <FullScreenDialog
        open={isOptionsPopupOpen}
        title="What is the best option?"
        actionButtons={null}
        onClose={() => setOptionsPopupOpen(false)}
        content={() => <OptionsListWithPopper list={serviceAgenciesList} />}
      />
    </Stack>
  );
};

export default ServiceComponent;

export const OptionsListWithPopper = ({ list }) => {
  return (
    <Grid2 container spacing={2} flexWrap="wrap">
      {list?.map((item) => (
        <Grid2 size={{ xs: 6 }} key={item.id}>
          <StyledBox>
            <Stack direction="row" justifyContent="space-between" alignItems="center">
              <Typography variant="h3">{item.label}</Typography>
              {item.logo && (
                <img
                  alt="service agency logo"
                  src={item.logo}
                  style={{ minHeight: '50px', maxHeight: '50px' }}
                />
              )}
            </Stack>

            <Typography
              variant="body1"
              sx={{ mt: 1, fontSize: '14px' }}
              dangerouslySetInnerHTML={{ __html: item.description }}
            />
          </StyledBox>
        </Grid2>
      ))}
    </Grid2>
  );
};
