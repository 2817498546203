import { Box, Divider, Stack, Typography } from '@mui/material';
import React, { FC } from 'react';
import { ControllerInterface } from '../controller';
import { colors } from '@theme';
import { CommentHeader, Tags } from '@components';

export const Comments: FC<{ controller: ControllerInterface }> = ({ controller }) => {
  const { comments } = controller;

  if (!comments.length) return null;

  return (
    <Box sx={{ breakInside: 'avoid' }} pr="15px" pl="15px">
      <Typography variant="printH2" data-cy="report_comments_section_title">
        Comments
      </Typography>
      {comments?.map((comment) => {
        if (!comment.message) return null;
        return (
          <Stack mb={2} key={comment.id}>
            <CommentHeader
              noTruncate
              comment={comment}
              source="report__comments"
              showOptions={false}
            />
            <Stack direction="row" alignItems="flex-start" spacing={2} pt={1}>
              <Typography
                dangerouslySetInnerHTML={{ __html: comment.message }}
                variant="body2"
                sx={{ wordWrap: 'break-word', flex: 1 }}
              />
              <Tags items={comment.tags} showReportTag={false} source={`report__comments__tag`} />
            </Stack>
            <Divider color={colors.neutral.lighter} />
          </Stack>
        );
      })}
    </Box>
  );
};
