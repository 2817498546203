import React, { useMemo } from 'react';
import { useQueries } from 'react-query';
import { useParams } from 'react-router-dom';
import { HookState, IDrawRequest, ITableHeader, QueryNamesEnums } from '@interfaces';
import {
  convertReactTableColumnsToMuiDataGridColumns,
  currencyFormatter,
  getHookState,
  tableHeaders,
} from '@utils';
import { getProjectDrawRequestsList } from '@globalService';
import { Typography } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid-premium';
import { useLaunchDarklyFlags, useGraphQuery } from '@context';

export interface ControllerInterface {
  state: HookState;
  requests: IDrawRequest[];
  columns: ITableHeader[];
  newColumns: GridColDef[];
}

export const useRequestsList = (): ControllerInterface => {
  const { projectId } = useParams();
  const flags = useLaunchDarklyFlags();

  const requestedDataQueries = useQueries([
    {
      queryKey: [QueryNamesEnums.GET_PROJECT_DRAW_REQUEST_LIST, { projectId }],
      queryFn: getProjectDrawRequestsList.bind(this, projectId),
      enabled: !!projectId,
    },
  ]);

  const project = useGraphQuery({
    type: QueryNamesEnums.GET_PROJECT,
    keys: ['retainage_rate'],
    args: { project_id: projectId },
    options: {
      skip: !projectId,
    },
  });

  const drawRequestsQuery = requestedDataQueries[0];

  const retainageRate = useMemo(() => project.data?.retainage_rate, [project.data]);
  const isLoading = useMemo(() => drawRequestsQuery.isLoading, [drawRequestsQuery.isLoading]);

  const columns = useMemo(
    () => [
      tableHeaders.requestNameLink({ isLoading, projectId }),
      tableHeaders.date({
        header: 'Submitted date',
        accessor: 'submitted_at',
        isLoading,
        typoprops: { variant: 'body2' },
      }),
      tableHeaders.date({
        header: 'Mark as disbursed date',
        accessor: 'disbursed_at',
        isLoading,
        typoprops: { variant: 'body2' },
      }),
      tableHeaders.amount({
        header: 'Total draw amount',
        accessor: 'approved_amount',
        isLoading,
        disableSortBy: true,
        typoprops: { variant: 'body2' },
        CustomFooter,
        footerDataTestName: `payments_tab__requests_table__footer__approved_amount__value`,
      }),
      ...(retainageRate
        ? [
            tableHeaders.amount({
              header: 'Retainage heldback ($)',
              accessor: 'retainage_approved_amount_holdback',
              isLoading,
              disableSortBy: true,
              typoprops: { variant: 'body2' },
              CustomFooter,
              footerDataTestName: `payments_tab__requests_table__footer__retainage_approved_amount_holdback__value`,
            }),
            tableHeaders.amount({
              header: 'Retainage released ($)',
              accessor: 'retainage_release_approved',
              isLoading,
              disableSortBy: true,
              typoprops: { variant: 'body2' },
              CustomFooter,
              footerDataTestName: `payments_tab__requests_table__footer__retainage_release_approved__value`,
            }),
          ]
        : []),
      tableHeaders.amount({
        header: 'Draw fees ($)',
        accessor: 'fees_amount',
        isLoading,
        disableSortBy: true,
        typoprops: { variant: 'body2' },
        CustomFooter,
        footerDataTestName: `payments_tab__requests_table__footer__fees_amount__value`,
      }),
      ...(flags?.['ENG_9697_funding_source_payment_tab']
        ? [
            tableHeaders.amount({
              header: 'Net disbursed amount ($)',
              accessor: 'total_transactable_amount',
              isLoading,
              disableSortBy: true,
              typoprops: { variant: 'body2' },
              CustomFooter,
              footerDataTestName: `payments_tab__requests_table__footer__construction_holdback_with_fees__value`,
            }),
          ]
        : [
            tableHeaders.amount({
              header: 'Net disbursed amount ($)',
              accessor: 'construction_holdback_with_fees',
              isLoading,
              disableSortBy: true,
              typoprops: { variant: 'body2' },
              CustomFooter,
              footerDataTestName: `payments_tab__requests_table__footer__construction_holdback_with_fees__value`,
            }),
          ]),
    ],
    [isLoading, projectId, retainageRate, flags],
  );

  return {
    state: getHookState(requestedDataQueries),
    requests: drawRequestsQuery.data?.results,
    columns,
    newColumns: convertReactTableColumnsToMuiDataGridColumns(columns),
  };
};

const CustomFooter = ({ amount, dataTestName }: { amount: number; dataTestName?: string }) => (
  <Typography variant="body2SemiBold" data-cy={dataTestName}>
    {currencyFormatter(amount)}
  </Typography>
);
