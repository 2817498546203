import {
  DeleteAllListItemsParam,
  DeleteBuildingParam,
  DeleteListItemParam,
  DeleteModelParam,
  EnumTypeForList,
  IDocument,
  IDocumentType,
  IMilestone,
  IMilestoneColumn,
  IMilestonePhotos,
  IMilestoneTotal,
  IPermission,
  IProject,
  IProjectCoordinator,
  IProjectCreatePayload,
  IProjectFundingSource,
  IProjectFunds,
  IProjectMilestone,
  IProjectProgress,
  IProjectProperty,
  IProjectScores,
  IProjectSettings,
  IProjectsPortfolioItem,
  IProjectStats,
  IPropertyDetail,
  ITeam,
  IUser,
  IWatcher,
  IWorkflowReason,
  MilestoneTag,
  PatchBuildingParam,
  PatchListItemParam,
  PatchModelParam,
  PatchProjectMSGroupParam,
  PostProjectsWatchersParam,
  PostProjectWatchersParam,
  UpdateProjectPhotoPayload,
} from '@interfaces';

import {
  applyBulkPoliciesPath,
  inviteProjectBorrowersPath,
  milestoneById,
  project,
  projectBuildingById,
  projectBuildingModelById,
  projectBuildingModels,
  projectBuildingModelsBulk,
  projectBuildingModelsDelete,
  projectBuildings,
  projectBuildingsBulk,
  projectDocumentTypeByName,
  projectDocumentTypes,
  projectExitStrategies,
  projectFundingSource,
  projectFundingSources,
  projectFunds,
  projectGroupStats,
  projectListWithQuery,
  projectMilestoneColumns,
  projectMilestoneDocuments,
  projectMilestoneGroupDocuments,
  projectMilestones,
  projectMilestonesDelete,
  projectMilestonesGroupList,
  projectMilestonesList,
  projectMilestoneTagById,
  projectMilestoneTags,
  projectMSGroup,
  projectPermissions,
  projectPhotos,
  projectProgress,
  projectPropertyDetailsNames,
  projectPropertyTypes,
  projectScoresNew,
  projectSettings,
  projectsTeamsWithQuery,
  projectsUnWatchPath,
  projectsUsers,
  projectsWatchPath,
  projectTeams,
  projectTeamsWithQuery,
  projectThumb,
  projectTypes,
  projectUnWatchPath,
  projectWatchersPath,
  projectWatchPath,
  workflowReasons,
} from '../paths';

import { dataProvider, dataProviderV2 } from '@api';
import pickBy from 'lodash/pickBy';
import { debug } from '@utils';

export const updateProjectFields = async ({
  projectId,
  json,
}: {
  projectId: string;
  json: Partial<IProject>;
}): Promise<Response> =>
  dataProvider.patch(project(projectId), {
    json,
  });

export const getProjectFunds = async (id: string): Promise<IProjectFunds> =>
  dataProvider.get(projectFunds(id)).json();

export const getProjectMilestoneListColumns = async (projectId): Promise<IMilestoneColumn[]> =>
  await dataProvider.get(projectMilestoneColumns(projectId)).json();

export const getProjectProgress = async (projectId: string): Promise<IProjectProgress> =>
  dataProvider.get(projectProgress(projectId)).json();

export const getProjectBuildings = async (
  projectId: string,
): Promise<{ results: IProjectProperty[]; count: number }> =>
  dataProvider.get(projectBuildings(projectId)).json();

export const createProjectBuilding = async ({
  projectId,
  details,
}: {
  projectId: string;
  details?: IPropertyDetail[];
}): Promise<Response> =>
  dataProvider.post(projectBuildings(projectId), {
    json: { project: projectId, ...(details && { details }) },
  });

export const createProjectBuildingsBulk = async ({
  projectId,
  json,
}: {
  projectId: string;
  json?: {
    project: string;
    details?: IPropertyDetail[];
    name?: string;
    sqft?: number;
    description?: string;
  }[];
}): Promise<Response> =>
  dataProvider.post(projectBuildingsBulk(projectId), {
    json,
  });

export const createProjectBuildingModelsBulk = async ({
  projectId,
  json,
}: {
  projectId: string;
  json?: { project: string; details?: IPropertyDetail[] }[];
}): Promise<Response> =>
  dataProvider.post(projectBuildingModelsBulk(projectId), {
    json,
  });

export const getProjectScoresNew = async (id: string): Promise<IProjectScores> =>
  dataProvider.get(projectScoresNew(id)).json();

export const getProjectSettings = async (projectId: string): Promise<IProjectSettings> =>
  dataProvider.get(projectSettings(projectId)).json();

export const getProjectDocumentTypes = async (): Promise<IDocumentType[]> =>
  dataProvider.get(projectDocumentTypes()).json();

export const getProjectDocumentTypeByName = async (documentType: string): Promise<IDocumentType> =>
  dataProvider.get(projectDocumentTypeByName(documentType)).json();

export const getProjectMilestonesList = async ({
  projectId,
  query,
  groupBy: group_by,
  limit = '999',
  filterKey,
  milestoneTags,
  typeKeys,
}: {
  projectId: string;
  query?: string;
  groupBy?: string;
  limit?: string;
  filterKey?: string;
  milestoneTags?: string;
  typeKeys?: string;
}): Promise<{ results: IProjectMilestone[]; totals: IMilestoneTotal; count: number }> => {
  const notEmptyParams = pickBy({ limit, query, group_by }, Boolean);
  const queryFiltersParams = new URLSearchParams(notEmptyParams).toString();
  const filterParams = filterKey ? `${filterKey}=true&` : '';
  const typeKeysParams = typeKeys ? `&${typeKeys}&` : '';
  const milestoneTagsParams = milestoneTags ? `milestone_tags=${milestoneTags}&` : '';

  return dataProvider
    .get(
      projectMilestonesList({
        projectId,
        queryFiltersParams,
        filterParams,
        milestoneTagsParams,
        typeKeysParams,
      }),
    )
    .json();
};

export const getProjectMilestonesGroupsList = async ({
  projectId,
  query,
  groupBy: group_by,
  limit = '999',
  filterKey,
  milestoneTags,
  typeKeys,
}: {
  projectId: string;
  query?: string;
  groupBy?: string;
  limit?: string;
  filterKey?: string;
  milestoneTags?: string;
  typeKeys?: string;
}): Promise<{ results: IProjectMilestone[]; totals: IMilestoneTotal; count: number }> => {
  const notEmptyParams = pickBy({ limit, query, group_by }, Boolean);
  const queryFiltersParams = new URLSearchParams(notEmptyParams).toString();
  const filterParams = filterKey ? `${filterKey}=true&` : '';
  const typeKeysParams = typeKeys ? `&${typeKeys}&` : '';
  const milestoneTagsParams = milestoneTags ? `milestone_tags=${milestoneTags}&` : '';

  return dataProvider
    .get(
      projectMilestonesGroupList({
        projectId,
        queryFiltersParams,
        filterParams,
        milestoneTagsParams,
        typeKeysParams,
      }),
    )
    .json();
};

export const getProjectTeams = async ({
  projectId,
  companyId,
}: {
  projectId: string;
  companyId?: string;
}): Promise<{ results: ITeam[] }> => dataProvider.get(projectTeams(projectId, companyId)).json();

export const getProjectTeamsWithQuery = async ({
  projectId,
  query,
}: {
  projectId: string;
  query?: string;
}): Promise<{ results: ITeam[] }> =>
  dataProvider.get(projectTeamsWithQuery(projectId, query)).json();

export const getProjectTypes = async (query?: string): Promise<{ results: EnumTypeForList[] }> =>
  dataProvider.get(projectTypes(query)).json();

export const getProjectPropertyTypes = async (
  query?: string,
): Promise<{ results: EnumTypeForList[] }> => dataProvider.get(projectPropertyTypes(query)).json();

export const getProjectPropertyDetailsNames = async (): Promise<{
  results: EnumTypeForList[];
}> => dataProvider.get(projectPropertyDetailsNames()).json();

export const getProjectExitStrategiesList = async (): Promise<{
  results: EnumTypeForList[];
}> => dataProvider.get(projectExitStrategies()).json();

export const pathMilestoneItem = ({
  project,
  milestone,
  json,
}: PatchListItemParam): Promise<Response> =>
  dataProvider
    .patch(milestoneById(project, milestone), {
      json,
    })
    .json();

export const getProjectMilestone = async ({
  projectId,
  milestoneId,
  query = '',
}: {
  projectId: string;
  milestoneId: string;
  query?: string;
}): Promise<IMilestone> => dataProvider.get(milestoneById(projectId, milestoneId, query)).json();

export const deleteMilestoneItem = ({
  project,
  milestone,
}: DeleteListItemParam): Promise<Response> =>
  dataProvider.delete(milestoneById(project, milestone));

export const deleteProjectMilestones = async ({
  projectId,
  isHorizontal: is_horizontal,
  isVertical: is_vertical,
}: DeleteAllListItemsParam): Promise<Response> => {
  const notEmptyParams = pickBy({ is_horizontal, is_vertical }, Boolean);
  const queryFiltersParams = new URLSearchParams(notEmptyParams).toString();
  return dataProvider.post(projectMilestonesDelete(projectId, queryFiltersParams)).json();
};

export const getProjectPermissions = async (projectId: string): Promise<IPermission[]> =>
  dataProvider.get(projectPermissions(projectId)).json();

export const getProjectPhotos = async ({
  projectId,
  query,
}: {
  projectId: string;
  query?: string;
}): Promise<IMilestonePhotos[]> => dataProvider.get(projectPhotos(projectId, query)).json();

export const getProjectsTeamsWithQuery = async ({
  restQlParams,
}: {
  restQlParams: string;
}): Promise<{ results: ITeam[] }> =>
  dataProvider.get(projectsTeamsWithQuery({ restQlParams })).json();

export const getProjectsUsers = async (stringQueryParams: string): Promise<{ results: IUser[] }> =>
  dataProvider.get(projectsUsers(stringQueryParams)).json();

export const postProject = async (projectData: IProjectCreatePayload): Promise<IProject> =>
  dataProvider
    .post(projectListWithQuery(), {
      json: projectData,
    })
    .json();

export const postMilestoneToProject = ({ project, json = {} }): Promise<Response> =>
  dataProvider.post(projectMilestones(project), { json });

export const postBuildingModelToProject = ({ project }): Promise<Response> =>
  dataProvider.post(projectBuildingModels(project), {
    json: { project },
  });

export const deleteProjectBuildingModels = ({ project }): Promise<Response> =>
  dataProvider.post(projectBuildingModelsDelete(project));

export const deleteProjectBuildingModel = ({
  project,
  modelId,
}: DeleteModelParam): Promise<Response> =>
  dataProvider.delete(projectBuildingModelById(project, modelId));

export const deleteProjectBuilding = ({
  project,
  buildingId,
}: DeleteBuildingParam): Promise<Response> =>
  dataProvider.delete(projectBuildingById(project, buildingId));

export const getProjectModels = (
  projectId: string,
): Promise<{
  results: IProjectProperty[];
  count: number;
}> => dataProvider.get(projectBuildingModels(projectId)).json();

export const getProjectMilestoneTags = (
  projectId: string,
): Promise<{
  results: MilestoneTag[];
}> => dataProvider.get(projectMilestoneTags(projectId)).json();

export const postProjectMilestoneTag = ({
  projectId,
  json,
}: {
  projectId: string;
  json: Partial<MilestoneTag>;
}): Promise<Response> =>
  dataProvider
    .post(projectMilestoneTags(projectId), {
      json,
    })
    .json();

export const patchProjectMilestoneTag = ({
  projectId,
  milestoneTagId,
  json,
}: {
  projectId: string;
  milestoneTagId: string;
  json: Partial<MilestoneTag>;
}): Promise<Response> =>
  dataProvider
    .patch(projectMilestoneTagById(projectId, milestoneTagId), {
      json,
    })
    .json();

export const patchProjectModel = ({ project, model, json }: PatchModelParam): Promise<Response> =>
  dataProvider
    .patch(projectBuildingModelById(project, model), {
      json,
    })
    .json();

export const patchProjectBuilding = ({
  project,
  building,
  json,
}: PatchBuildingParam): Promise<Response> =>
  dataProvider
    .patch(projectBuildingById(project, building), {
      json,
    })
    .json();

export const getProjectGroupsStats = async ({
  filterStringQuery,
}: {
  filterStringQuery: string;
}): Promise<IProjectStats> => dataProvider.get(projectGroupStats(filterStringQuery)).json();

export const getWorkflowReasons = async (): Promise<IWorkflowReason[]> =>
  dataProvider.get(workflowReasons()).json();

export const postThumbToProject = ({
  projectId,
  file_representations,
}: UpdateProjectPhotoPayload): Promise<Response> =>
  dataProvider
    .post(projectThumb(projectId), {
      json: { file_representations },
    })
    .json();

export const getProjectMilestoneDocumentsV2 = async ({
  projectId,
  milestoneId,
  query = '',
  stringQueryParams = '',
}: {
  projectId: string;
  milestoneId: string;
  query?: string;
  stringQueryParams: string;
}): Promise<{ results: IDocument[]; count: number }> =>
  dataProviderV2
    .get(projectMilestoneDocuments(projectId, milestoneId, query, stringQueryParams))
    .json();

export const getProjectMilestoneGroupDocuments = async ({
  projectId,
  milestoneId,
  stringQueryParams = '',
}: {
  projectId: string;
  milestoneId: string;
  stringQueryParams: string;
}): Promise<{ results: IDocument[]; count: number }> =>
  dataProvider
    .get(projectMilestoneGroupDocuments(projectId, milestoneId, stringQueryParams))
    .json();

export const inviteProjectBorrowers = async ({ projectId }): Promise<Response> =>
  dataProvider.post(inviteProjectBorrowersPath(projectId));

export const updateProjectCoordinator = async ({
  projectId,
  coordinatorId,
}: IProjectCoordinator): Promise<Response> =>
  dataProvider.patch(project(projectId), { json: { coordinator: coordinatorId } });

export const patchProjectMSGroup = ({
  project,
  group_by,
  json,
  milestoneId,
}: PatchProjectMSGroupParam): Promise<IMilestone> => {
  return dataProvider
    .patch(projectMSGroup({ project, group_by, milestone: milestoneId }), {
      json,
    })
    .json();
};

export const postProjectWatchers = async ({
  projectId,
  projectIds,
  userIds,
}: PostProjectWatchersParam): Promise<Response> =>
  dataProvider.post(projectWatchPath(projectId), {
    json: { project_ids: projectIds, user_ids: userIds },
  });

export const removeProjectWatchers = async ({
  projectId,
  projectIds,
  userIds,
}: PostProjectWatchersParam): Promise<Response> =>
  dataProvider.post(projectUnWatchPath(projectId), {
    json: { project_ids: projectIds, user_ids: userIds },
  });

export const postProjectsWatchers = async ({
  projectIds,
  userIds,
}: PostProjectsWatchersParam): Promise<Response> =>
  dataProvider.post(projectsWatchPath(), {
    json: { project_ids: projectIds, user_ids: userIds },
  });

export const removeProjectsWatchers = async ({
  projectIds,
  userIds,
}: PostProjectsWatchersParam): Promise<Response> =>
  dataProvider.post(projectsUnWatchPath(), {
    json: { project_ids: projectIds, user_ids: userIds },
  });

export const getProjectWatchers = async ({
  projectId,
  stringQueryParams = '',
}: {
  projectId: string;
  stringQueryParams?: string;
}): Promise<{ results: IWatcher[]; count: number }> =>
  dataProvider.get(projectWatchersPath(projectId, stringQueryParams)).json();

export const getProjectsList = async ({
  filterStringQuery = '',
}: {
  filterStringQuery?: string;
}): Promise<{ results: IProjectsPortfolioItem[]; count: number }> => {
  try {
    return await dataProvider.get(projectListWithQuery(filterStringQuery)).json();
  } catch (error) {
    await debug(error, 'Something went wrong while getting portfolio');
  }
};

export const applyBulkPolicies = async ({ projectIds, policies }): Promise<Response> =>
  dataProvider.post(applyBulkPoliciesPath(), {
    json: { projects: projectIds, templates: policies },
  });

export const getProjectFundingSources = async (
  id: string,
): Promise<{ results: IProjectFundingSource[] }> =>
  dataProvider.get(projectFundingSources(id)).json();

export const updateProjectFundingSource = async ({
  projectId,

  fundingSourceId,

  json,
}: {
  projectId: string;

  fundingSourceId: string;

  json: Partial<IProjectFundingSource>;
}): Promise<Response> =>
  dataProvider.patch(projectFundingSource(projectId, fundingSourceId), { json });

export const createProjectFundingSource = async ({
  projectId,

  json,
}: {
  projectId: string;

  json: Partial<IProjectFundingSource>;
}): Promise<Response> => dataProvider.post(projectFundingSources(projectId), { json });
