import { DrawRequestStatus } from '@interfaces';
import { drawRequestListFields } from '@constants';

export const drawRequestList = (queryURL = '') => `projects/draw_requests/?${queryURL}`;
export const drawRequestListStats = (queryURL = '') => `projects/draw_requests/stats/?${queryURL}`;

export const projectDrawRequestPost = (projectId = 'project_pk') =>
  `projects/${projectId}/draw_requests/?query={status,id}`;

export const propjectDrawRequestReport = (projectId, drawRequestId) =>
  `projects/${projectId}/draw_requests/${drawRequestId}/report/`;

export const projectDrawRequestList = (projectId = 'project_pk') =>
  `projects/${projectId}/draw_requests/?query={${drawRequestListFields.join(',')}}&limit=100`;

export const drawRequestItem = ({
  projectId = 'project_pk',
  drawRequestId = 'draw_request_pk',
  restQlQuery = '',
  groupByQuery = '',
}) => `projects/${projectId}/draw_requests/${drawRequestId}/?${restQlQuery}${groupByQuery}`;

export const drawRequestMilestones = (
  projectId = 'project_pk',
  drawRequestId = 'draw_request_pk',
  queryFiltersParams = '',
  filterParams = '',
  restQlquery = '',
  milestoneTagsParams = '',
  typeKeysParams = '',
) =>
  `projects/${projectId}/draw_requests/${drawRequestId}/milestones/?${restQlquery}${filterParams}${typeKeysParams}${milestoneTagsParams}${queryFiltersParams}`;

export const drawRequestMilestoneGroups = (
  projectId = 'project_pk',
  drawRequestId = 'draw_request_pk',
  queryFiltersParams = '',
  filterParams = '',
  restQlquery = '',
  milestoneTagsParams = '',
  typeKeysParams = '',
) =>
  `projects/${projectId}/draw_requests/${drawRequestId}/milestone_groups/?${restQlquery}${filterParams}${typeKeysParams}${milestoneTagsParams}${queryFiltersParams}`;

export const drawRequestMilestoneColumns = (
  projectId = 'project_pk',
  drawRequestId = 'draw_request_pk',
) => `projects/${projectId}/draw_requests/${drawRequestId}/columns/`;

export const getWaitingYourApprovalPath = (id = 'project_pk') =>
  `projects/${id}/draw_requests/?draw_request_status=${DrawRequestStatus.IN_REVIEW}&limit=100&query={id,waits_current_user_approval,status}`;

export const postReviewPath = (projectId = 'project_pk', drawRequestId = 'draw_request_pk') =>
  `projects/${projectId}/draw_requests/${drawRequestId}/review/`;

export const requestMilestoneById = (
  project: string,
  drawRequest: string,
  milestone: string,
  query = '',
) =>
  `projects/${project}/draw_requests/${drawRequest}/milestones/${milestone}/${
    query && `?query=${query}`
  }`;

export const requestMilestoneGroupById = ({
  projectId = '',
  drawRequestId = '',
  milestoneId = '',
  group_by = '',
  restQlParams = '',
}) =>
  `projects/${projectId}/draw_requests/${drawRequestId}/milestone_groups/${milestoneId}/?group_by=${group_by}${
    restQlParams && `&query=${restQlParams}`
  }`;

export const drawRequestBulkUpdate = (project: string, drawRequest: string) =>
  `projects/${project}/draw_requests/${drawRequest}/milestones/bulk/`;

export const milestoneBulkUpdate = (project: string, drawRequest: string) =>
  `projects/${project}/draw_requests/${drawRequest}/milestones/autofill/`;

export const drawRequestDocumentsType = () => 'documents/draw_request/document_types/';

export const drawRequestDocuments = (
  projectId = 'project_pk',
  drawRequestId = 'draw_request_pk',
  stringQueryParams = '',
) => `projects/${projectId}/draw_requests/${drawRequestId}/documents/?${stringQueryParams}`;

export const milestoneDocuments = (
  projectId = 'project_pk',
  drawRequestId = 'draw_request_pk',
  milestoneId = 'milestoneId',
  query = '',
  stringQueryParams = '',
) =>
  `projects/${projectId}/draw_requests/${drawRequestId}/milestones/${milestoneId}/documents/?${stringQueryParams}${query}`;

export const milestoneGroupDocuments = (
  projectId = 'project_pk',
  drawRequestId = 'draw_request_pk',
  milestoneId = 'milestoneId',
  query = '',
  stringQueryParams = '',
) =>
  `projects/${projectId}/draw_requests/${drawRequestId}/milestone_groups/${milestoneId}/documents/?${stringQueryParams}${query}`;

export const drawRequestDocument = (
  projectId = 'project_pk',
  drawRequestId = 'draw_request_pk',
  documentId = 'document_pk',
) => `projects/${projectId}/draw_requests/${drawRequestId}/documents/${documentId}/`;

export const drawRequestDocumentRestore = (
  projectId = 'project_pk',
  drawRequestId = 'draw_request_pk',
  documentId = 'document_pk',
) => `projects/${projectId}/draw_requests/${drawRequestId}/documents/${documentId}/restore/`;

export const markRequestAsPaidPath = (
  projectId = 'project_pk',
  drawRequestId = 'draw_request_pk',
) => `projects/${projectId}/draw_requests/${drawRequestId}/mark_as_paid/`;

export const drawRequestPhotos = (projectId = 'project_pk', drawRequestId = 'draw_request_pk') =>
  `projects/${projectId}/draw_requests/${drawRequestId}/photos/`;

export const projectPhotoById = (projectId = 'project_pk', photoId = 'photo_pk') =>
  `projects/${projectId}/documents/${photoId}/`;

export const drawRequestAuditLog = (drawRequestId = 'draw_request_pk') =>
  `events/?draw_request_id=${drawRequestId}`;

export const projectAuditLog = (projectId = 'project_pk', queryURL = '') =>
  `events/?project_id=${projectId}${queryURL}`;

export const requestReviews = (
  projectId = 'project_pk',
  drawRequestId = 'draw_request_pk',
  restQlParams = '',
) =>
  `projects/${projectId}/draw_requests/${drawRequestId}/reviews/${restQlParams ? `?query=${restQlParams}` : ''}`;

export const drawRequestBatchUpdate = (projectId: string, drawRequestId: string) =>
  `projects/${projectId}/draw_requests/${drawRequestId}/bulk/`;

export const drawRequestSources = (projectId: string, drawRequestId: string) =>
  `projects/${projectId}/draw_requests/${drawRequestId}/sources/`;

export const drawRequestFundingSource = (
  projectId: string,
  drawRequestId: string,
  fundingSourceId: string,
) => `projects/${projectId}/draw_requests/${drawRequestId}/sources/${fundingSourceId}/`;
