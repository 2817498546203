import { Box, Typography } from '@mui/material';
import { currencyFormatter, getAddress, percentFormatter, getProjectDuration } from '@utils';
import React, { FC } from 'react';
import { LabelAndValue, InspectionLabelAndValue } from '@components';
import { ControllerInterface } from '../controller';
import first from 'lodash/first';
import { colors } from '@theme';
import { useDayJsFormatter } from '@hooks';

export const InspectionReport: FC<{ controller: ControllerInterface }> = ({ controller }) => {
  const { dateFormatter } = useDayJsFormatter();
  const { project, inspectionList, drawRequestData } = controller;
  const duration = getProjectDuration(project);

  return (
    <Box sx={{ breakInside: 'avoid' }} pr="15px" pl="15px">
      <Typography variant="printH2" data-cy="report_inspection_section_title">
        Inspection report
      </Typography>
      <Typography variant="body2SemiBold" color={colors.text.heading}>
        Project address
      </Typography>
      <LabelAndValue
        label="Address"
        text={getAddress(project.address)}
        colorLabel={colors.text.dark}
      />
      <Box p="10px" />
      <Typography variant="body2SemiBold" color={colors.text.heading}>
        Inspection report
      </Typography>
      <InspectionLabelAndValue
        label={inspectionList.length > 1 ? 'Inspections' : 'Inspection'}
        multi={inspectionList}
        inspection={first(inspectionList)}
      />

      {project?.property_existing_type && (
        <LabelAndValue
          label="Property type"
          text={project.property_existing_type}
          colorLabel={colors.text.dark}
        />
      )}
      <LabelAndValue
        label="Total loan commitment"
        text={currencyFormatter(project?.loan?.loc_commitment, '-')}
        colorLabel={colors.text.dark}
      />
      <LabelAndValue
        label="Total construction budget"
        text={currencyFormatter(project?.loan?.total_construction_budget, '-')}
        colorLabel={colors.text.dark}
      />
      <LabelAndValue
        label="Total construction holdback"
        text={currencyFormatter(project?.loan?.budget_construction_holdback, '-')}
        colorLabel={colors.text.dark}
      />
      <LabelAndValue
        label="Construction holdback approved (post draw)"
        text={currencyFormatter(
          drawRequestData.totals?.all?.approved_amount_cumulative -
            drawRequestData.totals?.all?.approved_budget_change,
          '-',
        )}
        colorLabel={colors.text.dark}
      />
      <LabelAndValue
        label="Construction holdback remaining (post draw)"
        text={currencyFormatter(
          project?.loan?.budget_construction_holdback -
            drawRequestData.totals?.all?.approved_amount_cumulative +
            drawRequestData.totals?.all?.approved_budget_change,
          '-',
        )}
        colorLabel={colors.text.dark}
      />
      <LabelAndValue
        label="Construction holdback approved for this draw"
        text={currencyFormatter(drawRequestData.totals?.all?.approved_amount, '-')}
        colorLabel={colors.text.dark}
      />
      <LabelAndValue
        label="Approved to date (pre draw)"
        text={percentFormatter({
          value: drawRequestData.totals?.all?.previous_lender_allowance_rate,
        })}
        colorLabel={colors.text.dark}
      />
      <LabelAndValue
        label="Lender allowance (post draw)"
        text={percentFormatter({
          value: drawRequestData.totals?.all?.lender_allowance_rate,
        })}
        colorLabel={colors.text.dark}
      />
      <LabelAndValue
        label="Inspection allowance (%)"
        text={percentFormatter({
          value: drawRequestData.totals?.all?.inspector_allowance_rate,
        })}
        colorLabel={colors.text.dark}
      />
      <LabelAndValue
        label="Previous inspection allowance (%)"
        text={percentFormatter({
          value: drawRequestData.inspector_allowance_rate_before,
        })}
        colorLabel={colors.text.dark}
      />
      <LabelAndValue
        label="Loan funding date"
        text={dateFormatter({ date: project.loan.funding_date })}
        colorLabel={colors.text.dark}
      />
      <LabelAndValue
        label="Loan maturity date"
        text={dateFormatter({ date: project.loan.maturity_date })}
        colorLabel={colors.text.dark}
      />

      <LabelAndValue label={duration.title} text={duration.value} colorLabel={colors.text.dark} />
      <LabelAndValue
        label="Current project completion date"
        text={dateFormatter({ date: project.estimated_completion_date })}
        colorLabel={colors.text.dark}
      />
      <Typography variant="printH2" data-cy="report_scope_section_title">
        Scope of work
      </Typography>
      <Typography variant="printBody1">{project?.scope_of_work}</Typography>
    </Box>
  );
};
