import { useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import isEmpty from 'lodash/isEmpty';
import {
  HookState,
  IDrawRequestFundingSource,
  IPieChartData,
  IProjectProgressItem,
  QueryNamesEnums,
} from '@interfaces';
import { getDrawRequestSources } from '@globalService';
import { calculateFraction, currencyFormatter, getHookState, percentFormatter } from '@utils';
import { colors } from '@theme';

interface ControllerInterface {
  state: HookState;
  requestProgressItems: { originalScheduledValue: Partial<IProjectProgressItem> };
  getRequestOriginalValuePieChartData: () => IPieChartData[];
  drawRequestFundingSources: IDrawRequestFundingSource[];
  constructionHoldbackFS: IDrawRequestFundingSource;
  borrowerEquityFS: IDrawRequestFundingSource;
}

const defaultProgressItems = {
  originalScheduledValue: { after_value: 0, before_value: 0, total: 0 },
};

export const useRequestPaymentData = (): ControllerInterface => {
  const { projectId, drawRequestId } = useParams();

  const drawRequestFundingSourcesQuery = useQuery<{ results: IDrawRequestFundingSource[] }, Error>(
    [QueryNamesEnums.GET_DRAW_REQUEST_FUNDING_SOURCES, { projectId, drawRequestId }],
    getDrawRequestSources.bind(this, projectId, drawRequestId),
    { enabled: Boolean(projectId && drawRequestId) },
  );

  const drawRequestFundingSources = useMemo(
    () =>
      drawRequestFundingSourcesQuery.data?.results
        ?.filter((fundingSource) => fundingSource.is_active)
        .sort((a, b) => a.index - b.index),
    [drawRequestFundingSourcesQuery.data],
  );

  const construction_holdback = useMemo(
    () => drawRequestFundingSources?.find((fundingSource) => fundingSource.is_transactable),
    [drawRequestFundingSources],
  );

  const borrower_equity = useMemo(
    () => drawRequestFundingSources?.find((fundingSource) => !fundingSource.is_transactable),
    [drawRequestFundingSources],
  );

  const revisedValueTotal = useMemo(
    () => (construction_holdback?.revised_total || 0) + (borrower_equity?.revised_total || 0),
    [construction_holdback?.revised_total, borrower_equity?.revised_total],
  );

  const createProgressItem = (after_value: number, before_value: number, total: number) => {
    const after = calculateFraction(after_value, total);
    const before = calculateFraction(before_value, total);
    return {
      after_value,
      before_value,
      total,
      after,
      before,
      currentDraw: `${currencyFormatter(after_value - before_value)} / ${percentFormatter({
        value: after - before,
        roundTo: 2,
      })}`,
      contributedText: `${currencyFormatter(before_value)} / ${percentFormatter({
        value: before,
        roundTo: 2,
      })}`,
      remainingText: `${currencyFormatter(total - after_value)} / ${percentFormatter({
        value: 100 - after,
        roundTo: 2,
      })}`,
      barValues: [before_value, after_value - before_value],
    };
  };

  const requestProgressItems = useMemo(() => {
    if (!drawRequestFundingSources || isEmpty(drawRequestFundingSources))
      return defaultProgressItems;

    return {
      originalScheduledValue: createProgressItem(
        construction_holdback.funded_after_current_request +
          borrower_equity.funded_after_current_request,
        construction_holdback.funded_before_current_request +
          borrower_equity.funded_before_current_request,
        revisedValueTotal,
      ),
    };
  }, [drawRequestFundingSources, construction_holdback, borrower_equity, revisedValueTotal]);

  const totalAmount = useMemo(
    () => construction_holdback?.amount + borrower_equity?.amount,
    [construction_holdback?.amount, borrower_equity?.amount],
  );

  const borrowerEquityFraction = useMemo(
    () => calculateFraction(borrower_equity?.amount, totalAmount),
    [borrower_equity?.amount, totalAmount],
  );

  const constructionHoldbackFraction = useMemo(
    () => calculateFraction(construction_holdback?.amount, totalAmount),
    [construction_holdback?.amount, totalAmount],
  );

  const getRequestOriginalValuePieChartData = useCallback(
    () => [
      {
        label: `Original borrower equity (${percentFormatter({ value: borrowerEquityFraction })})`,
        color: colors.status.orange.medium,
        value: borrower_equity?.amount,
      },
      {
        label: `Construction holdback (${percentFormatter({ value: constructionHoldbackFraction })})`,
        color: colors.status.information.medium,
        value: construction_holdback?.amount,
      },
    ],
    [borrowerEquityFraction, constructionHoldbackFraction],
  );

  return {
    state: getHookState(drawRequestFundingSourcesQuery),
    requestProgressItems,
    getRequestOriginalValuePieChartData,
    drawRequestFundingSources,
    constructionHoldbackFS: construction_holdback,
    borrowerEquityFS: borrower_equity,
  };
};
