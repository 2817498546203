import React, { useCallback, useMemo } from 'react';
import {
  gridColumnDefinitionsSelector,
  gridColumnVisibilityModelSelector,
  useGridApiContext,
  useGridSelector,
} from '@mui/x-data-grid-premium';
import { Stack, Box, Divider } from '@mui/material';
import snakeCase from 'lodash/snakeCase';
import cloneDeep from 'lodash/cloneDeep';
import { columnInitScheme } from '@utils';
import ColumnCheck from './ColumnCheck';

const GridColumnsPanel = () => {
  const apiRef = useGridApiContext();
  const columns = useGridSelector(apiRef, gridColumnDefinitionsSelector);
  const columnsWithVisible = useGridSelector(apiRef, gridColumnVisibilityModelSelector);

  const groupedColumns = useMemo(
    () =>
      columns
        .reduce((result: typeof columnInitScheme, column) => {
          const existIndex = [
            ...result[1].initColumns,
            ...result[2].initColumns,
            ...result[3].initColumns,
          ].indexOf(column.field);

          switch (true) {
            case existIndex === -1:
              result[0].columns.push(column);
              break;
            case existIndex < result[1].initColumns.length:
              result[1].columns.push(column);
              break;
            case existIndex < result[1].initColumns.length + result[2].initColumns.length:
              result[2].columns.push(column);
              break;
            case existIndex <
              result[1].initColumns.length +
                result[2].initColumns.length +
                result[3].initColumns.length:
              result[3].columns.push(column);
              break;
          }
          return result;
        }, cloneDeep(columnInitScheme))
        .filter((itm) => !!itm.columns.length),
    [columns],
  );

  const groupChange = useCallback(
    (item, isGroupChecked) => {
      const group = item.columns.reduce((data, column) => {
        data[column.field] = !isGroupChecked;
        return data;
      }, {});
      apiRef.current.setColumnVisibilityModel({ ...columnsWithVisible, ...group });
    },
    [apiRef.current, columnsWithVisible],
  );

  const columnChange = useCallback(
    (field: string) => {
      apiRef.current.setColumnVisibility(field, !columnsWithVisible[field]);
    },
    [columnsWithVisible],
  );

  return (
    <>
      {groupedColumns.map((item, upperIndex) => {
        const isGroupChecked = item.columns.every((item) =>
          Boolean(columnsWithVisible[item.field]),
        );

        return (
          <Stack flexWrap="nowrap" key={upperIndex} sx={{ width: 320 }}>
            <Stack direction="row" alignItems="stretch" spacing={2} pb={2} pt={2}>
              <Box borderRadius="0 4px 4px 0" bgcolor={item.color} width={4} />
              <Box>
                {item.name && (
                  <ColumnCheck
                    description={item.description}
                    key={upperIndex.toString()}
                    label={item.name}
                    checked={isGroupChecked}
                    onClick={() => groupChange(item, isGroupChecked)}
                    index={0}
                    id={'_'}
                    source={`${snakeCase(item.name)}__group`}
                  />
                )}
                {item.columns.map((column: any, index) =>
                  column.headerName ? (
                    <ColumnCheck
                      key={upperIndex + index.toString()}
                      id={column.field}
                      label={column.headerName}
                      checked={!(columnsWithVisible[column.field] === false)}
                      onClick={() => columnChange(column.field)}
                      tooltipText={
                        column.hasError ? 'Column with pending actions cannot be hidden' : null
                      }
                      disabled={!column.hideable}
                      index={index}
                      source={`__column_filter__${column.field}`}
                    />
                  ) : null,
                )}
              </Box>
            </Stack>
            <Divider />
          </Stack>
        );
      })}
    </>
  );
};

export default GridColumnsPanel;
