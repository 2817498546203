import React, { FC } from 'react';
import { Button, Stack } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { Popup } from '@components';
import { EditIcon, PlusIcon } from '@svgAsComponents';
import { LineItemEditProps } from './interface';
import { useLineItemEdit } from './controller';
import fieldsConfigs from './fields';
import { LineItemModalTypeEnums } from '@interfaces';

const LineItemEditV2: FC<LineItemEditProps> = ({ modalConfig, setLineItemModal, request }) => {
  const { type, fields, deleteAction, open } = modalConfig;
  const {
    isValidRequest,
    onClose,
    handleSubmitClick,
    handleDeleteClick,
    isSubmitting,
    isDeleting,
    fieldsModels,
  } = useLineItemEdit({
    modalConfig,
    setLineItemModal,
    requestId: request?.id,
  });

  return (
    <Popup
      open={open}
      title={`${type === LineItemModalTypeEnums.ADD ? 'Add' : 'Edit'} line item`}
      icon={type === LineItemModalTypeEnums.ADD ? PlusIcon : EditIcon}
    >
      <Stack sx={{ width: '100%' }}>
        <Stack sx={{ mt: 4 }} spacing={2}>
          {(fields || []).map((field, index) => {
            const Component = fieldsConfigs[field]().Component;
            const componentProps = fieldsConfigs[field]().componentProps;
            return (
              <Component key={index.toString()} field={fieldsModels[field]} {...componentProps} />
            );
          })}
        </Stack>
        <Stack
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
          sx={{ mt: 7.5 }}
          spacing={1}
        >
          <Button variant="text" onClick={onClose}>
            Cancel
          </Button>
          {Boolean(deleteAction) && (
            <LoadingButton
              loading={isDeleting}
              variant="new"
              color="error"
              onClick={handleDeleteClick}
              data-cy="delete_line_item_modal_button"
            >
              Delete
            </LoadingButton>
          )}
          <LoadingButton
            disabled={!isValidRequest}
            onClick={handleSubmitClick}
            loading={isSubmitting}
            data-cy={
              type === LineItemModalTypeEnums.ADD
                ? 'add_line_item_modal_button'
                : 'edit_line_item_modal_button'
            }
          >
            {type === LineItemModalTypeEnums.ADD ? 'Add' : 'Save'}
          </LoadingButton>
        </Stack>
      </Stack>
    </Popup>
  );
};

export default LineItemEditV2;
