import { IMilestoneColumn, MilestoneColumnIconType } from '@interfaces';
import { Stack, styled, Tooltip, Typography } from '@mui/material';
import { getColumnByName } from '@utils';
import React, { FC } from 'react';
import { SuccessIcon, HistoryIcon, WarningIcon } from '@svgAsComponents';
import { colors } from '@theme';

interface ColumnIconInterface {
  columns: IMilestoneColumn[];
  name: string;
  source: string;
}

const Wrapper = styled(Stack)(({ theme }) => ({
  backgroundColor: colors.neutral.lightest,
  borderRadius: theme.spacing(0.5),
  overflow: 'hidden',
  alignItems: 'center',
  justifyContent: 'center',
  height: theme.spacing(3),
  width: theme.spacing(3),
  cursor: 'pointer',
}));

const Icon: FC<{ type: MilestoneColumnIconType }> = ({ type }) => {
  switch (type) {
    case 'COMPLETED':
      return (
        <Wrapper sx={{ background: colors.status.success.lighter }}>
          <SuccessIcon color={colors.status.success.medium} size={16} />
        </Wrapper>
      );
    case 'IN_PROGRESS':
      return (
        <Wrapper>
          <HistoryIcon size={16} />
        </Wrapper>
      );
    case 'WARNING':
      return (
        <Wrapper sx={{ background: colors.status.warning.lighter }}>
          <WarningIcon color={colors.status.warning.medium} size={16} />
        </Wrapper>
      );
    case 'ERROR':
      return (
        <Wrapper sx={{ background: colors.status.error.lighter }}>
          <WarningIcon color={colors.status.error.medium} size={16} />
        </Wrapper>
      );
    default:
      return null;
  }
};

export const ColumnIcon: FC<ColumnIconInterface> = ({ columns, name, source }) => {
  const column = getColumnByName(columns || [], name);

  return column?.icon_type ? (
    <Tooltip placement="top-end" title={column.icon_tooltip} sx={{ mr: 'auto' }}>
      <Stack
        zIndex={1500000}
        ml={1}
        direction="row"
        alignItems="center"
        data-cy={`${source}__header__${name}__${column.icon_type.toLowerCase()}_icon`}
        spacing={0.5}
      >
        {column.count && (
          <Typography variant="labelSemiBold" sx={{ color: colors.status.error.medium }}>
            {column.count}
          </Typography>
        )}
        <Icon type={column.icon_type} />
      </Stack>
    </Tooltip>
  ) : null;
};
