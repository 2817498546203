import React, { FC, useCallback, useContext, useMemo } from 'react';
import { Tooltip, Typography } from '@mui/material';
import { ColumnLabel, HeaderText, MilestoneListColumnType } from './common';
import { currencyFormatter, notAllowNegative } from '@utils';
import { CommonRowType, ColumnV2Width, IMilestoneTotal } from '@interfaces';
import { TableContext } from '../controller';
import { useDebounceInput, useStringFieldModel } from '@models';
import { TextInputWithTooltip } from '@components';

const Cell: FC<{ row: CommonRowType }> = ({ row }) => {
  const { apiUpdate, source, getLineItemError } = useContext(TableContext);
  const error = getLineItemError(row.id, 'retainage_release_requested');

  const retainage_release_requested = useStringFieldModel({
    initError: error,
    initValue: (row['retainage_release_requested'] || '').toString(),
    withProgressCheck: true,
    blockNavigationKey: 'retainage_release_requested',
  });

  const isEditable = useMemo(
    () =>
      row.activeToEdit &&
      row.retainage_rate &&
      (row.localIsUserCreator || row.isSubmission) &&
      row.is_retainage_release_requested_editable,
    [row],
  );

  const effect = useCallback(
    (props) => {
      +retainage_release_requested.floatValue !== +row['retainage_release_requested'] &&
        apiUpdate({
          milestoneId: row.id,
          value: retainage_release_requested.floatValue,
          name: 'retainage_release_requested',
          ...props,
        });
    },
    [retainage_release_requested.floatValue, row['retainage_release_requested']],
  );

  const { onBlur, onChange } = useDebounceInput({
    effect,
    handleChange: retainage_release_requested.handleChange,
    afterEffect: () => retainage_release_requested.changeNavigationBlockContext(false),
  });

  return isEditable ? (
    <TextInputWithTooltip
      sx={{ width: '100%', maxWidth: 400 }}
      value={retainage_release_requested.value}
      onChange={onChange}
      onBlur={onBlur}
      type="currency"
      error={Boolean(error)}
      errorTip={error}
      inputProps={{
        isAllowed: notAllowNegative,
      }}
      dataTestName={`${source}__body__retainage_release_requested__index_${row?.project_milestone?.index}`}
    />
  ) : (
    <Tooltip title={`Retainage can only be requested for completed line items.`}>
      <Typography
        variant="body3"
        data-cy={`${source}__body__retainage_release_requested__index_${row?.project_milestone?.index}`}
      >
        {currencyFormatter(row.retainage_release_requested, '-')}
      </Typography>
    </Tooltip>
  );
};

const Footer: FC<{ row: IMilestoneTotal }> = ({ row }) => {
  const { source } = useContext(TableContext);

  return (
    <Typography
      variant="body3SemiBold"
      data-cy={`${source}__footer__retainage_release_requested__value`}
    >
      {currencyFormatter(row.retainage_release_requested || 0)}
    </Typography>
  );
};

const retainageReleaseRequested: MilestoneListColumnType = {
  name: 'retainage_release_requested',
  columnText: 'Requested retainage release ($)',
  isEditable: true,
  renderCell: ({ row }) => <Cell row={row} />,
  Footer: (row) => <Footer row={row} />,
  minWidth: (activeToEdit = false) =>
    activeToEdit ? ColumnV2Width.INPUT : ColumnV2Width.NUMBER_MIN,
  maxWidth: (activeToEdit = false) =>
    activeToEdit ? ColumnV2Width.INPUT : ColumnV2Width.NUMBER_MAX,
  Header: (
    <HeaderText tooltipText="Requested retainage release ($)">
      <ColumnLabel>Requested retainage</ColumnLabel>
      <ColumnLabel>release ($)</ColumnLabel>
    </HeaderText>
  ),
};

export default retainageReleaseRequested;
