import { useCallback, useMemo } from 'react';
import { useQuery } from 'react-query';
import { getHookState, isAppraisalDocumentType } from '@utils';
import {
  DocumentContentTypeEnum,
  DocumentTypeEnum,
  IDocumentType,
  IProjectDocument,
  QueryNamesEnums,
  TransloaditTemplateEnum,
} from '@interfaces';
import { useFilesUploader } from '@hooks';
import { getProjectDocuments, getProjectDocumentTypes } from '@globalService';
import { ServiceOrderDocumentsParams } from './interface';

export const useAppraisalDocuments = ({
  projectId,
  serviceOrderId,
}: ServiceOrderDocumentsParams) => {
  const stringQueryParams = `&document_type=${DocumentTypeEnum.APPRAISAL}`;
  const projectDocumentsQuery = useQuery<{ results: IProjectDocument[]; count: number }, Error>(
    [QueryNamesEnums.GET_PROJECT_DOCUMENTS, { projectId, stringQueryParams }],
    getProjectDocuments.bind(this, { projectId, stringQueryParams }),
    { enabled: Boolean(projectId) },
  );

  const projectDocumentTypesQuery = useQuery<IDocumentType[], Error>(
    [QueryNamesEnums.GET_PROJECT_DOCUMENT_TYPES],
    getProjectDocumentTypes.bind(this),
  );

  const {
    isFilesUploaderOpened,
    filesUploaderClose,
    transloaditSignature,
    uploadMedia,
    restrictions,
  } = useFilesUploader();

  const handleOpenDocumentUploader = useCallback(() => {
    const content_type = DocumentContentTypeEnum.PROJECT;

    const fields = { content_type, object_id: projectId };
    const templateType = TransloaditTemplateEnum.DOCUMENTS;

    uploadMedia({
      fields,
      templateType,
    });
  }, [projectId, uploadMedia, serviceOrderId]);

  const documentTypes = useMemo(
    () => projectDocumentTypesQuery.data?.filter((type) => isAppraisalDocumentType(type.name)),
    [projectDocumentTypesQuery.data],
  );

  const documents = useMemo(
    () => projectDocumentsQuery.data?.results,
    [projectDocumentsQuery.data?.results],
  );

  return {
    state: getHookState(projectDocumentsQuery),
    handleOpenDocumentUploader,
    isFilesUploaderOpened,
    transloaditSignature,
    filesUploaderClose,
    documentTypes,
    restrictions,
    documents,
    isLoading: projectDocumentsQuery.isFetching,
  };
};
