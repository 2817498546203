import { useCallback, useContext, useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import find from 'lodash/find';
import map from 'lodash/map';
import { useParams } from 'react-router-dom';

import {
  IDrawRequest,
  ILineItemModal,
  IMilestone,
  LineItemHighlightVariantEnum,
  LineItemModalTypeEnums,
  QueryNamesEnums,
  TableKeyEnum,
} from '@interfaces';
import {
  checkIsCreator,
  checkIsReallocateComplete,
  getRequestRetainageRate,
  getTeamRole,
  getTooltipText,
  isDrawRequest,
  isRequestDraft,
} from '@utils';
import { useLineItemsFilter, useRequestTotals } from '@hooks';
import { AuthContext, SettingsContext, useLaunchDarklyFlags } from '@context';
import { drawRequestQueryFields, LineItemFilterValues } from '@constants';
import { IMilestoneList } from '../interfaces';
import { getDrawRequest, getDrawRequestMilestones } from '@globalService';

export const useMilestoneList = ({
  drawRequest,
  isReallocationAllowed,
}: {
  drawRequest: IDrawRequest;
  isReallocationAllowed: boolean;
}): IMilestoneList => {
  const { projectId, requestId: drawRequestId } = useParams();
  const { isCurrentProjectArchived, isPHBProject } = useContext(SettingsContext);

  const { user } = useContext(AuthContext);
  const teamRole = getTeamRole(user);
  const [lineItemModal, setLineItemModal] = useState<ILineItemModal>({
    open: false,
    type: LineItemModalTypeEnums.ADD,
    lineItem: null,
  });
  const flags = useLaunchDarklyFlags();

  const {
    filterValue,
    handleFilterClick,
    defaultOptions,
    isMilestoneMutatingOrFetching,
    filterKey,
  } = useLineItemsFilter({
    defaultState: LineItemFilterValues.ALL.filterValue,
    tableKey: TableKeyEnum.SUBMISSION,
  });

  const drawRequestMilestonesQuery = useQuery<{ results: IMilestone[] }, Error>(
    [QueryNamesEnums.GET_DRAW_REQUEST_MILESTONES, { projectId, drawRequestId, filterKey }],
    getDrawRequestMilestones.bind(this, { projectId, drawRequestId, filterKey }),
    { enabled: Boolean(projectId && drawRequestId && !isPHBProject) },
  );

  const currentDrawRequestMilestonesQuery = useQuery<
    { results: IMilestone[]; count: number },
    Error
  >(
    [
      QueryNamesEnums.GET_DRAW_REQUEST_MILESTONES,
      {
        projectId,
        drawRequestId,
        filterKey: LineItemFilterValues.CURRENT_REQUEST_ONLY.filterKey,
        restQlParams: '{}',
        limit: '1',
      },
    ],
    getDrawRequestMilestones.bind(this, {
      projectId,
      drawRequestId,
      filterKey: LineItemFilterValues.CURRENT_REQUEST_ONLY.filterKey,
      restQlParams: '{}',
      limit: '1',
    }),
    { enabled: Boolean(projectId && drawRequestId) },
  );

  const totalKey = useMemo(
    () => find(defaultOptions, { filterValue })?.totalKey || 'all',
    [filterValue],
  );
  const { refetchAndReplaceTotals } = useRequestTotals({
    projectId,
    drawRequestId: drawRequest.id,
    totalKey,
    filterKey,
  });
  const drawRequestTotalsQuery = useQuery<IDrawRequest, Error>(
    [
      QueryNamesEnums.GET_DRAW_REQUEST,
      {
        projectId,
        drawRequestId: drawRequest.id,
        query: `{totals{${totalKey}},${drawRequestQueryFields}}`,
      },
    ],
    getDrawRequest.bind(this, {
      projectId,
      drawRequestId: drawRequest.id,
      query: `{totals{${totalKey}},${drawRequestQueryFields}}`,
    }),
  );

  const isReallocateComplete = useMemo(() => checkIsReallocateComplete(drawRequest), [drawRequest]);

  const isEditable = useMemo(() => isRequestDraft(drawRequest?.status), [drawRequest]);

  const draftMilestones = useMemo(() => {
    if (!drawRequestMilestonesQuery.data?.results) return [];

    return drawRequestMilestonesQuery.data?.results?.map((item) => ({
      ...item,
      localIsUserCreator: checkIsCreator(drawRequest, teamRole),
      localNew: item.milestone_is_new_for_current_draw,
      activeToEdit: isEditable,
      canAddPhotos: isEditable,
      localHighlight: item.milestone_is_new_for_current_draw
        ? LineItemHighlightVariantEnum.CURRENT
        : item.requested_adjustments || item.requested_amount
          ? isReallocateComplete
            ? LineItemHighlightVariantEnum.CURRENT
            : LineItemHighlightVariantEnum.ERROR
          : undefined,
      disabled: {
        value: isCurrentProjectArchived,
        reason: getTooltipText({ isCurrentProjectArchived }),
      },
      isReallocationAllowed,
      isSubmission: true,
    }));
  }, [
    drawRequest,
    isReallocateComplete,
    filterValue,
    isEditable,
    drawRequestMilestonesQuery.data,
    isReallocationAllowed,
  ]);

  const addLineList = useCallback(() => {
    setLineItemModal({
      open: true,
      type: LineItemModalTypeEnums.ADD,
      lineItem: null,
      fields: ['name', 'cost_type', 'requested_revised_estimate'],
      submitAction: 'addToRequest',
    });
  }, []);

  const retainageRate = useMemo(() => getRequestRetainageRate(drawRequest), [drawRequest]);

  const initColumns = useMemo(() => {
    if (!drawRequest) return [];

    return [
      'nameV2',
      ...(flags?.[`ENG_7895_table_v3__${TableKeyEnum.SUBMISSION}`] ? ['externalId'] : []),
      'costType',
      'prefundingCost',
      'originalEstimate',
      'previousChanges',
      'previousChangesRate',
      ...(flags?.[`ENG_7895_table_v3__${TableKeyEnum.SUBMISSION}`]
        ? ['revisedEstimateInDraft']
        : ['revisedEstimate']),
      ...(isReallocationAllowed
        ? [
            ...(flags?.['ENG_9922_allow_lender_to_change_request_amounts']
              ? ['requestedAdjustmentsV2']
              : ['requestedAdjustments']),
            'requestedAdjustmentsRate',
            'requestedRevisedEstimate',
          ]
        : []),
      'previousApprovedAmountCumulative',
      'previousLenderAllowanceRate',
      ...(flags?.[`ENG_7895_table_v3__${TableKeyEnum.SUBMISSION}`]
        ? ['balanceToFinishInDraft']
        : ['balanceToFinish']),
      'balanceToFinishRate',
      ...(isDrawRequest(drawRequest)
        ? [
            ...(flags?.['ENG_9922_allow_lender_to_change_request_amounts']
              ? ['requestedAmountV2']
              : ['requestedAmount']),
            'requestedAmountRelative',
          ]
        : []),
      ...(isDrawRequest(drawRequest) && retainageRate
        ? [
            'retainageRequestedHoldback',
            'retainageBalanceTodateRequested',
            'retainageReleaseRequested',
            'disbursementAmountRequested',
          ]
        : []),
      'spaceItem',
      'editLineItem',
      ...(isDrawRequest(drawRequest) ? ['submitCredit'] : []),
      'documentsPhotosUploaderMenuV2',
      'documentsPhotosGalleryMenuV2',
      'comments',
    ];
  }, [drawRequest, flags, retainageRate, isReallocationAllowed]);

  const isRequestChanged = useMemo(
    () => currentDrawRequestMilestonesQuery.data?.count > 0,
    [currentDrawRequestMilestonesQuery.data?.count],
  );

  const openEditMilestoneModal = useCallback(
    (lineItem: IMilestone) => {
      setLineItemModal({
        open: true,
        type: LineItemModalTypeEnums.EDIT,
        lineItem,
        fields: ['name', 'cost_type'],
        submitAction: 'editInRequest',
      });
    },
    [setLineItemModal],
  );

  const totals = useMemo(
    () => ({
      ...drawRequestTotalsQuery.data?.totals?.[find(defaultOptions, { filterValue })?.totalKey],
      isFiltered: filterValue !== LineItemFilterValues.ALL.filterValue,
    }),
    [filterValue, drawRequestTotalsQuery.data],
  );

  return {
    isReallocateComplete,
    initColumns,
    filteredMilestones: draftMilestones,
    drawRequest,
    project: projectId,
    addLineList,
    refetch: () => {
      refetchAndReplaceTotals();
      currentDrawRequestMilestonesQuery.refetch();
    },
    isDrawRequest: isDrawRequest(drawRequest),
    isRequestChanged,
    isEditable,
    lineItemModal,
    setLineItemModal,
    openEditMilestoneModal,
    filterValue,
    handleFiltersChange: handleFilterClick,
    filterOptions: map(defaultOptions, 'filterValue'),
    isMilestoneMutatingOrFetching,
    totals,
  };
};
