import React, { FC } from 'react';
import { Typography, Box, Stack, Tooltip } from '@mui/material';
import { currencyFormatter, isDrawRequest } from '@utils';
import { StringFieldModel, DateFieldModel } from '@models';
import { IDrawRequest, IMilestone, IMilestoneTotal, ToolTipLineVariantEnum } from '@interfaces';

import { CenteredStyledBox, LabelAndValue, ToolTipLine } from '@components';
import { colors } from '@theme';
import { useLaunchDarklyFlags } from '@context';
import { useDayJsFormatter } from '@hooks';
import { BudgetReviewIcon } from '@svgAsComponents';

export const ConfirmationDraw: FC<{
  drawRequest: IDrawRequest;
  inspectionComment: StringFieldModel;
  inspectionRequestedAt: DateFieldModel;
  documentsSummary: {
    [key: string]: number;
  };
  feesAmount: number;
  showDocumentsSection: boolean;
  totalRetainageRequested: number;
  projectRetainageRate?: number;
  isReallocationAllowed?: boolean;
  milestones: IMilestone[];
}> = ({
  drawRequest,
  inspectionComment,
  documentsSummary,
  feesAmount,
  showDocumentsSection,
  totalRetainageRequested,
  projectRetainageRate,
  isReallocationAllowed,
  milestones,
  inspectionRequestedAt,
}) => {
  const { dateFormatter } = useDayJsFormatter();
  const flags = useLaunchDarklyFlags();
  return (
    <Stack alignItems="center" spacing={2}>
      {!drawRequest?.is_lump_sum_request && (
        <>
          <SubtotalByLineItems request={drawRequest} milestones={milestones} />
          {!flags?.['ENG_6297_borrower_equity_in_confirmation_summary'] && (
            <Totals
              requestedReallocation={drawRequest?.requested_reallocation}
              totals={drawRequest?.totals?.all}
            />
          )}
          {flags?.['ENG_6297_borrower_equity_in_confirmation_summary'] && (
            <BudgetAndAdjustments
              requestedReallocation={drawRequest?.requested_reallocation}
              totals={drawRequest?.totals?.all}
              isReallocationAllowed={isReallocationAllowed}
            />
          )}
        </>
      )}
      <DrawSummaryBlock
        totals={drawRequest?.totals?.all}
        totalRetainageRequested={totalRetainageRequested}
        drawRequest={drawRequest}
        projectRetainageRate={projectRetainageRate}
        feesAmount={feesAmount}
      />
      {showDocumentsSection && (
        <CenteredStyledBox>
          <Typography variant="h3">Documents</Typography>
          <Box sx={{ mb: 3 }} />
          <Box sx={{ flexWrap: 'wrap', display: 'flex' }}>
            {Object.entries(documentsSummary).map(([type, quantity], index) => (
              <Typography sx={{ mr: 2.5 }} variant="body2" key={index}>{`${type} (${quantity} ${
                quantity === 1 ? 'file' : 'files'
              })`}</Typography>
            ))}
          </Box>
          {!Object.entries(documentsSummary)?.length && (
            <ToolTipLine
              typographyVariant="body2"
              variant={ToolTipLineVariantEnum.ERROR}
              text="Absence of the documents may delay this draw."
              size="medium"
            />
          )}
        </CenteredStyledBox>
      )}
      <CenteredStyledBox>
        <Typography variant="h3">Inspection availability</Typography>
        <Box sx={{ mb: 3 }} />
        <Typography variant="body2SemiBold">
          {inspectionRequestedAt.value &&
            `No earlier than ${dateFormatter({
              date: inspectionRequestedAt.value,
            })}`}
        </Typography>
        {inspectionComment.value && (
          <Typography
            dangerouslySetInnerHTML={{ __html: inspectionComment.value }}
            variant="body2"
            sx={{ whiteSpace: 'pre-wrap', mt: 2 }}
          />
        )}
      </CenteredStyledBox>
    </Stack>
  );
};

const DrawSummaryBlock: FC<{
  totals: IMilestoneTotal;
  totalRetainageRequested: number;
  drawRequest: IDrawRequest;
  projectRetainageRate?: number;
  feesAmount: number;
}> = ({ totals, totalRetainageRequested, drawRequest, projectRetainageRate, feesAmount }) => (
  <CenteredStyledBox>
    <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={1}>
      <Typography variant="h3">Draw request summary</Typography>

      {drawRequest?.is_lump_sum_request && (
        <Tooltip title="Lump sum request">
          <Stack
            direction="row"
            alignItems="center"
            spacing={1}
            data-cy="request_submission__summary__lump_sum_icon"
          >
            <BudgetReviewIcon size={24} />
          </Stack>
        </Tooltip>
      )}
    </Stack>

    <Box sx={{ mb: 3 }} />
    <Stack spacing={2}>
      <LabelAndValue
        size="body2"
        text={currencyFormatter(totals?.requested_amount || 0)}
        label="Total draw amount"
        textDataTestName="request__submission__summary__requested_amount__value"
      />
      {Boolean(totalRetainageRequested) && (
        <LabelAndValue
          size="body2"
          text={currencyFormatter(
            -1 * drawRequest?.totals?.all?.retainage_requested_amount_holdback,
          )}
          label={`Retainage heldback for this draw (${projectRetainageRate}%)`}
          textDataTestName="request__submission__summary__retainage_requested_amount_holdback__value"
        />
      )}
      {Boolean(totalRetainageRequested) && (
        <LabelAndValue
          size="body2"
          text={currencyFormatter(totalRetainageRequested)}
          label="Requested retainage release"
          textDataTestName="request__submission__summary__retainage_release_requested__value"
        />
      )}
      {Boolean(feesAmount) && (
        <LabelAndValue
          size="body2"
          text={currencyFormatter(feesAmount * -1)}
          label="Estimated draw fees"
          textDataTestName="request__submission__summary__fees_amount__value"
        />
      )}
      {Boolean(totalRetainageRequested) && (
        <ToolTipLine
          typographyVariant="body2"
          text="Retainage based on total draw, excluding soft costs and new items. Amounts are estimates only and are pending approval from the lender."
        />
      )}
    </Stack>
  </CenteredStyledBox>
);

export const ConfirmationChangeRequest: FC<{
  drawRequest: IDrawRequest;
  documentsSummary: {
    [key: string]: number;
  };
  milestones: IMilestone[];
}> = ({ drawRequest, documentsSummary, milestones }) => {
  return (
    <Stack alignItems="center" spacing={2}>
      <SubtotalByLineItems request={drawRequest} milestones={milestones} />

      {Object.entries(documentsSummary).length > 0 && (
        <CenteredStyledBox>
          <Typography variant="h3">Documents</Typography>
          <Box sx={{ mb: 3 }} />
          <Box sx={{ flexWrap: 'wrap', display: 'flex' }}>
            {Object.entries(documentsSummary).map(([type, quantity], index) => (
              <Typography sx={{ mr: 2.5 }} variant="body2" key={index}>{`${type} (${quantity} ${
                quantity === 1 ? 'file' : 'files'
              })`}</Typography>
            ))}
          </Box>
        </CenteredStyledBox>
      )}
    </Stack>
  );
};

const LocalLabel = ({ children }) => (
  <Typography
    minWidth={130}
    sx={{ color: colors.text.medium }}
    variant="labelSemiBold"
    textAlign="right"
  >
    {children}
  </Typography>
);

export const SubtotalByLineItems: FC<{
  request: IDrawRequest;
  milestones: IMilestone[];
}> = ({ request, milestones }) => {
  return (
    <Stack alignItems="center" spacing={2}>
      <CenteredStyledBox>
        <Typography variant="h3">Subtotal by line item</Typography>
        <Box sx={{ mb: 3 }} />
        <Stack spacing={1}>
          <LabelAndValue size="label" label="Line item" labelStyle={{ fontWeight: 600 }}>
            <Stack spacing={3} direction="row">
              {isDrawRequest(request) && <LocalLabel>Requested amount</LocalLabel>}
              <LocalLabel>Requested +/- change</LocalLabel>
              <LocalLabel>Revised value</LocalLabel>
            </Stack>
          </LabelAndValue>
          <Box sx={{ mb: 1 }} />
          {milestones
            ?.filter((item) => item.requested_adjustments || item.requested_amount)
            ?.map((item) => (
              <LabelAndValue
                key={item.id}
                size="body2"
                label={item.name}
                labelStyle={{
                  whiteSpace: 'normal',
                  maxWidth: isDrawRequest(request) ? '305px' : '460px',
                }}
              >
                <Stack spacing={3} direction="row">
                  {isDrawRequest(request) && (
                    <Typography minWidth={130} textAlign="right" variant="body2">
                      {currencyFormatter(item.requested_amount)}
                    </Typography>
                  )}
                  <Typography minWidth={130} textAlign="right" variant="body2">
                    {currencyFormatter(item.requested_adjustments)}
                  </Typography>
                  <Typography minWidth={130} textAlign="right" variant="body2">
                    {currencyFormatter(item.requested_revised_estimate)}
                  </Typography>
                </Stack>
              </LabelAndValue>
            ))}
          <Stack justifyContent="space-between" alignItems="center" direction="row" sx={{ mt: 2 }}>
            <Typography minWidth={130} textAlign="left" variant="body2">
              SUBTOTAL
            </Typography>
            <Stack spacing={3} direction="row">
              {isDrawRequest(request) && (
                <Typography minWidth={130} textAlign="right" variant="body2SemiBold">
                  {currencyFormatter(request.totals.all.requested_amount || 0)}
                </Typography>
              )}
              <Typography minWidth={130} textAlign="right" variant="body2SemiBold">
                {currencyFormatter(request.totals.all.requested_budget_change || 0)}
              </Typography>
              <Typography minWidth={130} textAlign="right" variant="body2SemiBold">
                {currencyFormatter(request.totals.all.revised_estimate)}
              </Typography>
            </Stack>
          </Stack>
        </Stack>
      </CenteredStyledBox>
    </Stack>
  );
};

export const Totals: FC<{
  requestedReallocation: number;
  totals: IMilestoneTotal;
}> = ({ requestedReallocation, totals }) => {
  return (
    <Stack alignItems="center" spacing={2}>
      <CenteredStyledBox>
        <Typography variant="h4">Totals</Typography>
        <Box sx={{ mb: 3 }} />
        <Stack spacing={1}>
          <LabelAndValue
            size="body2"
            text={currencyFormatter(totals?.requested_budget_change || 0)}
            label="Requested total budget change"
          />
          <LabelAndValue
            size="body2"
            text={currencyFormatter(totals?.revised_estimate)}
            label="Requested scheduled values"
          />
          <LabelAndValue
            size="body2"
            text={currencyFormatter(requestedReallocation || 0)}
            label="Requested reallocations"
          />
          <LabelAndValue
            size="body2"
            text={currencyFormatter(totals?.requested_amount || 0)}
            label="Total request"
          />
        </Stack>
      </CenteredStyledBox>
    </Stack>
  );
};

export const BudgetAndAdjustments: FC<{
  requestedReallocation: number;
  totals: IMilestoneTotal;
  isReallocationAllowed?: boolean;
}> = ({ requestedReallocation, totals, isReallocationAllowed }) => {
  return (
    <Stack alignItems="center" spacing={2}>
      <CenteredStyledBox>
        <Typography variant="h4">Budget & adjustments</Typography>
        <Box sx={{ mb: 3 }} />
        <Stack spacing={1}>
          <LabelAndValue
            size="body2"
            text={currencyFormatter(totals?.revised_estimate)}
            label="Revised scheduled values"
          />
          {isReallocationAllowed && (
            <LabelAndValue
              size="body2"
              text={currencyFormatter(requestedReallocation || 0)}
              label="Reallocations"
            />
          )}
        </Stack>
      </CenteredStyledBox>
    </Stack>
  );
};
