import { useCallback, useContext, useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { useLocation, useNavigate } from 'react-router-dom';
import { GridEventListener, GridSortModel } from '@mui/x-data-grid';

import {
  convertReactTableColumnsToMuiDataGridColumns,
  getHookState,
  getLink,
  getTeamRole,
  isAllowed,
  tableHeaders as headers,
  getSortStringByGridSortModel,
} from '@utils';
import {
  IInspection,
  IRightDrawerParams,
  IServiceOrder,
  PermissionNamesEnums,
  QueryNamesEnums,
  ServiceOrderStatusEnum,
  TableKeyEnum,
} from '@interfaces';
import { AuthContext, PermissionsContext, SettingsContext } from '@context';
import {
  getInspectionsListForAllProjects,
  getServiceOrdersListForAllProjects,
} from '@globalService';
import {
  useCommentsAndDocumentsPreview,
  useRightMenu,
  useSorting,
  useTablePagination,
} from '@hooks';
import { inspectionListQueryFields, serviceOrdersListQueryFields } from '@constants';
import { ControllerInterface } from './interface';

export const useServicesQueue = (): ControllerInterface => {
  const { permissions } = useContext(PermissionsContext);
  const { settings } = useContext(SettingsContext);
  const { user } = useContext(AuthContext);
  const teamRole = getTeamRole(user);
  const navigate = useNavigate();
  const location = useLocation();
  const [filtersReady, setFiltersReady] = useState<boolean>(false);
  const serviceTypesMap = useMemo(
    () => settings?.display?.service_types,
    [settings?.display?.service_types],
  );

  const { page, rowsPerPage, rowsPerPageOptions, onPageChange, onRowsPerPageChange } =
    useTablePagination({});
  const { handleSortClick, sortValue } = useSorting();

  const [filterStringQuery, setFilterStringQuery] = useState<string>('');
  const [tableSortingModel, setTableSortingModel] = useState<GridSortModel>([]);

  const [rightDrawerParams, setRightDrawerParams] = useState<IRightDrawerParams>({});

  const { updateCommentsPreviewInfo } = useCommentsAndDocumentsPreview({
    projectId: rightDrawerParams.projectId,
    drawRequestId: rightDrawerParams.requestId,
    inspectionId: rightDrawerParams.inspectionId,
    serviceOrderId: rightDrawerParams.serviceOrderId,
  });

  const onRightDrawerClose = useCallback(() => {
    updateCommentsPreviewInfo();
    setRightDrawerParams((old) => ({ ...old, activeTab: '' }));
  }, [updateCommentsPreviewInfo]);

  const { handleRightDrawerOpenerClick, ...rightMenu } = useRightMenu({
    onClose: onRightDrawerClose,
  });
  const updateRightDrawer = ({
    title,
    projectId,
    requestId,
    inspectionId,
    serviceOrderId,
    activeTab,
  }: IRightDrawerParams) => {
    handleRightDrawerOpenerClick({ title });
    setRightDrawerParams({ projectId, requestId, inspectionId, serviceOrderId, activeTab });
  };

  const sortKeysMap = {
    'Date ordered': 'ordered_at',
    'project.status': 'status',
  };

  const params = {
    offset: (page * rowsPerPage).toString(),
    limit: rowsPerPage.toString(),
    sorting: getSortStringByGridSortModel(tableSortingModel, sortKeysMap),
  };

  const queryParams = new URLSearchParams(params);
  const queryURL =
    queryParams.toString() +
    `${filterStringQuery ? `&${filterStringQuery}` : ''}` +
    `&excluded_status=${ServiceOrderStatusEnum.CREATED}`;

  const inspectionsQuery = useQuery<{ results: IInspection[]; count: number }, Error>(
    [QueryNamesEnums.GET_INSPECTIONS_LIST, { queryURL }],
    getInspectionsListForAllProjects.bind(this, {
      queryURL:
        queryURL +
        `&query={${[...inspectionListQueryFields, ...(isAllowed(PermissionNamesEnums.CUSTOMER_SUCCESS_ACCESS, permissions) ? ['customer{name}'] : [])].join()}}`,
    }),
    {
      keepPreviousData: true,
      enabled: filtersReady,
    },
  );
  const { data, isLoading, isFetching } = useMemo(() => inspectionsQuery, [inspectionsQuery]);

  const serviceOrdersQuery = useQuery<{ results: IServiceOrder[]; count: number }, Error>(
    [QueryNamesEnums.GET_SERVICE_ORDERS_LIST, { queryURL }],
    // some huck to make agency filter work for both inspections and service orders
    getServiceOrdersListForAllProjects.bind(this, {
      stringQueryParams:
        queryURL.replace('inspection_agency', 'service_agency') +
        `&query={${[...serviceOrdersListQueryFields, ...(isAllowed(PermissionNamesEnums.CUSTOMER_SUCCESS_ACCESS, permissions) ? ['customer{name}'] : [])].join()}}`,
    }),
    {
      keepPreviousData: true,
      enabled: filtersReady,
    },
  );

  const rows = useMemo(
    () =>
      isLoading || serviceOrdersQuery.isLoading
        ? Array.from({ length: 10 }, (_, index) => ({ id: index.toString() }))
        : [...(data?.results || []), ...(serviceOrdersQuery.data?.results || [])].sort((a, b) => {
            const dateA = a.ordered_at || a.created_at;
            const dateB = b.ordered_at || b.created_at;
            return new Date(dateB).getTime() - new Date(dateA).getTime();
          }),
    [isLoading, data, serviceOrdersQuery],
  );

  const columns = useMemo(
    () =>
      convertReactTableColumnsToMuiDataGridColumns([
        headers.orderedServiceDate({
          header: 'Date ordered',
          isLoading,
        }),
        headers.headerTemplate({
          headerName: 'Loan #',
          accessor: 'project.loan.external_id',
          isLoading,
          disableSortBy: true,
          minWidth: '150px',
        }),
        headers.headerTemplate({
          isLoading,
          headerName: 'Project',
          accessor: 'project.name',
          disableSortBy: true,
          minWidth: '250px',
        }),
        headers.serviceType({
          isLoading,
          serviceTypesMap,
          minWidth: '160px',
        }),
        headers.serviceStatus({
          isLoading,
          disableSortBy: false,
        }),
        headers.headerTemplate({
          isLoading,
          headerName: 'ID',
          disableSortBy: true,
          accessor: 'service_number',
          minWidth: '200px',
        }),
        headers.serviceAgencyColumn({
          isLoading,
          teamRole,
          disableSortBy: true,
        }),

        headers.inspectionAgencyStatus({
          isLoading,
          disableSortBy: true,
        }),
        ...(isAllowed(PermissionNamesEnums.INSPECTIONS_EDIT, permissions)
          ? [
              headers.serviceCost({
                isLoading,
                source: 'services_queue__table__body',
              }),
            ]
          : []),
        ...(isAllowed(PermissionNamesEnums.CUSTOMER_SUCCESS_ACCESS, permissions)
          ? [
              headers.amount({
                isLoading,
                accessor: 'provider_service_cost',
                header: 'Provider cost',
                disableSortBy: true,
                dataTestName: 'services_queue__table__body__provider_cost',
              }),
            ]
          : []),
        headers.scheduledInspectionDate({
          accessor: 'scheduled_at',
          header: 'Date scheduled',
          isLoading,
          disableSortBy: true,
        }),
        headers.date({
          accessor: 'completed_at',
          header: 'Date completed',
          isLoading,
        }),
        headers.headerTemplate({
          headerName: 'External ID',
          accessor: 'provider_order_id',
          isLoading,
          disableSortBy: true,
        }),
        ...(isAllowed(PermissionNamesEnums.CUSTOMER_SUCCESS_ACCESS, permissions)
          ? [
              headers.headerTemplate({
                headerName: 'Transaction ID',
                accessor: 'transaction_id',
                isLoading,
                disableSortBy: true,
              }),
            ]
          : []),
        headers.drNumber({
          isLoading,
        }),
        headers.inspectorAllowanceColumn({
          isLoading,
        }),
        headers.projectStatus({
          isLoading,
          accessor: 'project.status',
          disableSortBy: false,
        }),
        ...(isAllowed(PermissionNamesEnums.CUSTOMER_SUCCESS_ACCESS, permissions)
          ? [
              headers.headerTemplate({
                headerName: 'Customer',
                accessor: 'customer.name',
                isLoading,
                disableSortBy: true,
              }),
            ]
          : []),
        headers.serviceActions({
          isLoading,
          source: 'services_queue__table__body__actions',
        }),
        headers.serviceDocumentsAndComments({
          isLoading,
          updateRightDrawer,
          serviceTypesMap,
          source: 'services_queue__table__body',
        }),
      ]),
    [isLoading, permissions, teamRole, serviceTypesMap],
  );

  const handleRowClick: GridEventListener<'rowClick'> = (params) => {
    const link = getLink({ row: params.row, tableKey: TableKeyEnum.SERVICES_LIST });
    link && navigate(link, { state: location?.pathname });
  };

  const itemsCount = useMemo(
    () => data?.count + serviceOrdersQuery?.data?.count,
    [data, serviceOrdersQuery?.data?.count],
  );

  return {
    state: getHookState(inspectionsQuery),
    rows,
    columns,
    handleSortClick,
    sortValue,
    isFetching,
    isLoading,
    handleRowClick,
    setFilterStringQuery,
    paginationProps: {
      page,
      rowsPerPage,
      rowsPerPageOptions,
      onPageChange,
      onRowsPerPageChange,
      itemsCount,
    },
    rightDrawerParams,
    rightMenu,
    onFiltersReady: () => setFiltersReady(true),
    onSortModelChange: setTableSortingModel,
  };
};
