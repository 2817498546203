import React from 'react';
import { useParams } from 'react-router';
import { Button, Grid2, Stack, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import findIndex from 'lodash/findIndex';
import isEmpty from 'lodash/isEmpty';
import { HookState, IPhoto, PHBViews, PopupTypeEnum } from '@interfaces';
import {
  AddPhotoBox,
  ConfirmationModal,
  FilterButton,
  ImageSlickCarousel,
  LoadingSkeleton,
  MediaDetails,
  PhotoCardWithPopper,
  ServiceMessage,
  StyledBox,
  TablePaginationNew,
} from '@components';
import { colors } from '@theme';
import NoImages from '@assets/no_images.svg';
import * as Controller from './controller';

const PhotosTab = () => {
  const controller = Controller.usePhotos();
  const {
    state,
    projectId,
    drawRequestId,
    photos,
    activePhoto,
    handleFiltersChange,
    filterValue,
    tablePagination: { page, rowsPerPage, rowsPerPageOptions, onPageChange, onRowsPerPageChange },
    itemsCount,
    isPHBProject,
    getDataParamsPaginated,
    isAddPhotoAvailable,
  } = controller;

  switch (state) {
    case HookState.FETCHING: {
      return <LoadingSkeleton type="overviewBlock" />;
    }
    case HookState.ERROR: {
      return <ServiceMessage text="photos" />;
    }

    case HookState.SUCCESS: {
      return (
        <StyledBox sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
          {activePhoto && <PhotosDetails controller={controller} />}
          {!activePhoto && (
            <>
              {(Boolean(photos?.length) || !isEmpty(filterValue)) && (
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  sx={{ pb: 3 }}
                >
                  {!isPHBProject && (
                    <Stack direction="row" spacing={2} justifyContent="flex-start">
                      <FilterButton
                        title="By line items"
                        filterKey={PHBViews.LINE_ITEMS}
                        getDataParamsPaginated={getDataParamsPaginated}
                        initValues={filterValue}
                        cypressSelector="photos_tab__filter__by_line_item"
                        withPagination={true}
                        handleFiltersChange={handleFiltersChange}
                        strictSerialize={(data) =>
                          data.map((item) => ({
                            value: item.id,
                            label: item.name,
                          }))
                        }
                      />
                    </Stack>
                  )}
                  <Stack
                    alignItems="center"
                    flexDirection="row"
                    justifyContent="flex-end"
                    sx={{ width: '100%' }}
                  >
                    <TablePaginationNew
                      page={page}
                      rowsPerPage={rowsPerPage}
                      rowsPerPageOptions={rowsPerPageOptions}
                      itemsCount={itemsCount}
                      onPageChange={onPageChange}
                      onRowsPerPageChange={onRowsPerPageChange}
                      source="photos_tab__pagination"
                    />
                    {isAddPhotoAvailable && Boolean(photos?.length) && (
                      <AddPhotoBox
                        projectId={projectId}
                        drawRequestId={drawRequestId}
                        dataTestName="photos_tab__photos__upload__button"
                        iconBackgroundColor={colors.main.primary.lightest}
                        buttonProps={{ variant: 'new', color: 'secondary', sx: { ml: 2 } }}
                      />
                    )}
                  </Stack>
                </Stack>
              )}
              <GalleryViewNew controller={controller} />
            </>
          )}
        </StyledBox>
      );
    }

    default:
      return null;
  }
};

export default PhotosTab;

const GalleryViewNew = ({ controller }) => {
  const { projectId } = useParams();
  const { photos, handlePhotoClick, drawRequestId, isAddPhotoAvailable } = controller;

  if (!photos?.length)
    return (
      <Stack justifyContent="center" alignItems="center" sx={{ flexGrow: 1, mb: 6 }}>
        <img src={NoImages} style={{ maxWidth: '100%', width: '182px' }} alt="no photos" />
        <Typography variant="h2" sx={{ mt: 3, color: colors.text.heading }}>
          No progress photos
        </Typography>
        {isAddPhotoAvailable && (
          <AddPhotoBox
            projectId={projectId}
            drawRequestId={drawRequestId}
            dataTestName="photos_tab__gallery__photos__upload__button"
            iconBackgroundColor={colors.main.primary.lightest}
          />
        )}
      </Stack>
    );

  return (
    <Grid2 container direction="row" spacing={2}>
      {photos.map(
        (media) =>
          media.file_representations && (
            <Grid2
              key={media.id}
              size={{ xs: 12, sm: 6, md: 4, lg: 3, xl: 2 }}
              id="photo-container"
              onClick={media.mime_type.includes('video') ? undefined : handlePhotoClick(media?.id)}
              data-cy="photos_tab__card__image__button"
            >
              <PhotoCardWithPopper
                photo={media}
                size="medium"
                dataTestName={'photos_tab__card__image__button'}
              />
            </Grid2>
          ),
      )}
    </Grid2>
  );
};

const PhotosDetails = ({ controller }) => {
  const {
    closeCallback,
    photos,
    activePhoto,
    callbackOnPhotoChange,
    handlePhotoDelete,
    isPhotoDeleting,
    userId,
    handleProjectImageUpdate,
    confirmUpdateProjectCoverModal,
  } = controller;
  const startIndex = findIndex(photos, (o) => (o as IPhoto).id === activePhoto?.id);

  return (
    <Stack
      sx={{
        width: '100%',
        maxWidth: '100%',
        height: '100%',
        flexDirection: { sm: 'column', lg: 'row' },
        alignItems: { sm: 'center', lg: 'flex-start' },
      }}
    >
      <Stack
        sx={{
          flex: 1,
          width: {
            sm: '90vh',
            lg: 'calc(100vw - 600px)',
          },
          mt: 4.5,
          mx: { sm: 0, lg: 4 },
          my: { sm: 4, lg: 0 },
          borderRight: {
            xs: 'none',
            lg: `1px solid ${colors.neutral.light}`,
          },
          borderBottom: {
            xs: `1px solid ${colors.neutral.light}`,
            lg: 'none',
          },
          pb: { xs: 4, lg: 0 },
        }}
      >
        <Stack justifyContent="center" alignItems="center" sx={{ flex: 1 }}>
          <ImageSlickCarousel
            slides={photos}
            startIndex={startIndex}
            callbackOnPhotoChange={callbackOnPhotoChange}
            activePhoto={activePhoto}
          />
        </Stack>
      </Stack>

      <Stack justifyContent="space-between" sx={{ minWidth: 450, maxWidth: 450, height: '100%' }}>
        <MediaDetails media={activePhoto} size="medium" closeCallback={closeCallback} />
        <Stack direction="row" justifyContent="flex-end" spacing={1} mt={6} mb={2}>
          <LoadingButton
            color="error"
            variant="new"
            loading={isPhotoDeleting}
            onClick={() => handlePhotoDelete(activePhoto.id)}
            disabled={userId !== activePhoto.created_by?.id}
            data-cy="photos_tab__card__delete__button"
          >
            Delete
          </LoadingButton>
          <Button
            variant="new"
            color="secondary"
            component="a"
            href={activePhoto.link}
            download
            data-cy="photos_tab__card__download__button"
          >
            Download
          </Button>
          <Button
            onClick={confirmUpdateProjectCoverModal.askConfirm}
            variant="new"
            color="secondary"
            data-cy="photos_tab__card__make_as_cover__button"
            disabled={!!activePhoto?.file_representations?.webm}
          >
            Make as cover
          </Button>
        </Stack>
      </Stack>
      {confirmUpdateProjectCoverModal.isConfirmModalOpened && (
        <ConfirmationModal
          open={confirmUpdateProjectCoverModal.isConfirmModalOpened}
          title="Confirmation"
          text="This photo will be set as project cover photo."
          onClose={confirmUpdateProjectCoverModal.closeConfirmModal}
          confirmCallback={() => {
            handleProjectImageUpdate();
            confirmUpdateProjectCoverModal.closeConfirmModal();
          }}
          type={PopupTypeEnum.CONFIRMATION}
          source="photos_tab__card__make_as_cover"
        />
      )}
    </Stack>
  );
};
