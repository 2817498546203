import { GridEditInputCell } from '@mui/x-data-grid';
import React from 'react';
import { MilestoneListColumnTypeV2 } from './common';
import { isPercentAllowed, percentFormatter } from '@utils';
import { PercentsInput } from '@components';
import { InputBaseComponentProps } from '@mui/material';

const adjustmentsRate: MilestoneListColumnTypeV2 = {
  field: 'approved_adjustments_rate',
  valueFormatter: (value) => percentFormatter({ returnValue: ' ', value }),
  headerAlign: 'right',
  headerClassName: 'number',
  cellClassName: 'number',
  headerName: 'Approved +/- change (%)',
  description: '% of approved changes to revised scheduled value for this request',
  renderEditCell: (props) => (
    <GridEditInputCell
      {...props}
      inputProps={{
        isAllowed: isPercentAllowed,
      }}
      inputComponent={PercentsInput as unknown as React.ElementType<InputBaseComponentProps>}
    />
  ),
  editable: false,
  minWidth: 120,
};

export default adjustmentsRate;
