import dayjs from 'dayjs';
export const isValidDate = (value: Date) => {
  if (isNaN(Date.parse(value?.toString()))) return { value: false, reason: 'Invalid date' };

  if (dayjs(value).year() <= 1900)
    return { value: false, reason: 'Please use the format MM/DD/YYYY' };

  return { value: true, reason: '' };
};

export const getNextDay = (date): Date => {
  if (!date) return null;
  const nextDay = new Date(date);
  nextDay.setDate(nextDay.getDate() + 1);
  return nextDay;
};
export const getPrevDay = (date): Date => {
  if (!date) return null;
  const prevDay = new Date(date);
  prevDay.setDate(prevDay.getDate() - 1);
  return prevDay;
};
