import { useParams } from 'react-router-dom';
import { useMemo, useContext } from 'react';

import { QueryNamesEnums, PermissionNamesEnums } from '@interfaces';
import { isAllowed, Override } from '@utils';
import { SettingsContext, PermissionsContext, useGraphQuery } from '@context';

export type ControllerInterface = Override<
  ReturnType<typeof useProjectSettings>,
  {
    isLoading: boolean;
    isAllProjectDetailsDisabled: boolean;
    isLimitedProjectDetailsDisabled: boolean;
    hasInspectionsViewPermission: boolean;
    hasInspectionsEditPermission: boolean;
  }
>;

export const useProjectSettings = () => {
  const { projectId } = useParams();
  const { permissions } = useContext(PermissionsContext);
  const { isCurrentProjectArchived } = useContext(SettingsContext);
  const project = useGraphQuery({
    type: QueryNamesEnums.GET_PROJECT,
    keys: [
      'id',
      'legal_entity',
      'payment_configuration_type',
      'payment_configuration_comment',
      'inspection_additional_contact_name',
      'inspection_additional_contact_phone',
      'inspection_entry_access_code',
      'inspection_is_order_automatic',
      'inspection_primary_contact_user_id',
      'inspection_cadence',
      'fees',
      'is_lump_sum_enabled',
    ],
    args: { project_id: projectId },
  });

  const hasInspectionsViewPermission = useMemo(
    () => isAllowed(PermissionNamesEnums.INSPECTIONS_VIEW, permissions),
    [permissions],
  );

  const hasInspectionsEditPermission = useMemo(
    () => isAllowed(PermissionNamesEnums.INSPECTIONS_EDIT, permissions),
    [permissions],
  );

  // permission projects_details_edit (mostly for lenders)
  const isAllProjectDetailsDisabled = useMemo(
    () =>
      isCurrentProjectArchived ||
      !isAllowed(PermissionNamesEnums.PROJECTS_DETAILS_EDIT, permissions),
    [permissions, isCurrentProjectArchived],
  );
  // permission projects_edit (mostly for borrowers)
  const isLimitedProjectDetailsDisabled = useMemo(
    () => isCurrentProjectArchived || !isAllowed(PermissionNamesEnums.PROJECTS_EDIT, permissions),
    [permissions, isCurrentProjectArchived],
  );

  return {
    isLoading: project.isLoading,
    project: project.data,
    isAllProjectDetailsDisabled,
    isLimitedProjectDetailsDisabled,
    hasInspectionsViewPermission,
    hasInspectionsEditPermission,
  };
};
