import React from 'react';
import { Grid2 } from '@mui/material';
import { colors } from '@theme';
import {
  PaymentGraphs,
  PaymentGraphsV2,
  ProjectPaymentRequestList,
  ProjectPaymentSummary,
  ProjectPaymentSummaryV2,
} from '../index';
import { useLaunchDarklyFlags } from '@context';

const ProjectPaymentInfo = () => {
  const flags = useLaunchDarklyFlags();
  return (
    <Grid2
      container
      alignItems="stretch"
      sx={{
        flexFlow: 'column nowrap',
        backgroundColor: colors.background.gray,
      }}
    >
      {flags?.['ENG_9697_funding_source_payment_tab'] ? (
        <>
          <ProjectPaymentSummaryV2 />
          <PaymentGraphsV2 />
        </>
      ) : (
        <>
          <ProjectPaymentSummary />
          <PaymentGraphs />
        </>
      )}
      <ProjectPaymentRequestList />
    </Grid2>
  );
};

export default ProjectPaymentInfo;
