import { ExtendGridStatePremium } from '@interfaces';
import { Stack } from '@mui/material';
import { GridColumnHeaderParams, useGridApiContext, GridColumnHeaderTitle } from '@mui/x-data-grid';
import React from 'react';
import { ColumnIcon } from '../ColumnIcon';

const HeaderComponent = (params: GridColumnHeaderParams) => {
  const api = useGridApiContext();
  const state = api.current.state as ExtendGridStatePremium;

  return (
    <Stack alignItems="center" direction="row">
      <GridColumnHeaderTitle label={params.colDef?.headerName || ''} columnWidth={0} />
      <ColumnIcon
        columns={state.additionalData?.columnErrors}
        name={params.field}
        source={'milestones'}
      />
    </Stack>
  );
};

export default HeaderComponent;
