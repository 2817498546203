import {
  GridCellModes,
  GridCellModesModel,
  GridCellParams,
  GridColDef,
  GridEventListener,
  GridRowsProp,
  GridState,
} from '@mui/x-data-grid-premium';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { GridApiPremium } from '@mui/x-data-grid-premium/models/gridApiPremium';
import { ExtendGridStatePremium, IMilestone } from '@interfaces';
import { indexColumn, rowOrderColumn } from '../components/systemColumns';

const useTableState = (
  apiRef: React.MutableRefObject<GridApiPremium>,
  columns: GridColDef[],
  rows: GridRowsProp,
  errors?: {
    rowErrors: any;
    columnErrors: any;
  },
  totals?: Partial<IMilestone>,
  rowReordering?: boolean,
  withColumnIndex?: boolean,
  withSingleClickHandle?: boolean,
) => {
  const [tableLoadingBlock, setTableLoading] = useState(false);
  const [cellModesModel, setCellModesModel] = useState<GridCellModesModel>({});

  const onCellClick: GridEventListener<'cellClick'> = useCallback(
    (params, event) => {
      const {
        id,
        field,
        isEditable,
        cellMode,
        colDef: { type },
      } = params;
      // Deprecate select index column
      event.defaultMuiPrevented = field === 'id';

      if (isEditable && type === 'singleSelect' && cellMode === 'view') {
        apiRef.current.startCellEditMode({ id, field });
      }

      if (!withSingleClickHandle || !params.isEditable) return;

      if (
        (event.target as any).nodeType === 1 &&
        !event.currentTarget.contains(event.target as Element)
      ) {
        return;
      }

      setCellModesModel((prevModel) => {
        return {
          // Revert the mode of the other cells from other rows
          ...Object.keys(prevModel).reduce(
            (acc, id) => ({
              ...acc,
              [id]: Object.keys(prevModel[id]).reduce(
                (acc2, field) => ({
                  ...acc2,
                  [field]: { mode: GridCellModes.View },
                }),
                {},
              ),
            }),
            {},
          ),
          [params.id]: {
            // Revert the mode of other cells in the same row
            ...Object.keys(prevModel[params.id] || {}).reduce(
              (acc, field) => ({ ...acc, [field]: { mode: GridCellModes.View } }),
              {},
            ),
            [params.field]: { mode: GridCellModes.Edit },
          },
        };
      });
    },
    [withSingleClickHandle],
  );

  const onCellModesModelChange = useCallback((newModel: GridCellModesModel) => {
    setCellModesModel(newModel);
  }, []);

  const memoColumns = useMemo<GridColDef[]>(() => {
    const columnsPrefix = [];
    if (withColumnIndex) columnsPrefix.push(indexColumn);
    if (rowReordering) columnsPrefix.push(rowOrderColumn);

    return [...columnsPrefix, ...columns];
  }, [withColumnIndex, rowReordering, columns]);

  const renderRows = useMemo(() => {
    return (totals ? [...rows, totals] : rows).map((item) => ({
      ...item,
      __reorder__: item.name,
    }));
  }, [rows, totals]);

  const isCellEditable = useCallback(
    (params: GridCellParams) => {
      if (params.row.id === 'totals') return false;
      const state = apiRef.current.state as ExtendGridStatePremium;
      const errorForCurrentCell = state.additionalData?.rowErrors?.[params.field]?.[params.row.id];
      const byMilestone = params.colDef['editableByMilestone']?.(params.row);
      return !!errorForCurrentCell || !!byMilestone;
    },
    [apiRef],
  );

  useEffect(() => {
    if (errors)
      apiRef.current.setState((state: GridState) => ({
        ...state,
        additionalData: errors,
      }));
    apiRef.current.forceUpdate();
  }, [errors]);

  return {
    tableLoadingBlock,
    setTableLoading,
    renderRows,
    memoColumns,
    isCellEditable,
    cellModesModel,
    onCellClick,
    onCellModesModelChange,
  };
};

export default useTableState;
