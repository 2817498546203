import { useState, useEffect, Dispatch, SetStateAction } from 'react';
import { Override } from '@utils';
import { ServiceOrderStatusEnum, ServiceTypeEnum, IServiceOrder } from '@interfaces';
import { useStringFieldModel, StringFieldModel } from '@models';
import { useServiceQueriesAndMutations } from './utils';

export type ControllerInterface = Override<
  ReturnType<typeof useService>,
  {
    serviceAgenciesList: { value: string; label: string }[];
    serviceAgenciesListIsLoading: boolean;
    agencySelected: string | null;
    setAgencySelected: (value: string) => void;
    serviceOrder: IServiceOrder;
    handleServiceOrderRequest: () => void;
    isSubmitting: boolean;
    isSubmitDisabled: boolean;
    isSuccessModalOpen: boolean;
    commentField: StringFieldModel;
    isOptionsPopupOpen: boolean;
    setOptionsPopupOpen: Dispatch<SetStateAction<boolean>>;
  }
>;

export const useService = ({
  projectId,
  drawRequestId,
  onClose,
  serviceType,
}: {
  projectId: string;
  onClose: () => void;
  drawRequestId?: string;
  serviceType: ServiceTypeEnum;
}) => {
  const commentField = useStringFieldModel({
    initValue: '',
  });

  const {
    serviceAgenciesList,
    serviceAgenciesListIsLoading,
    createServiceOrder,
    serviceOrder,
    patchServiceOrder,
    projectServicesQueryWithCreatedStatus,
    deleteServiceOrderMutation,
    isSuccessModalOpen,
    project,
  } = useServiceQueriesAndMutations({
    projectId,
    onClose,
    serviceType,
  });

  const [agencySelected, setAgencySelected] = useState(null);
  const [isOptionsPopupOpen, setOptionsPopupOpen] = useState(false);

  useEffect(() => {
    if (serviceAgenciesList?.length) setAgencySelected(serviceAgenciesList[0]?.value);
  }, [serviceAgenciesList]);

  useEffect(() => {
    const createServiceOrderFunction = async (json) => {
      await createServiceOrder.mutateAsync({
        projectId,
        json,
      });
    };

    // don't create service order if there're no list of agencies yet and it is not selected
    if (!agencySelected || serviceOrder?.id) return;

    if (projectServicesQueryWithCreatedStatus.isSuccess) {
      const createdServices = projectServicesQueryWithCreatedStatus.data?.results;

      if (createdServices?.length) {
        deleteAllCreatedServiceOrders(createdServices);
      } else {
        const json = {
          service_agency: agencySelected,
          service_type: serviceType,
          status: ServiceOrderStatusEnum.CREATED,
          ...(drawRequestId ? { draw_request: drawRequestId } : {}),
        };

        createServiceOrderFunction(json);
      }
    }
  }, [
    drawRequestId,
    agencySelected,
    serviceOrder?.id,
    projectServicesQueryWithCreatedStatus.isSuccess,
    serviceType,
  ]);

  const deleteAllCreatedServiceOrders = async (createdServices) => {
    const deletionPromises = createdServices.map((serviceOrder) =>
      deleteServiceOrderMutation.mutateAsync({
        projectId,
        serviceOrderId: serviceOrder.id,
      }),
    );

    // Wait for all deletions to complete
    await Promise.all(deletionPromises);

    // Create a new service order
    await createServiceOrder.mutateAsync({
      projectId,
      json: {
        service_agency: agencySelected,
        service_type: serviceType,
        status: ServiceOrderStatusEnum.CREATED,
        ...(drawRequestId ? { draw_request: drawRequestId } : {}),
      },
    });
  };

  const handleServiceOrderRequest = async () => {
    const json = {
      service_type: serviceType,
      service_agency: agencySelected,
      ...(commentField.value ? { comment: commentField.value } : {}),
    };

    await patchServiceOrder.mutateAsync({ projectId, serviceOrderId: serviceOrder.id, json });
  };

  return {
    serviceAgenciesList,
    serviceAgenciesListIsLoading,
    agencySelected,
    setAgencySelected,
    serviceOrder,
    handleServiceOrderRequest,
    isSubmitting: patchServiceOrder.isLoading,
    isSubmitDisabled:
      !agencySelected || patchServiceOrder.isLoading || createServiceOrder.isLoading,
    isSuccessModalOpen,
    commentField,
    project,
    isOptionsPopupOpen,
    setOptionsPopupOpen,
  };
};
