import { useContext, useMemo, useState } from 'react';
import {
  checkIsAdmin,
  getTeamRole,
  isActiveProject,
  isCreatedProject,
  isProjectPaused,
  isAllowed,
} from '@utils';
import { IRightMenu, PermissionNamesEnums, ProjectStatusEnum, QueryNamesEnums } from '@interfaces';
import { AuthContext, PermissionsContext, SettingsContext, useGraphQuery } from '@context';
import { ProjectStatusMap } from '@constants';
import { useParams } from 'react-router-dom';
import { useProjectActivationWarning, useRightMenu } from '@hooks';

export interface ControllerInterface {
  valuesBasedOnPermissions: {
    statusesList: { [key: string]: string };
    labelText: string;
  };
  changeStatusComponent: {
    open: boolean;
    handleOpen: (value: boolean) => void;
  };
  showAuditLog: boolean;
  rightMenu: IRightMenu;
  handleRightDrawerOpenerClick: ({ title }: { title: string }) => void;
  filters: string[];
  handleFiltersChange: (newFilters) => void;
  handleSortClick: () => void;
  sorting: boolean;
}

export const useProjectOptions = (): ControllerInterface => {
  const { projectId } = useParams();
  const [isChangeStatusModalOpened, setIsChangeStatusModalOpened] = useState(false);
  const handleChangeStatusModal = (value) => setIsChangeStatusModalOpened(value);
  const { isCurrentProjectActive, isCurrentProjectArchived } = useContext(SettingsContext);
  const { permissions } = useContext(PermissionsContext);
  const { user } = useContext(AuthContext);
  const teamRole = getTeamRole(user);
  const projectActivationWarning = useProjectActivationWarning({
    projectId,
  });

  const { handleRightDrawerOpenerClick, ...rightMenu } = useRightMenu({});

  const [sorting, setSorting] = useState<boolean>(true);
  const [filters, setFilters] = useState([]);

  const handleSortClick = () => setSorting((old) => !old);

  const handleFiltersChange = (newFilters) => setFilters(newFilters);

  const project = useGraphQuery({
    type: QueryNamesEnums.GET_PROJECT,
    keys: ['status'],
    args: { project_id: projectId },
  });

  const valuesBasedOnPermissions = useMemo(() => {
    const isEditStatusEnabled = isAllowed(PermissionNamesEnums.PROJECTS_EDIT_STATUS, permissions);

    let statusesList = {};

    if (isEditStatusEnabled) {
      if (
        isProjectPaused(project.data) ||
        (isCreatedProject(project.data?.status) && !projectActivationWarning) ||
        isCurrentProjectArchived
      ) {
        statusesList[ProjectStatusEnum.ACTIVE] = ProjectStatusMap[ProjectStatusEnum.ACTIVE];
      }

      if (
        isProjectPaused(project.data) ||
        isCreatedProject(project.data?.status) ||
        isCurrentProjectArchived
      ) {
        statusesList[ProjectStatusEnum.DISCARDED] = ProjectStatusMap[ProjectStatusEnum.DISCARDED];
      }

      statusesList[ProjectStatusEnum.INACTIVE_COMPLETE] =
        ProjectStatusMap[ProjectStatusEnum.INACTIVE_COMPLETE];
      statusesList[ProjectStatusEnum.INACTIVE_INCOMPLETE] =
        ProjectStatusMap[ProjectStatusEnum.INACTIVE_INCOMPLETE];

      if (isActiveProject(project.data?.status)) {
        statusesList[ProjectStatusEnum.PAUSED] = ProjectStatusMap[ProjectStatusEnum.PAUSED];
      }

      if (isActiveProject(project.data?.status) || isProjectPaused(project.data)) {
        statusesList[ProjectStatusEnum.CREATED] = ProjectStatusMap[ProjectStatusEnum.CREATED];
      }
    }

    if (checkIsAdmin(teamRole)) {
      statusesList = ProjectStatusMap;
    }

    return {
      statusesList,
      labelText: 'Update project status',
    };
  }, [
    permissions,
    teamRole,
    isCurrentProjectActive,
    project.data,
    projectActivationWarning,
    isCurrentProjectArchived,
  ]);

  const showAuditLog = useMemo(
    () => isAllowed(PermissionNamesEnums.AUDITLOG_VIEW, permissions),
    [permissions],
  );

  return {
    valuesBasedOnPermissions,
    changeStatusComponent: {
      open: isChangeStatusModalOpened,
      handleOpen: handleChangeStatusModal,
    },
    showAuditLog,
    rightMenu,
    filters,
    handleFiltersChange,
    handleSortClick,
    sorting,
    handleRightDrawerOpenerClick,
  };
};
