import React, { FC } from 'react';
import { Stack, Button, ButtonGroup, Tooltip, IconButton } from '@mui/material';
import {
  Filter,
  IconButtonWithTooltip,
  MilestoneListWithPatch,
  ToggleButtonGroup,
} from '@components';
import { TableKeyEnum } from '@interfaces';
import { CommentIconWithIndicator, UpdateIcon } from '@svgAsComponents';
import { AutofillAllowance } from '../../project/components';
import { RequestTableControllerInterface } from '../RequestTableV2/controller';
import { useParams } from 'react-router-dom';
import TypeFilter from '../TypeFilter';
import { useDayJsFormatter } from '@hooks';

const MilestoneListContainer: FC<{
  controller: RequestTableControllerInterface;
}> = ({ controller }) => {
  const { requestId } = useParams();
  const { dateFormatter } = useDayJsFormatter();

  const {
    initColumns,
    onExpandClick,
    filterOptions,
    filterValue,
    handleShowFilterClick,
    isLoading,
    showAutofillButton,
    drawRequest,
    handleAutofillLenderAllowance,
    isAutofillLoading,
    totals,
    groupByKeys,
    updateRightDrawer,
    onMilestoneUpdate,
    activeView,
    setActiveView,
    isLineItemsView,
    tableItems,
    patchMsGroup,
    viewTypes,
    typeFilterValues,
    setTypeFilterValues,
    setOpenLumpSumModal,
    isLumpSumEnabled,
    menuItems,
    canViewReport,
    openPdfReport,
    reportCallback,
  } = controller;

  return (
    <MilestoneListWithPatch
      withColumnIndication
      tableKey={TableKeyEnum.PHB_REQUEST_LINE_ITEMS}
      initColumns={initColumns}
      milestones={tableItems}
      key="listWithEditModels"
      exportToCSV
      onExpandTable={onExpandClick}
      requestId={requestId}
      totals={totals}
      headerLeftPart={[
        {
          Component: (
            <Stack sx={{ mr: 2 }}>
              <ToggleButtonGroup
                value={activeView}
                typesList={viewTypes}
                handleChange={(_e, value) => value && setActiveView(value)}
                size="small"
                source="requests_tab__line_items_table"
              />
            </Stack>
          ),
        },
        ...(isLineItemsView
          ? [
              {
                Component: (
                  <TypeFilter
                    handleTypeClick={setTypeFilterValues}
                    typesFilterValues={typeFilterValues}
                  />
                ),
              },
            ]
          : [
              {
                Component: (
                  <Filter
                    filterLabel="Show"
                    onChangeCallback={handleShowFilterClick}
                    options={filterOptions}
                    filterValue={filterValue}
                    source="requests_tab__line_items_table__filter"
                  />
                ),
              },
            ]),
      ]}
      headerRightPart={[
        ...(canViewReport
          ? [
              {
                Component: (
                  <ButtonGroup disableElevation sx={{ mr: 1 }}>
                    <Button
                      size="small"
                      variant="new"
                      color="secondary"
                      onClick={drawRequest?.report ? openPdfReport : reportCallback}
                      data-cy="requests_tab__generate_report__button"
                    >
                      {drawRequest?.report ? 'View report' : 'Generate report'}
                    </Button>
                    {drawRequest?.report && (
                      <Tooltip
                        title={`Generate new report. Last version: ${dateFormatter({
                          date: drawRequest?.report?.created_at,
                          withTime: true,
                        })}`}
                      >
                        <IconButton color="primary" onClick={reportCallback}>
                          <UpdateIcon />
                        </IconButton>
                      </Tooltip>
                    )}
                  </ButtonGroup>
                ),
              },
            ]
          : []),
        ...(showAutofillButton
          ? [
              {
                Component: (
                  <AutofillAllowance
                    drawRequest={drawRequest}
                    handleAutofillLender={handleAutofillLenderAllowance}
                    loading={isAutofillLoading}
                  />
                ),
              },
            ]
          : []),
        ...(isLumpSumEnabled
          ? [
              {
                Component: (
                  <Button
                    size="small"
                    variant="new"
                    color="secondary"
                    sx={{ ml: 1 }}
                    onClick={() => setOpenLumpSumModal(true)}
                  >
                    Enter lump sum
                  </Button>
                ),
              },
            ]
          : []),
        {
          Component: (
            <IconButtonWithTooltip
              tooltipText="Comments"
              onClick={updateRightDrawer()}
              data-cy="requests_tab__comments__icon"
            >
              <CommentIconWithIndicator
                hasComments={drawRequest?.comments_preview?.has_comments}
                hasUnreadComments={drawRequest?.comments_preview?.has_unread_comments}
              />
            </IconButtonWithTooltip>
          ),
        },
      ]}
      menuItems={menuItems}
      source="requests_tab__line_items_table"
      patchMilestone={patchMsGroup}
      isLoading={isLoading}
      groupByFilter={groupByKeys}
      requestStatus={drawRequest?.status}
      onCreditChangeCompleted={onMilestoneUpdate}
      lockSecondColumn
      withCredit
      withProgress
    />
  );
};

export default MilestoneListContainer;
