import { QueryClient, QueryClientConfig } from 'react-query';
import {
  normalizeGraph,
  mergeGraphs,
  getDeltaGraph,
  QueryGraphLike,
  getArgumentsForGetterByGraph,
  isEmptyGraph,
} from '@utils';
import merge from 'lodash/merge';
import { dataProviders } from './provider';

declare module 'react-query' {
  interface QueryClient {
    graphRegister(
      queryKey: unknown[],
      graphData: {
        keys?: string[];
        nested?: Record<string, string[]>;
      },
      options?: {
        automaticReady?: boolean;
      },
    ): void;
    getGraphState(queryKey: unknown[]): QueryGraphLike | undefined;
    getGraphBoundFunction(queryKey: unknown[]): () => Promise<unknown>;
    graphInited(queryKey: unknown[]): void;
    graphReady(queryKey: unknown[]): boolean;
    getGraphQueryKey(queryKey: unknown[]): unknown[];
  }
}

enum TimerType {
  Init = 'init',
  Fetch = 'fetch',
}

type TimerCallback = () => void;

export class CustomQueryClient extends QueryClient {
  private timers: Map<TimerType, Map<string, NodeJS.Timeout>> = new Map([
    [TimerType.Init, new Map()],
    [TimerType.Fetch, new Map()],
  ]);

  private readonly DEBOUNCE_TIMES: Record<TimerType, number> = {
    [TimerType.Init]: 50,
    [TimerType.Fetch]: 100,
  };

  constructor(config: QueryClientConfig) {
    super(config);

    this.getQueryCache().subscribe((event) => {
      //console.log('event', event.query.queryKey, event);
      if (event.type === 'queryUpdated') {
        const [type, ...rest] = event.query.queryKey;
        let needsFetch = false;
        let queryKey = rest;
        if (type === '_graphState') {
          const graphState = event.query.state.data as QueryGraphLike;
          if (graphState?.readyToInit) {
            const fetchQuery = this.getQueryState(queryKey);
            needsFetch =
              !isEmptyGraph(graphState?.requestedGraph) && fetchQuery.status === 'success';
          }
        } else if (event.action.type === 'success') {
          const graphStateFnName = this.getGraphQueryKey(event.query.queryKey);
          const graphState = this.getQueryData<QueryGraphLike>(graphStateFnName);
          if (graphState?.readyToInit && !isEmptyGraph(graphState?.requestedGraph)) {
            queryKey = event.query.queryKey;
            needsFetch = true;
          }
        }

        if (needsFetch) {
          const debounceKey = this.getDebounceKey(queryKey);
          this.debounce(TimerType.Fetch, debounceKey, () => {
            // console.log(queryKey);
            this.fetchMoreKeys(queryKey);
          });
        }
      }
    });
  }

  private getDebounceKey(queryKey: unknown[]): string {
    const [type, args] = queryKey;
    if (typeof args === 'object' && args !== null) {
      return `${type}:${JSON.stringify(args)}`;
    }
    return `${type}:${args}`;
  }

  public getGraphQueryKey(queryKey: unknown[]): unknown[] {
    return ['_graphState', ...queryKey];
  }

  private debounce(type: TimerType, key: string, callback: TimerCallback): void {
    const timerMap = this.timers.get(type);
    const existingTimer = timerMap.get(key);

    if (existingTimer) {
      clearTimeout(existingTimer);
    }

    const timer = setTimeout(() => {
      callback();
      timerMap.delete(key);
    }, this.DEBOUNCE_TIMES[type]);

    timerMap.set(key, timer);
  }

  private async fetchMoreKeys(queryKey: unknown[]) {
    const graphStateFnName = this.getGraphQueryKey(queryKey);
    const { requestedGraph } = this.getQueryData<QueryGraphLike>(graphStateFnName);
    const deltaStructure = JSON.parse(requestedGraph);

    if (!Object.keys(deltaStructure).length) return;
    // console.log('Fetching more keys', queryKey, deltaStructure);
    const boundFunction = this.getGraphBoundFunction(queryKey);
    const addedData = await this.fetchQuery([queryKey, requestedGraph], boundFunction);

    this.setQueryData(queryKey, (oldData: unknown) => {
      return merge(oldData, addedData);
    });

    this.removeQueries([queryKey, requestedGraph]);
  }

  getGraphBoundFunction(queryKey): () => Promise<unknown> {
    return () => {
      const [queryFn, args] = queryKey;

      const graphStateFnName = this.getGraphQueryKey(queryKey);
      const graphState = this.getQueryData<QueryGraphLike>(graphStateFnName);
      const getterGraph = !isEmptyGraph(graphState?.requestedGraph)
        ? graphState?.requestedGraph
        : !isEmptyGraph(graphState?.loadedGraph)
          ? graphState?.loadedGraph
          : normalizeGraph([], {});

      const graphArgs = getArgumentsForGetterByGraph(getterGraph, args);
      const fetcher = dataProviders[queryFn];
      this.setQueryData<QueryGraphLike>(graphStateFnName, (oldData) => ({
        ...oldData,
        loadedGraph: mergeGraphs(getterGraph, oldData?.loadedGraph || normalizeGraph([], {})),
        requestedGraph: normalizeGraph([], {}),
      }));

      if (!fetcher) {
        // console.warn('No query function found for', queryKey);
        return Promise.resolve(null);
      }
      // console.log('Fetching', queryKey, graphArgs);
      return fetcher(graphArgs) as Promise<unknown>;
    };
  }

  public graphInited(queryKey: unknown[]): void {
    const graphStateFnName = this.getGraphQueryKey(queryKey);
    this.setQueryData<QueryGraphLike>(graphStateFnName, (oldData) => ({
      ...oldData,
      readyToInit: true,
    }));
  }

  public graphReady(queryKey: unknown[]): boolean {
    const graphState = this.getGraphState(queryKey);
    return !!graphState?.readyToInit;
  }

  public graphRegister(
    queryKey: unknown[],
    graphData: {
      keys?: string[];
      nested?: Record<string, string[]>;
    },
    options: {
      automaticReady?: boolean;
    } = {},
  ): void {
    const debounceKey = this.getDebounceKey(queryKey);
    const graphStateFnName = this.getGraphQueryKey(queryKey);
    const newGraph = normalizeGraph(graphData.keys ?? [], graphData.nested ?? {});

    this.setQueryData<QueryGraphLike>(graphStateFnName, (oldData) => {
      const requestedGraph = getDeltaGraph(
        oldData?.requestedGraph ? mergeGraphs(oldData.requestedGraph, newGraph) : newGraph,
        oldData?.loadedGraph || normalizeGraph([], {}),
      );
      return {
        ...oldData,
        requestedGraph,
      };
    });

    if (options.automaticReady !== false) {
      this.debounce(TimerType.Init, debounceKey, () => {
        this.graphInited(queryKey);
      });
    }
  }

  getGraphState(queryKey: unknown[]): QueryGraphLike | undefined {
    const graphStateFnName = this.getGraphQueryKey(queryKey);
    return this.getQueryData<QueryGraphLike>(graphStateFnName);
  }

  destroy() {
    this.timers.forEach((timerMap) => {
      timerMap.forEach((timer) => clearTimeout(timer));
      timerMap.clear();
    });
  }
}
