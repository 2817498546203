import React, { FC } from 'react';
import { Stack } from '@mui/material';
import { DateTimePickerWithTimezone, CustomTextField, Popup } from '@components';
import { PaymentInfoIcon } from '@svgAsComponents';
import { useMarkAsPaid } from './controller';
import LoadingButton from '@mui/lab/LoadingButton';

interface ComponentProps {
  onClose: () => void;
  drawRequestId: string;
}
const MarkAsPaidConfirmationModal: FC<ComponentProps> = ({ onClose, drawRequestId }) => {
  const { handleMarkAsPaid, transactionId, transactionDate, isSubmitting } = useMarkAsPaid({
    onClose,
    drawRequestId,
  });

  return (
    <Popup open maxWidth="sm" title="Mark as disbursed" icon={PaymentInfoIcon}>
      <Stack sx={{ width: '100%' }}>
        <Stack spacing={2.5}>
          <CustomTextField field={transactionId} label="Transaction ID" />
          <DateTimePickerWithTimezone
            label="Transaction date"
            field={transactionDate}
            inputProps={{
              'data-cy': 'request__mark_as_disbursed_popup__transaction_date__input',
            }}
            maxDate={new Date()}
          />
        </Stack>
        <Stack direction="row" justifyContent="flex-end" alignItems="center" sx={{ mt: 7.5 }}>
          <LoadingButton
            variant="text"
            onClick={onClose}
            loading={isSubmitting}
            data-cy="request__mark_as_disbursed_popup__cancel__button"
          >
            Cancel
          </LoadingButton>
          <LoadingButton
            onClick={handleMarkAsPaid}
            loading={isSubmitting}
            data-cy="request__mark_as_disbursed_popup__confirm__button"
            sx={{ ml: 1 }}
          >
            Mark
          </LoadingButton>
        </Stack>
      </Stack>
    </Popup>
  );
};

export default MarkAsPaidConfirmationModal;
