import { FilterObject } from '../interface';
import { PermissionNamesEnums, QueryNamesEnums } from '@interfaces';

export const servicesTypeFilter: FilterObject = {
  title: 'Service type',
  filterKey: 'service_type',
  permissionKey: PermissionNamesEnums.INSPECTIONS_VIEW,
  needToUpdateUrl: true,
  needToUpdateSetting: true,
  getDataParamsPaginated: (pagination, q, skip) => ({
    type: QueryNamesEnums.GET_UI_SETTINGS_SERVICES_TYPE,
    keys: ['name_display', 'name'],
    args: {
      query: '',
      pagination,
      q,
    },
    options: {
      skip,
      paginate: true,
    },
  }),
  strictSerialize: (data: any) =>
    data?.map((item) => ({
      value: item.name,
      label: item.name_display,
    })),
  cypressSelector: 'filters__services_list_type',
  withPagination: true,
};
