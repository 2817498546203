import { useQueries } from 'react-query';
import { getHookState, Override } from '@utils';
import { IDrawRequest, QueryNamesEnums } from '@interfaces';
import { useParams } from 'react-router-dom';
import { useGraphQuery } from '@context';

import { getDrawRequest } from '@globalService';

export type ControllerInterface = Override<
  ReturnType<typeof useDisbursementStatement>,
  {
    drawRequestData?: IDrawRequest;
  }
>;

export const useDisbursementStatement = (drawRequestId: string) => {
  const { projectId } = useParams();

  const requestedDataQueries = useQueries([
    {
      queryKey: [QueryNamesEnums.GET_DRAW_REQUEST, { projectId, drawRequestId }],
      queryFn: getDrawRequest.bind(this, { projectId, drawRequestId }),
    },
  ]);

  const project = useGraphQuery({
    type: QueryNamesEnums.GET_PROJECT,
    keys: ['name', 'address', 'loan', 'id'],
    args: { project_id: projectId },
  });

  return {
    state: getHookState([...requestedDataQueries, project]),
    drawRequestData: requestedDataQueries[0].data,
    project: project.data,
  };
};
