import React, { FC } from 'react';
import { Button, Stack } from '@mui/material';
import {
  CustomCurrencyTextField,
  CustomPercentTextField,
  LabelAndValue,
  PieChartItem,
  Popup,
} from '@components';
import * as Controller from './controller';
import { ComponentProps } from './interface';
import { calculateAmount, calculateFraction, currencyFormatter } from '@utils';

const RequestPaymentConfigurationEditPopupV2: FC<ComponentProps> = ({ onClose, drawRequestId }) => {
  const controller = Controller.useEditRequestPaymentConfigurationV2({
    drawRequestId,
    onClose,
  });
  const {
    constructionHoldback,
    constructionHoldbackFraction,
    borrowerEquity,
    borrowerEquityFraction,
    handleSubmit,
    disableSaveButton,
    originalValuePieChartData,
    totalAmount,
  } = controller;
  const source = 'request__payment_configuration';

  return (
    <Popup open maxWidth="md" title="Edit payment contribution for this draw">
      <Stack sx={{ width: '100%', mt: 3 }}>
        <Stack spacing={3}>
          <PieChartItem data={originalValuePieChartData} />
          <Stack direction="row" spacing={2}>
            <CustomCurrencyTextField
              label="Construction holdback ($)"
              field={constructionHoldback}
              onChange={(e) => {
                constructionHoldback.setValue(e.target.value);
                borrowerEquity.setValue((totalAmount - +e.target.value)?.toString());
                const contributionFraction = calculateFraction(+e.target.value, totalAmount);
                constructionHoldbackFraction.setValue(contributionFraction?.toString());
                borrowerEquityFraction.setValue(
                  calculateFraction(totalAmount - +e.target.value, totalAmount)?.toString(),
                );
              }}
              inputProps={{ 'data-cy': `${source}__construction_holdback__input` }}
              validationText={constructionHoldback.errorTip}
            />
            <CustomPercentTextField
              label="Construction holdback (%)"
              field={constructionHoldbackFraction}
              onChange={(e) => {
                constructionHoldbackFraction.setValue(e.target.value);
                borrowerEquityFraction.setValue((100 - +e.target.value)?.toString());
                const constructionAmount = calculateAmount(e.target.value, totalAmount);
                constructionHoldback.setValue(constructionAmount?.toString());
                borrowerEquity.setValue(
                  calculateAmount((100 - +e.target.value)?.toString(), totalAmount)?.toString(),
                );
              }}
              inputProps={{ 'data-cy': `${source}__construction_holdback_fraction__input` }}
              validationText={constructionHoldbackFraction.errorTip}
            />
          </Stack>
          <Stack direction="row" spacing={2}>
            <Stack sx={{ width: '100%' }}>
              <CustomCurrencyTextField
                label="Borrower equity ($)"
                field={borrowerEquity}
                onChange={(e) => {
                  borrowerEquity.setValue(e.target.value);
                  constructionHoldback.setValue((totalAmount - +e.target.value)?.toString());
                  const borrowerFraction = calculateFraction(+e.target.value, totalAmount);
                  borrowerEquityFraction.setValue(borrowerFraction?.toString());
                  constructionHoldbackFraction.setValue(
                    calculateFraction(totalAmount - +e.target.value, totalAmount)?.toString(),
                  );
                }}
                inputProps={{ 'data-cy': `${source}__borrower_equity__input` }}
                validationText={borrowerEquity.errorTip}
              />
            </Stack>
            <CustomPercentTextField
              field={borrowerEquityFraction}
              label="Borrower equity (%)"
              onChange={(e) => {
                borrowerEquityFraction.setValue(e.target.value);
                constructionHoldbackFraction.setValue((100 - +e.target.value)?.toString());
                const borrowerAmount = calculateAmount(e.target.value, totalAmount);
                borrowerEquity.setValue(borrowerAmount?.toString());
                constructionHoldback.setValue(
                  calculateAmount((100 - +e.target.value)?.toString(), totalAmount)?.toString(),
                );
              }}
              inputProps={{ 'data-cy': `${source}__borrower_equity_fraction__input` }}
              validationText={borrowerEquityFraction.errorTip}
            />
          </Stack>
          <LabelAndValue
            label="Total approved amount:"
            text={currencyFormatter(totalAmount)}
            justifyContent="flex-start"
          />
        </Stack>
        <Stack direction="row" justifyContent="flex-end" alignItems="center" sx={{ mt: 7.5 }}>
          <Button
            variant="text"
            onClick={onClose}
            data-cy="request__payment_configuration__cancel__button"
          >
            Cancel
          </Button>
          <Button
            onClick={handleSubmit}
            sx={{ ml: '0.5rem' }}
            data-cy="request__payment_configuration__save__button"
            disabled={disableSaveButton}
          >
            Save
          </Button>
        </Stack>
      </Stack>
    </Popup>
  );
};

export default RequestPaymentConfigurationEditPopupV2;
