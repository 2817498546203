import { TEAM_ROLES } from '@constants';
import { QueryNamesEnums } from '@interfaces';
import { FilterObject } from '../interface';
import { checkIsOwner, getTeamRole } from '@utils';

export const borrowerFilter: FilterObject = {
  title: 'Borrower',
  filterKey: 'borrower',
  userPermission: (user) => !checkIsOwner(getTeamRole(user)),
  getDataParamsPaginated: (pagination, q, skip) => ({
    type: QueryNamesEnums.GET_COMPANIES_BY_ROLE,
    keys: ['id', 'name'],
    args: {
      role: TEAM_ROLES.Owner,
      pagination,
      q,
    },
    options: {
      skip,
      paginate: true,
    },
  }),
  strictSerialize: (data) =>
    data.map((item) => ({
      value: item.id,
      label: item.name,
    })),
  cypressSelector: 'filters__borrower',
  needToUpdateUrl: true,
  needToUpdateSetting: true,
  withPagination: true,
};
