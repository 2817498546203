import React from 'react';
import { Box, Skeleton, Stack } from '@mui/material';
import { HookState } from '@interfaces';
import {
  LabelAndValue,
  LoadingSkeleton,
  ServiceMessage,
  StyledBox,
  IconButtonWithTooltip,
} from '@components';
import * as Controller from './controller';
import { currencyFormatter } from '@utils';
import { EditIcon } from '@svgAsComponents';

const ProjectPaymentSummaryV2 = () => {
  const {
    state,
    feesAmount,
    paymentModelText,
    navigateToPaymentSettings,
    canEditFees,
    canEditProjectModel,
  } = Controller.useProjectPaymentSummary();

  switch (state) {
    case HookState.FETCHING: {
      return (
        <>
          <StyledBox>
            <Skeleton />
          </StyledBox>
          <Box mb={2} />
          <LoadingSkeleton type="twoBlocks" />
        </>
      );
    }
    case HookState.ERROR: {
      return <ServiceMessage text="project payment summary" />;
    }

    case HookState.SUCCESS: {
      return (
        <Stack direction={{ lg: 'row', xs: 'column' }} spacing={2}>
          <StyledBox sx={{ width: { lg: '50%', xs: '100%' } }}>
            <Stack flex={1} spacing={1}>
              <LabelAndValue
                label="Payment model"
                text={paymentModelText}
                icon={
                  canEditProjectModel ? (
                    <IconButtonWithTooltip
                      onClick={() => navigateToPaymentSettings('loan')}
                      sx={{ py: 0 }}
                      tooltipText="Edit"
                      data-cy="payments_tab__project__payment_model__edit__icon"
                    >
                      <EditIcon size={24} />
                    </IconButtonWithTooltip>
                  ) : null
                }
                iconPosition="right"
              />
            </Stack>
          </StyledBox>
          <StyledBox sx={{ width: { lg: '50%', xs: '100%' } }}>
            <Stack flex={1} spacing={1}>
              <LabelAndValue
                label="All fees"
                text={currencyFormatter(feesAmount, '-')}
                icon={
                  canEditFees ? (
                    <IconButtonWithTooltip
                      sx={{ py: 0 }}
                      onClick={() => navigateToPaymentSettings('payments')}
                      data-cy="payments_tab__project__fees__edit__icon"
                      tooltipText="Edit"
                    >
                      <EditIcon size={24} />
                    </IconButtonWithTooltip>
                  ) : null
                }
                iconPosition="right"
              />
            </Stack>
          </StyledBox>
        </Stack>
      );
    }
    default:
      return null;
  }
};

export default ProjectPaymentSummaryV2;
