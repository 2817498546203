import React, { FC, useContext } from 'react';
import { Box, IconButton, Stack, Theme, Typography, useMediaQuery } from '@mui/material';
import { useParams } from 'react-router-dom';

import {
  calculateFraction,
  currencyFormatter,
  getStatusChip,
  getTextOrDash,
  getValueColor,
  isAllowed,
  percentFormatter,
  WithPermission,
} from '@utils';
import { DateValidationEnum, HookState, PermissionNamesEnums, ValueTypeEnum } from '@interfaces';
import {
  IconButtonWithTooltip,
  InspectionLabelAndValue,
  LabelAndValue,
  LabelAndValueWithPreload,
  LoadingSkeleton,
  ProgressWithArrow,
  ServiceMessage,
  StyledBox,
  TeamsAndApprovals,
} from '@components';
import { EditIcon, WarningIcon } from '@svgAsComponents';
import { colors } from '@theme';
import * as Controller from './controller';
import { ChangeDataPopup, RequestCoordinator } from '../index';

import {
  ApprovedToDate,
  BalanceToFinish,
  CompletionWithPrefunding,
  RemainingReallocationBalanceWithProgress,
  RetainageBalanceToDate,
  UserAndCompanyLabel,
} from './subcomponents';
import { PermissionsContext, useLaunchDarklyFlags } from '@context';
import { useDayJsFormatter } from '@hooks';
import { SummaryEditableDataEnum } from '../interface';

const RequestSummary = () => {
  const { projectId, requestId: drawRequestId } = useParams();
  const controller = Controller.useRequestSummary(projectId, drawRequestId);

  switch (controller.state) {
    case HookState.FETCHING: {
      return <LoadingSkeleton type="twoBlocks" />;
    }
    case HookState.ERROR: {
      return <ServiceMessage text="Draws" />;
    }

    case HookState.SUCCESS: {
      return <RequestSummaryBody controller={controller} />;
    }

    default:
      return null;
  }
};

const RequestSummaryBody: FC<{
  controller: Controller.ControllerInterface;
}> = ({ controller }) => {
  const flags = useLaunchDarklyFlags();
  const { projectId } = useParams();
  const xlMediaQuery = useMediaQuery((theme: Theme) => theme.breakpoints.up('xl'));
  const lgMediaQuery = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'));
  const { permissions } = useContext(PermissionsContext);

  const {
    drawRequest,
    isCompleted,
    isInReview,
    isApproved,
    isDraft,
    isUpdating,
    isHistoricalRequestEditable,
    isHistorical,
    updateRequestData,
    lastInspection,
    isDRFetching,
    isDrawRequest,
    retainageRate,
    projectLoanData,
    budgetChangeError,
    isApprovedOrCompleted,
    changedData,
    setChangedData,
    expanded,
    getExpandButton,
    teamsList,
    isInspectionViewProhibited,
    isInspectionAllowanceEditable,
    isReallocationAllowed,
    isDisbursementDateEditable,
    outOfBudgetError,
  } = controller;

  const {
    approved_budget_change,
    requested_budget_change,
    requested_amount,
    requested_amount_relative,
    approved_amount,
    approved_amount_relative,
    revised_estimate,
    previous_lender_allowance_rate,
    approved_amount_cumulative,
    lender_allowance_rate,
    requested_revised_estimate,
    variance_to_lender_allowance_rate,
  } = drawRequest?.totals?.all || {};

  const { dateFormatter, getDateValidationRule } = useDayJsFormatter();

  return (
    <>
      {!lgMediaQuery && (
        <StyledBox
          sx={{ p: 2, mb: 1, flexDirection: 'row', display: 'flex' }}
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography variant="h4">Summary</Typography>
          <Box data-tour="expand_summary_button">{getExpandButton()}</Box>
        </StyledBox>
      )}
      {expanded ? (
        <Stack
          direction={{ xs: 'column', md: 'row' }}
          flexWrap={{ md: 'wrap', lg: 'nowrap' }}
          sx={{ gap: 1 }}
        >
          <StyledBox sx={{ flexGrow: 1, maxWidth: { xs: '100%', md: '50%', lg: '22%' } }}>
            <Stack flex={1} spacing={1}>
              {isHistoricalRequestEditable && (
                <WithPermission permissionKey={PermissionNamesEnums.DRAWREQUESTS_MANUAL_CREATE}>
                  <LabelAndValueWithPreload
                    label="Draw #"
                    isLoading={isDRFetching}
                    text={drawRequest.counter_per_request_type.toString() || '-'}
                    icon={
                      <IconButton
                        onClick={() =>
                          setChangedData({
                            title: 'Edit draw #',
                            type: SummaryEditableDataEnum.NUMBER,
                            data: drawRequest.counter_per_request_type,
                          })
                        }
                        sx={{ p: 0, pl: 1 }}
                        data-cy="draw_number_edit"
                      >
                        <EditIcon size={20} />
                      </IconButton>
                    }
                    textDataTestName="draw_number"
                  />
                </WithPermission>
              )}
              <LabelAndValueWithPreload
                label="Submitted date"
                isLoading={isDRFetching}
                text={dateFormatter({ date: drawRequest.submitted_at, withTime: true }) || '-'}
                icon={
                  isHistoricalRequestEditable && (
                    <WithPermission permissionKey={PermissionNamesEnums.DRAWREQUESTS_MANUAL_CREATE}>
                      <IconButton
                        onClick={() =>
                          setChangedData({
                            title: 'Date submitted',
                            type: SummaryEditableDataEnum.SUBMITTED,
                            data: drawRequest.submitted_at,
                            validationRule: (value) =>
                              getDateValidationRule({
                                value,
                                rule: DateValidationEnum.LESS_OR_EQUAL,
                                maxDate: new Date(),
                              }),
                            maxDate: new Date(),
                          })
                        }
                        sx={{ p: 0, pl: 1 }}
                        data-cy="draw_submitted_date_edit"
                      >
                        <EditIcon size={20} />
                      </IconButton>
                    </WithPermission>
                  )
                }
                textDataTestName="draw_submitted_date"
              />
              {isDrawRequest && (
                <WithPermission permissionKey={PermissionNamesEnums.INSPECTIONS_VIEW}>
                  <InspectionLabelAndValue inspection={lastInspection} />
                </WithPermission>
              )}
              <LabelAndValueWithPreload
                label="Approved date"
                isLoading={isDRFetching}
                text={dateFormatter({ date: drawRequest.approved_at, withTime: true }) || '-'}
                icon={
                  isHistoricalRequestEditable && (
                    <WithPermission permissionKey={PermissionNamesEnums.DRAWREQUESTS_MANUAL_CREATE}>
                      <IconButton
                        onClick={() =>
                          setChangedData({
                            title: 'Date approved',
                            type: SummaryEditableDataEnum.APPROVED,
                            data: drawRequest.approved_at,
                            validationRule: (value) =>
                              getDateValidationRule({
                                value,
                                rule: DateValidationEnum.LESS_OR_EQUAL,
                                maxDate: new Date(),
                              }),
                            maxDate: new Date(),
                          })
                        }
                        sx={{ p: 0, pl: 1 }}
                        data-cy="draw_approved_date_edit"
                      >
                        <EditIcon size={20} />
                      </IconButton>
                    </WithPermission>
                  )
                }
                textDataTestName="draw_approved_date"
              />
              {isDrawRequest && (
                <LabelAndValueWithPreload
                  label={
                    isHistorical || drawRequest.disbursed_at
                      ? 'Disbursement date'
                      : 'Estimated disbursement date'
                  }
                  isLoading={isDRFetching}
                  text={
                    dateFormatter({
                      date: drawRequest.disbursed_at || drawRequest.estimated_disbursement_date,
                    }) || '-'
                  }
                  icon={
                    isDisbursementDateEditable && (
                      <WithPermission permissionKey={PermissionNamesEnums.PROJECTS_DETAILS_EDIT}>
                        <IconButton
                          onClick={() =>
                            setChangedData({
                              title: isHistorical
                                ? 'Disbursement date'
                                : 'Estimated disbursement date',
                              type: isHistorical
                                ? SummaryEditableDataEnum.DISBURSED_AT
                                : SummaryEditableDataEnum.ESTIMATED_DISBURSEMENT_DATE,
                              data: isHistorical
                                ? drawRequest.disbursed_at
                                : drawRequest.estimated_disbursement_date,
                              validationRule: (value) =>
                                getDateValidationRule({
                                  value,
                                  rule: DateValidationEnum.MORE_OR_EQUAL,
                                  minDate: !isHistorical ? new Date() : undefined,
                                }),
                              minDate: !isHistorical ? new Date() : undefined,
                            })
                          }
                          sx={{ p: 0, pl: 1 }}
                          data-cy="requests_tab__summary__estimated_disbursement_date__edit__icon"
                        >
                          <EditIcon size={20} />
                        </IconButton>
                      </WithPermission>
                    )
                  }
                  textDataTestName="requests_tab__summary__estimated_disbursement_date__value"
                />
              )}
              <LabelAndValue label="Draw status">{getStatusChip(drawRequest)}</LabelAndValue>
              <UserAndCompanyLabel
                actionUser={isDraft ? drawRequest.created_by : drawRequest.submitted_by}
                isUpdating={isDRFetching}
                label={isDraft ? 'Created by' : 'Submitted by'}
              />
              <TeamsAndApprovals
                request={drawRequest}
                projectId={projectId}
                teamsList={teamsList}
              />
            </Stack>
          </StyledBox>

          <StyledBox sx={{ flexGrow: 1, maxWidth: { xs: '100%', md: '50%', lg: 'auto' } }}>
            <Stack flex={1} direction="column" spacing={1}>
              <LabelAndValue
                label={xlMediaQuery ? 'This request only' : ''}
                text="Requested  ➝ Approved"
                color={colors.text.medium}
                labelStyle={{ fontWeight: 600 }}
                justifyContent={lgMediaQuery && !xlMediaQuery ? 'flex-start' : 'space-between'}
                marginRightAfterLabel={!lgMediaQuery}
              />

              {isDrawRequest && (
                <LabelAndValueWithPreload
                  isLoading={isUpdating}
                  label="Draw amount"
                  valueIcon={outOfBudgetError ? <WarningIcon size={14} /> : null}
                  valueIconTooltip={outOfBudgetError}
                  valueIconTooltipDataTestName="draw_amount__tooltip_text"
                >
                  <ProgressWithArrow
                    prevValue={getTextOrDash(requested_amount, requested_amount_relative)}
                    nextValue={getTextOrDash(
                      approved_amount,
                      drawRequest?.lender_allowance_rate_incremental,
                    )}
                    showProgress
                    getTextStyleForActive={isInReview}
                    prevValueDataTestName="draw_amount_previous"
                    nextValueDataTestName="draw_amount"
                    nextValueType={
                      isInReview
                        ? outOfBudgetError
                          ? { [ValueTypeEnum.IS_ERROR]: true }
                          : { [ValueTypeEnum.IS_IN_REVIEW]: true }
                        : null
                    }
                  />
                </LabelAndValueWithPreload>
              )}

              {isReallocationAllowed && (
                <LabelAndValueWithPreload
                  isLoading={isUpdating}
                  label="Reallocations"
                  valueIcon={budgetChangeError ? <WarningIcon size={14} /> : null}
                  valueIconTooltip={budgetChangeError}
                  valueIconDataTestName="requests_tab__summary__reallocation__error_icon"
                >
                  <ProgressWithArrow
                    prevValue={getTextOrDash(
                      drawRequest.requested_reallocation,
                      drawRequest.requested_reallocation_rate,
                    )}
                    nextValue={getTextOrDash(
                      drawRequest.approved_reallocation,
                      drawRequest.approved_reallocation_rate,
                    )}
                    showProgress
                    nextValueType={
                      isInReview
                        ? budgetChangeError
                          ? { [ValueTypeEnum.IS_ERROR]: true }
                          : { [ValueTypeEnum.IS_IN_REVIEW]: true }
                        : null
                    }
                    prevValueDataTestName="draw_reallocation_previous"
                    nextValueDataTestName="draw_reallocation"
                  />
                </LabelAndValueWithPreload>
              )}
              {Boolean(retainageRate) && (
                <LabelAndValueWithPreload isLoading={isUpdating} label={`Retainage released`}>
                  <ProgressWithArrow
                    prevValue={currencyFormatter(
                      drawRequest.totals.all.retainage_release_requested || 0,
                    )}
                    nextValue={currencyFormatter(
                      drawRequest.totals.all.retainage_release_approved || 0,
                    )}
                    getTextStyleForActive={isInReview}
                    prevValueDataTestName="draw__retainage_release_requested"
                    nextValueDataTestName="draw__retainage_release_approved"
                  />
                </LabelAndValueWithPreload>
              )}
              {isDrawRequest && (
                <WithPermission permissionKey={PermissionNamesEnums.INSPECTIONS_VIEW}>
                  <LabelAndValue
                    label="Inspection recommendation"
                    text={getTextOrDash(
                      drawRequest.totals.all.inspector_allowance_incremental,
                      drawRequest.totals.all.inspector_allowance_rate_incremental,
                    )}
                  />
                </WithPermission>
              )}

              <RequestCoordinator
                request={drawRequest}
                isCompleted={isCompleted}
                source="requests_tab__summary"
              />
            </Stack>
          </StyledBox>

          <StyledBox sx={{ flexGrow: 1, maxWidth: { xs: '100%', md: '50%', lg: 'auto' } }}>
            <Stack direction="row">
              <Stack flex={1} spacing={1}>
                <LabelAndValue
                  label={xlMediaQuery ? 'Sources' : ''}
                  text="Pre ➝ Post"
                  color={colors.text.medium}
                  justifyContent={lgMediaQuery && !xlMediaQuery ? 'flex-start' : 'space-between'}
                  marginRightAfterLabel={!lgMediaQuery}
                  labelStyle={{ fontWeight: 600 }}
                />
                <LabelAndValueWithPreload
                  isLoading={isUpdating}
                  label="Revised scheduled values"
                  valueIcon={budgetChangeError ? <WarningIcon size={14} /> : null}
                  valueIconTooltip={budgetChangeError}
                  valueIconDataTestName="requests_tab__summary__revised_estimate__error_icon"
                >
                  <ProgressWithArrow
                    prevValue={currencyFormatter(requested_revised_estimate, '-')}
                    nextValue={currencyFormatter(revised_estimate, '-')}
                    showProgress
                    prevValueType={
                      requested_budget_change ? { [ValueTypeEnum.IS_ERROR]: true } : null
                    }
                    nextValueType={
                      isInReview
                        ? approved_budget_change
                          ? { [ValueTypeEnum.IS_ERROR]: true }
                          : { [ValueTypeEnum.IS_IN_REVIEW]: true }
                        : null
                    }
                    prevValueDataTestName="requests_tab__summary__revised_estimate__value"
                    nextValueDataTestName="requests_tab__summary__revised_estimate__nextValue"
                  />
                </LabelAndValueWithPreload>
                {isDrawRequest && (
                  <ApprovedToDate
                    isUpdating={isUpdating}
                    totals={drawRequest?.totals?.all || {}}
                    isInReview={isInReview}
                    outOfBudgetError={!!outOfBudgetError}
                  />
                )}

                {isDrawRequest && (
                  <BalanceToFinish
                    isUpdating={isUpdating}
                    totals={drawRequest?.totals?.all || {}}
                    isInReview={isInReview}
                    outOfBudgetError={!!outOfBudgetError}
                  />
                )}

                {flags?.['ENG_9927_completion_with_prefunding'] && isDrawRequest && (
                  <CompletionWithPrefunding
                    isUpdating={isUpdating}
                    isInReview={isInReview}
                    totals={drawRequest?.totals?.all || {}}
                  />
                )}

                {Boolean(retainageRate) && (
                  <RetainageBalanceToDate
                    retainageRate={retainageRate}
                    isUpdating={isUpdating}
                    totals={drawRequest?.totals?.all || {}}
                    isInReview={isInReview}
                  />
                )}
                <LabelAndValueWithPreload
                  isLoading={isUpdating}
                  label="Original borrower equity"
                  text={getTextOrDash(
                    projectLoanData?.original_borrower_equity || 0,
                    calculateFraction(
                      projectLoanData?.original_borrower_equity || 0,
                      projectLoanData?.total_construction_budget,
                    ),
                  )}
                  textDataTestName="requests_tab__summary__borrower_equity__value"
                />
                <LabelAndValueWithPreload
                  isLoading={isUpdating}
                  label="Construction holdback"
                  text={getTextOrDash(
                    projectLoanData?.construction_holdback || 0,
                    calculateFraction(
                      projectLoanData?.construction_holdback,
                      projectLoanData?.total_construction_budget,
                    ),
                  )}
                  textDataTestName="requests_tab__summary__construction_holdback__value"
                />
              </Stack>
              {isInspectionViewProhibited && <Box sx={{ mt: 1 }}>{getExpandButton()}</Box>}
            </Stack>
          </StyledBox>

          {((isReallocationAllowed && isInReview) ||
            !isDrawRequest ||
            isAllowed(PermissionNamesEnums.INSPECTIONS_VIEW, permissions)) && (
            <Stack
              spacing={1}
              sx={{ flexGrow: 1, maxWidth: { xs: '100%', md: '50%', lg: 'auto' } }}
            >
              {isDrawRequest && (
                <WithPermission permissionKey={PermissionNamesEnums.INSPECTIONS_VIEW}>
                  <StyledBox sx={{ flexGrow: 1 }}>
                    <Stack direction="row">
                      <Stack flex={1} spacing={1}>
                        <LabelAndValue
                          label={xlMediaQuery ? 'Project totals' : ''}
                          text="Pre ➝ Post"
                          color={colors.text.medium}
                          labelStyle={{ fontWeight: 600 }}
                          justifyContent={
                            lgMediaQuery && !xlMediaQuery ? 'flex-start' : 'space-between'
                          }
                          marginRightAfterLabel={!lgMediaQuery}
                        />
                        <LabelAndValueWithPreload
                          label="Lender allowance"
                          isLoading={isUpdating}
                          valueIcon={outOfBudgetError ? <WarningIcon size={14} /> : null}
                        >
                          <ProgressWithArrow
                            prevValue={percentFormatter({
                              value: previous_lender_allowance_rate,
                              roundTo: 0,
                              returnValue: '0%',
                            })}
                            nextValue={percentFormatter({
                              value: lender_allowance_rate,
                              roundTo: 0,
                              returnValue: '0%',
                            })}
                            getTextStyleForActive={isInReview}
                            prevValueDataTestName="requests_tab__summary__lender_allowance__value"
                            nextValueDataTestName="requests_tab__summary__lender_allowance__next_value"
                            nextValueType={
                              isInReview
                                ? outOfBudgetError
                                  ? { [ValueTypeEnum.IS_ERROR]: true }
                                  : { [ValueTypeEnum.IS_IN_REVIEW]: true }
                                : null
                            }
                          />
                        </LabelAndValueWithPreload>
                        <LabelAndValue
                          label="Inspection allowance"
                          icon={
                            isHistoricalRequestEditable && (
                              <WithPermission
                                permissionKey={PermissionNamesEnums.DRAWREQUESTS_MANUAL_CREATE}
                              >
                                <IconButtonWithTooltip
                                  onClick={() =>
                                    setChangedData({
                                      title: 'Inspection allowance',
                                      type: SummaryEditableDataEnum.RATE,
                                      data: drawRequest.inspector_allowance_rate,
                                    })
                                  }
                                  sx={{ p: 0, pl: 1 }}
                                  tooltipText={
                                    isInspectionAllowanceEditable
                                      ? ''
                                      : 'The total inspection allowance rate cannot be edited if an inspection allowance rate has been entered for a line item.'
                                  }
                                  disabled={!isInspectionAllowanceEditable}
                                  data-cy="requests_tab__summary__inspection_allowance__edit__icon"
                                >
                                  <EditIcon size={20} />
                                </IconButtonWithTooltip>
                              </WithPermission>
                            )
                          }
                          textDataTestName="draw_inspection_allowance"
                        >
                          <ProgressWithArrow
                            prevValue={percentFormatter({
                              value: drawRequest.inspector_allowance_rate_before,
                              returnValue: '0%',
                            })}
                            nextValue={percentFormatter({
                              value: drawRequest.totals?.all?.inspector_allowance_rate,
                              returnValue: '0%',
                            })}
                            prevValueDataTestName="draw_inspection_allowance_rate_previous"
                            nextValueDataTestName="draw_inspection_allowance_rate"
                          />
                        </LabelAndValue>
                        <LabelAndValueWithPreload
                          isLoading={isUpdating}
                          label="Variance to lender allowance"
                          valueIconTooltip={outOfBudgetError}
                          valueIcon={outOfBudgetError ? <WarningIcon size={14} /> : null}
                        >
                          <ProgressWithArrow
                            prevValue={percentFormatter({
                              value: drawRequest.gap_before,
                              roundTo: 0,
                              returnValue: '0%',
                            })}
                            nextValue={percentFormatter({
                              value: variance_to_lender_allowance_rate,
                              roundTo: 0,
                              returnValue: '0%',
                            })}
                            getTextStyleForActive={isInReview}
                            prevValueDataTestName="requests_tab__summary__variance_to_lender_allowance__value"
                            nextValueDataTestName="requests_tab__summary__variance_to_lender_allowance__next_value"
                            nextValueType={
                              isInReview
                                ? outOfBudgetError
                                  ? { [ValueTypeEnum.IS_ERROR]: true }
                                  : { [ValueTypeEnum.IS_IN_REVIEW]: true }
                                : null
                            }
                          />
                        </LabelAndValueWithPreload>
                      </Stack>
                      {lgMediaQuery && (
                        <Box data-tour="expand_summary_button" sx={{ mt: 1 }}>
                          {getExpandButton()}
                        </Box>
                      )}
                    </Stack>
                  </StyledBox>
                </WithPermission>
              )}
              {!isDrawRequest && (
                <StyledBox sx={{ flexGrow: 1 }}>
                  <Stack direction="row">
                    <Stack flex={1} spacing={1}>
                      <LabelAndValue label="" text="Pre ➝ Post" color={colors.text.medium} />
                      <ApprovedToDate
                        isUpdating={isUpdating}
                        totals={drawRequest?.totals?.all || {}}
                        isInReview={isInReview}
                        outOfBudgetError={!!outOfBudgetError}
                      />
                      <BalanceToFinish
                        isUpdating={isUpdating}
                        totals={drawRequest?.totals?.all || {}}
                        isInReview={isInReview}
                        outOfBudgetError={!!outOfBudgetError}
                      />
                    </Stack>
                    {lgMediaQuery && <Box sx={{ mt: 1 }}>{getExpandButton()}</Box>}
                  </Stack>
                </StyledBox>
              )}
              {isReallocationAllowed && isInReview && (
                <StyledBox
                  sx={{ flexGrow: 1, display: 'flex', alignItems: 'center', width: '100%' }}
                >
                  <Stack sx={{ flex: 1, mr: '30px' }} spacing={1}>
                    <RemainingReallocationBalanceWithProgress
                      controller={controller}
                      valueErrorText={budgetChangeError}
                    />
                  </Stack>
                </StyledBox>
              )}
            </Stack>
          )}
        </Stack>
      ) : (
        <Stack direction={{ lg: 'row', md: 'column' }} spacing={1}>
          <StyledBox sx={{ flexGrow: 1, display: 'flex', alignItems: 'center' }}>
            <Stack direction="row" alignItems="flex-end" justifyContent="flex-start">
              {isCompleted ? (
                <Typography variant="h4" sx={{ mr: '4px' }}>{`${
                  isDrawRequest ? 'Draw' : 'Change'
                } #${drawRequest.counter_per_request_type}`}</Typography>
              ) : (
                <>
                  <Typography variant="h4" sx={{ mr: '4px' }}>{`Active ${
                    isDrawRequest ? 'draw' : 'change'
                  } #${drawRequest.counter_per_request_type}`}</Typography>

                  <Typography variant="body3">
                    {isInReview ? '(requested)' : isApproved ? '(approved)' : '(draft)'}
                  </Typography>
                </>
              )}
            </Stack>
          </StyledBox>

          {!isInReview && !isDraft && (
            <StyledBox sx={{ flexGrow: 1 }}>
              <Stack flex={1} spacing={1}>
                <LabelAndValue label={`${isDrawRequest ? 'Draw' : 'Change'} status`}>
                  {getStatusChip(drawRequest)}
                </LabelAndValue>
              </Stack>
            </StyledBox>
          )}

          {isDrawRequest && (
            <StyledBox sx={{ flexGrow: 1 }}>
              <Stack flex={1} spacing={1}>
                <LabelAndValueWithPreload
                  isLoading={isUpdating}
                  color={getValueColor({ isInReview })}
                  label={isApprovedOrCompleted ? 'Approved draw' : 'Draw amount'}
                  text={getTextOrDash(
                    isApprovedOrCompleted ? approved_amount : requested_amount,
                    isApprovedOrCompleted ? approved_amount_relative : requested_amount_relative,
                  )}
                  textDataTestName={`requests_tab__summary__collapsed__${
                    isApprovedOrCompleted ? 'approved_amount' : 'requested_amount'
                  }__value`}
                />
              </Stack>
            </StyledBox>
          )}
          {isReallocationAllowed && (
            <StyledBox sx={{ flexGrow: 1 }}>
              <Stack flex={1} spacing={1}>
                {isApprovedOrCompleted ? (
                  <LabelAndValueWithPreload
                    isLoading={isUpdating}
                    color={getValueColor({ isInReview })}
                    label="Approved reallocations"
                    text={currencyFormatter(drawRequest?.approved_reallocation, '-')}
                    textDataTestName="requests_tab__summary__collapsed__approved_reallocation__value"
                  />
                ) : (
                  <RemainingReallocationBalanceWithProgress
                    controller={controller}
                    valueErrorText={budgetChangeError}
                  />
                )}
              </Stack>
            </StyledBox>
          )}

          <StyledBox sx={{ flexGrow: 1 }}>
            <Stack direction="row">
              {isApprovedOrCompleted ? (
                <Stack flex={1} spacing={1}>
                  {isDrawRequest ? (
                    <LabelAndValueWithPreload
                      label="Lender allowance"
                      text={getTextOrDash(approved_amount_cumulative, lender_allowance_rate)}
                      isLoading={isUpdating}
                      textDataTestName="requests_tab__summary__collapsed__lender_allowance__value"
                    />
                  ) : (
                    <ApprovedToDate
                      isUpdating={isUpdating}
                      totals={drawRequest?.totals?.all || {}}
                      isInReview={isInReview}
                      outOfBudgetError={!!outOfBudgetError}
                    />
                  )}
                </Stack>
              ) : (
                <Stack flex={1} spacing={1}>
                  {isDrawRequest ? (
                    <BalanceToFinish
                      isUpdating={isUpdating}
                      totals={drawRequest?.totals?.all || {}}
                      isInReview={isInReview}
                      outOfBudgetError={!!outOfBudgetError}
                    />
                  ) : (
                    <ApprovedToDate
                      isUpdating={isUpdating}
                      totals={drawRequest?.totals?.all || {}}
                      isInReview={isInReview}
                      outOfBudgetError={!!outOfBudgetError}
                    />
                  )}
                </Stack>
              )}
              {lgMediaQuery && <Box sx={{ mt: 1 }}>{getExpandButton()}</Box>}
            </Stack>
          </StyledBox>
        </Stack>
      )}
      {changedData && (
        <ChangeDataPopup
          onClose={() => setChangedData(null)}
          onSave={(value) => {
            updateRequestData(changedData.type, value as Date);
            setChangedData(null);
          }}
          initData={changedData.data}
          title={changedData.title}
          type={changedData.type}
          source="draw"
          minDate={changedData.minDate}
          maxDate={changedData.maxDate}
          validationRule={changedData.validationRule}
        />
      )}
    </>
  );
};

export default RequestSummary;
