import { QueryNamesEnums } from '@interfaces';
import { useParams } from 'react-router-dom';
import { currencyFormatter } from '@utils';
import { useGraphQuery } from '@context';

interface Amounts {
  prefundingCost: string;
  constructionHoldback?: string;
  borrowerEquity?: string;
  scheduleOfValues: string;
}

interface ControllerInterface {
  budgetAmounts: Amounts;
  loanAmounts: Amounts;
}

export const useErrorsPopup = (): ControllerInterface => {
  const { projectId } = useParams();

  const project = useGraphQuery({
    type: QueryNamesEnums.GET_PROJECT,
    keys: ['loan'],
    args: { project_id: projectId },
  });
  const loan = project.data?.loan;

  return {
    budgetAmounts: {
      prefundingCost: currencyFormatter(loan?.funding_source_inactive_amount || 0),
      scheduleOfValues: currencyFormatter(loan?.funding_source_active_amount || 0),
    },
    loanAmounts: {
      prefundingCost: currencyFormatter(loan?.prefunding_cost || 0),
      constructionHoldback: currencyFormatter(loan?.construction_holdback || 0),
      borrowerEquity: currencyFormatter(loan?.borrower_equity || 0),
      scheduleOfValues: currencyFormatter(loan?.post_funding_construction_budget || 0),
    },
  };
};
