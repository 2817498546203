import { LineItemRightDrawer, RightDrawer } from '@components';
import React, { createContext, FC, PropsWithChildren, useContext, useMemo, useState } from 'react';
import {
  CommonRowType,
  DrawRequestTypeEnum,
  ICommentsPreview,
  IMilestone,
  IMilestoneTotal,
  IProjectMilestone,
  PatchListItem,
  ProductionBuildCommonRowType,
  QueryNamesEnums,
  RequestTableTabsEnum,
  RequestTableTabTypesEnum,
  TableKeyEnum,
} from '@interfaces';
import { commentsPreviewQueryFields } from '@constants';
import { getProjectMilestonesList } from '@globalService';
import { useCommentsAndDocumentsPreview, useRightMenu } from '@hooks';
import { useQuery } from 'react-query';
import { useParams } from 'react-router';
import { ITableRightDrawer } from './interface';
import { SettingsContext, useLaunchDarklyFlags } from '@context';

export interface DrawerContextInterface {
  tableKey?: TableKeyEnum | DrawRequestTypeEnum;
  refetch?: ({ isMilestonesUpdate }?: { isMilestonesUpdate?: boolean }) => void;
  resetMutation?: (args: PatchListItem) => void;
  deleteMilestone?: (milestone: string, isModelBuilding?: boolean) => void;
  openEditMilestoneModal?: (milestone: IMilestone) => void;
  updateRightDrawer?: ({
    milestoneId,
    milestoneName,
    milestoneSubmitId,
    tab,
  }: ITableRightDrawer) => (event: React.MouseEvent<HTMLElement>) => void;
  commentsPreviewInfo?: {
    [key: string]: ICommentsPreview;
  };
  source?: string;
}

interface MilestoneListDrawer {
  refetch?: () => void;
  resetMutation?: (args: PatchListItem) => void;
  openEditMilestoneModal?: (milestone: IMilestone) => void;
  deleteMilestone?: (milestone: string) => void;
  commentsPreviewQueryEnabled: boolean;
  tableKey?: TableKeyEnum | DrawRequestTypeEnum;
  source: string;
  onCreditChangeCompleted?: (data: IMilestone) => void;
  milestones: CommonRowType[] | ProductionBuildCommonRowType[];
}

export const DrawerContext = createContext<DrawerContextInterface>({});

export const MilestroneListDrawer: FC<PropsWithChildren<MilestoneListDrawer>> = ({
  children,
  refetch,
  deleteMilestone,
  openEditMilestoneModal,
  commentsPreviewQueryEnabled,
  tableKey,
  source,
  onCreditChangeCompleted,
  milestones,
}) => {
  const { isPHBProject } = useContext(SettingsContext);
  const { projectId, requestId, inspectionId } = useParams();
  const flags = useLaunchDarklyFlags();
  const query = commentsPreviewQueryFields;
  const commentsPreviewQuery = useQuery<
    { results: Partial<IProjectMilestone>[]; totals: IMilestoneTotal },
    Error
  >(
    [QueryNamesEnums.GET_PROJECT_MILESTONES, { projectId, query, filterKey: 'all' }],
    getProjectMilestonesList.bind(this, { projectId, query, filterKey: 'all' }),
    { enabled: commentsPreviewQueryEnabled && !isPHBProject },
  );
  const commentsPreviewInfo = useMemo(() => {
    const result = {};
    if (flags?.['ENG_9292_redo_blue_dot_logic_for_comments'] && milestones?.length) {
      milestones.forEach((item) => (result[item.id] = item.comments_preview));
    }
    if (
      !flags?.['ENG_9292_redo_blue_dot_logic_for_comments'] &&
      commentsPreviewQuery.data?.results?.length
    ) {
      commentsPreviewQuery.data?.results.forEach(
        (item) => (result[item.id] = item.comments_preview),
      );
    }
    return result;
  }, [milestones, commentsPreviewQuery.data?.results, flags]);

  const [rightDrawerParams, setRightDrawerParams] = useState<{
    milestoneId?: string;
    tab: RequestTableTabsEnum;
    type?: RequestTableTabTypesEnum;
    creditAvailable?: boolean;
  }>({
    milestoneId: '',
    tab: RequestTableTabsEnum.COMMENTS,
    creditAvailable: false,
  });

  const { updateCommentsPreviewInfo } = useCommentsAndDocumentsPreview({
    projectId,
    milestoneId: rightDrawerParams.milestoneId,
    drawRequestId: requestId,
    inspectionId,
  });
  const { handleRightDrawerOpenerClick, ...rightMenu } = useRightMenu({
    onClose: !isPHBProject && updateCommentsPreviewInfo,
  });

  const updateRightDrawer =
    ({
      milestoneId,
      milestoneName,
      tab,
      type,
      milestoneSubmitId,
      creditAvailable,
    }: ITableRightDrawer) =>
    () => {
      handleRightDrawerOpenerClick({ title: milestoneName });
      setRightDrawerParams({
        tab,
        type,
        creditAvailable,
        ...(milestoneId ? { milestoneId } : {}),
        ...(milestoneSubmitId ? { milestoneSubmitId } : {}),
        ...(inspectionId ? { inspectionId } : {}),
        ...(requestId ? { requestId } : {}),
      });
    };
  const drawerContext = useMemo<DrawerContextInterface>(
    () => ({
      tableKey,
      ...(refetch ? { refetch } : {}),
      ...(deleteMilestone ? { deleteMilestone } : {}),
      ...(openEditMilestoneModal ? { openEditMilestoneModal } : {}),
      updateRightDrawer,
      commentsPreviewInfo,
      source,
    }),
    [commentsPreviewInfo],
  );

  return (
    <DrawerContext.Provider value={drawerContext}>
      {children}
      <RightDrawer {...rightMenu}>
        <LineItemRightDrawer
          setOpen={rightMenu.setOpen}
          rightDrawerParams={{ ...rightDrawerParams, projectId, onCreditChangeCompleted }}
          source={source}
        />
      </RightDrawer>
    </DrawerContext.Provider>
  );
};
