import { useMemo, useState, Dispatch, SetStateAction } from 'react';
import { useMutation, useQueryClient } from 'react-query';

import { IProject, QueryNamesEnums, ErrorDual } from '@interfaces';
import { updateProjectFields } from '@globalService';
import { parsePathErrorDual } from '@utils';
import { useSafeSnackbar } from '@hooks';

interface HookInterface {
  isBatchSubmissionEnabled: boolean;
  setIsBatchSubmissionEnabled: Dispatch<SetStateAction<boolean>>;
  handleSubmitClick: () => Promise<boolean>;
  isSubmitting: boolean;
  isUpdated: boolean;
  exitPath: string;
}

export const useOtherSettings = ({ project }: { project: IProject }): HookInterface => {
  const { enqueueSnackbar } = useSafeSnackbar();
  const { id: projectId } = project || {};

  const queryClient = useQueryClient();
  const { is_lump_sum_enabled } = project || {};
  const [isBatchSubmissionEnabled, setIsBatchSubmissionEnabled] = useState<boolean>(
    is_lump_sum_enabled ?? false,
  );

  const isBatchSubmissionEnabledChanged = useMemo(
    () => isBatchSubmissionEnabled !== project.is_lump_sum_enabled,
    [isBatchSubmissionEnabled, project.is_lump_sum_enabled],
  );

  // check if project details are updated
  const isOtherSettingsUpdated = useMemo(
    () => isBatchSubmissionEnabledChanged,
    [isBatchSubmissionEnabledChanged],
  );

  const projectMutation = useMutation<
    Response,
    ErrorDual,
    { projectId: string; json: Partial<IProject> }
  >(updateProjectFields, {
    onError: (error) => {
      enqueueSnackbar(
        parsePathErrorDual(error) || 'Something went wrong while updating project info',
        { variant: 'error' },
      );
    },
  });

  const handleSubmitClick = async () => {
    const projectPayload = {
      is_lump_sum_enabled: isBatchSubmissionEnabled,
    };
    await projectMutation.mutateAsync({
      projectId,
      json: projectPayload,
    });
    await invalidateQueries();
    return true;
  };

  const invalidateQueries = async () => {
    await queryClient.invalidateQueries([QueryNamesEnums.GET_PROJECT, { project_id: projectId }]);
  };

  const exitPath = useMemo(() => `/projects/${projectId}/overview`, [projectId]);

  return {
    isBatchSubmissionEnabled,
    setIsBatchSubmissionEnabled,
    handleSubmitClick,
    isSubmitting: projectMutation.isLoading,
    isUpdated: isOtherSettingsUpdated,
    exitPath,
  };
};
