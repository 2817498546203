import React from 'react';
import { MilestoneListColumnTypeV2 } from './common';
import { currencyFormatter, currencyToNumber, notAllowNegative } from '@utils';
import { CurrencyInput } from '@components';
import { GridEditInputCell } from '@mui/x-data-grid';
import { InputBaseComponentProps } from '@mui/material';

const retainageReleaseApproved: MilestoneListColumnTypeV2 = {
  field: 'retainage_release_approved',
  headerAlign: 'right',
  headerClassName: 'number',
  cellClassName: 'number',
  headerName: 'Approved retainage release ($)',
  description: 'Approved retainage release ($)',
  valueFormatter: (value) => currencyFormatter(value, ''),
  valueParser: (value) => currencyToNumber(value),
  renderEditCell: (props) => (
    <GridEditInputCell
      {...props}
      inputProps={{
        isAllowed: notAllowNegative,
      }}
      inputComponent={CurrencyInput as unknown as React.ElementType<InputBaseComponentProps>}
    />
  ),
  minWidth: 120,
  editableByMilestone: (row) =>
    row.activeToEdit && row.retainage_rate && row.is_retainage_release_approved_editable,
};

export default retainageReleaseApproved;
