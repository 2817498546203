import { IRoleNew, QueryNamesEnums } from '@interfaces';
import { useGraphQuery } from '@context';
import { useParams } from 'react-router';
import { useMemo } from 'react';
import sortBy from 'lodash/sortBy';
import { FilteredDataByGraph } from '@utils';

export const useProjectWorkflow = () => {
  const { projectId } = useParams();

  const listData = useGraphQuery({
    type: QueryNamesEnums.GET_PROJECT_ROLES,
    keys: ['company_role', 'id', 'review_level', 'permissions', 'display_name'],
    args: {
      project_id: projectId,
    },
  });

  const roles = useMemo<
    FilteredDataByGraph<
      IRoleNew,
      'permissions' | 'company_role' | 'id' | 'review_level' | 'display_name'
    >[]
  >(() => sortBy(listData?.data?.results || [], 'review_level'), [listData.data]);

  return {
    status: listData.status,
    roles,
  };
};
