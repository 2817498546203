import React, { Dispatch, useContext, useMemo } from 'react';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';

import {
  getUnitTag,
  getModelTag,
  isCompletedProject,
  getProjectDuration,
  getDataLayerHookState,
  Override,
} from '@utils';
import {
  HookState,
  IProjectProperty,
  IPropertyDetailLocal,
  MilestoneTag,
  QueryNamesEnums,
} from '@interfaces';
import { getProjectMilestoneTags, getProjectModels } from '@globalService';
import { SettingsContext, useLaunchDarklyFlags, useGraphQuery } from '@context';
import { statusMap } from '@constants';
import { useProjectDetailsFields } from '@hooks';

export type ControllerInterface = Override<
  ReturnType<typeof useProject>,
  {
    projectProperty: IPropertyDetailLocal[];
    projectType: string;
    exitStrategy: string;
    isLongDescription: boolean;
    state: HookState;
    completion: {
      title: string;
      value: Date | string;
      isCompletedProject: boolean;
    };
    duration: {
      title: string;
      value: string;
    };
    contractual: string | Date;
    setAnchorEl: Dispatch<React.SetStateAction<EventTarget & HTMLSpanElement>>;
    anchorEl: Element;
    statusValue: {
      color: string;
      backgroundColor: string;
      text: string;
    };
    modelsCount: number;
    unitsCount: number;
    navigateToPHBBudget: () => void;
    isProjectCompleted: boolean;
    unitName: string;
    modelName: string;
    navigateToProjectSettings: () => void;
    models: IProjectProperty[];
  }
>;

export const useProject = (projectId: string) => {
  const {
    settings: { display: staticStatuses },
  } = useContext(SettingsContext);
  const navigate = useNavigate();
  const flags = useLaunchDarklyFlags();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const project = useGraphQuery({
    type: QueryNamesEnums.GET_PROJECT,
    keys: [
      'scope_of_work',
      'thumb_representations',
      'estimated_completion_date',
      'original_completion_date',
      'duration',
      'status',
      'type',
      'exit_strategy',
      'property_proposed_type',
      'property_existing_type',
      'estimated_completion_date_change_reason',
      'estimated_start_date',
      'status_change_reason',
      'address',
      'id',
      'units_number',
      'name',
    ],
    args: { project_id: projectId },
  });

  const projectMilestoneTagsQuery = useQuery<{ results: MilestoneTag[] }, Error>(
    [QueryNamesEnums.GET_PROJECT_MILESTONE_TAGS, { projectId }],
    getProjectMilestoneTags.bind(this, projectId),
    { enabled: Boolean(projectId) },
  );

  const { propertyDetails, count: unitsCount } = useProjectDetailsFields({ project: project.data });

  const projectModelsQuery = useQuery<{ results: IProjectProperty[]; count: number }, Error>(
    [QueryNamesEnums.GET_PROJECT_BUILDING_MODELS, { projectId }],
    getProjectModels.bind(this, projectId),
  );

  const isLongDescription = useMemo(
    () => project.data?.scope_of_work?.length > 150,
    [project.data],
  );

  const isProjectCompleted = useMemo(
    () => isCompletedProject(project.data?.status),
    [project.data?.status],
  );

  const completion = useMemo(() => {
    const date = project.data?.estimated_completion_date || project.data?.original_completion_date;
    return {
      title: isProjectCompleted ? 'Project completion date' : 'Estimated project completion date',
      value: date,
      isCompletedProject: isProjectCompleted,
    };
  }, [project.data, isProjectCompleted, flags]);

  const duration = useMemo(() => {
    if (!project.data) return;
    return getProjectDuration(project.data);
  }, [project.data]);

  const statusValue = useMemo(
    () => (staticStatuses ? statusMap(project.data?.status, staticStatuses, 'project') : {}),
    [project.data, staticStatuses],
  );

  const modelsCount = useMemo(() => projectModelsQuery.data?.count, [projectModelsQuery.data]);

  const navigateToPHBBudget = () => {
    if (modelsCount) return navigate(`/projects/${projectId}/budget/edit-models`);
    if (unitsCount) return navigate(`/projects/${projectId}/budget/edit-units`);
  };

  const navigateToProjectSettings = () => {
    navigate(`/projects/${projectId}/settings/general`);
  };

  const modelTag = useMemo(
    () => getModelTag(projectMilestoneTagsQuery.data?.results),
    [projectMilestoneTagsQuery.data?.results],
  );
  const unitTag = useMemo(
    () => getUnitTag(projectMilestoneTagsQuery.data?.results),
    [projectMilestoneTagsQuery.data?.results],
  );

  return {
    project: project.data,
    projectProperty: propertyDetails.list,
    projectType: project.data?.type,
    exitStrategy: project.data?.exit_strategy,
    isLongDescription,
    state: getDataLayerHookState(project),
    completion,
    contractual: project.data?.original_completion_date,
    duration,
    statusValue,
    anchorEl,
    setAnchorEl,
    modelsCount,
    unitsCount,
    navigateToPHBBudget,
    isProjectCompleted,
    unitName: unitTag?.name,
    modelName: modelTag?.name,
    navigateToProjectSettings,
    models: projectModelsQuery.data?.results,
  };
};
