import React from 'react';
import ReactDOM from 'react-dom/client';
import config from '@config';
import * as Sentry from '@sentry/react';
import { Replay } from '@sentry/replay';
import { BrowserRouter as Router } from 'react-router-dom';
import { ReactQueryDevtools } from 'react-query/devtools';
import { QueryClientProvider } from 'react-query';
import { LicenseInfo } from '@mui/x-license';
import { datadogRum } from '@datadog/browser-rum';
import { datadogLogs } from '@datadog/browser-logs';
import { BASE_CONFIG } from '@api';
import { AppUpdateProvider, SSOProvider, CustomQueryClient } from '@context';
import { isQAEnvironment, packageJsonVersion } from '@utils';

import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorkerRegistration';

if (config.sentryEnv && !navigator.userAgent.includes('Electron')) {
  const integrations = [
    Sentry.browserTracingIntegration({
      tracingOrigins: ['backend.trustpoint.ai', 'backend.sandbox.trustpoint.ai', /^\//],
    }),
  ];

  if (!isQAEnvironment) {
    integrations.push(
      new Replay({ maskAllText: false, stickySession: true, maskInputOptions: { password: true } }),
    );
  }

  Sentry.init({
    dsn: `${config.sentryDsn}`,
    integrations,
    environment: `${config.sentryEnv}`,
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.2,
    replaysSessionSampleRate: 0.2,
    replaysOnErrorSampleRate: 1.0,
    // exclude errors from google sign in
    denyUrls: ['https://accounts.google.com/gsi/status'],
  });
}

if (config.datadogApplicationID && !navigator.userAgent.includes('Electron')) {
  datadogRum.init({
    applicationId: config.datadogApplicationID,
    clientToken: config.datadogClientToken,
    site: config.datadogSite,
    service: 'galvatron',
    env: config.datadogEnv,
    version: packageJsonVersion,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 100,
    trackResources: true,
    trackLongTasks: true,
    trackUserInteractions: true,
    actionNameAttribute: 'data-cy',
    enableExperimentalFeatures: ['feature_flags'],
    defaultPrivacyLevel: 'allow',
    allowedTracingUrls: ['https://backend.trustpoint.ai', 'https://backend.sandbox.trustpoint.ai'],
  });

  datadogLogs.init({
    clientToken: config.datadogClientToken,
    site: config.datadogSite,
    service: 'galvatron',
    env: config.datadogEnv,
    version: packageJsonVersion,
    forwardErrorsToLogs: true,
    sessionSampleRate: 100,
  });
}

LicenseInfo.setLicenseKey(config.dataGridKey);

const queryClient = new CustomQueryClient({
  defaultOptions: {
    queries: { ...BASE_CONFIG },
  },
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Router>
    <QueryClientProvider client={queryClient}>
      <AppUpdateProvider>
        <SSOProvider>
          <App />
        </SSOProvider>
      </AppUpdateProvider>
      <ReactQueryDevtools />
    </QueryClientProvider>
  </Router>,
);

serviceWorker.unregister();
