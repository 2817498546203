import { useContext, useMemo } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import {
  ChangeRequestConfigEnum,
  ErrorDual,
  HookState,
  ICompanyFull,
  ICompanyOverview,
  ICoordinator,
  IUser,
  PermissionNamesEnums,
  QueryNamesEnums,
  UserStatusEnum,
} from '@interfaces';
import { getHookState, getUserFullNameOrEmail, isAllowed, parsePathErrorDual } from '@utils';
import { getCompanyUsers, getMyCompany, updateCompany } from '@globalService';
import { enqueueSnackbar } from 'notistack';
import { PermissionsContext } from '@context';
import { companyUsersFields } from '@constants';

export type ControllerInterface = {
  state: HookState;
  changeRequestConfiguration: { label: string; value: string };
  options: { label: string; value: string }[];
  defaultCoordinator: ICoordinator | null;
  companyUsers: { label: string; value: string }[];
  companyUpdate: (value: string) => void;
  hasPoliciesEditPermission: boolean;
  defaultCoordinatorUpdate: (value: string) => void;
  hasCoordinatorEditPermission: boolean;
};

const CHANGE_REQUEST_CONFIGURATION_OPTIONS = [
  { label: 'Changes accepted with or without draws', value: ChangeRequestConfigEnum.ALL_REQUESTS },
  { label: 'Changes accepted with draws only', value: ChangeRequestConfigEnum.ONLY_DRAW_REQUESTS },
  {
    label: 'Changes accepted stand alone only',
    value: ChangeRequestConfigEnum.ONLY_CHANGE_REQUESTS,
  },
];

export const useCompanyOtherSettings = (): ControllerInterface => {
  const queryClient = useQueryClient();
  const { permissions } = useContext(PermissionsContext);
  const hasPoliciesEditPermission = useMemo(
    () => isAllowed(PermissionNamesEnums.POLICIES_EDIT, permissions),
    [permissions],
  );
  const hasCoordinatorEditPermission = useMemo(
    () => isAllowed(PermissionNamesEnums.DRAWREQUESTS_COORDINATOR_EDIT, permissions),
    [permissions],
  );

  const companyQuery = useQuery<ICompanyFull, Error>(
    [QueryNamesEnums.GET_MY_COMPANY],
    getMyCompany.bind(this),
  );

  const companyUsersQuery = useQuery<{ results: IUser[] }, Error>(
    [
      QueryNamesEnums.GET_COMPANY_USERS,
      { companyId: companyQuery.data?.id, sorting: 'first_name', query: companyUsersFields },
    ],
    getCompanyUsers.bind(this, {
      companyId: companyQuery.data?.id,
      sorting: '&sorting=first_name',
      restQlParams: companyUsersFields,
    }),
    { enabled: Boolean(companyQuery.data?.id) && hasCoordinatorEditPermission },
  );

  const companyUpdate = (value: string) => {
    updateCompanyMutation.mutateAsync({ company: { change_request_mode: value } });
  };

  const updateCompanyMutation = useMutation<Response, ErrorDual, ICompanyOverview>(updateCompany, {
    onSuccess: () => {
      queryClient.invalidateQueries(QueryNamesEnums.GET_MY_COMPANY);
    },
    onError: (error) => {
      enqueueSnackbar(parsePathErrorDual(error), { variant: 'error' });
    },
  });

  const defaultCoordinatorUpdate = (value: string) => {
    updateCompanyMutation.mutateAsync({ company: { coordinator: value } });
  };

  const companyUsers = useMemo(() => {
    return (
      companyUsersQuery.data?.results
        ?.filter((user) => user.status === UserStatusEnum.ACTIVE)
        .map((user) => ({
          label: user.full_name || getUserFullNameOrEmail(user),
          value: user.id,
        })) || []
    );
  }, [companyUsersQuery.data]);

  return {
    state: getHookState(companyQuery),
    changeRequestConfiguration: CHANGE_REQUEST_CONFIGURATION_OPTIONS.find(
      (option) => option.value === companyQuery.data?.change_request_mode,
    ),
    defaultCoordinator: companyQuery.data?.coordinator,
    companyUsers,
    companyUpdate,
    options: CHANGE_REQUEST_CONFIGURATION_OPTIONS,
    hasPoliciesEditPermission,
    defaultCoordinatorUpdate,
    hasCoordinatorEditPermission,
  };
};
