import { useContext, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  HookState,
  PaymentConfiguration,
  PermissionNamesEnums,
  QueryNamesEnums,
} from '@interfaces';
import { isAllowed, isCreatedProject, percentFormatter } from '@utils';
import { PermissionsContext, useGraphQuery } from '@context';
import { useProjectPaymentData } from '@hooks';

interface ControllerInterface {
  state: HookState;
  feesAmount: number;
  paymentModelText: string;
  navigateToPaymentSettings: (navigateTo: string) => void;
  canEditFees: boolean;
  canEditProjectModel: boolean;
}

export const useProjectPaymentSummary = (): ControllerInterface => {
  const { projectId } = useParams();
  const navigate = useNavigate();
  const { permissions } = useContext(PermissionsContext);
  const { borrowerEquityFraction, constructionHoldbackFraction } = useProjectPaymentData();

  const project = useGraphQuery({
    type: QueryNamesEnums.GET_PROJECT,
    keys: [
      'fees',
      'status',
      'payment_configuration_type',
      'construction_holdback_rate',
      'borrower_equity_rate',
    ],
    args: { project_id: projectId },
  });

  const feesAmount = useMemo(
    () =>
      project?.data?.fees?.reduce((prev, curr) => {
        return prev + curr.amount;
      }, 0),
    [project?.data?.fees],
  );

  const paymentModelText = useMemo(() => {
    const configurationType =
      project?.data?.payment_configuration_type === PaymentConfiguration.PER_DRAW_REQUEST
        ? 'Partial'
        : 'Sequential';

    const proportion = `${percentFormatter({ value: constructionHoldbackFraction })} / ${percentFormatter({ value: borrowerEquityFraction })}`;

    return `${proportion} Loan to equity, ${configurationType}`;
  }, [project]);

  const canEditFees = useMemo(
    () => isAllowed(PermissionNamesEnums.PAYMENTS_MARK_AS_PAID, permissions),
    [permissions],
  );

  const canEditProjectModel = useMemo(
    () =>
      isCreatedProject(project.data?.status) &&
      isAllowed(PermissionNamesEnums.PROJECT_ONBOARDING, permissions),
    [permissions, project.status],
  );

  const navigateToPaymentSettings = (navigateTo: string) => {
    navigate(`/projects/${projectId}/settings/${navigateTo}`, {
      state: { prevUrl: `/projects/${projectId}/payments/all` },
    });
  };

  return {
    state: project.status as HookState,
    feesAmount,
    paymentModelText,
    navigateToPaymentSettings,
    canEditFees,
    canEditProjectModel,
  };
};
