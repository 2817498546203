import { alpha, styled } from '@mui/system';
import { Box } from '@mui/material';
import { gridClasses } from '@mui/x-data-grid-premium';
import { colors } from '@theme';
import { TableStyleProps } from './interface';

export const TRIGGERHEIGHT = 60;

export const TableStyle = styled(Box, {
  shouldForwardProp: (prop) =>
    prop !== 'containerSpacing' &&
    prop !== 'onFullScreen' &&
    prop !== 'dynamicHeight' &&
    prop !== 'withoutAdaptiveScroll',
})<TableStyleProps>(
  ({
    containerSpacing = { top: 60, bottom: 0 },
    dynamicHeight,
    onFullScreen,
    withoutAdaptiveScroll,
    theme,
  }) => ({
    background: 'white',
    ...(onFullScreen
      ? {
          width: '100%',
          position: 'fixed',
          zIndex: 1200,
          top: 60,
          left: 0,
          right: 0,
          bottom: 0,
          height: `calc( 100vh - ${containerSpacing?.top + containerSpacing?.bottom}px)`,
          maxHeight: '100vh',
        }
      : {
          width: '100%',
          height: dynamicHeight ? `${dynamicHeight}px` : 'auto',
          ['& .MuiDataGrid-virtualScroller']: withoutAdaptiveScroll
            ? {}
            : {
                overflowY: 'hidden',
              },
        }),
    [`.${gridClasses.columnHeaderTitle}`]: {
      display: '-webkit-box',
      WebkitLineClamp: '2',
      WebkitBoxOrient: 'vertical',
    },
    [`.${gridClasses.menuIcon}`]: {
      position: 'absolute',
      right: 5,
      top: 2,
      bottom: 2,
      marginLeft: 0,
      alignItems: 'center',
      backgroundColor: 'rgba(247, 247, 247, 0.6)',
      [' .MuiSvgIcon-root ']: {
        fill: colors.neutral.darkest,
      },
    },
    [`.${gridClasses.iconButtonContainer}`]: {
      position: 'absolute',
      right: 20,
      top: 2,
      bottom: 2,
      marginLeft: 0,
      alignItems: 'center',
      backgroundColor: 'rgba(247, 247, 247, 0.6)',
      [' .MuiSvgIcon-root ']: {
        fill: colors.neutral.darkest,
      },
    },
    [`.${gridClasses['columnHeader--alignRight']}`]: {
      [`.${gridClasses.menuIcon}`]: {
        left: 5,
        right: 'auto',
      },
      [`.${gridClasses.iconButtonContainer}`]: {
        left: 20,
        right: 'auto',
      },
    },
    [`.${gridClasses['scrollbar--horizontal']}`]: {
      position: 'fixed',
      left: 20,
      right: 20,
      zIndex: 10000000,
      width: 'auto',
    },
    [`.${gridClasses.cell}.icon`]: {
      borderRight: 0,
      padding: 0,
      justifyContent: 'center',
      backgroundColor: 'rgba(247, 247, 247, 0.6)',
    },
    [`.${gridClasses.row}.totals`]: {
      fontWeight: 'bold',
      [`.${gridClasses.cell}.icon button`]: {
        display: 'none',
      },
      [`.${gridClasses.cell}.select::after`]: {
        display: 'none',
      },
    },
    [`.${gridClasses.columnHeader}:focus`]: {
      outline: 'none',
    },
    [`.${gridClasses.columnHeader}.not-bordered`]: {
      borderRight: 0,
      [`& .${gridClasses.columnSeparator}`]: {
        display: 'none',
      },
    },
    [`.${gridClasses.columnHeader}.number`]: {
      textAlign: 'right',
    },
    [`.${gridClasses.columnHeader}.icon`]: {
      borderRight: 0,
      [`& .${gridClasses.columnSeparator}`]: {
        display: 'none',
      },
    },
    [`.${gridClasses.cell}.index-column`]: {
      display: 'flex',
      alignItems: 'right',
      overflow: 'hidden',
      flexDirection: 'row',
      lineHeight: '1em',
      justifyContent: 'flex-end',
      backgroundColor: colors.neutral.lightest,
      borderBottom: `1px solid ${colors.neutral.lighter}`,
      color: colors.text.medium,
      paddingRight: theme.spacing(1),
      fontSize: '0.75rem',
    },
    [`.${gridClasses.cell}.not-selectable:focus`]: {
      outline: 'none',
    },
    [`.${gridClasses.cell}.text`]: {
      display: 'flex',
      alignItems: 'center',
      overflow: 'hidden',
      flexDirection: 'row',
      lineHeight: '1em',
      justifyContent: 'flex-start',
      [`&:not(.${gridClasses['cell--editable']})`]: {
        backgroundColor: 'rgba(247, 247, 247, 0.6)',
        color: colors.neutral.dark,
      },
    },
    [`.${gridClasses.cell}.select`]: {
      display: 'flex',
      alignItems: 'center',
      overflow: 'hidden',
      flexDirection: 'row',
      lineHeight: '1em',
      justifyContent: 'fex-start',
      position: 'relative',
      [`&:not(.${gridClasses['cell--editing']})`]: {
        [`&:after`]: {
          position: 'absolute',
          right: 15,
          content: `""`,
          width: 0,
          height: 0,
          borderStyle: 'solid',
          borderWidth: '0 5px 5px 5px',
          borderColor: 'transparent transparent #585858 transparent',
          transform: 'rotate(180deg)',
        },
      },
      [`&:not(.${gridClasses['cell--editable']})`]: {
        backgroundColor: 'rgba(247, 247, 247, 0.6)',
        color: colors.neutral.dark,
        [`&:after`]: {
          display: 'none',
        },
      },
    },
    [`.${gridClasses['cell']}.${gridClasses['cell--pinnedLeft']}`]: {
      borderBottom: '1px solid #E8E8E8',
    },
    [`.${gridClasses['cell']}.${gridClasses['cell--pinnedRight']}`]: {
      borderBottom: '1px solid #E8E8E8',
    },
    [`.${gridClasses.cell}.number`]: {
      display: 'flex',
      alignItems: 'center',
      overflow: 'hidden',
      flexDirection: 'row',
      lineHeight: '1em',
      justifyContent: 'flex-end',
      [`&:not(.${gridClasses['cell--editable']})`]: {
        backgroundColor: 'rgba(247, 247, 247, 0.6)',
        color: colors.neutral.dark,
      },
    },
    [`.${gridClasses.root}`]: {
      [`.${gridClasses.row}:hover`]: {
        backgroundColor: 'transparent',
      },
      [`.${gridClasses.row}.Mui-selected`]: {
        backgroundColor: 'transparent',
      },
      [`.${gridClasses.row}.Mui-selected:hover`]: {
        backgroundColor: 'transparent',
      },
      [`.${gridClasses.cell}.${gridClasses['cell--editable']}.Mui-selected`]: {
        backgroundColor: 'inherit',
      },
      [`.${gridClasses.cell}.${gridClasses['cell--editable']}.Mui-selected:hover`]: {
        backgroundColor: 'inherit',
      },
      [`& .${gridClasses.cell}.Mui-selected.${gridClasses['cell--rangeTop']}`]: {
        borderTop: `1px solid ${alpha(theme.palette.primary.main, 0.5)}`,
      },
      [`& .${gridClasses.cell}.Mui-selected.${gridClasses['cell--rangeBottom']}`]: {
        borderBottom: `1px solid ${alpha(theme.palette.primary.main, 0.5)}`,
      },
      [`& .${gridClasses.cell}.Mui-selected.${gridClasses['cell--rangeLeft']}`]: {
        borderLeft: `1px solid ${alpha(theme.palette.primary.main, 0.5)}`,
      },
      [`& .${gridClasses.cell}.Mui-selected.${gridClasses['cell--rangeRight']}`]: {
        borderRight: `1px solid ${alpha(theme.palette.primary.main, 0.5)}`,
      },
    },
    [`.${gridClasses['root--densityCompact']}`]: {
      [`.${gridClasses.cell}`]: {
        fontSize: '0.8rem',
        ['.MuiTypography-root']: {
          fontSize: '0.8rem',
        },
      },
      [`.${gridClasses.columnHeaderTitle}`]: {
        fontSize: '0.75rem',
      },
    },
  }),
);
