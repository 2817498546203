import React, { FC, JSXElementConstructor, useState } from 'react';
import { ClickAwayListener, LinearProgress, Stack, Tooltip, Typography } from '@mui/material';
import { PopperProps } from '@mui/material/Popper';
import Autocomplete, {
  AutocompleteChangeReason,
  AutocompleteCloseReason,
} from '@mui/material/Autocomplete';
import truncate from 'lodash/truncate';

import { PopperComponent, StyledInput, StyledPopper } from '../FilterButton/elements';
import { CheckboxIconChecked, CheckboxIconDefault } from '@svgAsComponents';
import { useDebouncedEffect } from '@models';
import { debounceFunction } from '@utils';
import { LabelType } from '@interfaces';

const PopperWithAutoComplete: FC<{
  anchorEl: HTMLElement;
  id: string;
  handleClose: () => void;
  open: boolean;
  options: LabelType[];
  closeOnChange?: boolean;
  handleValuesChange: (values: string[]) => void;
  placeHolder: string;
  values: string[];
  fixedWidth?: number;
  maxLabelLength?: number;
  loadNext?: () => void;
  isLoading?: boolean;
  inputChange?: (value: string) => void;
  multiple?: boolean;
}> = ({
  anchorEl,
  id,
  handleClose,
  open,
  options,
  closeOnChange,
  handleValuesChange,
  placeHolder,
  values,
  fixedWidth,
  maxLabelLength = 999,
  loadNext,
  isLoading,
  inputChange,
  multiple = true,
}) => {
  const [inputValue, setInputValue] = useState('');
  const handleInputChange = (event) => {
    if (event) setInputValue(event?.target?.value);
  };

  const handleChange = (
    event: React.SyntheticEvent<Element, Event>,
    newValue: LabelType[] | LabelType,
    reason: AutocompleteChangeReason,
  ) => {
    if (
      event.type === 'keydown' &&
      (event as React.KeyboardEvent).key === 'Backspace' &&
      reason === 'removeOption'
    ) {
      return;
    }

    if (Array.isArray(newValue)) {
      const onlyValues = newValue.map((item: { value?: any; toString(): string }) =>
        'value' in item ? item.value : item.toString(),
      );
      handleValuesChange(onlyValues);
    } else {
      handleValuesChange([newValue.value || newValue.toString()]);
    }
    if (closeOnChange) handleClose();
  };

  const handleScroll = (event) => {
    const threshold = 5;
    const bottom =
      Math.abs(event.target.scrollHeight - event.target.scrollTop - event.target.clientHeight) <
      threshold;
    if (loadNext && bottom) {
      loadNext();
    }
  };

  useDebouncedEffect(
    () => {
      inputChange?.(inputValue);
    },
    [inputValue],
    500,
  );

  return (
    <StyledPopper
      id={id}
      open={open}
      anchorEl={anchorEl}
      placement="bottom-start"
      sx={{ width: fixedWidth || '320px' }}
      nonce={undefined}
      onResize={undefined}
      onResizeCapture={undefined}
    >
      <ClickAwayListener onClickAway={handleClose}>
        <div>
          <Autocomplete
            open
            multiple={multiple}
            onClose={(
              _event: React.SyntheticEvent<Element, Event>,
              reason: AutocompleteCloseReason,
            ) => {
              if (reason === 'escape') handleClose();
            }}
            value={options.filter((option) => option && values.includes(option.value))}
            onChange={handleChange}
            inputValue={inputValue || ''}
            onInputChange={handleInputChange}
            disableCloseOnSelect={multiple}
            PopperComponent={PopperComponent as JSXElementConstructor<PopperProps>}
            renderTags={() => null}
            noOptionsText="No labels"
            renderOption={(props, option, { selected }) =>
              renderOption({ props, option, selected, maxLabelLength })
            }
            ListboxProps={{
              onScroll: debounceFunction(handleScroll, 500),
            }}
            options={options}
            getOptionLabel={(option) => option?.label || ''}
            getOptionDisabled={() => false}
            renderInput={(params) => (
              <>
                {isLoading && (
                  <LinearProgress
                    sx={{ top: 0, left: 0, right: 0, height: '2px', position: 'absolute' }}
                  />
                )}
                <StyledInput
                  {...params}
                  ref={params.InputProps.ref}
                  inputProps={params.inputProps}
                  autoFocus
                  placeholder={isLoading ? 'Loading...' : placeHolder}
                />
              </>
            )}
          />
        </div>
      </ClickAwayListener>
    </StyledPopper>
  );
};

const renderOption = ({
  props,
  option,
  selected,
  maxLabelLength,
}: {
  props: React.HTMLAttributes<HTMLLIElement>;
  option: LabelType;
  selected: boolean;
  maxLabelLength: number;
}) => (
  <li {...props} key={option.value}>
    <Stack flexDirection="row" alignItems="center" sx={{ minHeight: 24 }}>
      <Stack sx={{ height: 18, mr: '12px' }}>
        {selected ? <CheckboxIconChecked size={18} /> : <CheckboxIconDefault size={18} />}
      </Stack>
      <Stack>
        <Tooltip title={option.label?.length > maxLabelLength ? option.label : ''}>
          <Typography variant="body3" sx={{ whiteSpace: 'normal' }}>
            {truncate(option.label, { length: maxLabelLength })}
          </Typography>
        </Tooltip>
      </Stack>
    </Stack>
  </li>
);

export default PopperWithAutoComplete;
