import React, { useMemo } from 'react';
import { IDocument, IPhoto } from '@interfaces';
import { Stack, Typography, Divider, Box } from '@mui/material';
import { useDayJsFormatter } from '@hooks';
import { IconButtonWithTooltip, LabelAndValue, Tags, GoogleMap } from '@components';
import { CloseIcon, SuccessIcon } from '@svgAsComponents';
import { colors } from '@theme';
interface IMediaDetailsProps {
  media: IPhoto | IDocument;
  size: string;
  closeCallback?: () => void;
  mediaType?: 'Photo' | 'Video';
}

const DetailsSizes = {
  small: {
    title: 'labelSemiBold',
    label: 'label',
  },
  medium: {
    title: 'h2',
    label: 'body2',
  },
};

const MediaDetails = ({ media, size, closeCallback, mediaType = 'Photo' }: IMediaDetailsProps) => {
  const { dateFormatter } = useDayJsFormatter();

  const milestones = useMemo(() => {
    if ('milestones' in media) {
      return media.milestones?.map((milestone) => milestone.name).join(', ');
    }
    return media?.milestone_names?.join(', ');
  }, [media]);

  const location = useMemo(() => {
    if ('location' in media) {
      return { ...media.location, lng: media.location?.long };
    }
    return {
      address: media?.metadata?.progress_photo_address,
      is_verified: media?.metadata?.progress_photo_is_verified,
      lat: media?.metadata?.progress_photo_latitude,
      lng: media?.metadata?.progress_photo_longitude,
    };
  }, [media]);

  const device = useMemo(() => {
    if ('device' in media) {
      return media.device;
    }
    const deviceName = media?.metadata?.progress_photo_device_name;
    const deviceVendor = media?.metadata?.progress_photo_device_vendor;
    return (deviceName ? deviceName + ', ' : '') + (deviceVendor || '');
  }, [media]);

  const imageTags = useMemo((): string[] => {
    if ('tags' in media) {
      return (media?.tags as string[]) || [];
    }
    // TODO: Remove source_label after ENG-9658 is done
    if ('source_label' in media) {
      return (media?.source_label as string)?.split('/') || [];
    }
    return [];
  }, [media]);

  const loadedAt = useMemo(() => {
    if ('loaded_at' in media) {
      return media.loaded_at;
    }
    return media.created_at;
  }, [media]);

  if (!media) return null;

  return (
    <Stack spacing={1}>
      <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ pb: 1 }}>
        <Typography variant={DetailsSizes[size]?.title}>{mediaType} details</Typography>
        {closeCallback && (
          <IconButtonWithTooltip
            onClick={closeCallback}
            data-cy="photos_tab__card__close__button"
            tooltipText="Close"
          >
            <CloseIcon />
          </IconButtonWithTooltip>
        )}
      </Stack>

      <LabelAndValue size={DetailsSizes[size]?.label} label="Line item" text={milestones || '-'} />
      <LabelAndValue
        size={DetailsSizes[size]?.label}
        label="Approximate location"
        text={location?.address || '-'}
        icon={
          location?.is_verified && <SuccessIcon color={colors.status.success.medium} size={18} />
        }
      />
      {size !== 'small' && <GoogleMap location={location} />}
      <LabelAndValue size={DetailsSizes[size]?.label} label="Device" text={device || '-'} />
      <LabelAndValue
        size={DetailsSizes[size]?.label}
        label="Taken on"
        text={
          dateFormatter({
            date: media.metadata?.progress_photo_file_created_at || media.created_at,
          }) || '-'
        }
      />
      <LabelAndValue
        size={DetailsSizes[size]?.label}
        label="Loaded by"
        text={media.created_by_service_name || media.created_by?.['full_name'] || '-'}
      />
      <LabelAndValue
        size={DetailsSizes[size]?.label}
        label="Loaded at"
        text={dateFormatter({ date: loadedAt }) || '-'}
      />
      <LabelAndValue
        size={DetailsSizes[size]?.label}
        label={`${mediaType} name`}
        text={media.original_filename || '-'}
      />
      <Box sx={{ py: 1 }} />
      {media.comment && (
        <>
          <Typography variant="h4">Comments</Typography>
          <Typography variant="body3">{media.comment}</Typography>
          <Box sx={{ py: 1 }}>
            <Divider />
          </Box>
        </>
      )}
      <Tags items={imageTags} source="photos_tab__card" />
    </Stack>
  );
};

export default MediaDetails;
