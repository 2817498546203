import { useCallback, useEffect, useState } from 'react';
import { ILocalFee, QueryNamesEnums } from '@interfaces';
import { useQueries } from 'react-query';
import { getDrawRequest } from '@globalService';
import { useParams } from 'react-router-dom';
import { useGraphQuery } from '@context';

export interface IFeesControllerInterface {
  addFee: () => void;
  fees: ILocalFee[];
  deleteFee: (index: number) => void;
  updateFee: (key: keyof ILocalFee, value: string | number, index: number) => void;
  isFeesUpdated: boolean;
}

export const useFees = (): IFeesControllerInterface => {
  const { projectId, drawRequestId } = useParams();
  const [fees, setFees] = useState<ILocalFee[]>([]);
  const [isFeesUpdated, setIsFeesUpdated] = useState(false);

  const project = useGraphQuery({
    type: QueryNamesEnums.GET_PROJECT,
    keys: ['fees'],
    args: { project_id: projectId },
    options: {
      skip: !projectId,
    },
  });

  const requestedProjectQueries = useQueries([
    {
      queryKey: [QueryNamesEnums.GET_DRAW_REQUEST, { projectId, drawRequestId }],
      queryFn: getDrawRequest.bind(this, { projectId, drawRequestId }),
      enabled: Boolean(projectId && drawRequestId),
    },
  ]);

  const drawRequestData = requestedProjectQueries[0].data;

  const createFeesObject = (fee) => ({
    ...fee,
    isNew: false,
  });

  useEffect(() => {
    const paymentConfig = drawRequestData || project.data;
    setFees(paymentConfig?.fees?.map(createFeesObject) || []);
  }, [project.data?.fees, drawRequestData?.fees]);

  const addFee = () =>
    setFees((fees) => [
      ...fees,
      {
        name: '',
        amount: null,
        isNew: true,
      },
    ]);

  const updateFee = (key: keyof ILocalFee, value: string | number, index: number) =>
    setFees((fees) => Object.assign([...fees], { [index]: { ...fees[index], [key]: value } }));

  const deleteFee = useCallback(
    (index) => {
      const newFees = fees.filter((fee, i) => i !== index);
      setFees(newFees);
    },
    [fees, projectId],
  );

  useEffect(() => {
    const projectFees = project.data?.fees?.map(createFeesObject);
    setIsFeesUpdated(JSON.stringify(projectFees) !== JSON.stringify(fees));
  }, [project.data?.fees, fees]);

  return { addFee, fees, deleteFee, updateFee, isFeesUpdated };
};
