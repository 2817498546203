import { useCallback, useContext, useMemo } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { matchPath, useLocation, useParams } from 'react-router-dom';
import { useSafeSnackbar } from '@hooks';

import {
  checkIsOwner,
  isDrawRequest,
  isProjectPolicies,
  isRequestApproved,
  isRequestCompleted,
  isRequestDraft,
  isRequestInReview,
  isAllowed,
} from '@utils';
import {
  ActiveDRPoliciesParam,
  ICompany,
  IDrawRequest,
  IProjectChecklist,
  PermissionNamesEnums,
  PoliciesItemListLocal,
  PoliciesTypeEnum,
  PolicyListSourceEnums,
  QueryNamesEnums,
  TemplateChecklistParam,
  TPolicies,
} from '@interfaces';
import {
  getCompanyPoliciesTemplates,
  getDrawRequest,
  getMyCompany,
  getProjectDrawRequestsList,
  updateActiveRequestPolicies,
  updateChecklistTemplate,
  updateProjectPoliciesTemplate,
} from '@globalService';
import { PermissionsContext, SettingsContext, useGraphQuery } from '@context';
import { ControllerInterface } from './interface';
import { PoliciesTypeMap, TEAM_ROLES } from '@constants';

export const usePoliciesContainer = ({
  assigneeRole,
  policies,
  editMode,
  source,
}: {
  assigneeRole: string;
  policies: TPolicies;
  editMode: PolicyListSourceEnums;
  source: PolicyListSourceEnums;
}): ControllerInterface => {
  const { projectId } = useParams();
  const { pathname } = useLocation();
  const match = matchPath('/projects/:projectId/:tab/*', pathname);
  const tabPathname = match?.params['*']?.split('/')[0];
  const drawRequestId = match?.params['*']?.split('/')[1];
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSafeSnackbar();
  const { permissions } = useContext(PermissionsContext);
  const { isCurrentProjectArchived, isCurrentProjectActive } = useContext(SettingsContext);

  const isProjectPoliciesTab = useMemo(() => isProjectPolicies(tabPathname), [tabPathname]);

  const project = useGraphQuery({
    type: QueryNamesEnums.GET_PROJECT,
    keys: ['is_budget_locked'],
    args: { project_id: projectId },
    options: {
      skip: !isProjectPoliciesTab,
    },
  });

  const isProjectActivationPolicy = useMemo(
    () =>
      isProjectPoliciesTab &&
      (isCurrentProjectActive ||
        isCurrentProjectArchived ||
        (checkIsOwner(assigneeRole) && project.data.is_budget_locked)),
    [
      isProjectPoliciesTab,
      isCurrentProjectActive,
      isCurrentProjectArchived,
      project.data,
      assigneeRole,
    ],
  );

  const isTemplate = useMemo(
    () =>
      isProjectPoliciesTab
        ? (!isCurrentProjectActive && !isCurrentProjectArchived && !checkIsOwner(assigneeRole)) ||
          !project.data.is_budget_locked
        : !drawRequestId,
    [
      isProjectPoliciesTab,
      isCurrentProjectActive,
      isCurrentProjectArchived,
      drawRequestId,
      project.data,
      assigneeRole,
    ],
  );

  const drawRequestQuery = useQuery(
    [QueryNamesEnums.GET_DRAW_REQUEST, { projectId, drawRequestId }],
    getDrawRequest.bind(this, { projectId, drawRequestId }),
    { enabled: Boolean(drawRequestId) },
  );
  const drawRequestData = drawRequestQuery.data;

  const companyQuery = useQuery<ICompany, Error>(
    [QueryNamesEnums.GET_MY_COMPANY],
    getMyCompany.bind(this),
  );

  const projectRequestsQuery = useQuery<{ results: IDrawRequest[] }, Error>(
    [QueryNamesEnums.GET_PROJECT_DRAW_REQUEST_LIST, { projectId }],
    getProjectDrawRequestsList.bind(this, projectId),
  );

  const companyPoliciesTemplateQuery = useQuery<IProjectChecklist[], Error>(
    [QueryNamesEnums.GET_COMPANY_POLICIES_TEMPLATES, { companyId: companyQuery.data?.id }],
    getCompanyPoliciesTemplates.bind(this, companyQuery.data?.id),
    {
      enabled:
        Boolean(companyQuery.data?.id) &&
        isAllowed(PermissionNamesEnums.POLICIES_EDIT, permissions),
    },
  );

  const projectRequestsData = useMemo(() => projectRequestsQuery.data, [projectRequestsQuery.data]);

  const isEditable = useMemo(() => {
    // policies list is not editable if it doesn't exist (no policies list id)
    if (!policies?.id || !projectRequestsData || (!isTemplate && !drawRequestData)) return false;

    // project policies are not editable if project is archived or active
    if (isProjectActivationPolicy) return false;

    // DR policies are not editable for approved and completed DRs
    if (isRequestCompleted(drawRequestData?.status) || isRequestApproved(drawRequestData?.status))
      return false;

    // borrower policies are not editable if DR is completed or approved
    return !isTemplate && assigneeRole === TEAM_ROLES.Owner
      ? isRequestDraft(drawRequestData?.status) || isRequestInReview(drawRequestData?.status)
      : true;
  }, [isProjectActivationPolicy, projectRequestsData, drawRequestData, policies, isTemplate]);

  const isTemplateSelectVisible = useMemo(() => {
    // project policies are not editable if project is archived or active
    if (isProjectActivationPolicy) return false;

    return isAllowed(PermissionNamesEnums.POLICIES_EDIT, permissions);
  }, [projectRequestsData, isProjectActivationPolicy, permissions]);

  const updatePoliciesItems = useMutation<Response, Error, TemplateChecklistParam>(
    updateChecklistTemplate,
    {
      onSuccess: () => {
        queryClient.invalidateQueries([
          QueryNamesEnums.GET_PROJECT_CHECKLIST_TEMPLATES,
          { projectId },
        ]);
      },
      onError: (error) => {
        enqueueSnackbar(error.message, { variant: 'error' });
      },
    },
  );

  const updateProjectPoliciesItems = useMutation<Response, Error, TemplateChecklistParam>(
    updateProjectPoliciesTemplate,
    {
      onSuccess: () => {
        queryClient.invalidateQueries([QueryNamesEnums.GET_PROJECT_CHECKLIST, { projectId }]);
      },
      onError: (error) => {
        enqueueSnackbar(error.message, { variant: 'error' });
      },
    },
  );

  const savePoliciesList = useCallback(
    (policiesId: string) =>
      ({ items }: PoliciesItemListLocal) => {
        const itemsList = items.map((item, index) => ({
          name: { name: item.label },
          id: item.id,
          index,
          ...(item.type && { type: item.type }),
          ...(item.created_by_team && { created_by_team: item.created_by_team }),
          description: item.description,
          is_custom: item.is_custom,
        }));

        if (isProjectPoliciesTab) {
          updateProjectPoliciesItems.mutateAsync({
            projectId,
            checklistId: policiesId,
            policiesParams: { items: itemsList },
          });
          return;
        }

        if (drawRequestId) {
          updateActiveDRPoliciesItems.mutateAsync({
            projectId,
            drawRequestId,
            checklistId: policies.id,
            policiesParams: { items: itemsList },
          });
          return;
        }

        updatePoliciesItems.mutateAsync({
          projectId,
          checklistId: policiesId,
          policiesParams: { items: itemsList },
        });
      },
    [isProjectPoliciesTab, projectId, drawRequestId],
  );

  const isFirstDrawTab = tabPathname === PoliciesTypeEnum.FIRST_DRAW;

  const updateActiveDRPoliciesItems = useMutation<Response, Error, ActiveDRPoliciesParam>(
    updateActiveRequestPolicies,
    {
      onSuccess: () => {
        queryClient.invalidateQueries([
          QueryNamesEnums.GET_DRAW_REQUEST_ITEM_CHECKLIST,
          { projectId, drawRequestId },
        ]);
      },
      onError: (error) => {
        enqueueSnackbar(error.message, { variant: 'error' });
      },
    },
  );

  const updateActiveDRPolicies = useCallback(
    ({ value }) => {
      const policiesTemplate = companyPoliciesTemplateQuery.data?.find((o) => o.id === value);

      if (!policiesTemplate || !policies) return;

      const policiesParams = {
        items: [
          ...policiesTemplate.items.map((item, index) => ({
            name: { name: item.name },
            index,
            ...(item.type && { type: item.type }),
            description: item.description,
            is_custom: item.is_custom,
          })),
          ...(policies.items
            ?.filter((o) => Boolean(o.created_by_team))
            .map((o) => ({ name: { name: o.label }, created_by_team: o.created_by_team })) || []),
        ],
        template: policiesTemplate.id,
        assignee_role: policiesTemplate.assignee_role,
      };

      updateActiveDRPoliciesItems.mutateAsync({
        projectId,
        drawRequestId,
        checklistId: policies.id,
        policiesParams,
      });
    },
    [companyPoliciesTemplateQuery.data, policies],
  );

  const modalText =
    'This checklist will be reset to default, but it will not affect items created by your team.';

  const showResetToDefault = useMemo(
    () => editMode !== source && !isTemplate && isEditable,
    [isTemplate, isEditable, editMode],
  );

  const templatesList = useMemo(() => {
    const type = isDrawRequest(drawRequestData)
      ? drawRequestData?.counter_per_request_type === 1
        ? PoliciesTypeMap[PoliciesTypeEnum.FIRST_DRAW]
        : PoliciesTypeMap[PoliciesTypeEnum.FUTURE_DRAWS]
      : PoliciesTypeMap[PoliciesTypeEnum.FUTURE_CHANGES];
    return (
      companyPoliciesTemplateQuery.data?.filter(
        (o) => o.assignee_role === assigneeRole && o.type === type,
      ) || []
    );
  }, [companyPoliciesTemplateQuery.data, drawRequestData, assigneeRole]);

  return {
    isEditable,
    isTemplate,
    isCurrentProjectArchived,
    savePoliciesList,
    isTemplateSelectVisible,
    isFirstDrawTab,
    updateActiveDRPolicies,
    modalText,
    showResetToDefault,
    templatesList,
    isProjectActivation: isProjectPoliciesTab,
    isLoading:
      updateProjectPoliciesItems.isLoading ||
      updateActiveDRPoliciesItems.isLoading ||
      updatePoliciesItems.isLoading,
  };
};
