import {
  useMutation,
  UseMutationResult,
  useQuery,
  useQueryClient,
  UseQueryResult,
} from 'react-query';
import { useParams } from 'react-router-dom';

import {
  DeleteAllListItemsParam,
  ErrorDual,
  IDrawRequest,
  IMilestoneTotal,
  IProjectMilestone,
  MutationKeyEnum,
  QueryNamesEnums,
} from '@interfaces';
import {
  deleteProjectBuildingModels,
  deleteProjectMilestones,
  getProjectDrawRequestsList,
  getProjectMilestonesGroupsList,
  getProjectMilestonesList,
} from '@globalService';
import { parsePathErrorDual, usePHBGrouping, Override } from '@utils';
import { useSafeSnackbar } from '@hooks';
import { useGraphQuery } from '@context';

export type UseGetQueriesInterface = Override<
  ReturnType<typeof useGetQueries>,
  {
    drawRequests: IDrawRequest[];
    projectMilestonesTotalsQuery: UseQueryResult<
      { results: IProjectMilestone[]; totals: IMilestoneTotal },
      Error
    >;
    lineItemsQuery: UseQueryResult<{ results: IProjectMilestone[]; count: number }, Error>;
    modelsQuery: UseQueryResult<{ results: IProjectMilestone[]; count: number }, Error>;
    unitsQuery: UseQueryResult<{ results: IProjectMilestone[]; count: number }, Error>;
    deleteBudgetMutation: UseMutationResult<Response, ErrorDual, DeleteAllListItemsParam>;
    deleteAllModelsMutation: UseMutationResult<Response, ErrorDual, { project: string }>;
  }
>;

export const useGetQueries = ({ setIsDeleteBudgetPopupOpen, filterKey, typeFilterValue }) => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSafeSnackbar();

  const query = '{*}';
  const totalsQuery = '{totals}';
  const { projectId } = useParams();
  const { unitLineItemGrouping, lineItemUnitGrouping, modelUnitsGrouping } = usePHBGrouping();

  const project = useGraphQuery({
    type: QueryNamesEnums.GET_PROJECT,
    keys: ['id', 'retainage_rate', 'status'],
    args: { project_id: projectId },
  })?.data;

  const drawRequestsQuery = useQuery<{ results: IDrawRequest[] }, Error>(
    [QueryNamesEnums.GET_PROJECT_DRAW_REQUEST_LIST, { projectId }],
    getProjectDrawRequestsList.bind(this, projectId),
  );

  const drawRequests = drawRequestsQuery.data?.results;

  const projectMilestonesTotalsQuery = useQuery<
    { results: IProjectMilestone[]; totals: IMilestoneTotal },
    Error
  >(
    [QueryNamesEnums.GET_PROJECT_MILESTONES, { projectId, query: totalsQuery }],
    getProjectMilestonesList.bind(this, { projectId, query: totalsQuery }),
    { enabled: Boolean(projectId) },
  );

  const lineItemsQuery = useQuery<{ results: IProjectMilestone[]; count: number }, Error>(
    [
      QueryNamesEnums.GET_PROJECT_MILESTONES,
      {
        projectId,
        query,
        groupBy: lineItemUnitGrouping,
        typeKeys: typeFilterValue,
      },
    ],
    getProjectMilestonesGroupsList.bind(this, {
      projectId,
      query,
      groupBy: lineItemUnitGrouping,
      typeKeys: typeFilterValue,
    }),
    {
      enabled: Boolean(projectId && lineItemUnitGrouping),
    },
  );

  const modelsQuery = useQuery<{ results: IProjectMilestone[]; count: number }, Error>(
    [
      QueryNamesEnums.GET_PROJECT_MILESTONES,
      {
        projectId,
        query,
        groupBy: modelUnitsGrouping,
        filterKey,
      },
    ],
    getProjectMilestonesGroupsList.bind(this, {
      projectId,
      query,
      groupBy: modelUnitsGrouping,
      filterKey,
    }),
    {
      enabled: Boolean(projectId && modelUnitsGrouping),
    },
  );

  const unitsQuery = useQuery<{ results: IProjectMilestone[]; count: number }, Error>(
    [
      QueryNamesEnums.GET_PROJECT_MILESTONES,
      {
        projectId,
        query,
        groupBy: unitLineItemGrouping,
        filterKey,
      },
    ],
    getProjectMilestonesGroupsList.bind(this, {
      projectId,
      query,
      groupBy: unitLineItemGrouping,
      filterKey,
    }),
    { enabled: Boolean(projectId && unitLineItemGrouping) },
  );

  const deleteBudgetMutation = useMutation<Response, ErrorDual, DeleteAllListItemsParam>(
    deleteProjectMilestones,
    {
      mutationKey: MutationKeyEnum.MILESTONE_DELETE,
      onSuccess: () => {
        queryClient.invalidateQueries([QueryNamesEnums.GET_PROJECT_MILESTONES, { projectId }]);
      },
      onError: (error) => {
        enqueueSnackbar(error.message, { variant: 'error' });
      },
      onSettled: () => {
        setIsDeleteBudgetPopupOpen(false);
      },
    },
  );

  const deleteAllModelsMutation = useMutation<Response, ErrorDual, { project: string }>(
    deleteProjectBuildingModels,
    {
      onSuccess: () => {
        queryClient.invalidateQueries([QueryNamesEnums.GET_PROJECT_BUILDING_MODELS, { projectId }]);
        queryClient.invalidateQueries([QueryNamesEnums.GET_PROJECT_BUILDING, { projectId }]);
        queryClient.invalidateQueries([QueryNamesEnums.GET_PROJECT_MILESTONE_TAGS, { projectId }]);
        queryClient.invalidateQueries([QueryNamesEnums.GET_PROJECT_MILESTONES, { projectId }]);
      },
      onError: (error) => {
        enqueueSnackbar(parsePathErrorDual(error), { variant: 'error' });
      },
    },
  );

  return {
    project,
    drawRequests,
    projectMilestonesTotalsQuery,
    lineItemsQuery,
    modelsQuery,
    unitsQuery,
    deleteBudgetMutation,
    deleteAllModelsMutation,
  };
};
