import { useQueries, useQuery } from 'react-query';
import first from 'lodash/first';
import {
  getBasicUrl,
  getBorrower,
  getHookState,
  getMilestoneTagId,
  getPoliciesByRole,
  getPreviousRequestsByID,
  getTeamRole,
  Override,
} from '@utils';

import {
  HookState,
  IActivityLogItem,
  IDocument,
  IDrawRequest,
  IInspection,
  IMilestone,
  IMilestonePhotos,
  IProjectComment,
  ITeam,
  MilestoneTagsTypesEnums,
  QueryNamesEnums,
  TPolicies,
  TTeamRole,
} from '@interfaces';

import { useParams } from 'react-router-dom';
import { ACTIVE_COMMENTS_TYPES, LineItemFilterValues, TEAM_ROLES } from '@constants';
import {
  getCommentsWithPagination,
  getDrawRequest,
  getDrawRequestAuditLog,
  getDrawRequestDocumentsList,
  getDrawRequestInspectionsList,
  getDrawRequestItemChecklist,
  getDrawRequestMilestones,
  getDrawRequestPhotos,
  getProjectBuildings,
  getProjectDrawRequestsList,
  getProjectMilestoneTags,
  getProjectPropertyDetailsNames,
  getProjectTeams,
} from '@globalService';
import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { AuthContext, SettingsContext, useGraphQuery } from '@context';

export type ControllerInterface = Override<
  ReturnType<typeof useReport>,
  {
    state: HookState;
    drawRequestData: IDrawRequest;
    previousDrawRequest: IDrawRequest[];
    customerLogo: string;
    borrower: ITeam;
    photos: IMilestonePhotos[];
    documents: { results: IDocument[]; count: number };
    auditLog: IActivityLogItem[];
    customerName: string;
    inspectionList: IInspection[];
    comments: IProjectComment[];
    policies: TPolicies;
    borrowerPolicies: TPolicies;
    printRef: React.MutableRefObject<HTMLElement>;
    lastInspection: IInspection;
    renderedPage: React.MutableRefObject<number>;
    milestones: IMilestone[];
    setOtherModuleLoaded: React.Dispatch<React.SetStateAction<number>>;
    setImagesLoaded: React.Dispatch<React.SetStateAction<boolean>>;
    modelsUnits: IMilestone[];
  }
>;

export const useReport = () => {
  // TODO: rename after naming change
  const printRef = useRef<HTMLElement>(null);
  const { projectId, requestId } = useParams();
  const { user } = useContext(AuthContext);
  const renderedPage = useRef<number>(0);
  const [dataLoaded, setDataLoaded] = useState<boolean>(false);
  const [otherModulesLoaded, setOtherModuleLoaded] = useState<number>(0);
  const [imagesLoaded, setImagesLoaded] = useState<boolean>(false);
  const { isPHBProject } = useContext(SettingsContext);

  const commentsUrl = useMemo(
    () =>
      `${getBasicUrl({
        requestType: 'get',
        projectId,
        requestId,
        milestoneId: null,
        inspectionId: null,
      })}?tag=Report&include_children=true&limit=50`,
    [projectId, requestId],
  );

  const teamRole = getTeamRole(user);

  const project = useGraphQuery({
    type: QueryNamesEnums.GET_PROJECT,
    keys: [
      'name',
      'customer_name',
      'lender_company_logo',
      'status',
      'status_change_reason',
      'loan',
      'address',
      'thumb_representations',
      'estimated_completion_date',
      'start_date',
      'estimated_start_date',
      'scope_of_work',
      'retainage_rate',
      'duration',
      'property_existing_type',
      'property_proposed_type',
    ],
    args: { project_id: projectId },
    options: {
      skip: !projectId,
    },
  });

  const requestedDataQueries = useQueries([
    {
      queryKey: [QueryNamesEnums.GET_DRAW_REQUEST, { projectId, requestId }],
      queryFn: getDrawRequest.bind(this, { projectId, drawRequestId: requestId }),
    },
    {
      queryKey: [QueryNamesEnums.GET_DRAW_REQUEST_PHOTOS, { projectId, drawRequestId: requestId }],
      queryFn: getDrawRequestPhotos.bind(this, projectId, requestId),
    },
    {
      queryKey: [
        QueryNamesEnums.GET_DRAW_REQUEST_DOCUMENTS,
        { projectId, drawRequestId: requestId },
      ],
      queryFn: getDrawRequestDocumentsList.bind(this, {
        projectId,
        drawRequestId: requestId,
        stringQueryParams: 'limit=20',
      }),
    },
    {
      queryKey: [QueryNamesEnums.GET_DRAW_REQUEST_AUDIT_LOG, { drawRequestId: requestId }],
      queryFn: getDrawRequestAuditLog.bind(this, requestId),
    },
    {
      queryKey: [QueryNamesEnums.GET_PROJECT_DRAW_REQUEST_LIST, { projectId }],
      queryFn: getProjectDrawRequestsList.bind(this, projectId),
    },
    {
      queryKey: [
        QueryNamesEnums.GET_DRAW_REQUEST_INSPECTIONS,
        { projectId, drawRequestId: requestId },
      ],
      queryFn: getDrawRequestInspectionsList.bind(this, { projectId, drawRequestId: requestId }),
    },
    {
      queryKey: [QueryNamesEnums.GET_PROJECT_TEAMS, { projectId, companyId: null }],
      queryFn: getProjectTeams.bind(this, { projectId }),
    },
    {
      queryKey: [QueryNamesEnums.GET_PROJECT_COMMENTS, { url: commentsUrl }],
      queryFn: getCommentsWithPagination.bind(this, {
        url: commentsUrl,
      }),
    },
    {
      queryKey: [
        QueryNamesEnums.GET_DRAW_REQUEST_ITEM_CHECKLIST,
        { projectId, drawRequestId: requestId },
      ],
      queryFn: getDrawRequestItemChecklist.bind(this, projectId, requestId),
      enabled: Boolean(requestId),
    },
    {
      queryKey: [
        QueryNamesEnums.GET_DRAW_REQUEST_MILESTONES,
        {
          projectId,
          drawRequestId: requestId,
          filterKey: isPHBProject
            ? LineItemFilterValues.HORIZONTAL_SOFT_COST.filterKey
            : LineItemFilterValues.ALL.filterKey,
        },
      ],
      queryFn: getDrawRequestMilestones.bind(this, {
        projectId,
        drawRequestId: requestId,
        filterKey: isPHBProject
          ? LineItemFilterValues.HORIZONTAL_SOFT_COST.filterKey
          : LineItemFilterValues.ALL.filterKey,
      }),
    },
    {
      queryKey: [QueryNamesEnums.GET_PROJECT_MILESTONE_TAGS, { projectId }],
      queryFn: getProjectMilestoneTags.bind(this, projectId),
    },
    {
      queryKey: [QueryNamesEnums.GET_PROJECT_PROPERTY_DETAILS_NAMES],
      queryFn: getProjectPropertyDetailsNames,
    },
    {
      queryKey: [QueryNamesEnums.GET_PROJECT_BUILDING, { projectId }],
      queryFn: getProjectBuildings.bind(this, projectId),
    },
  ]);

  const modelId = useMemo(
    () => getMilestoneTagId(requestedDataQueries[10]?.data?.results, MilestoneTagsTypesEnums.MODEL),
    [requestedDataQueries[10].data],
  );
  const unitId = useMemo(
    () => getMilestoneTagId(requestedDataQueries[10]?.data?.results, MilestoneTagsTypesEnums.UNIT),
    [requestedDataQueries[10].data],
  );

  const drawRequestModelsUnitsQuery = useQuery<{ results: IMilestone[] }, Error>(
    [
      QueryNamesEnums.GET_DRAW_REQUEST_MILESTONES,
      { projectId, drawRequestId: requestId, groupBy: `${modelId},${unitId}` },
    ],
    getDrawRequestMilestones.bind(this, {
      projectId,
      drawRequestId: requestId,
      groupBy: `${modelId},${unitId}`,
    }),
    { enabled: Boolean(isPHBProject && modelId && unitId) },
  );

  const comments = requestedDataQueries[7].data?.results?.filter(
    (comment: IProjectComment) =>
      comment.message && ACTIVE_COMMENTS_TYPES.includes(comment.content_type),
  );

  const teams = requestedDataQueries[6].data?.results;
  const borrower = useMemo(() => getBorrower(teams), [teams]);

  const previousDrawRequest = useMemo(
    () => getPreviousRequestsByID(requestedDataQueries[4].data?.results, requestId),
    [requestedDataQueries[4].data?.results, requestId],
  );

  const setMetaForReady = useCallback(() => {
    const newMetaTag = document.createElement('meta');
    newMetaTag.name = 'report-ready';
    newMetaTag.content = 'true';
    setTimeout(() => {
      document.head.appendChild(newMetaTag);
    }, 1000);
  }, []);

  useEffect(() => {
    if (getHookState([project, ...requestedDataQueries]) === HookState.SUCCESS) {
      setDataLoaded(true);
    }
  }, [project, requestedDataQueries]);

  useEffect(() => {
    if (
      dataLoaded &&
      (requestedDataQueries[1].data.length == 0 || imagesLoaded) &&
      otherModulesLoaded == 2 &&
      (drawRequestModelsUnitsQuery.data?.results || drawRequestModelsUnitsQuery.isIdle)
    ) {
      setMetaForReady();
    }
  }, [
    requestedDataQueries,
    otherModulesLoaded,
    imagesLoaded,
    drawRequestModelsUnitsQuery,
    dataLoaded,
  ]);

  const milestones = useMemo(
    () => requestedDataQueries[9].data?.results,
    [requestedDataQueries[9].data],
  );

  return {
    state: getHookState([project, ...requestedDataQueries]),
    drawRequestData: requestedDataQueries[0].data,
    project: project.data,
    photos: requestedDataQueries[1].data,
    documents: requestedDataQueries[2].data,
    auditLog: requestedDataQueries[3].data?.results,
    inspectionList: requestedDataQueries[5].data?.results,
    previousDrawRequest,
    customerName: project.data?.customer_name,
    customerLogo: project.data?.lender_company_logo || user?.active_team?.company?.logo,
    borrower,
    comments,
    policies: getPoliciesByRole({ policies: requestedDataQueries[8].data, teamRole }),
    borrowerPolicies: getPoliciesByRole({
      policies: requestedDataQueries[8].data,
      teamRole: TEAM_ROLES.Owner as TTeamRole,
    }),
    printRef,
    lastInspection: first(requestedDataQueries[5].data?.results),
    modelsUnits: drawRequestModelsUnitsQuery.data?.results,
    renderedPage,
    milestones,
    setOtherModuleLoaded,
    setImagesLoaded,
  };
};
