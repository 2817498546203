import { useParams } from 'react-router-dom';
import { useGraphQuery } from '@context';
import { QueryNamesEnums } from '@interfaces';
import { Override } from '@utils';
export type ControllerInterface = Override<
  ReturnType<typeof useProjectNameLabel>,
  NonNullable<unknown>
>;

export const useProjectNameLabel = ({ prId }: { prId?: string }) => {
  const { projectId } = useParams();
  const projectIdToUse = prId || projectId;
  const project = useGraphQuery({
    type: QueryNamesEnums.GET_PROJECT,
    keys: ['id', 'name', 'status', 'status_change_reason'],
    args: { project_id: projectIdToUse },
  });

  return {
    project: project.data,
  };
};
