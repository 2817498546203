import { useQuery, useQueryClient } from 'react-query';
import { useMemo } from 'react';

import {
  DocumentContentTypeEnum,
  DocumentTypeEnum,
  IDocumentType,
  IProject,
  QueryNamesEnums,
  TransloaditTemplateEnum,
} from '@interfaces';
import { getProjectDocumentTypeByName } from '@globalService';
import { IFileUploader, useFilesUploader } from '@hooks';

interface ControllerInterface {
  fileUploader: IFileUploader;
  startUploading: () => void;
  thumbTypeId: string;
  closeFileUploader: () => void;
  projectLocation: {
    lat: number;
    lng: number;
  };
  projectImage: string;
}

export const useProjectImage = ({
  project,
}: {
  project: Pick<IProject, 'id' | 'thumb_representations' | 'address'>;
}): ControllerInterface => {
  const queryClient = useQueryClient();
  const fileUploader = useFilesUploader();

  const { data: documentThumbTypeData } = useQuery<IDocumentType, Error>(
    [QueryNamesEnums.GET_PROJECT_DOCUMENT_TYPES_BY_NAME, { name: DocumentTypeEnum.THUMB }],
    () => getProjectDocumentTypeByName(DocumentTypeEnum.THUMB),
  );

  const startUploading = () => {
    const fields = {
      content_type: DocumentContentTypeEnum.PROJECT,
      object_id: project?.id,
    };

    fileUploader.uploadMedia({
      fields,
      templateType: TransloaditTemplateEnum.DOCUMENTS,
    });
  };

  const closeFileUploader = () => {
    queryClient.invalidateQueries(QueryNamesEnums.GET_PROJECTS_LIST);
    fileUploader.filesUploaderClose();
  };

  const projectImage = useMemo(() => {
    return (
      project?.thumb_representations?.thumb?.url ||
      project?.thumb_representations?.original?.url ||
      ''
    );
  }, [project]);

  const projectLocation = useMemo(() => {
    return {
      lat: project?.address?.latitude,
      lng: project?.address?.longitude,
    };
  }, [project]);

  return {
    fileUploader,
    startUploading,
    thumbTypeId: documentThumbTypeData?.id,
    closeFileUploader,
    projectLocation,
    projectImage,
  };
};
