import { FilterObject } from '../interface';
import { QueryNamesEnums } from '@interfaces';

export const loansTypeFilter: FilterObject = {
  title: 'Loan type',
  filterKey: 'loan_type',
  needToUpdateUrl: true,
  needToUpdateSetting: true,
  getDataParamsPaginated: (pagination, q, skip) => ({
    type: QueryNamesEnums.GET_UI_SETTINGS_LOANS_TYPE,
    keys: ['name_display', 'name'],
    args: {
      query: '',
      pagination,
      q,
    },
    options: {
      skip,
      paginate: true,
    },
  }),
  strictSerialize: (data) =>
    data?.map((item) => ({
      value: item.name,
      label: item.name_display,
    })),
  cypressSelector: 'filters__loan_type',
};
