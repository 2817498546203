import React, { useContext, useMemo } from 'react';
import { Stack, Tooltip, Typography } from '@mui/material';

import { LabelAndValue, StatusChip, StyledLink } from '@components';
import { SettingsContext } from '@context';
import { statusMap } from '@constants';
import { useProjectNameLabel } from './controller';
interface ComponentProps {
  showStatusChip?: boolean;
  prId?: string;
}
const ProjectNameLabel = ({ showStatusChip = false, prId }: ComponentProps) => {
  const {
    settings: { display: staticStatuses },
    isCurrentProjectActive,
  } = useContext(SettingsContext);
  const { project } = useProjectNameLabel({ prId });
  const statusValue = useMemo(
    () => (staticStatuses ? statusMap(project?.status, staticStatuses, 'project') : {}),
    [project?.status, staticStatuses],
  );

  return (
    <Stack direction="row" spacing={1}>
      <LabelAndValue label="Project:" size="body2" marginRightAfterLabel={false}>
        <StyledLink to={`/projects/${project?.id}`}>{project?.name}</StyledLink>
      </LabelAndValue>
      {showStatusChip && statusValue && (
        <Tooltip
          title={
            isCurrentProjectActive ? (
              ''
            ) : (
              <>
                Reason:{' '}
                <Typography
                  variant="body3"
                  dangerouslySetInnerHTML={{ __html: project?.status_change_reason }}
                />
              </>
            )
          }
        >
          <span>
            <StatusChip
              color={statusValue.color}
              backgroundColor={statusValue.backgroundColor}
              label={statusValue.text}
              size="medium"
            />
          </span>
        </Tooltip>
      )}
    </Stack>
  );
};

export default ProjectNameLabel;
