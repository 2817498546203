import React, { ChangeEvent } from 'react';
import { Grid2, Stack, TextField, Typography } from '@mui/material';
import {
  CustomAutocomplete,
  CustomTextField,
  IsProductionBuildCheckbox,
  ProjectImage,
  StyledBox,
  TabWrapperWithLeaveWarning,
} from '@components';
import { mainLevelOptions, nestedLevelOptions, states } from '@constants';
import * as Controller from './controller';
import PropertyDetails from '../../NewProjectV2/components/PropertyDetails';

const GeneralSettingsV2 = ({ isAllProjectDetailsDisabled, isLimitedProjectDetailsDisabled }) => {
  const controller = Controller.useGeneralSettings({
    editable: !isAllProjectDetailsDisabled && !isLimitedProjectDetailsDisabled,
  });
  const {
    projectDetailsFields,
    handleSubmitClick,
    isSubmitting,
    exitPath,
    exitStrategiesList,
    projectTypesList,
    propertyTypesList,
    propertyDetails,
    isAllProjectDataValid,
    isCreateByModels,
    number_of_units,
    main_level_name,
    nested_level_name,
    isProductionBuildProject,
    updateIsProductionBuildProject,
    propertyRows,
    setPropertyRows,
    isActiveProject,
    isUpdated,
    project,
  } = controller;
  const {
    projectName,
    scopeOfWork,
    exitStrategy,
    projectType,
    existingPropertyType,
    proposedPropertyType,
    address_1,
    city,
    state,
    zipCode,
  } = projectDetailsFields;

  return (
    <TabWrapperWithLeaveWarning
      onSave={handleSubmitClick}
      isUpdated={isUpdated}
      isDisabled={!isAllProjectDataValid}
      tabValue="general"
      path={exitPath}
      isSubmitting={isSubmitting}
      currentPagePathname={'/projects/*/settings/general'}
    >
      <StyledBox>
        <Stack spacing={2}>
          <Stack direction="row" spacing={2}>
            <ProjectImage
              project={project}
              disabled={isAllProjectDetailsDisabled && isLimitedProjectDetailsDisabled}
              source="project__settings__general__project_image"
            />
            <Stack sx={{ flex: 1 }} spacing={2}>
              <Grid2 container spacing={2}>
                <Grid2 size={{ xs: 12, lg: 8 }}>
                  <CustomTextField
                    field={projectName}
                    label="Project name"
                    required
                    inputProps={{ 'data-cy': 'project__overview__name__input' }}
                    disabled={isAllProjectDetailsDisabled}
                  />
                </Grid2>
              </Grid2>

              <Grid2 container spacing={2}>
                <Grid2 size={{ xs: 12, lg: 4 }}>
                  <CustomTextField
                    field={address_1}
                    label="Street address"
                    required
                    inputProps={{
                      'data-cy': `project__settings__general__address_1_input`,
                    }}
                    disabled={isAllProjectDetailsDisabled}
                  />
                </Grid2>
                <Grid2 size={{ xs: 12, lg: 4 }}>
                  <CustomTextField
                    field={city}
                    label="City"
                    required
                    inputProps={{
                      'data-cy': `project__settings__general__city_input`,
                    }}
                    disabled={isAllProjectDetailsDisabled}
                  />
                </Grid2>
              </Grid2>
              <Grid2 container spacing={2}>
                <Grid2 size={{ xs: 12, lg: 4 }}>
                  <CustomAutocomplete
                    field={state}
                    label="State"
                    options={states}
                    required
                    inputProps={{
                      'data-cy': `project__settings__general__state_input`,
                    }}
                    disabled={isAllProjectDetailsDisabled}
                  />
                </Grid2>
                <Grid2 size={{ xs: 12, lg: 4 }}>
                  <CustomTextField
                    field={zipCode}
                    label="ZIP code"
                    validationText="Please enter a valid 5-digit zip code"
                    required
                    inputProps={{
                      'data-cy': `project__settings__general__zip_code_input`,
                    }}
                    disabled={isAllProjectDetailsDisabled}
                  />
                </Grid2>
              </Grid2>
            </Stack>
          </Stack>
        </Stack>
      </StyledBox>
      <StyledBox>
        <Typography variant="h3" sx={{ mb: 2 }}>
          Properties
        </Typography>
        <Stack spacing={2} direction="row" sx={{ width: '100%' }}>
          <Stack spacing={2} sx={{ width: '100%' }}>
            <CustomAutocomplete
              field={exitStrategy}
              label="Exit strategy"
              options={exitStrategiesList}
              inputProps={{
                'data-cy': 'project__settings__general__exit_strategy__input',
              }}
              disabled={isAllProjectDetailsDisabled}
            />
            <CustomAutocomplete
              field={existingPropertyType}
              label="Property type (Existing)"
              options={propertyTypesList}
              inputProps={{
                'data-cy': 'project__settings__general__property_type_exisiting__input',
              }}
              disabled={isAllProjectDetailsDisabled}
            />
          </Stack>
          <Stack spacing={2} sx={{ width: '100%' }}>
            <CustomAutocomplete
              field={projectType}
              label="Project type"
              options={projectTypesList}
              required
              inputProps={{
                'data-cy': 'project__settings__general__project_type__input',
              }}
              disabled={isAllProjectDetailsDisabled}
            />

            <CustomAutocomplete
              field={proposedPropertyType}
              label="Property type (Proposed)"
              options={propertyTypesList}
              required
              inputProps={{
                'data-cy': 'project__settings__general__property_type_proposed__input',
              }}
              disabled={isAllProjectDetailsDisabled}
            />
          </Stack>
          <Stack spacing={2} sx={{ width: '100%' }}>
            <TextField
              data-cy="project__settings__general__scope_of_work__input"
              fullWidth
              variant="outlined"
              label="Scope of work"
              value={scopeOfWork.value || ''}
              sx={{ mb: '24px' }}
              multiline
              rows={3}
              onChange={(event: ChangeEvent<HTMLInputElement>) => scopeOfWork.handleChange(event)}
              disabled={isAllProjectDetailsDisabled && isLimitedProjectDetailsDisabled}
            />
          </Stack>
        </Stack>
        <Stack direction="row" sx={{ mt: 2 }} spacing={2} alignItems="flex-start">
          {isProductionBuildProject && (
            <CustomAutocomplete
              field={main_level_name}
              options={mainLevelOptions}
              label="Main level"
              freeSolo
              required
              handleTextFieldChange={(value: string) =>
                main_level_name.setValue({ name: value, name_display: value })
              }
              inputProps={{
                'data-cy': 'project__settings__general__main_level_name__input',
              }}
            />
          )}
          <CustomTextField
            field={number_of_units}
            label={`# of ${isProductionBuildProject ? main_level_name.value?.name_display || 'units' : 'units'}`}
            inputProps={{
              'data-cy': 'project__settings__general__units_number__input',
            }}
            sx={{ maxWidth: '50%' }}
          />
          {isProductionBuildProject && (
            <CustomAutocomplete
              field={nested_level_name}
              options={nestedLevelOptions}
              label="Nested level (Optional)"
              freeSolo
              handleTextFieldChange={(value: string) =>
                nested_level_name.setValue({ name: value, name_display: value })
              }
              inputProps={{
                'data-cy': 'project__settings__general__nested_level_name__input',
              }}
            />
          )}
          <IsProductionBuildCheckbox
            wrapperPadding={0}
            onChange={() => {
              updateIsProductionBuildProject(!isProductionBuildProject);
            }}
            label="Advanced structure"
            checked={isProductionBuildProject}
            disabled={isAllProjectDetailsDisabled || isActiveProject}
          />
        </Stack>
      </StyledBox>
      <PropertyDetails
        isCreateByModels={isCreateByModels}
        isProductionBuildProject={isProductionBuildProject}
        propertyRows={propertyRows}
        setPropertyRows={setPropertyRows}
        unitsNumber={Number(number_of_units.value)}
        propertyDetails={propertyDetails}
      />
    </TabWrapperWithLeaveWarning>
  );
};

export default GeneralSettingsV2;
