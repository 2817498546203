import { QueryNamesEnums, PermissionNamesEnums } from '@interfaces';
import { FilterObject } from '../interface';

export const servicesListAgencyFilter: FilterObject = {
  title: 'Other services provider',
  filterKey: 'service_agency',
  permissionKey: PermissionNamesEnums.INSPECTIONS_VIEW,
  needToUpdateUrl: true,
  needToUpdateSetting: true,
  getDataParamsPaginated: (pagination, q, skip) => ({
    type: QueryNamesEnums.GET_SERVICE_AGENCIES,
    keys: ['id', 'display_name'],
    args: { pagination, q },
    options: {
      skip,
      paginate: true,
    },
  }),
  strictSerialize: (data) =>
    data.map((item) => ({
      value: item.display_name,
      label: item.display_name,
    })),
  cypressSelector: 'filters__services_list_agency',
  withPagination: true,
};
