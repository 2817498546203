import { useContext, useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { PermissionsContext, useGraphQuery } from '@context';
import { rolesMap } from '@constants';
import { HookState, ITeam, PermissionNamesEnums, QueryNamesEnums } from '@interfaces';
import { getHookState, isCreatedProject, isAllowed, Override } from '@utils';
import { getProjectCompanies } from '@globalService';
import find from 'lodash/find';

export type ControllerInterface = Override<
  ReturnType<typeof useProjectPeople>,
  {
    state: HookState;
    projectQueryState: HookState;
    companies: ITeam[];
    goBack: () => void;
    goToAddBorrower: () => void;
    columns: string[];
    borrowerCompany: ITeam;
    investorCompany?: ITeam;
    isProjectStatusCreated: boolean;
    isAddBorrowerCompanyModalOpened: boolean;
    setAddBorrowerCompanyModalOpened: (value: boolean) => void;
  }
>;

export const useProjectPeople = () => {
  const { projectId } = useParams();
  const navigate = useNavigate();
  const { permissions } = useContext(PermissionsContext);
  const [isAddBorrowerCompanyModalOpened, setAddBorrowerCompanyModalOpened] = useState(false);

  const project = useGraphQuery({
    type: QueryNamesEnums.GET_PROJECT,
    keys: ['status', 'is_reallocation_enabled', 'coordinator', 'name'],
    args: { project_id: projectId },
  });

  const projectCompaniesData = useQuery<ITeam[], Error>(
    [QueryNamesEnums.GET_PROJECT_COMPANIES, { projectId }],
    getProjectCompanies.bind(this, projectId),
  );

  const columns = useMemo(
    () => [
      'name',
      'email',
      'phone',
      'teams',
      'lastOnline',
      ...(isAllowed(PermissionNamesEnums.DRAWREQUESTS_COORDINATOR_EDIT, permissions)
        ? ['coordinator']
        : []),
      'status',
      ...(isAllowed(PermissionNamesEnums.PROJECT_ONBOARDING, permissions) ? ['optionsMenu'] : []),
    ],
    [permissions],
  );

  const borrowerCompany = useMemo(() => {
    if (projectCompaniesData.data?.length)
      return find(projectCompaniesData.data, { role: rolesMap.OWNER });
    return null;
  }, [projectCompaniesData.data]);

  const investorCompany = useMemo(() => {
    if (projectCompaniesData.data?.length)
      return find(projectCompaniesData.data, { role: rolesMap.INVESTOR });
    return undefined;
  }, [projectCompaniesData.data]);

  const isProjectStatusCreated = useMemo(
    () => isCreatedProject(project.data?.status),
    [project.data?.status],
  );

  return {
    state: getHookState(projectCompaniesData),
    projectQueryState: getHookState(project),
    companies: projectCompaniesData.data || [],
    goBack: () => navigate(`/projects/${projectId}/overview/`),
    goToAddBorrower: () => navigate(`/projects/${projectId}/add-borrower/`),
    project: project.data,
    columns,
    borrowerCompany,
    investorCompany,
    isProjectStatusCreated,
    isAddBorrowerCompanyModalOpened,
    setAddBorrowerCompanyModalOpened,
  };
};
