import { currencyFormatter } from '@utils';
import { useGraphQuery } from '@context';
import { QueryNamesEnums } from '@interfaces';

export type ControllerInterface = {
  isLoading: boolean;
  budget: {
    prefundingCost: string;
    scheduleOfValues: string;
  };
  loan: {
    prefundingCost: string;
    totalLoanCommitment: string;
    constructionHoldback: string;
    borrowerEquity: string;
    scheduleOfValues: string;
  };
};

export const useBudgetSummary = (projectId: string): ControllerInterface => {
  const project = useGraphQuery({
    type: QueryNamesEnums.GET_PROJECT,
    keys: ['loan'],
    args: { project_id: projectId },
  });
  const loan = project.data?.loan;

  return {
    isLoading: project.isLoading,
    budget: {
      prefundingCost: currencyFormatter(loan?.funding_source_inactive_amount || 0),
      scheduleOfValues: currencyFormatter(loan?.funding_source_active_amount || 0),
    },
    loan: {
      prefundingCost: currencyFormatter(loan?.prefunding_cost || 0),
      totalLoanCommitment: currencyFormatter(loan?.total_construction_budget || 0),
      constructionHoldback: currencyFormatter(loan?.construction_holdback || 0),
      borrowerEquity: currencyFormatter(loan?.borrower_equity || 0),
      scheduleOfValues: currencyFormatter(loan?.post_funding_construction_budget || 0),
    },
  };
};
