import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { ProjectPaymentInfo } from '../draw-request/components';
import { RequestPaymentInfoV2 } from '../';

const PaymentsTab = () => {
  return (
    <Routes>
      <Route path="all" element={<ProjectPaymentInfo />} />
      <Route path="draw-requests/:drawRequestId" element={<RequestPaymentInfoV2 />} />
    </Routes>
  );
};

export default PaymentsTab;
