import { useMutation } from 'react-query';
import { useParams } from 'react-router-dom';

import { useReviewRequestInvalidation, useSafeSnackbar, useDayJsFormatter } from '@hooks';
import { getErrorText } from '@utils';
import { ErrorDual, MarkRequestAsPaidPayload } from '@interfaces';
import { markRequestAsPaid } from '@globalService';
import { DateFieldModel, StringFieldModel, useDateFieldModel, useStringFieldModel } from '@models';

interface ControllerInterface {
  handleMarkAsPaid: () => void;
  transactionId: StringFieldModel;
  transactionDate: DateFieldModel;
  isSubmitting: boolean;
}

export const useMarkAsPaid = ({ onClose, drawRequestId }): ControllerInterface => {
  const { getInitialValue } = useDayJsFormatter();
  const { enqueueSnackbar } = useSafeSnackbar();
  const { projectId } = useParams();
  const handleRequestReviewInvalidation = useReviewRequestInvalidation();
  const transactionId = useStringFieldModel({ initValue: '' });
  const transactionDate = useDateFieldModel({
    initValue: getInitialValue({}),
  });

  const markAsPaidMutation = useMutation<Response, ErrorDual, MarkRequestAsPaidPayload>(
    markRequestAsPaid,
    {
      onSuccess: () => {
        handleRequestReviewInvalidation({ projectId, drawRequestId });
      },
      onError: (error: ErrorDual) => {
        const errorText = getErrorText(error);
        enqueueSnackbar(errorText, { variant: 'error' });
      },
      onSettled: () => {
        onClose();
      },
    },
  );

  const handleMarkAsPaid = async () => {
    await markAsPaidMutation.mutateAsync({
      project: projectId,
      drawRequest: drawRequestId,
      settled_at: transactionDate.value,
      external_transaction_id: transactionId.value,
    });
  };

  return {
    handleMarkAsPaid,
    transactionId,
    transactionDate,
    isSubmitting: markAsPaidMutation.isLoading,
  };
};
